import React from 'react';
import BillRed from '../../assets/images/V2.1/BillRed.png';
import {
  Container,
  TopContainer,
  MainText,
  DescText,
  BottomContainer,
  LeftContainer,
  RightContainer,
  Title,
  Desc,
  Price,
  BulletList,
  LastText,
  BulletListContainer,
  PriceContainer,
  PriceSubText,
  PointLastText,
  LinkContainer,
} from './_components/PricePageStyle';
import { useHistory } from 'react-router';
import { serviceUrl } from '../../constants/urls';
import { useMediaQueryForMobile } from '../../hooks/useMediaQueryForMobile';
import { handleDownLoad } from '../SellerboxAppPage/SellerboxAppPage';
import { useStoreValue } from '../../contexts';

const PricePage = () => {
  const history = useHistory();
  const navigate = (url) => {
    history.push(url);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  const isMobile = useMediaQueryForMobile();
  const { isEnteredByNaver } = useStoreValue();
  return (
    <Container>
      <TopContainer>
        <img src={BillRed} width={64} height={64} />
        <MainText>이용요금</MainText>
        <DescText>셀러박스 플러스 멤버십의 요금을 확인하세요</DescText>
      </TopContainer>
      <BottomContainer isMobile={isMobile}>
        <LeftContainer isMobile={isMobile}>
          <div>
            <Title type={'free'}>Free</Title>
            <Desc type={'free'}>기본 기능은 무료로 사용할 수 있어요</Desc>
            <Price type={'free'} isMobile={isMobile}>
              0원
            </Price>
            <BulletList type={'free'}>
              <li>30분 간격 주문 수집</li>
              <li>AI 리뷰 답변 생성 하루 3회</li>
              <li>상품 순위 모니터링 1개</li>
            </BulletList>
          </div>
          <LastText>무료로 시작하세요!</LastText>
        </LeftContainer>
        <RightContainer isMobile={isMobile}>
          <div>
            <Title type={'plus'}>Plus</Title>
            <Desc type={'plus'}>모든 기능을 제한없이 사용할 수 있어요</Desc>
            <PriceContainer>
              <Price type={'plus'} isMobile={isMobile}>
                15,000원
              </Price>
              <PriceSubText>월 이용료 (VAT포함)</PriceSubText>
            </PriceContainer>
            <BulletListContainer>
              <BulletList type={'plus'}>
                <li>10분 간격 주문 수집</li>
                <li>AI 리뷰 답변 생성 무제한</li>
                <li>상품 순위 모니터링 10개</li>
              </BulletList>
            </BulletListContainer>
            <BulletList type={'free'}>
              <li>상품 발송 (주문접수, 송장입력)</li>
              <li>AI 리뷰 감정 분석</li>
              <li>AI 상품명 최적화</li>
              <li>AI 상세페이지 진단</li>
              <li>키워드 분석도구 ‘셀키’ 이용</li>
            </BulletList>
          </div>
          <LastText>
            셀러박스 App에서
            <br />
            <PointLastText onClick={() => navigate(serviceUrl)}>14일 무료체험</PointLastText>{' '}
            시작해보세요
          </LastText>
        </RightContainer>
        {isMobile && (
          <LinkContainer>
            <img
              src="images/mobile-home-google-download.png"
              onClick={() => {
                handleDownLoad('android', isEnteredByNaver);
              }}
              className="pointer"
              style={{ width: 152, height: 48 }}
              alt=""
            />
            <img
              src="images/mobile-home-apple-download.png"
              onClick={() => {
                handleDownLoad('ios', isEnteredByNaver);
              }}
              className="pointer"
              style={{ width: 152, height: 48 }}
              alt=""
            />
          </LinkContainer>
        )}
      </BottomContainer>
    </Container>
  );
};

export default PricePage;

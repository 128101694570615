import React from 'react';
import { WholePageWrapper } from '../SellerboxAppPageV1';
import useWindowSize from '../../hooks/useWindowSize';
import { Button } from '@mui/material';
import { useHistory } from 'react-router';
import font from '../../style/font';
import { colors } from '../../style';
import { signIn } from '../../constants/urls';
import { useStoreAction } from '../../contexts';

const NaverRegisterToken = () => {
  //Splash화면 대용으로 사용 가능 ( 초기에 접속하면 설정이나 다른 작업들을 진행 가능 )
  //모두 진행되면 signIn으로 페이지 전환
  const dimensions = useWindowSize();
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const { setNaverToken } = useStoreAction();
  localStorage.setItem('enteredByMarketName', 'naver');

  const handleClick = () => {
    //토큰 등록 및 signIn으로 넘어가기
    const token = params.get('token') ?? '';

    setNaverToken(token);
    history.replace(signIn);
  };

  return (
    <WholePageWrapper style={{ justifyContent: 'flex-start', boxSizing: 'border-box' }}>
      {/* 기존에 signIn 이전 스탭으로 존재했던 스플래시 화면 ( 사용 X ) */}
      <div
        style={{
          width: dimensions.width,
          height: dimensions.hieght,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: colors.gray.GR100,
        }}
      >
        <div
          style={{
            backgroundColor: '#FFFFFF',
            maxWidth: '600px',
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0px 27px',
          }}
        >
          <div
            style={{
              textAlign: 'center',
              marginBottom: 16,
              ...font.Title1B24,
              color: colors.gray.GR700,
            }}
          >
            셀러박스 시작하기
          </div>
          <div
            style={{
              textAlign: 'center',
              marginBottom: 72,
              ...font.Body2M16,
              color: colors.gray.GR700,
            }}
          >
            {'셀러박스와 함께'}
            <br />
            {'더 쉬운 판매를 경험해 보세요'}
          </div>
          <img
            src="svg/naverRegisterImage.svg"
            style={{ width: '100%' }}
            alt="naverRegisterImage"
          />

          <div
            style={{
              position: 'fixed',
              width: '100%',
              bottom: 30,
              maxWidth: '600px',
              padding: '0px 27px',
            }}
          >
            <Button
              style={{
                width: '100%',
                alignItems: 'center',
                borderRadius: 16,
                color: colors.gray.White,
                backgroundColor: colors.orange.OR500,
                padding: '16px 0px',
                boxSizing: 'border-box',
              }}
              onClick={handleClick}
            >
              <img src="svg/phoneIcon.svg" style={{ marginRight: 8 }} alt="phoneIcon" />
              <div
                style={{
                  ...font.Body1S16,
                }}
              >
                휴대폰 번호로 시작하기
              </div>
            </Button>
          </div>
        </div>
      </div>
    </WholePageWrapper>
  );
};

export default NaverRegisterToken;

import { ListItemButton, Collapse, ListItemButtonProps } from "@mui/material";
import * as React from "react";
import { ListItem, styled, Typography } from "@mui/material";
import { format } from "date-fns";

import { ReactComponent as DownArrow } from "../assets/icons/DownArrow.svg";
import { ReactComponent as HoverDownArrow } from "../assets/icons/HoverDownArrow.svg";
import { useMediaQueryForMobile } from "../hooks/useMediaQueryForMobile";
import { QTypography } from "./FAQItem";

export type NoticeItemTestType = {
  title: string;
  date: string;
  contents: string;

  // openIndex: number;
  // setOpenIndex: any;
  // index: number;
};
export interface INoticeItemTestProps
  extends Omit<ListItemButtonProps, "title"> {
  title: React.ReactNode;
  date: React.ReactNode;
  contents: React.ReactNode;
  openIndex: number;
  setOpenIndex: any;
  index: number;
}
export const NoticeItemTest: React.FC<INoticeItemTestProps> = ({
  title,
  date,
  contents,
  style,
  openIndex,
  setOpenIndex,
  index,
}) => {
  const [open, setOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (index !== openIndex) {
      setOpen(false);
    }
  }, [openIndex]);

  const onClickItem = () => {
    if (open) {
      setOpenIndex(-1);
    } else {
      setOpenIndex(index);
    }
    setOpen(!open);
  };

  const [hover, setHover] = React.useState<boolean>(false);

  const isMobile = useMediaQueryForMobile();
  return (
    <>
      <div
        onMouseOver={(e) => {
          setHover(true);
        }}
        onMouseLeave={(e) => {
          setHover(false);
        }}
      >
        <StyledListItemButton style={style} onClick={onClickItem}>
          <TitleWrapperDiv>
            <QTypography variant="h22">{"Q"}</QTypography>
            <Typography variant="h22">{title}</Typography>
          </TitleWrapperDiv>
          {/* <DateWrapperDiv>
            <DateTypography variant="h22">
              {format(date, "yyyy.MM.dd")}
            </DateTypography>
          </DateWrapperDiv> */}

          {!isMobile &&
            (hover ? (
              <StyledHoverArrow open={open} />
            ) : (
              <StyledDownArrow open={open} />
            ))}
        </StyledListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <DescriptionWrapperDiv>
            <Typography variant={"body12"}>{contents}</Typography>
          </DescriptionWrapperDiv>
        </Collapse>
      </div>
    </>
  );
};

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: 80,
  borderBottom: "1px solid #E5E5E5",
}));

const TitleWrapperDiv = styled("div")(({ theme }) => ({
  flex: 1,
}));

const DateWrapperDiv = styled("div")(({ theme }) => ({
  width: 148,
  [theme.breakpoints.down("sm")]: {
    width: 78,
    marginLeft: 10,
  },
}));

const DateTypography = styled(Typography)(({ theme }) => ({
  color: "#A4A4A4",
}));

const StyledDownArrow = styled(DownArrow)<{ open: boolean }>(
  ({ theme, open }) => ({
    transform: open ? "rotate(180deg)" : "rotate(0deg)",
    transition: "transform 200ms ease-in-out",
  })
);

export const StyledHoverArrow = styled(HoverDownArrow)<{ open: boolean }>(
  ({ theme, open }) => ({
    transform: open ? "rotate(180deg)" : "rotate(0deg)",
    transition: "transform 200ms ease-in-out",
  })
);
const DescriptionWrapperDiv = styled("div")(({ theme }) => ({
  padding: 50,
  [theme.breakpoints.down("sm")]: {
    padding: 20,
  },
}));

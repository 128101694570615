const orange = {
  OR700: '#DD6E00',
  OR500: '#FF7F00',
  OR300: '#FFD5AC',
  OR250: '#FFE2C8',
  OR200: '#FFECD7',
  OR100: '#FFF4E8',
};

const purple = {
  PU500: '#854CFF',
  PU250: '#F0EDFF',
};
// const mint = {
//   m500: "#14Dh6D6",
//   m200: "#B9F3F3",
//   m100: "#EEF9F7",
// };

const gray = {
  Black: '#000000',
  GR900: '#1A1A1A',
  GR800: '#3D3D3D',
  GR750: '#6F6F6F',
  GR700: '#555555',
  GR600: '#939393',
  GR500: '#A5A5A5',
  GR400: '#C8C8C8',
  GR300: '#DFDFDF',
  GR200: '#EFEFEF',
  GR150: '#F5F5F5',
  GR100: '#F9F9F9',
  GR50: '#FBFBFB',
  White: '#FFFFFF',
};

const blue = {
  Blue600: '#2069D7',
  Blue400: '#5B9DFF',
  Blue100: '#E1EDFF',
};

const red = {
  Red600: '#E02626',
  Red500: '#FF7F00',
  Red400: '#FF5B5B',
  Red300: '#FF7171',
  Red100: '#FFE9E9',
};

const chart = {
  CPink: '#FF8181',
  CYellow: '#F6C518',
  CYellow100: '#FFF9DE',
  CGreen: '#56DEAD',
  CGreen100: '#E4F9F1',
  CBlue: '#83B4FF',
};

// const green = {
//   g500: "#00C667",
//   g100: "#EEFFE8",
//   alpha20: "rgba(0, 198, 103, 0.2)",
// };

// const navy = {
//   n800: "#3A4552",
//   alpha90: "rgba(58, 69, 82, 0.9)",
// };

// const gray1 = {
//   alpha80: "rgba(25, 25, 25, 0.8)",
//   alpha60: "rgba(25, 25, 25, 0.6)",
// };
// const yellow = {
//   y100: "#FFF9E8",
//   y600: "#F2BA19",
// };

export {
  /** BrandColor */
  orange,
  purple,
  // mint,
  /**BannerColor */
  // yellow,
  /**GrayColor*/
  gray,
  /*SemanticColor*/
  blue,
  red,
  chart,
  // green,
  // navy,
  /*Opacity Color*/
  // gray1,
};

import styled from 'styled-components';
import { colors } from '../../../style';

export const Container = styled('div')({
  marginTop: -112,
  overflowX: 'hidden',
  overflowY: 'hidden',
});

export const ContentContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  backgroundColor: colors.gray.GR50,
});

export const MobileContentContainer = styled('div')({
  backgroundColor: colors.gray.GR50,
  width: '100%',
  paddingTop: 40,
});

export const EventContainer = styled('div')({
  width: 1290,
  height: 180,
  borderRadius: 24,
  background: 'linear-gradient(90deg, #7C94FF 0%, #917DF7 54%, #B972F3 100%)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: 115,
});

export const MobileEventContainer = styled('div')({
  borderRadius: 24,
  background: 'linear-gradient(90deg, #7C94FF 0%, #917DF7 54%, #B972F3 100%)',
  marginLeft: 20,
  marginRight: 20,
  height: 145,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  marginBottom: 60,
});
export const EventTextContainer = styled.div({
  padding: 24,
  paddingRight: 0,
});

export const EventTitle = styled.div({
  fontSize: 16,
  fontWeight: 800,
  color: colors.gray.White,
  lineHeight: '20.8px',
  marginBottom: 10,
});
export const EventDesc = styled.div({
  fontSize: 14,
  fontWeight: 500,
  color: colors.gray.White,
  lineHeight: '22px',
});

export const TextContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: 60,
});

export const MainText = styled.div({
  fontSize: 24,
  color: colors.gray.White,
  fontWeight: 800,
});

export const SubTextContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  marginTop: 16,
});

export const SubText = styled.div({
  fontSize: 18,
  color: colors.gray.White,
  fontWeight: 500,
});

import styled from 'styled-components';
import { colors } from '../../../../style';
import font from '../../../../style/font';
import { motion } from 'framer-motion/dist/framer-motion';

export const Container = styled.div({
  marginTop: 120,
  width: 1290,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const MainText = styled.div({
  fontSize: 40,
  fontWeight: 800,
  color: colors.gray.GR900,
  marginBottom: 25,
});
export const ContentContainer = styled.div({
  width: '100%',
});
export const TitleContainer = styled.div({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  gap: 30,
  borderBottom: '1px solid',
  borderBottomColor: colors.gray.GR300,
  position: 'relative',
});
export const Title = styled.div<{
  selected: boolean;
}>(
  {
    paddingTop: 30,
    paddingBottom: 30,
    textAlign: 'center',
    width: 410,
    ...font.Title3S18,
    cursor: 'pointer',
    transition: 'color 0.3s ease',
    '&:hover': {
      color: colors.gray.GR900,
    },
  },
  ({ selected }) => ({
    color: selected ? colors.gray.GR900 : colors.gray.GR600,
    fontWeight: selected ? 600 : 500,
  })
);
export const Indicator = styled.div<{ leftPosition: number }>(({ leftPosition }) => ({
  position: 'absolute',
  top: '85px',
  right: '0px',
  width: '410px',
  height: '2px',
  backgroundColor: colors.gray.GR900,
  transition: 'left 0.3s ease',
  left: `${leftPosition}px`,
}));

export const DescContainer = styled.div({
  display: 'flex',
  marginTop: 40,
  gap: 30,
});
export const Desc = styled.div({});

export const StepContainer = styled.div({
  position: 'relative',
  marginBottom: 20,
});
export const StepText = styled.div({
  position: 'absolute',
  top: 7,
  left: 10,
  fontSize: 16,
  fontWeight: 800,
  color: colors.gray.White,
  lineHeight: '25.6px',
});
export const DescTitle = styled.div({
  fontSize: 20,
  fontWeight: 700,
  lineHeight: '32px',
  color: colors.gray.GR800,
});
export const DescText = styled.div({
  fontSize: 16,
  fontWeight: 400,
  lineHeight: '25.6px',
  color: colors.gray.GR600,
  whiteSpace: 'pre-wrap',
  marginTop: 8,
  marginBottom: 32,
});

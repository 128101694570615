import React, { useEffect,useState } from "react";
import { marketList, ShowMarketIcon,getKorNameByEn } from "./marketList";
import { Modal } from "@mui/material";
import LoadingGear from "../../../assets/svg/loadingGear.svg";
import * as api from "../../../controller/api";
import { lineHeight } from "@mui/system";
import checkGray from "../../../assets/images/checkGray.png";
import checkWhite from "../../../assets/images/checkWhite.png";
import exitIcon from "../../../assets/images/exitIcon.png";
import enableHideEye from "../../../assets/images/enableHideEye.png";
import disableHideEye from "../../../assets/images/disableHideEye.png";
import whiteCircleCheck from "../../../assets/images/whiteCircleCheck.png";
import whiteCircleCheckedOutlined from "../../../assets/images/whiteCircleCheckedOutlined.png";

import "./Market.css";
const config = {
    width: 800,
    height: 500
}


//소환한 페이지에사 reciver

// window.addEventListener("message",getIframeMessage);

// if(event.data.messageType==="alert"){
//     enqueueSnackbar(
//       event.data.messageData.message,
//       {
//         variant: event.data.messageData.type
//       }
//     );
// }

// 소환한 페이지에 메시지 보내기 
export const messageToParent = (request: any) => {
        window.parent.postMessage({messageType: request.messageType, messageData: request.messageData},"*")
}

// ex) snackbar alert 쏘기  receiver쪽에도 필요
// alertData = {
//     message: "123123",
//     type: "error"
// }
export const alertToParent = (alertData: any) => {
    messageToParent({
        messageType: "alert",
        messageData: alertData
    })
}

export const marketListDataToParent= (marketListData: any) => {
    messageToParent({
        messageType: "marketList",
        messageData: marketListData
    })
}

export const DisabledCheckBox = () => {
    return (
        <div style={{width: 24, height: 24, borderRadius: 6, backgroundColor: "#F4F4F6", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <img src={checkGray} />
        </div>
    )
}

export const EnabledCheckBox = () => {
    return (
        <div style={{width: 24, height: 24, borderRadius: 6, backgroundColor: "#FF7F00", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <img src={checkWhite} />
        </div>
    )
}



export const Market = () => {

    const [markets,setMarkets]=useState<any>(marketList)
    const [linkedMarkets,setLinkedMarkets] = useState<any>(null);
    
    const [CplatToken,setCplatToken] = useState<any>((new URLSearchParams(window.location.search)).get("CplatToken"));
    //default ai-seller 
    const [serviceType,setServiceType] = useState<any>((new URLSearchParams(window.location.search)).get("ServiceType"));

    //ai-seller validateCheckedList 반품 가능한지 검사 리스트
    const [marketValidateList,setMarketValidateList]=useState<any>([]);


    const [isAbleToPostMessage,setIsAbleToPostMessage]=useState<boolean>(false);
    //modal Data



    const [alertText,setAlertText] = useState<string>("");
    const [isMarketRegisterModalOpen,setIsMarketRegisterModalOpen]=useState<boolean>(false);
    const [selectedMarket,setSelectedMarket] = useState<any>(null);
    const [selectedAccountTypeIndex,setSelectedAccountTypeIndex]=useState<number>(0);
    const [inputData,setInputData]=useState<any>({SecondaryAuthenticationCode: undefined});
    const [isApiLoading,setIsApiLoading]=useState<boolean>(false);
    const [isNeedSecondaryAuthenticationCode,setIsNeedSecondaryAuthenticationCode]=useState<boolean>(false);
    const [isNeedAppAuth,setIsNeedAppAuth]=useState<boolean>(false);
    const [modalInputHideObject,setModalInputHideObject] = useState<any>({"0": true});
    const [secondaryAuthenticationTimer,setSecondaryAuthenticationTimer]=useState(0);
    const [secondaryAuthenticationTimerClearObject,setSecondaryAuthenticationTimerClearObject]=useState<any>(null);
    

    // useEffect(()=>{
        
        
    //     setInterval(()=>{
    //         alertToParent({message: "123123", type: "success"});
    //     },2000)
    // },[])

    const resetTimer = () => {
        clearTimeout(secondaryAuthenticationTimerClearObject);
        setSecondaryAuthenticationTimer(0)
    }

    const startTimer = () => {
        setSecondaryAuthenticationTimer(60);
    }

    useEffect(()=>{
        if(isNeedSecondaryAuthenticationCode){
            startTimer();
        }else{
            resetTimer();
        }
    },[isNeedSecondaryAuthenticationCode]);

    useEffect(()=>{
        clearTimeout(secondaryAuthenticationTimerClearObject);
        if(secondaryAuthenticationTimer!==0){
            setSecondaryAuthenticationTimerClearObject(setTimeout(()=>{
                setSecondaryAuthenticationTimer(secondaryAuthenticationTimer-1);
            },1000))
        }
    },[secondaryAuthenticationTimer])


    //연동된 마켓 정보를 parent로 보내는 로직
    useEffect(()=>{
        if(isAbleToPostMessage){
            marketListDataToParent(linkedMarkets===null ? [] : linkedMarkets);
        }
    },[linkedMarkets])


    //토큰 있으면 마켓 리스트 받아오는 로직
    useEffect(()=>{
        if(CplatToken===null){
            console.log("No - Token");
            //토큰 없을때 처리해야함
        }else{
            UserGetRegisteredMarketAccountList();
        }
    },[])


    //마켓연동 모달 열기
    const handleModalOpen=(market: any)=>{
        if(market.Company[0]!=="스마트스토어"){
            alertToParent({message: "현재 사용이 불가능한 마켓입니다.", type: "info"});
            return;
        }
        setSelectedMarket(market);
        setIsMarketRegisterModalOpen(true);
        let tmpInputDate: any = {};
        market.Needs.forEach((need: any)=>{
            tmpInputDate[need.type] = ""
        });
        setInputData(tmpInputDate);
    }


    //마켓연동 모달 닫기 및 초기화
    const handleModalClose=(event: any = null)=>{
        if(event){
            event.preventDefault();
        }
        setAlertText("");
        setSelectedMarket(null);
        setIsMarketRegisterModalOpen(false);
        setInputData({SecondaryAuthenticationCode: undefined});
        setSelectedAccountTypeIndex(0);
        setIsNeedSecondaryAuthenticationCode(false);
        setIsApiLoading(false);
        setIsNeedAppAuth(false);
        setModalInputHideObject({"0": true});
    }

    //2차인증 보내는 API
    const sendSecondaryAuthenticationCode = () => {
        if(isApiLoading===false){
            setIsApiLoading(true);
            api.UserSetAuthenticationCodeByMarket({
                MarketName: selectedMarket?.CategoryEn[selectedAccountTypeIndex],
                MarketId: inputData[`${selectedMarket.Needs[0].type}`],
                AuthCode: inputData.SecondaryAuthenticationCode,
            })
            .then((res: any)=>{
                let { data, message, code } = res.data;
                if(code==="200"){
                    if(serviceType==="default"){
                        UserGetRegisteredMarketAccountList();
                        handleModalClose();
                        alertToParent({message: "마켓이 등록되었습니다.", type: "success"});
                    }else if(serviceType==="ai-seller"){
                        api.SetMarketStatusTemp({
                            cplatToken: CplatToken,
                            marketName: selectedMarket?.CategoryEn[selectedAccountTypeIndex],
                            marketId: inputData.MarketId
                        }).then((marketValidProcessResponse: any)=>{
                            if(marketValidProcessResponse.data.code==="200"){
                                UserGetRegisteredMarketAccountList();
                                handleModalClose();
                                alertToParent({message: "마켓이 등록되었습니다.", type: "success"});
                            }else if("611"){
                                UserGetRegisteredMarketAccountList();
                                handleModalClose();
                                alertToParent({message: "입력한 ID는 이미 사용 가능한 ID 목록에 있습니다. 1:1 문의로 알려주새요.", type: "error"});
                            }
                        })
                    }
                }else{
                    alertToParent({message: "2차 인증에 실패하였습니다.", type: "error"});
                    setAlertText("2차 인증에 실패하였습니다.");
                }
            })
            .finally(()=>{
                setIsApiLoading(false);
            })
        }
    }


    //1챠인증 ( ID,PW ) 보내는 API
    const UserGetRegisteredMarketAccountList = () => {
        api.UserGetRegisteredMarketAccountList({
            CplatToken
        })
        .then((res: any)=>{
            let { data, message, code } = res.data;
            if(code==="200"){
                if(serviceType==="ai-seller"){
                    api.GetMarketStatusTemp({cplatToken: CplatToken})
                    .then((marketValidProcessResponse: any)=>{
                        setMarketValidateList(marketValidProcessResponse.data.data.length>0 ? marketValidProcessResponse.data.data[0].MarketStatusList : []);
                        let tmpList = data[0].MarketAccountList;
                        tmpList.map((td: any,tdi: number)=>{
                            let target = linkedMarkets?.find((lm: any,lmi: number)=>td.Key===lm.key && td.Value===lm.Value);
                            if(target){
                                td.isDeleting = target.isDeleting;
                            }else{
                                td.isDeleting = false;
                            }
                            return td;
                        });
                        setIsAbleToPostMessage(true);
                        setLinkedMarkets(tmpList);

                    });
                }else{
                    let tmpList = data[0].MarketAccountList;
                    tmpList.map((td: any,tdi: number)=>{
                        let target = linkedMarkets?.find((lm: any,lmi: number)=>td.Key===lm.key && td.Value===lm.Value);
                        if(target){
                            td.isDeleting = target.isDeleting;
                        }else{
                            td.isDeleting = false;
                        }
                        return td;
                    });
                    setIsAbleToPostMessage(true);
                    setLinkedMarkets(tmpList);
                }
            }else if(code==="530"){
                setLinkedMarkets(null);
            }else{
                alertToParent({message: "연동된 마켓을 불러오지 못했습니다.", type: "warning"});
            }
            //핸들링 할게 있나
        })
    }

    //연동 시작하는 모듈
    const handleRegister = () => {

        //입력창 확인
        if(Object.values(inputData).filter((data: any)=>data==="").length>0){
            alertToParent({message: "모든 항목을 입력하세요.", type: "warning"});
            return;
        }

        if(isApiLoading===false){
            setAlertText("");
            setIsApiLoading(true);
            if(selectedMarket?.CategoryEn[selectedAccountTypeIndex]==="naver"){
                setIsNeedAppAuth(true);
            }else{
                setIsNeedAppAuth(false);
            }
    
            api.UserRegisterMarketAccount({
                MarketName: selectedMarket?.CategoryEn[selectedAccountTypeIndex],
                MarketLoginInfo: JSON.stringify(inputData),
                CplatToken: CplatToken
            })
            .then((res: any)=>{
                let { message, data ,code } = res.data;
                if(code==="200"){
                    if(serviceType==="default"){
                        UserGetRegisteredMarketAccountList();
                        handleModalClose();
                        alertToParent({message: "마켓이 등록되었습니다.", type: "success"});
                    }else if("ai-seller"){
                        api.SetMarketStatusTemp({
                            cplatToken: CplatToken,
                            marketName: selectedMarket?.CategoryEn[selectedAccountTypeIndex],
                            marketId: inputData.MarketId
                        }).then((marketValidProcessResponse: any)=>{
                            if(marketValidProcessResponse.data.code==="200"){
                                UserGetRegisteredMarketAccountList();
                                handleModalClose();
                                alertToParent({message: "마켓이 등록되었습니다.", type: "success"});
                            }else if("611"){
                                UserGetRegisteredMarketAccountList();
                                handleModalClose();
                                alertToParent({message: "입력한 ID는 이미 사용 가능한 ID 목록에 있습니다. 1:1 문의로 알려주새요.", type: "error"});
                            }
                        })
                    }
                }else if(code==="610"){
                    setAlertText("아이디 또는 비밀번호가 일치하지 않습니다.");
                    alertToParent({message: message, type: "error"});
                    // alertToParent({message: "아이디 또는 비밀번호가 일치하지 않습니다.", type: "error"});
                }else if(code==="611"){
                    setAlertText("이미 사용중인 마켓입니다.");
                    alertToParent({message: message, type: "error"});
                    // alertToParent({message: "이미 사용중인 마켓입니다.", type: "error"});
                }else if(code==="615"){
                    alertToParent({message: message, type: "success"});
                    // alertToParent({message: "2차 인증을 진행해주세요.", type: "success"});
                    setAlertText("2차 인증 및 매니저 등록을 위한 SMS가 발송됩니다.");
                    setIsNeedAppAuth(false);
                    setIsNeedSecondaryAuthenticationCode(true);
                }else{
                    alertToParent({message: message, type: "success"});
                }
            })
            .finally(()=>{
                setIsNeedAppAuth(false);
                setIsApiLoading(false);
            })
        }else{
            alertToParent({message: "이미 연동중 입니다.", type: "warning"});
        }
    }

    const handleValidate = (linkedMarket: any) => {
        if(["smartstore","naver"].indexOf(linkedMarket.Key) === -1){
            alertToParent({message: "검수가 불가능한 마켓입니다.", type: "warning"});
            return;
        }
        api.SetMarketStatusTemp({
            cplatToken: CplatToken,
            marketName: linkedMarket.Key,
            marketId: linkedMarket.Value
        }).then((marketValidProcessResponse: any)=>{
            if(marketValidProcessResponse.data.code==="200"){
                UserGetRegisteredMarketAccountList();
                // handleModalClose();
                alertToParent({message: "마켓을 검수중입니다.", type: "success"});
            }
        })
    }

    const handleReadMore = (linkedMarket: any) => {
        messageToParent({
            messageType: "route",
            messageData: {
                path: `/MarketValidProcess?MarketId=${linkedMarket.validateData.MarketId}&MarketName=${linkedMarket.validateData.MarketName}`
            }
        })
    }

    const handleUnregister = (market: any) => {
        if(market.isDeleting===false){
            let copiedLinkedMarkets = linkedMarkets.slice();
            copiedLinkedMarkets.forEach((m: any,mi: number)=>{
                if(mi===copiedLinkedMarkets.indexOf(copiedLinkedMarkets.find((d: any)=>JSON.stringify(d)===JSON.stringify(market)))){
                    m.isDeleting=true;
                }
            })
            setLinkedMarkets(copiedLinkedMarkets);
            messageToParent({
                messageType: "deleting",
                messageData: true
            });
            api.UserUnregisterMarketAccount({
                CplatToken: CplatToken,
                MarketName: market.Key,
                MarketLoginInfo: JSON.stringify({
                    MarketName: market.Key,
                    MarketId: market.Value,
                }),
            })
            .then((res: any)=>{
                let { code, data ,message } = res.data;
                if(code==="200"){
                    alertToParent({message: `${getKorNameByEn(market.Key)} 계정 을 삭제했습니다.`,type: "success"})
                    UserGetRegisteredMarketAccountList()
                }else{
                    alertToParent({message: `오류가 발생했습니다.`,type: "error"})
                    let copiedLinkedMarkets = linkedMarkets.slice();
                    copiedLinkedMarkets.forEach((m: any,mi: number)=>{
                        if(mi===copiedLinkedMarkets.indexOf(copiedLinkedMarkets.find((d: any)=>JSON.stringify(d)===JSON.stringify(market)))){
                            m.isDeleting=false;
                        }
                    })
                    setLinkedMarkets(copiedLinkedMarkets);
                }
            })
            .finally(()=>{
                messageToParent({
                    messageType: "deleting",
                    messageData: false
                });
            });
        }
    }

    const handleModalInputHIde = (index: number) => {
        let tmp = {...modalInputHideObject};
        if(tmp[index]===undefined){
            tmp[index]=true;
        }else{
            delete tmp[index];
        }
        setModalInputHideObject(tmp);
    }

    return (
        <div style={{width: "800px",display: "flex", alignItems: "center", justifyContent: "center",backgroundColor: "#FFFFFF"}}>
            <div style={{boxSizing: "border-box",width: config.width, height: config.height,padding: 30,display: "flex", justifyContent: "space-between"}}>

                <div >
                    <div style={{height: 30,display: "flex", alignItems: "center",fontWeight: 600,justifyContent: "center", fontSize: 16,marginBottom: 10}}>
                        연동 가능한 마켓
                    </div>
                    {/* <div style={{boxSizing: "border-box",borderTop: "2px solid #BABABA",borderBottom: "2px solid #BABABA",overflow: "scroll", height: "calc(100% - 30px)", width: config.width/2-40}}> */}
                    <div className="nonScrollBar" style={{borderRadius: 8,boxSizing: "border-box",overflow: "scroll", height: "calc(100% - 30px)", width: config.width/2-40,padding: "12px 12px"}}>
                        {markets.map((market: any,marketIndex: number)=>
                            <div style={{display: 'flex', alignItems: "center",justifyContent: "space-between",marginTop: marketIndex===0 ? 8 : 0,borderRadius: 8,margin: "0px 6px",marginBottom: 8,padding: "12px 20px",boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.2)",height: 50}}>
                                <div style={{display: "flex",alignItems: "center"}}>
                                    <ShowMarketIcon height={32} width={32} name={market.Company[0]} />
                                    <div style={{marginLeft: 12,fontSize: 16, fontWeight: 400, fontFamily: "Noto Sans KR"}}>{market.Company[0]}</div>
                                </div>
                                <div 
                                    style={{display: "flex", alignItems: "center", justifyContent: "center", position: "relative",cursor: "pointer",backgroundColor: serviceType==="ai-seller" ? market.Company[0]==="스마트스토어" ? "#FF7F00" : "rgba(238, 238, 238, 1)" : "#FF7F00", width: 102, height: 42,borderRadius: 32, lineHeight: "15px", textAlign: "center", color: "#FFFFFF",fontSize: 15, fontWeight: 500}}
                                    onClick={()=>{
                                        handleModalOpen(market)
                                    }}
                                >
                                    {
                                        serviceType==="ai-seller" ? market.Company[0]==="스마트스토어" ? <img src={whiteCircleCheck} style={{marginRight: 4,marginBottom: 1}} /> : "" : <img src={whiteCircleCheck} style={{marginRight: 4,marginBottom: 1}} />
                                    }
                                    {
                                         serviceType==="ai-seller" ? market.Company[0]==="스마트스토어" ? "연동하기" : "연동불가" : "연동하기"
                                    }
                                    
                                </div>
                            </div>
                        )}    
                    </div>
                </div>

                <div>
                    <div style={{height: 30,display: "flex", alignItems: "center",fontWeight: 600,justifyContent: "center", fontSize: 16,marginBottom: 10}}>
                        <div>연동된 마켓</div>
                    </div>

                    <div className="nonScrollBar" style={{borderRadius: 8,boxSizing: "border-box",overflow: "scroll", height: "calc(100% - 30px)", width: config.width/2-40,padding: "12px 0px"}}>
                        
                        {/* {console.log(marketValidateList)} */}
                        {linkedMarkets!==null && linkedMarkets.map((market: any)=>({
                            ...market,
                            ["validateData"]:marketValidateList.find((validateData: any)=>(validateData.MarketId===market.Value && validateData.MarketName===market.Key))
                        }))?.map((linkedMarket: any,linkedMarketIndex: number)=>(

                                <div style={{position: "relative"}}>
                                        {
                                            // linkedMarket.isDeleting && <div style={{position: 'absolute', zIndex: 1,backgroundColor: "gray", opacity: 0.5, width: "100%", height: '100%'}}></div>
                                            linkedMarket.isDeleting && <div style={{marginTop: linkedMarketIndex===0 ? 8 : 0,margin: "0px 6px",marginBottom: 8,position: 'absolute', zIndex: 1,backgroundColor: "gray", opacity: 0.4, width: "calc(100% - 12px)", height: '74px'}}></div>
                                        }
                                        {
                                            // linkedMarket.isDeleting && <img src={LoadingGear} style={{zIndex: 2,width: 80, height: 80,position: "absolute"}} />
                                            linkedMarket.isDeleting && <div style={{marginTop: linkedMarketIndex===0 ? 8 : 0,margin: "0px 6px",marginBottom: 8,width: "100%",zIndex: 2,display: 'flex',justifyContent: 'center',alignItems: "center",position: "absolute"}}><img src={LoadingGear} style={{width: 60, height: 60}} /></div>
                                        }
                                    <div key={linkedMarketIndex} style={{display: 'flex', alignItems: "center",justifyContent: "space-between",marginTop: linkedMarketIndex===0 ? 8 : 0,margin: "0px 6px",marginBottom: 8,borderRadius: 8,padding: "12px 20px",boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.2)",height: 50}}>
                                        <div style={{display: "flex",alignItems: "center"}}>
                                            <ShowMarketIcon height={32} width={32} nameEn={linkedMarket.Key} />
                                            <div style={{marginLeft: 12,fontSize: 15,lineHeight: "15px", fontWeight: 400, fontFamily: "Noto Sans KR"}}>{linkedMarket.Value}</div>
                                        </div>

                                        {
                                            linkedMarket.validateData!==undefined
                                            ?
                                            linkedMarket.validateData.Status==="succeed"
                                            ?
                                            <div
                                                onClick={()=>{
                                                    handleUnregister(linkedMarket);
                                                }}
                                                style={{display: "flex", alignItems: "center",position: "relative",justifyContent: "center",cursor: linkedMarket.isDeleting ? "progress" :"pointer",backgroundColor: "rgba(255, 127, 0, 0.1)",width: 102, height: 42,borderRadius: 32, lineHeight: "15px", textAlign: "center", color: "#FF9133",fontSize: 15, fontWeight: 500}}
                                            >
                                                <img src={whiteCircleCheckedOutlined} style={{marginRight: 4,marginBottom: 1}} />
                                                {linkedMarket.isDeleting ? "삭제중" : "삭제"}
                                            </div>
                                            :
                                            <div
                                                onClick={()=>{
                                                    handleReadMore(linkedMarket);
                                                }}
                                                style={{display: "flex", alignItems: "center",position: "relative",justifyContent: "center",cursor: linkedMarket.isDeleting ? "progress" :"pointer",backgroundColor: "rgba(238, 238, 238, 1)",width: 102, height: 42,borderRadius: 32, lineHeight: "15px", textAlign: "center", color: "#000000",fontSize: 15, fontWeight: 500}}
                                            >
                                                {/* <img src={whiteCircleCheckedOutlined} style={{marginRight: 4,marginBottom: 1}} /> */}
                                                    자세히 보기
                                            </div> 
                                            :
                                            <div
                                                onClick={()=>{
                                                    handleValidate(linkedMarket);
                                                }}
                                                style={{display: "flex", alignItems: "center",position: "relative",justifyContent: "center",cursor: linkedMarket.isDeleting ? "progress" :"pointer",backgroundColor: "rgba(238, 238, 238, 1)",width: 102, height: 42,borderRadius: 32, lineHeight: "15px", textAlign: "center", color: "#000000",fontSize: 15, fontWeight: 500}}
                                            >
                                                
                                                {/* <img src={whiteCircleCheckedOutlined} style={{marginRight: 4,marginBottom: 1}} /> */}
                                                {
                                                    serviceType==="ai-seller" ? ["smartstore","naver"].indexOf(linkedMarket.Key) !== -1 ? "검수하기" : "검수불가" : "검수하기"
                                                }
                                                    
                                            </div>
                                                
                                        }
                                            
                                    </div>
                                </div>

                        ))}
                    </div>
                </div>

            </div>
            <Modal
                open={isMarketRegisterModalOpen}
                hideBackdrop={false}
                disableAutoFocus={true}
                style={{display: 'flex', justifyContent: "center", alignItems: "flex-start",backgroundColor: "#transparent"}}
            >

                <div  style={{width: config.width, height: config.height,display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{ position: "relative",boxSizing: "border-box",padding : "32px",width: 392, height: 440,borderRadius: 32, overflow: "hidden", backgroundColor: "#FFFFFF",display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: 'center',paddingBottom: 86}} onClick={(e)=>{e.stopPropagation()}}>
                        <img style={{position: "absolute", top: 20, right: 20, cursor: "pointer"}} onClick={handleModalClose} src={exitIcon} />
                        {
                            isNeedAppAuth === false ?
                            <>
                                {
                                    isApiLoading && <div style={{position: 'absolute', zIndex: 1,backgroundColor: "gray", opacity: 0.5, width: "100%", height: '200%'}}></div>
                                }
                                {
                                    isApiLoading && <img src={LoadingGear} style={{zIndex: 2,width: 80, height: 80,position: "absolute"}} />
                                }
                                <div style={{marginBottom: 10}}>
                                    <ShowMarketIcon name={selectedMarket?.Company[0]} />
                                </div>
                                <div style={{marginBottom: 20,fontSize: 20, fontWeight: 600}}>
                                    {selectedMarket?.Company[0]}
                                </div>
                                {
                                    isNeedSecondaryAuthenticationCode ?
                                    <>
                                        <div  style={{position: "relative",width: "100%",boxSizing :"border-box",display: 'flex',alignItems: "center"}}>
                                            <input value={inputData.SecondaryAuthenticationCode} onChange={(e: any)=>{
                                                    if(e.target.value.length<=6){
                                                        setInputData({...inputData,SecondaryAuthenticationCode: e.target.value});
                                                    }
                                                }} type="text" className="textInputNoMargin" placeholder={`인증번호를 입력해주세요.`} />
                                            <div className="absoluteBox flex font14middle" style={{color: "#0785FA",justifyContent: "center", alignItems: "center",width: 60, height: 40,right: 10}} >
                                                {secondaryAuthenticationTimer}초 남음
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div style={{width: "100%",boxSizing :"border-box",display: 'flex',marginBottom: 10}}>
                                            {
                                                selectedMarket?.Category.length > 1 && selectedMarket?.Category.map((category: any, categoryIndex: number)=>(
                                                    <div style={{display: "flex", alignItems: "center", cursor: "pointer",marginRight: 6,padding: 2}} onClick={()=>{setSelectedAccountTypeIndex(categoryIndex)}}>
                                                        {
                                                            selectedAccountTypeIndex===categoryIndex ? <EnabledCheckBox /> : <DisabledCheckBox />
                                                        }
                                                        {/* <div style={{width: 16, height: 16, borderRadius: "50%",border: "1px solid #DADADA", backgroundColor : selectedAccountTypeIndex===categoryIndex ? "#FF9133" : "#FFFFFF",display: "flex", alignItems: "center",marginRight: 2}}></div> */}
                                                        <div style={{marginLeft: 6,fontWeight: selectedAccountTypeIndex===categoryIndex ? 700 : 500,letterSpacing: "-6%", color: selectedAccountTypeIndex===categoryIndex ? "#FF9133" : "#000000"}}>
                                                            {selectedMarket.ModalDisplayCategory[categoryIndex]}
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        {
                                            selectedMarket?.Needs.map((need: any,needIndex: number)=>(<div className="textInput flexRowWrapper" style={{position: "relative"}}>
                                                {
                                                    needIndex!==0 && <div style={{position: "absolute",right: 20,top: 13+(modalInputHideObject[needIndex]===undefined ? -3 : 0)}} onClick={()=>{handleModalInputHIde(needIndex)}}>
                                                        <img  src={(modalInputHideObject[needIndex]===undefined) ? enableHideEye : disableHideEye} style={{cursor: "pointer"}} />
                                                    </div>
                                                }
                                                <input onChange={(e: any)=>{
                                                    setInputData({...inputData,[need.type]: e.target.value});
                                                }} type={modalInputHideObject[needIndex]===undefined ? "password" : "text"} className="textInput"style={{marginBottom: 0}} placeholder={`${need.display}를 입력하세요.`} />
                                            </div>
                                            ))
                                        }
                                        <div style={{paddingLeft: 10,display: 'flex', width: "100%",color :"#FF9133",fontSize: 13, fontWeight: 500}}>
                                            {alertText}
                                        </div>
                                    </>
                                }

                                {
                                    (selectedMarket?.Company[0]==="스마트스토어" && !isNeedSecondaryAuthenticationCode) &&
                                    <div className="flexRowWrapper"  >
                                        <div className="flexColumnWrapper">
                                            <div className="v-space30" />
                                            <div className="font14middle grayColor" >
                                                네이버, 판매자아이디 로그인 방식을 확인해 주세요.
                                            </div>
                                        </div>
                                        
                                    </div>
                                    
                                }

                                <div onClick={isNeedSecondaryAuthenticationCode ? sendSecondaryAuthenticationCode : handleRegister} 
                                className="registerButton"
                                // style={{backgroundColor: "#FFFFFF", border: "1px solid #FF9133",width: 70, height: 30,borderRadius: 32, lineHeight: "30px", textAlign: "center", color: "#FF9133",fontSize: 15, fontWeight: 500,marginTop: 10}}
                                >{ isNeedSecondaryAuthenticationCode ? "인증" : "연동하기"}</div>
                            </>
                            :
                            <>
                                <div style={{display: "flex",flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                                    <ShowMarketIcon name={selectedMarket?.Company[0]} />
                                    <div style={{marginLeft: 12,fontSize: 20, fontWeight: 400, fontFamily: "Noto Sans KR"}}>{selectedMarket?.Company[0]}</div>
                                </div>
                                <div style={{boxSizing: "border-box",padding: 20,width: "100%",fontSize: 16, fontWeight: 400,marginTop: 20,textAlign: "center",border: "2px solid #F4F4F6", borderRadius: 8}}>
                                    2차 인증이 되어있다면,
                                    <br />
                                    네이버 앱을 통해 인증 요청을 허용하시거나 
                                    <br />
                                    OTP 인증번호를 입력해 주세요.
                                </div>
                            </>
                        }
                        {/* setIsNeedAppAuth(true);
                }else{
                    setIsNeedAppAuth(false);
                }
                setIsNeedSecondaryAuthenticationCode(true); */}


                        {/* export const UserGetRegisteredMarketAccountList = () => {
  console.log("UserGetRegisteredMarketAccountList");

  const token = store.getState().user.CplatToken;
  let payload = {
    CplatToken: token,
  };
  return axios.post(
    backendurl + `user/get_registered_market_account_list`,
    payload
  );
}; */}

{/* MarketName: marketData.CategoryEn[selectedStoreIndex],
          MarketLoginInfo: JSON.stringify(inputData), */}
          
          {/* type
          cplattoken */}

                    </div>
                </div>
            </Modal>
        </div>
    )
}

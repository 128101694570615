import React,{ useState,useEffect } from "react";
export const KakaoLogin = () => {
    useEffect(()=>{
        if(window.location.href.indexOf("?accessToken=")!==-1){  //=> accessToken은 nodejs 서버의 passport로 인증하고 JWT로 발행한 토큰
            // => sellerbox server 에( sign_in_by_social )의 파라미터로 보내면 CplatToken을 얻을 수 있음
            let parsedToken = window.location.href.split("?accessToken=")[1];
            // window.parent.postMessage({ kakaoToken: parsedToken=="null" ? null : parsedToken }, '*');
        }else{
            window.location.href = process.env.REACT_APP_SOCIAL_BACKEND_URL + "auth/kakaoAi";
        }
    },[])
    return (
        <div>
            {/* 로딩화면 넣으면 됨 ?? 필요 없을수도 ( 페이지 전환이 너무 빨라서 ) */}
        </div>
    );
}
import React, { useEffect, useState } from "react";
import { useMediaQueryForMobile } from "../hooks/useMediaQueryForMobile";
import { Modal } from "@mui/material";
import { ModalInnerButton } from "./ContactPage";
import { PcPageWrapper, WholePageWrapper, MobileContentWhiteWrapper, MobilePageWrapper, MobileContentGrayWrapper, AnimationDiv, MobileContentLightGrayWrapper, MobileContentLightWhiteWrapper, MobileContentGrayWrapperWithNoPadding, handleDownLoad } from "./SellerboxAppPageV1"
import { SetTempSellkeyPhoneNo } from "../controller/api";
import axios from "axios";


export const SellerboxPromotionPage = () => {
    const isMobile = useMediaQueryForMobile();
    const [phoneNumber,setPhoneNumber]=useState("");
    const [isAgreed,setIsAgreed] = useState(false);
    const [isApiLoading,setIsApiLoading]=useState(false);
    const [isModalOpen,setIsModalOpen]=useState(false);
    const [modalContent,setModalContent]=useState("");
    const [isSuccess,setIsSuccess]=useState(false);
    const handlePhoneNumber = (event) => {
        let validCharList = ["0","1","2","3","4","5","6","7","8","9"];
        let value = event.target.value;
        let isVaild = value.split("").reduce((acc,cur)=>{
            if(validCharList.indexOf(cur)!==-1)return true;
            else return false;            
        },true) && value.length <= 11;
        if(isVaild)setPhoneNumber(value);
    }

    const handleAgree = () => {
        if(!isApiLoading)setIsAgreed(!isAgreed);
    }

    const handleErrorModal = (message,isSuccess) => {
        setModalContent(message);
        setIsSuccess(isSuccess);
        setIsModalOpen(true);
        setIsApiLoading(false);
    }

    const handleSubmit = () => {

        if(getIsActiveButton()===false)return;

        setIsApiLoading(true);

        let checkRegExp = /^01([0|1|6|7|8|9]?)-?([0-9]{3,4})-?([0-9]{4})$/;
        if(!checkRegExp.test(phoneNumber)){
            return handleErrorModal("올바른 휴대폰 번호를 입력해 주세요.",false);
        }
        
        
        //API CALL
        SetTempSellkeyPhoneNo({
            PhoneNo: phoneNumber
        })
        .then(res=>{
            if(res.data.code=="200"){
                handleErrorModal("등록이 완료되었습니다. 앱스토어로 이동합니다.",true);
                return ;
            }else{
                handleErrorModal(res.data.message,false);
                return;
            }
        })
        .catch(err=>{
            handleErrorModal("일시적인 오류가 발생했습니다.\n 다시 시도해 주세요.",false);
            return ;
        })
    }

    const getIsActiveButton = () => {
        return phoneNumber.length>9 && isAgreed;
    }

    return (
        <WholePageWrapper>
            
            <MobilePageWrapper style={isMobile ? {} : {width: 600,marginTop: 50}} >

                <MobileContentWhiteWrapper style={{marginTop: 30}} >
                    <div className="orangeColor fullWidth" style={{fontSize: 18, fontWeight: 700}} >
                        샐러박스 앱 설치하고
                    </div>

                    
                    <div style={{display: "flex",width: "100%", flexDirection: "column", alignItems: "flex-start"}}>
                        <div style={{marginTop: 10,width: "auto",fontSize: 24, fontWeight: 500, color: "#1A1A1A", position: "relative"}} >
                            키워드 분석 서비스
                            <img src="/images/sellerboxHomePigTailImage.png" style={{width: 141, position :"absolute", top: -60,right: -131}} />
                        </div>

                        <div style={{fontSize: 24, fontWeight: 500, color: "#1A1A1A"}} >
                            1개월 무료로 이용하세요!
                        </div>
                    </div>

                    <div style={{height: 24}} />

                    <input className="eventPhoneNumberInput" placeholder="휴대폰 번호 입력 (‘-’ 제외)" type="text" style={{width: '100%', borderRadius: 8, border: "none", padding: "11px 14px"}} value={phoneNumber} onChange={handlePhoneNumber} />

                    <div style={{height: 14}} />

                    <div  className="flexRowWrapper rowLeft font14small" style={{gap: 8, color: "#8B8B8B", alignItems: "center"}} >
                        <div onClick={handleAgree} style={{cursor: "pointer",display: "flex", alignItems: "center"}} >
                            {
                                isAgreed
                                ?
                                <img src="/images/enabledCheckBox.png" style={{width: 20}} />
                                :
                                <img src="/images/disabledCheckBox.png" style={{width: 20}} />
                            }
                        </div>
                        
                        <div onClick={handleAgree} className="pointer" >
                            1개월 무료이용권 대상자 확인을 위한 용도로 휴대폰 
                            번호를 등록합니다
                        </div>
                    </div>

                    <div style={{height: 32}} />

                    <div className={getIsActiveButton() ? "enabledSubmitButton" : "disabledSubmitButton" } style={{opacity: isApiLoading ? 0.5 : 1}}  onClick={handleSubmit} >
                        무료 이용권 신청
                    </div>
                    

                    <div style={{height: 10}} />

                    <div className="font14small" style={{color:  "#FF7F00"}} >*셀러박스 가입 후 셀키 1개월 무료 이용권이 제공됩니다.</div>

                    <div style={{height: 60}} />

                </MobileContentWhiteWrapper>

                <MobileContentGrayWrapper>

                    <div style={{height: 30}} />

                    <div className="fullWidth">
                        <div className="orangeColor" style={{fontSize: 28, fontWeight: 800}}>
                            셀러박스
                        </div>
                        <div style={{fontSize: 28, fontWeight: 400}}>
                            성공에 필요한 <br />
                            모든 데이터를 <br />
                            확인하세요.  <br />
                        </div>
                    </div>


                    <AnimationDiv>
                        <div className="v-space20" />
                        <img src={"images/mobile-home-image-1.png"} style={{width: "100%", boxSizing: 'border-box', padding: "0px 20px"}} />
                    </AnimationDiv>
                        <div className="v-space24" />

                        <div className="flexRowWrapper" style={{gap: 10}}>
                            <img className="pointer" src={"images/mobile-home-google-download.png"} style={{width: "calc(50% - 5px)"}} 
                                onClick={()=>{
                                    console.log(process.env.REACT_APP_GOOGLE_DOWNLOAD_URL)
                                    window.open(process.env.REACT_APP_GOOGLE_DOWNLOAD_URL,"_self");
                                }}
                            />
                            <img className="pointer" src={"images/mobile-home-apple-download.png"} style={{width: "calc(50% - 5px)"}} 
                                onClick={()=>{
                                    window.open(process.env.REACT_APP_APPLE_DOWNLOAD_URL,"_self");
                                }}
                            />
                        </div>

                        <div style={{height: 8}} />

                        {/* <img src={"images/scrollDownIcon.png"} style={{width: 67}} /> */}

                        <div style={{height: 20}} />

                </MobileContentGrayWrapper>

                <MobileContentLightGrayWrapper>
                    <div style={{height: 40}} />
                    <div className="fullWidth">
                        <div style={{height: 24}} />
                        <div className="font16middle" style={{color: "#6F6F6F"}} >
                            셀러박스에 운영중인 마켓을 연동해 보세요. <br />
                            판매 금액, 판매 건수, 정산 현황을 한눈에 <br />
                            확인할 수 있어요.
                        </div>
                    </div>

                    <div style={{height: 28}} />

                    <div className="flexRowWrapper" style={{gap: 20}}>
                        <div className="flexCenter" style={{width: "50%"}}>
                            <AnimationDiv delay={0.15} >
                                <img src={"images/mobile-home-daily-1.png"} style={{width: "100%", boxShadow: "-6.14035px 7.89474px 11.4035px rgba(0, 0, 0, 0.05)",borderRadius: "10.5263px"}} />
                            </AnimationDiv>                                
                        </div>
                        <div className="flexColumnWrapper" style={{gap: 20,width: "50%"}}>
                            <AnimationDiv>
                                <img src={"images/mobile-home-daily-2.png"} style={{width: "100%", boxShadow: "-6.14035px 7.89474px 11.4035px rgba(0, 0, 0, 0.05)",borderRadius: "10.5263px"}} />
                            </AnimationDiv>
                            <AnimationDiv delay={0.3} >
                                <img src={"images/mobile-home-daily-3.png"} style={{width: "100%", boxShadow: "-6.14035px 7.89474px 11.4035px rgba(0, 0, 0, 0.05)",borderRadius: "10.5263px"}} />
                            </AnimationDiv>
                        </div>
                    </div>

                    <div style={{height: 40}} />
                    
                    <div className="fullWidth">
                        <div className="font16middle" style={{color: "#6F6F6F"}}  >주문 발생 알림<br />실시간으로 확인하세요!</div>
                    </div>
                    
                    <div style={{height: 28}} />


                    <div className="flexRowWrapper" style={{gap: 20}}>
                        <AnimationDiv>
                            <img src={"images/mobile-home-sales-1.png"} style={{width: "100%", boxShadow: "-6.14035px 7.89474px 11.4035px rgba(0, 0, 0, 0.05)",borderRadius: "10.5263px"}} />
                        </AnimationDiv>
                        <AnimationDiv delay={0.15} >
                            <img src={"images/mobile-home-sales-2.png"} style={{width: "100%", boxShadow: "-6.14035px 7.89474px 11.4035px rgba(0, 0, 0, 0.05)",borderRadius: "10.5263px"}} />
                        </AnimationDiv>
                    </div>
                    
                    <div style={{height: 60}} />
                    
                </MobileContentLightGrayWrapper>

                <MobileContentWhiteWrapper>

                    <div style={{height: 60}} />

                    <div className="fullWidth" >
                        <img src="/images/sellerboxKiwiIcon.png" style={{width: 120}} />
                    </div>
                    
                    <div style={{display: "flex",width: "100%", flexDirection: "column", alignItems: "flex-start"}}>
                        <div style={{marginTop: 32,width: "auto",fontSize: 28, fontWeight: 800, color: "#FF7F00",lineHeight: "140%"}} >
                            셀러박스 키위로
                        </div>

                        <div style={{fontSize: 28, fontWeight: 400, color: "#111111",lineHeight: "140%"}} >
                            좋은 키워드 찾고
                        </div>
                        <div style={{fontSize: 28, fontWeight: 400, color: "#111111",lineHeight: "140%"}} >
                            경쟁상점 매출 분석하세요
                        </div>
                    </div>
                    
                    <div style={{height: 12}} />
                    
                    <div className="flexRowWrapper rowLeft" >
                        <div style={{width: "auto",borderRadius: 8, backgroundColor: "#FFF7DD", color: "#DBA400", padding: "10px 16px"}} className="font16bold" >
                            1개월 무료 사용 가능!
                        </div>
                    </div>

                    <div style={{height: 45}} />

                    <div className="flexColumnWrapper" style={{gap: 24}}>
                        <AnimationDiv>
                            <img src={"/images/sellerboxKiwiImage1.png"} style={{width: "100%", boxShadow: "-6.14035px 7.89474px 11.4035px rgba(0, 0, 0, 0.05)",borderRadius: "10.5263px"}} />
                        </AnimationDiv>
                        <AnimationDiv  >
                            <img src={"/images/sellerboxKiwiImage2.png"} style={{width: "100%", boxShadow: "-6.14035px 7.89474px 11.4035px rgba(0, 0, 0, 0.05)",borderRadius: "10.5263px"}} />
                        </AnimationDiv>
                        <AnimationDiv  >
                            <img src={"/images/sellerboxKiwiImage3.png"} style={{width: "100%", boxShadow: "-6.14035px 7.89474px 11.4035px rgba(0, 0, 0, 0.05)",borderRadius: "10.5263px"}} />
                        </AnimationDiv>
                    </div>
                    
                    <div style={{height: 42}} />

                    <div className="flexRowWrapper rowLeft font16bold" style={{color: "#FF6363",gap: 5}}  >
                        <img src="/images/alertIcon.png" style={{width: 20}} /> 알림톡 발송 서비스
                    </div>

                    <div style={{height: 10}} />
                        
                    <div className="fullWidth">
                        <div className="font20bold" style={{color: "#111111"}}  >매일 아침 순위를 보내드려요!</div>

                        <div style={{height: 24}} />

                        <div className="font16bold" style={{color: "#666666"}} >
                            내 상품에 등록된 키워드를 <br />
                            매번 찾아보지 않아도 확인 가능해요.
                        </div>

                        <div style={{height: 12}} />

                        <div className="font16bold" style={{color: "#666666"}} >
                            상품 모니터링에 등록된 키워드 별 <br />
                            순위 변화를 매일 아침 알려드려요.
                        </div>

                        <div style={{height: 30}} />

                        <AnimationDiv  >
                            <img src={"/images/alertTalkImage.png"} style={{width: "100%", boxShadow: "-6.14035px 7.89474px 11.4035px rgba(0, 0, 0, 0.05)",borderRadius: "10.5263px"}} />
                        </AnimationDiv>

                        <div style={{height: 120}} />
                        
                    </div>
                    

                </MobileContentWhiteWrapper>

                {/* <MobileContentGrayWrapperWithNoPadding style={{padding: "0px 20px"}} >
                    
                    <div style={{height: 42}} />

                    <div className="fullWidth" style={{fontSize: 22, fontWeight: 600, color: "#434343"}}>
                        가입하실 전화번호를 등록하세요.<br />
                        셀러박스 가입 후 <span style={{color: "#FF7229"}} >1개월 무료 이용권을</span><br />
                        제공해 드립니다!
                    </div>

                    <div style={{height: 33}} />

                    <input className="eventPhoneNumberInput" placeholder="휴대폰 번호 입력 (‘-’ 제외)" type="text" style={{width: '100%', borderRadius: 8, backgroundColor: "#FFFFFF", border: "none", padding: "11px 14px"}} value={phoneNumber} onChange={handlePhoneNumber} />
                    
                    <div style={{height: 14}} />

                    <div className="flexRowWrapper rowLeft font14small" style={{gap: 8, color: "#8B8B8B"}} >
                        <div onClick={handleAgree} style={{cursor: "pointer"}} >
                            {
                                isAgreed
                                ?
                                <img src="/images/enabledCheckBox.png" style={{width: 20}} />
                                :
                                <img src="/images/disabledCheckBox.png" style={{width: 20}} />
                            }
                        </div>
                        
                        <div>
                            1개월 무료이용권 대상자 확인을 위한 용도로 휴대폰 
                            번호를 등록합니다
                        </div>
                    </div>

                    <div style={{height: 50}} />

                    <div className={getIsActiveButton() ? "enabledSubmitButton" : "disabledSubmitButton" } style={{opacity: isApiLoading ? 0.5 : 1}}  onClick={handleSubmit} >
                        전송하기
                    </div>

                    <div style={{height: 138}} />

                </MobileContentGrayWrapperWithNoPadding> */}
                
                <Modal
                    open={isModalOpen}
                    onClose={() => {}}
                    disableAutoFocus={true}
                    style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100vw",
                    justifyContent: "center",
                    alignItems: "center",
                    }}
                >
                    <div  style={{backgroundColor: "#FFFFFF", padding: "20px 20px", borderRadius: 8, minHeight: 160, minWidth: 300,maxWidth: 300,display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <div className="flexCenter font14middle"  style={{minHeight: "calc(100% - 38px)",whiteSpace: "pre-wrap"}}>
                            {modalContent}
                        </div>
                        <ModalInnerButton
                            style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "140px",
                            height: "38px",
                            borderRadius: "10px",
                            backgroundColor: "#FF7F00",
                            color: "#FFFFFF",
                            fontWeight: 500,
                            cursor: "pointer",
                            }}
                            onClick={(e) => {
                                if(isSuccess)handleDownLoad();
                                setIsModalOpen(false);
                                setIsSuccess(false);
                            }}
                        >
                            {"확인"}
                        </ModalInnerButton>
                    </div>
                </Modal>
                

                <img src="/images/scrollUpIcon.png"  style={{cursor: "pointer",position: "fixed", bottom: 20, right: 20, borderRadius: "50%", width: 60, height: 60,boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.15)"}} onClick={()=>{
                    window.scrollTo(0,0);
                }} />

            </MobilePageWrapper>
        </WholePageWrapper>
    ); 
}

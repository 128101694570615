import * as React from 'react';
import { useHistory, useLocation } from 'react-router';
import { recruitmentUrl } from '../../constants/urls';
import { useMediaQueryForMobile } from '../../hooks/useMediaQueryForMobile';
import WebMenuBar from './_components/WebMenuBar';
import MobileMenuBar from './_components/MobileMenuBar';
import styled from 'styled-components';

export function MenuBar({}) {
  const history = useHistory();
  const { pathname } = useLocation();
  const xsMatches = useMediaQueryForMobile();
  const [isScrolled, setIsScrolled] = React.useState(false);

  const goUrl = React.useCallback(
    (url: string) => () => {
      history.push(url);
    },
    [history]
  );

  const isDarkTheme = pathname === recruitmentUrl;
  const width = window.innerWidth;

  // 스크롤 이벤트 핸들러
  const handleScroll = React.useCallback(() => {
    if (window.scrollY === 0) {
      setIsScrolled(false);
    } else {
      setIsScrolled(true);
    }
  }, []);

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <MenuBarContainer isScrolled={isScrolled}>
      <div
        style={{
          maxWidth: 1440,
          width: (width || 0) > 1440 ? 1440 : '100%',
          left: 0,
          right: 0,
          marginLeft: 'auto',
          marginRight: 'auto',
          position: 'relative',
        }}
      >
        {!xsMatches ? (
          <WebMenuBar goUrl={goUrl} isDarkMode={isDarkTheme} />
        ) : (
          <MobileMenuBar goUrl={goUrl} isDarkMode={isDarkTheme} />
        )}
      </div>
    </MenuBarContainer>
  );
}
const MenuBarContainer = styled('header')<{ isScrolled: boolean }>`
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: ${({ isScrolled }) => (isScrolled ? 'rgba(255, 255, 255, 1)' : 'transparent')};
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: ${({ isScrolled }) => (isScrolled ? '0 2px 4px rgba(0, 0, 0, 0.1)' : 'none')};
`;

import styled from 'styled-components';
import { colors } from '../../../../style';
import font from '../../../../style/font';
import { motion } from 'framer-motion/dist/framer-motion';
import { selectedType } from './MainContainer';

export const Container = styled.div({
  marginTop: 120,
  marginBottom: 120,
});

export const MainText = styled.div({
  fontSize: 40,
  fontWeight: 800,
  color: colors.gray.GR900,
});
export const SubText = styled.div({
  fontSize: 22,
  fontWeight: 500,
  color: colors.gray.GR900,
  marginTop: 16,
  marginBottom: 32,
});
export const ContentContainer = styled.div<{}>({
  display: 'flex',
  width: '100%',
  height: 442,
  backgroundColor: colors.gray.White,
  borderRadius: 20,
});
export const TitleContainer = styled.div<{
  type: selectedType;
}>(
  {
    display: 'flex',
    flexDirection: 'column',
    width: 330,
    position: 'relative',
  },
  ({ type }) => ({
    justifyContent: type === 'Sellerbox' ? 'center' : 'flex-start',
    paddingTop: type === 'Sellerbox' ? 0 : 46,
  })
);

export const Title = styled.div<{
  isActived: boolean;
  type: selectedType;
}>(
  {
    fontSize: 18,
    marginTop: 22,
    marginBottom: 22,
    textAlign: 'end',
    paddingRight: 50,
    cursor: 'pointer',
    transition: 'color 0.3s ease',
    lineHeight: '26px',
  },
  ({ isActived, type }) => ({
    color: !isActived
      ? colors.gray.GR750
      : type === 'Sellerbox'
      ? colors.orange.OR500
      : colors.purple.PU500,
    fontWeight: isActived ? 600 : 500,
    '&:hover': {
      color: type === 'Sellerbox' ? colors.orange.OR500 : colors.purple.PU500,
    },
  })
);

export const Indicator = styled.div<{ topPosition: number; type: selectedType }>(
  ({ topPosition, type }) => ({
    position: 'absolute',
    right: '0px',
    width: '3px',
    height: '26px',
    backgroundColor: type === 'Sellerbox' ? colors.orange.OR500 : colors.purple.PU500,
    transition: 'top 0.3s ease',
    top: `${topPosition}px`,
  })
);

export const DescContainer = styled.div({
  backgroundColor: colors.gray.GR150,
  borderTopRightRadius: 20,
  borderEndEndRadius: 20,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'end',
  whiteSpace: 'pre-wrap',
});
export const Desc = styled(motion.div)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});
export const DescText = styled.div({
  marginTop: 8,
  textAlign: 'center',
  ...font.Title3S18,
});

import React, { useEffect, useState } from 'react';
import {
  Container,
  LeftContainer,
  RightContainer,
  LeftDecoration,
  RightTopDecoration,
  RightBottomDecoration,
  MainImageContainer,
  TextContainer,
  MainTextContainer,
  MainText,
  SubTextContainer,
  SubText,
  FreeText,
  StyledPCButton,
  textVariants,
  MainImage,
  imageVariants,
} from './MainContainerStyle';
import { ReactComponent as ArrowGrayRight } from '../../../../assets/icons/ArrowGrayRight.svg';
import { MAIN_DATA } from '../const';
import AppStoreLinkContainer from '../AppStoreLinkContainer';
import { useMediaQueryForMobile } from '../../../../hooks/useMediaQueryForMobile';

export type selectedType = 'Sellerbox' | 'Sellkey';

interface MainContainerProps {
  selected: selectedType;
}

const MainContainer = ({ selected }: MainContainerProps) => {
  const [activedTab, setActivedTab] = useState(0);
  const isMobile = useMediaQueryForMobile();

  const handleNextTab = () => {
    setActivedTab((prevTab) => (prevTab + 1) % MAIN_DATA[selected].text.length);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNextTab();
    }, 5000);

    return () => clearInterval(interval);
  }, [selected]);

  const renderTextContent = () => (
    <TextContainer>
      <MainTextContainer>
        {MAIN_DATA[selected].text[activedTab].main.map((item) => (
          <MainText
            key={item}
            type={selected}
            variants={textVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 0.4 }}
          >
            {item}
          </MainText>
        ))}
      </MainTextContainer>
      <SubTextContainer type={selected}>
        {MAIN_DATA[selected].text[activedTab].sub.map((item) => (
          <SubText
            key={item}
            type={selected}
            variants={textVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 0.4 }}
          >
            {item}
          </SubText>
        ))}
      </SubTextContainer>
      {selected === 'Sellerbox' && (
        <>
          <FreeText>무료로 시작하세요!</FreeText>
          <AppStoreLinkContainer isMobile={isMobile} />
        </>
      )}
      {selected === 'Sellkey' && (
        <StyledPCButton onClick={() => window.open('https://sellkey.sellerbox.io')}>
          셀러키워드 바로가기
          <ArrowGrayRight />
        </StyledPCButton>
      )}
    </TextContainer>
  );

  const renderImageContent = () => (
    <MainImageContainer type={selected}>
      <MainImage
        key={activedTab}
        src={MAIN_DATA[selected].image[activedTab]}
        variants={imageVariants}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.4 }}
      />
    </MainImageContainer>
  );

  return (
    <Container>
      <LeftContainer>{renderTextContent()}</LeftContainer>
      <RightContainer type={selected}>{renderImageContent()}</RightContainer>
      <LeftDecoration type={selected} />
      <RightTopDecoration />
      <RightBottomDecoration type={selected} />
    </Container>
  );
};

export default MainContainer;

import * as React from "react";
import mainLogo from "../assets/logo/mainLogo.png";
import { Divider, styled, Typography } from "@mui/material";
import RecuritementsImage01 from "../assets/images/Recruitments01.png";
import RecuritementsImage02 from "../assets/images/Recruitments02.png";

import Recruitments_Welfare01 from "../assets/images/Recruitments_Welfare01.png";
import Recruitments_Welfare02 from "../assets/images/Recruitments_Welfare02.png";
import Recruitments_Welfare03 from "../assets/images/Recruitments_Welfare03.png";
import Recruitments_Welfare04 from "../assets/images/Recruitments_Welfare04.png";

import { MySection } from "../widgets/MySection";
import { ImageMask } from "../widgets/ImageMask";
import { useMediaQueryForMobile } from "../hooks/useMediaQueryForMobile";
import { MyCarousel } from "../widgets/MyCarousel";
import { MyCircleButton } from "../widgets/MyCircleButton";
import { MySellerBox } from "../widgets/MySellerBox";

type WelFareItemType = {
  imageUrl: string;
  label: React.ReactNode;
  description: React.ReactNode;
};
const WelFareItems: WelFareItemType[] = [
  {
    imageUrl: Recruitments_Welfare01,
    label: (
      <Typography variant={"h4"} color={"white"}>
        {"자율"} <br /> {"출퇴근제"}
      </Typography>
    ),
    description: (
      <Typography variant="caption" color={"#A4A4A4"} >
        {"가장 빛나며 일할 수 있는 시간과 장소를 자유롭게 조정할 수 있습니다."}
      </Typography>
    ),
  },
  {
    imageUrl: Recruitments_Welfare02,
    label: (
      <Typography variant={"h4"} color={"white"}>
        {"식비 제공"}
      </Typography>
    ),
    description: (
      <Typography variant="caption" color={"#A4A4A4"}>
        {"밥심이 없어 지치지 않도록 중식과 석식"} 
        <br />
        {"비용은 회사에서 지급합니다."}
      </Typography>
    ),
  },
  {
    imageUrl: Recruitments_Welfare03,
    label: (
      <Typography variant={"h4"} color={"white"}>
        {"간식 코너"}
      </Typography>
    ),
    description: (
      <Typography variant="caption" color={"#A4A4A4"}>
        {
          "업무에 지치고 않고 리프레시 할 수 있도록 다양한 티와 주전부리를 마련했습니다."
        }
      </Typography>
    ),
  },
  {
    imageUrl: Recruitments_Welfare04,
    label: (
      <Typography variant={"h4"} color={"white"}>
        {"쾌적한"} <br /> {"업무 환경"}
      </Typography>
    ),
    description: (
      <Typography variant="caption" color={"#A4A4A4"}>
        {"본인이 원하는 사양의 최신 노트북을 제공합니다."}
      </Typography>
    ),
  },
];

type RecruitmentItemType = {
  action: () => void;
  label: React.ReactNode;
};

const RecuritmentItems: RecruitmentItemType[] = [
  {
    action: () => {window.open("https://www.wanted.co.kr/wd/81137")},
    label: "Product Owner",
  },
  // {
  //   action: () => {},
  //   label: "Data Analyst",
  // },
  // {
  //   action: () => {},
  //   label: "Marketer",
  // },
  {
    action: () => {window.open("https://www.wanted.co.kr/wd/83946")},
    label: "Designer",
  },
];

export interface IRecruitmentPageProps {}

export const RecruitmentPage: React.FC<IRecruitmentPageProps> = ({}) => {
  const xsMatches = useMediaQueryForMobile();

  return (
    <>
      <MySection
        style={{
          backgroundImage: `url(${RecuritementsImage01})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          justifyContent: "center",
          alignItems: "center",
          overflowX: "hidden",
        }}
      >
        <ImageMask
          style={{
            zIndex: 1,
          }}
        />
        <Typography
          variant={"h1"}
          textAlign={"center"}
          style={{
            color: "white",
            zIndex: 2,
          }}
        >
          {"데이터와 IT로"}
          <br />
          {"세상을 바꿀 열정을 가진 당신을"}
          <br />
          {"모시고 싶습니다."}
        </Typography>
      </MySection>
      <MySection
        style={{
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {/* <MySellerBox /> */}
        <img src={mainLogo} style={{width: "160px"}} />
        <Typography
          variant={"h2"}
          textAlign={"center"}
          style={{ paddingTop: 96, paddingBottom: 96 }}
        >
          {"서클플랫폼이 일하는 방식"}
        </Typography>
        <Typography variant={"body1"} textAlign={"center"}>
          {"세상의 불편함을 개선하고자 항상 고민합니다."} <br />
          {"수평적 의사소통을 통해 가장 나은 대안을 모색합니다."} <br />
          {"스스로 문제를 식별하고 모두 함께 해결합니다."} <br />
          {"사용자의 가치를 최우선으로 둡니다."}
        </Typography>
      </MySection>
      <MySection
        style={{
          justifyContent: "center",
          flexDirection: "column",
          gap: 60,
        }}
      >
        <ImageWrapper
          style={{
            overflow: "hidden",
            backgroundImage: `url(${RecuritementsImage02})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <ImageMask
            style={{
              overflow: "hidden",
              zIndex: 1,
            }}
          />
          <Typography
            variant={"h2"}
            textAlign={"center"}
            style={{
              color: "white",
              zIndex: 2,
            }}
          >
            {"서클 플랫폼의 복지"}
          </Typography>
        </ImageWrapper>
        {xsMatches ? (
          <MyCarousel itemWidth={238} itemHeight={390}>
            {WelFareItems.map((item, idx) => {
              return <ImageItem item={item} key={idx} />;
            })}
          </MyCarousel>
        ) : (
          // </div>
          <WelfareWrapper>
            {WelFareItems.map((item, idx) => {
              return <ImageItem item={item} key={idx} />;
            })}
          </WelfareWrapper>
        )}
      </MySection>
      <MySection
        style={{
          justifyContent: "center",
          paddingTop: 109,
          paddingBottom: 109,
          // maxWidth: 1440,
          // flexDirection: "column",
        }}
      >
        <RecruitmentApplyContainer>
          <Typography variant={"h2"}>{"채용중인 포지션"}</Typography>
          {RecuritmentItems.map((item, idx) => (
            <React.Fragment key={idx}>
              <RecruitMentItemDiv style={{}}>
                <Typography variant={"h5"}>{item.label}</Typography>
                <MyCircleButton onClick={item.action}>
                  <Typography variant={"body2"} color={"#6f6f6f"}>
                    {"지원공고 확인"}
                  </Typography>
                </MyCircleButton>
              </RecruitMentItemDiv>
              <Divider />
            </React.Fragment>
          ))}
        </RecruitmentApplyContainer>
      </MySection>
    </>
  );
};

const ImageWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  height: "60%",
  [theme.breakpoints.down("sm")]: {
    height: "50%",
  },
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const WelfareWrapper = styled("div")({
  display: "flex",
  justifyContent: "center",
  gap: 20,
});

const ImageItem = ({ item }: { item: WelFareItemType }) => {
  return (
    <ImageItemWrapper>
      <ImageItemImageWrapper
        style={{
          backgroundImage: `url(${item.imageUrl})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <Gradient>
          <LabelWrapper>{item.label}</LabelWrapper>
        </Gradient>
      </ImageItemImageWrapper>
      <DescriptionWrapper>{item.description}</DescriptionWrapper>
    </ImageItemWrapper>
  );
};

const ImageItemWrapper = styled("div")({
  width: 238,
  height: 400,
  display: "flex",
  flexDirection: "column",
  gap: 33,
});

const ImageItemImageWrapper = styled("div")({
  position: "relative",
  width: 238,
  height: 238,
});

const Gradient = styled("div")({
  position: "absolute",
  left: 0,
  bottom: 0,
  height: 155,
  width: "100%",
  background:
    "linear-gradient(180deg, rgba(28, 28, 28, 0) 40.29%, rgba(28, 28, 28, 0.1886) 48.96%, rgba(28, 28, 28, 0.46) 78.12%)",
});

const LabelWrapper = styled("div")({
  position: "absolute",
  left: 22,
  bottom: 20,
});

const DescriptionWrapper = styled("div")({});

const RecruitMentItemDiv = styled("div")(({ theme }) => ({
  height: 136,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    height: 120,
  },
}));
const RecruitmentApplyContainer = styled("div")(({ theme }) => ({
  maxWidth: 1100,
  width: "calc(100% - 340px)",
  // paddingTop: 109,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  [theme.breakpoints.down("sm")]: {
    paddingLeft: 30,
    paddingRight: 30,
    minWidth: 0,
    paddingTop: 109,
    width: "calc(100% - 20px)",
    // paddingTop: 50,
    // paddingBottom: 50,
  },
}));

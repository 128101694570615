// GNB
export const homeUrl = '/';
export const serviceUrl = '/services'; // 셀러박스
export const sellkeyUrl = '/sellkey'; // 셀키
export const priceUrl = '/price'; // 이용요금
export const faqUrl = '/clients/faq'; // 고객지원

export const promotion_3o3 = '/3o3_promotion2310';
export const companyUrl = '/company';
export const appDownloadUrl = '/appDownload';
export const recruitmentUrl = '/recruitment';
export const clientsUrl = '/clients';

export const partnerUrl = '/clients/partner';
export const noticeUrl = '/clients/notice';
export const ContactUrl = '/clients/contact';

export const naverRegisterToken = '/naverRegisterToken';
export const naverRegisterStart = '/naverRegisterStart';
export const signIn = '/signIn';
export const registerTerms = '/registerTerms';
export const termsDetail = '/termsDetail';
export const smartStoreGuideUrl = '/smartStoreGuide';
export const marketFinishUrl = '/market/finish';

export const logout = '/logout';
export const TermsOfUse = '/termsOfUse';
export const PrivacyPolicy = '/privacy';
export const MarketingAgree = '/marketingAgree';

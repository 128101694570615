import { styled } from "@mui/material";
import { Children } from "react";
import React from "react";

export interface IMyCarouselType {
  style?: React.CSSProperties;
  itemWidth: number;
  itemHeight: number;
}
export const MyCarousel: React.FC<IMyCarouselType> = ({
  style,
  children,
  itemWidth,
  itemHeight,
}) => {
  // return <CarouselContainer style={style}>{children}</CarouselContainer>;
  return (
    <CarouselContainer style={style}>
      <CarouselItemPadding itemwidth={itemWidth} itemheight={itemHeight} />
      {Children.map(children, (child, idx) => (
        <CarouselItem
          key={idx}
          style={{ width: itemWidth, height: itemHeight }}
        >
          {child}
        </CarouselItem>
      ))}
      <CarouselItemPadding itemwidth={itemWidth} itemheight={itemHeight} />
    </CarouselContainer>
  );
};

export const CarouselContainer = styled("div")({
  width: "100%",
  overflowX: "auto",
  overflowY: "hidden",
  display: "flex",
  scrollSnapType: "x mandatory",
  gap: 30,
});
export const CarouselItem = styled("div")({
  scrollSnapAlign: "center",
});
export const CarouselItemPadding = styled("div")<{
  itemwidth: number;
  itemheight: number;
}>(({ itemwidth, itemheight }) => ({
  width: itemwidth,
  minWidth: itemwidth,
  height: itemheight,
}));

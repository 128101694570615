import { Button, Dialog, Modal } from "@mui/material";
import React from "react";
import useAlertModal from "../../hooks/useAlertModal";
import { colors, font } from "../../style";

export interface AlertModalPorps {
    title: string;
    description: string;
    action?: () => void | null;
    isOpen: boolean;
    onClose?: () => void;
}
const AlertModal = ({
    title,
    description,
    action = () => {},
    isOpen,
    onClose = () => {}
}: AlertModalPorps) => {
    const requestAlertModal = useAlertModal();
    const handleCloseDialog = () => {
        action();
        requestAlertModal({
            isAlertModalOpen: false            
        });
    }
    return <Dialog
        open={isOpen}
        onClose={()=>{onClose();}}
        disableAutoFocus={true}    
    >
        <div style={{ borderRadius: 12, backgroundColor: "#FFFFFF", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 20, padding: "16px 20px"}} >
            <div
                style={{
                    color: colors.gray.GR800,
                    ...font.default.Title2B20
                }}
            >
                {title}
            </div>
            <div>
                {description}
            </div>
            <Button onClick={handleCloseDialog} >
                확인
            </Button>
        </div>
        
    </Dialog>
}
export default AlertModal;
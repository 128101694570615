import axios from 'axios';
// axios.defaults.withCredentials = true;
export const sendInquiry = (data) => {
  let formData = new FormData();
  formData.append('title', data.title);
  formData.append('name', data.name);
  formData.append('email', data.email);
  formData.append('phone', data.phone);
  formData.append('content', data.content);
  data.uploadFiles.forEach((file) => {
    formData.append('uploadFiles', file);
  });
  return axios.post(process.env.REACT_APP_BACKEND_URL + 'pr/inquiry', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const sendPartnerShip = (data) => {
  let formData = new FormData();
  formData.append('title', data.title);
  formData.append('content', data.content);
  formData.append('company', data.company);
  formData.append('name', data.name);
  formData.append('phone', data.phone);
  formData.append('email', data.email);
  formData.append('site', data.site);
  data.uploadFiles.forEach((file) => {
    formData.append('uploadFiles', file);
  });
  return axios.post(process.env.REACT_APP_BACKEND_URL + 'pr/partnerShip', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const getNotice = () => {
  return axios.post(
    process.env.REACT_APP_SELLERBOX_BACKEND + 'user/get_notice_info',
    {
      orderByMethod: 0,
      skip: 0,
      take: 30,
    }
    // {
    //   headers: {
    //     "Access-Control-Allow-Origin": "*"
    //   }
    // }
  );
};

export const getFaqInfo = () => {
  return axios.post(
    process.env.REACT_APP_SELLERBOX_BACKEND + 'user/get_faq_info',
    {
      searchKeyword: '',
      orderByMethod: 0,
      skip: 0,
      take: 10000,
    },
    {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    }
  );
};

const noticeinform = {
  OrderByMethod: 0,
  Skip: 1,
  Take: 10,
};

export const GetNoticeTest = async (title, content, date) => {
  const endPoint = 'https://api.cplat.io/v2/user/get_notice_info';
  const response = await fetch(endPoint, {
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(noticeinform),
  });
  return await response.json();
};
export const GetFaqListTest = async () => {
  const endPoint = 'https://api.cplat.io/v2/user/get_faq_info';
  const Faqinform = {
    SearchKeyword: '',
    OrderByMethod: 0,
    Skip: 0,
    Take: 10,
  };
  const response = await fetch(endPoint, {
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(Faqinform),
  });
  return await response.json();
};

export const getInvitationInfo = async (CplatToken) => {
  return axios.post('https://api.cplat.io/v2/user/get_invitation_info', {
    CplatToken: CplatToken,
  });
};

export const getInvitationNotice = async () => {
  return axios.post('https://api.cplat.io/v2/app/get_invitation_notice');
};

export const UserRegisterMarketAccount = (data) => {
  return axios.post(process.env.REACT_APP_SELLERBOX_BACKEND + `user/register_market_account`, data);
};

export const UserSetAuthenticationCodeByMarket = (data) => {
  return axios.post(
    process.env.REACT_APP_SELLERBOX_BACKEND + `user/set_authentication_code_by_market`,
    data
  );
};

export const UserGetRegisteredMarketAccountList = (data) => {
  return axios.post(
    process.env.REACT_APP_SELLERBOX_BACKEND + `user/get_registered_market_account_list`,
    data
  );
};

export const UserUnregisterMarketAccount = (data) => {
  return axios.post(
    process.env.REACT_APP_SELLERBOX_BACKEND + `user/unregister_market_account`,
    data
  );
};

export const GetMarketStatusTemp = (data) => {
  return axios.post(process.env.REACT_APP_SELLERBOX_BACKEND + 'ai-cs/get_market_status_temp', data);
};

export const SetMarketStatusTemp = (data) => {
  return axios.post(process.env.REACT_APP_SELLERBOX_BACKEND + 'ai-cs/set_market_status_temp', data);
};

export const GetMarketModel = (data) => {
  return axios.post(process.env.REACT_APP_SELLERBOX_BACKEND + 'service/get_market_model', data);
};

export const SetTempSellkeyPhoneNo = (data) => {
  return axios.post(
    process.env.REACT_APP_SELLERBOX_BACKEND + 'service/set_temp_sellkey_phone_no',
    data
  );
};

export const UserSendAuthenticationCode = (data) => {
  let payload = {
    ServiceType: 0,
    ...data,
  };
  return axios.post(
    process.env.REACT_APP_SELLERBOX_BACKEND + 'user/send_authentication_code',
    payload
  );
};

export const UserVerifyAuthenticationCode = (data) => {
  let payload = {
    ...data,
  };
  return axios.post(
    process.env.REACT_APP_SELLERBOX_BACKEND + 'user/verify_authentication_code',
    payload
  );
};

export const UserSignInByPhoneNo = (data) => {
  let payload = {
    ...data,
  };
  return axios.post(process.env.REACT_APP_SELLERBOX_BACKEND + 'user/sign_in_by_phone_no', payload);
};

export const UserSignUpByPhoneNo = (data) => {
  let payload = {
    ...data,
  };
  return axios.post(process.env.REACT_APP_SELLERBOX_BACKEND + `user/sign_up_by_phone_no`, payload);
};

export const UserSignInByPass = (data) => {
  let payload = {
    ...data,
  };
  return axios.post(process.env.REACT_APP_SELLERBOX_BACKEND + 'user/sign_in_by_pass', payload);
};

export const SetNaverCommerceToken = (data) => {
  let payload = {
    ...data,
  };
  return axios.post(
    process.env.REACT_APP_SELLERBOX_BACKEND + 'user/set_naver_commerce_token',
    payload
  );
};

export const SetShopbyCommerceCode = (data) => {
  return axios.post(
    process.env.REACT_APP_DEV_SELLERBOX_BACKEND + 'user/set_shopby_authorization_code',
    { ...data }
  );
};

export const SetCafe24CommerceCode = (data) => {
  // TODO live server url 수정
  return axios.post(
    process.env.REACT_APP_DEV_SELLERBOX_BACKEND + 'user/set_cafe24_authorization_code',
    { ...data }
  );
};

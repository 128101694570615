import React, { useEffect,useState } from "react";
import { marketList, ShowMarketIcon,getKorNameByEn } from "./marketList";

import { colors, Modal } from "@mui/material";
import LoadingGear from "../../../assets/svg/loadingGear.svg";
import * as api from "../../../controller/api";
import { lineHeight } from "@mui/system";
import checkGray from "../../../assets/images/checkGray.png";
import checkWhite from "../../../assets/images/checkWhite.png";
import enabledCircleCheckBox from  "../../../assets/images/enabledCircleCheckBox.png";
import disabledCircleCheckBox from  "../../../assets/images/disabledCircleCheckBox.png";

import transparentImage from "../../../assets/images/transparent.png";
import exitIcon from "../../../assets/images/exitIcon.png";
import enableHideEye from "../../../assets/images/enableHideEye.png";
import disableHideEye from "../../../assets/images/disableHideEye.png";
import whiteCircleCheck from "../../../assets/images/whiteCircleCheck.png";
import whiteCircleCheckedOutlined from "../../../assets/images/whiteCircleCheckedOutlined.png";
import mobileSecondaryAuthMock from "../../../assets/images/mobileSecondaryAuthMock.png";
import pcSecondaryAuthMock from "../../../assets/images/pcSecondaryAuthMock.png";
import smallevent from "../assets/images/smalleventtitle.png";
import QueryString from "qs";
import "./Market.css";



//소환한 페이지에사 reciver

// window.addEventListener("message",getIframeMessage);

// if(event.data.messageType==="alert"){
//     enqueueSnackbar(
//       event.data.messageData.message,
//       {
//         variant: event.data.messageData.type
//       }
//     );
// }

// 소환한 페이지에 메시지 보내기 
export const messageToParent = (request: any) => {
        window.parent.postMessage({messageType: request.messageType, messageData: request.messageData},"*")
}

// ex) snackbar alert 쏘기  receiver쪽에도 필요
// alertData = {
//     message: "123123",
//     type: "error"
// }
export const alertToParent = (alertData: any) => {
    messageToParent({
        messageType: "alert",
        messageData: alertData
    })
}

export const marketListDataToParent= (marketListData: any) => {
    messageToParent({
        messageType: "marketList",
        messageData: marketListData
    })
}

export const DisabledCircleCheckBox: any = () => {
    return (
        <div style={{width: 20, height: 20, borderRadius: 6, display: "flex", justifyContent: "center", alignItems: "center"}}>
                <img src={disabledCircleCheckBox} style={{width: 20}} />
        </div>
    );
}

export const EnabledCircleCheckBox: any = () => {
    return (
        <div style={{width: 20, height: 20, borderRadius: 6, display: "flex", justifyContent: "center", alignItems: "center"}}>
            <img src={enabledCircleCheckBox} style={{width: 20}} />
        </div>
    );
}

export const DisabledCheckBox = () => {
    return (
        <div style={{width: 24, height: 24, borderRadius: 6, backgroundColor: "#F4F4F6", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <img src={checkGray}  />
        </div>
    )
}

export const EnabledCheckBox = () => {
    return (
        <div style={{width: 24, height: 24, borderRadius: 6, backgroundColor: "#FF7F00", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <img src={checkWhite} />
        </div>
    )
}



export const SellkeyMarket = (props: any) => {


    let qs = QueryString.parse(window.location.search);
    
    const [markets,setMarkets]=useState<any>(marketList)
    const [linkedMarkets,setLinkedMarkets] = useState<any>(null);
    
    const [CplatToken,setCplatToken] = useState<any>((new URLSearchParams(window.location.search)).get("CplatToken"));
    //default ai-seller 
    const [serviceType,setServiceType] = useState<any>((new URLSearchParams(window.location.search)).get("ServiceType"));

    //ai-seller validateCheckedList 반품 가능한지 검사 리스트
    const [marketValidateList,setMarketValidateList]=useState<any>([]);


    const [isAbleToPostMessage,setIsAbleToPostMessage]=useState<boolean>(false);
    //modal Data



    const [alertText,setAlertText] = useState<any>({type: "", value: ""});
    const [isMarketRegisterModalOpen,setIsMarketRegisterModalOpen]=useState<boolean>(false);
    const [selectedMarket,setSelectedMarket] = useState<any>(null);
    const [selectedAccountTypeIndex,setSelectedAccountTypeIndex]=useState<number>(0);
    const [inputData,setInputData]=useState<any>({SecondaryAuthenticationCode: ""});
    const [isApiLoading,setIsApiLoading]=useState<boolean>(false);
    const [isNeedSecondaryAuthenticationCode,setIsNeedSecondaryAuthenticationCode]=useState<boolean>(false);
    const [isNeedAppAuth,setIsNeedAppAuth]=useState<boolean>(false);
    const [isNeedSecondaryAuthenticationCodeGenerating,setIsNeedSecondaryAuthenticationCodeGenerating]=useState<boolean>(false);
    const [modalInputHideObject,setModalInputHideObject] = useState<any>({"0": true});

    const [secondaryAuthenticationTimer,setSecondaryAuthenticationTimer]=useState(0);
    const [secondaryAuthenticationTimerClearObject,setSecondaryAuthenticationTimerClearObject]=useState<any>(null);

    const [isMobile,setIsMobile]=useState(qs?.isMobile==="true" ? true : false);
    const [config,setConfig]=useState({
        width: parseInt(qs?.width as string),
        height: parseInt(qs?.height as string)
    })
    


    // useEffect(()=>{
        
        
    //     setInterval(()=>{
    //         alertToParent({message: "123123", type: "success"});
    //     },2000)
    // },[])

    const restartTimer = () => {
        if(isNeedSecondaryAuthenticationCodeGenerating){
            alertToParent({message: "재전송 진행중입니다.", type: "error"});
            return;
        }    
        if(secondaryAuthenticationTimer>50){
            alertToParent({message: "재전송은 최소 10초의 시간이 경과 되어야 가능합니다.", type: "error"});
            return;
        }
        generateAuthCode();
    }

    const generateAuthCode = () => {
        setIsNeedSecondaryAuthenticationCodeGenerating(true);
        api.UserRegisterMarketAccount({
            MarketName: selectedMarket?.CategoryEn[selectedAccountTypeIndex],
            MarketLoginInfo: JSON.stringify(inputData),
            CplatToken: CplatToken
        })
        .then((res: any)=>{
            let { message, data ,code } = res.data;
            if(code==="615"){
                alertToParent({message: "재전송이 완료 되었습니다.", type: "info"});
                clearTimeout(secondaryAuthenticationTimerClearObject);
                setSecondaryAuthenticationTimer(60);
                setInputData({...inputData,SecondaryAuthenticationCode: ""});
            }else{
                alertToParent({message: "재전송이 실패 했습니다. 다시 시도해 주세요.", type: "error"});
            }
        })
        .finally(()=>{
            setIsNeedSecondaryAuthenticationCodeGenerating(false);
        })
    }

    const resetTimer = () => {
        clearTimeout(secondaryAuthenticationTimerClearObject);
        setSecondaryAuthenticationTimer(0)
        setInputData({...inputData,SecondaryAuthenticationCode: ""});
    }

    const startTimer = () => {
        setSecondaryAuthenticationTimer(60);
    }

    useEffect(()=>{
        if(isNeedSecondaryAuthenticationCode){
            startTimer();
        }else{
            resetTimer();
        }
    },[isNeedSecondaryAuthenticationCode]);

    

    useEffect(()=>{
        clearTimeout(secondaryAuthenticationTimerClearObject);
        if(secondaryAuthenticationTimer!==0){
            setSecondaryAuthenticationTimerClearObject(setTimeout(()=>{
                setSecondaryAuthenticationTimer(secondaryAuthenticationTimer-1);
            },1000))
        }
    },[secondaryAuthenticationTimer])


    //연동된 마켓 정보를 parent로 보내는 로직
    useEffect(()=>{
        if(isAbleToPostMessage){
            marketListDataToParent(linkedMarkets===null ? [] : linkedMarkets);
        }
    },[linkedMarkets])


    //토큰 있으면 마켓 리스트 받아오는 로직
    useEffect(()=>{
        if(CplatToken===null){
            console.log("No - Token");
            //토큰 없을때 처리해야함
        }else{
            handleModalOpen(marketList.find((market: any)=>market.Company.indexOf("스마트스토어")!==-1));
        }
    },[])


    //마켓연동 모달 열기
    const handleModalOpen=(market: any)=>{
        if(market.Company[0]!=="스마트스토어"){
            alertToParent({message: "현재 사용이 불가능한 마켓입니다.", type: "error"});
            return;
        }
        setSelectedMarket(market);
        setIsMarketRegisterModalOpen(true);
        let tmpInputDate: any = {};
        market.Needs.forEach((need: any)=>{
            tmpInputDate[need.type] = ""
        });
        setInputData(tmpInputDate);
    }


    //마켓연동 모달 닫기 및 초기화
    const handleModalClose=(event: any = null)=>{
        if(event){
            event.preventDefault();
        }
        setAlertText({type: "", value: ""});
        setSelectedMarket(null);
        setIsMarketRegisterModalOpen(false);
        setInputData({SecondaryAuthenticationCode: ""});
        setSelectedAccountTypeIndex(0);
        setIsNeedSecondaryAuthenticationCode(false);
        setIsApiLoading(false);
        setIsNeedAppAuth(false);
        setModalInputHideObject({"0": true});
        
        messageToParent({
            messageType: "close",
            messageData: null
        });
    }

    //2차인증 보내는 API
    const sendSecondaryAuthenticationCode = () => {
        if(inputData?.SecondaryAuthenticationCode===undefined || inputData?.SecondaryAuthenticationCode==="")return;
        if(secondaryAuthenticationTimer===0){
            alertToParent({message: "만료된 인증번호 입니다.", type: "error"});
            return;
        }
        if(isApiLoading===false){
            setIsApiLoading(true);
            api.UserSetAuthenticationCodeByMarket({
                MarketName: selectedMarket?.CategoryEn[selectedAccountTypeIndex],
                MarketId: inputData[`${selectedMarket.Needs[0].type}`],
                AuthCode: inputData.SecondaryAuthenticationCode,
            })
            .then((res: any)=>{
                let { data, message, code } = res.data;
                if(code==="200"){
                    UserGetRegisteredMarketAccountList();
                    handleModalClose();
                    alertToParent({message: "마켓이 등록되었습니다.", type: "success"});
                }else if(code==="611"){
                    UserGetRegisteredMarketAccountList();
                    // handleModalClose();
                    setIsNeedSecondaryAuthenticationCode(false);
                    alertToParent({message: "입력한 계정은 이미 사용중인 게정입니다.", type: "error"});
                    setAlertText({type: "", value: ""});
                }else{
                    alertToParent({message: "2차 인증에 실패하였습니다.", type: "error"});
                    setAlertText({type: "error", value: "2차 인증에 실패하였습니다."});
                }
            })
            .catch((err)=>{
                alertToParent({message: "오류가 발생했습니다. 다시 시도해 주세요.", type: "error"});
            })
            .finally(()=>{
                setIsApiLoading(false);
            })
        }
    }


    //1챠인증 ( ID,PW ) 보내는 API
    const UserGetRegisteredMarketAccountList = () => {
        api.UserGetRegisteredMarketAccountList({
            CplatToken
        })
        .then((res: any)=>{
            let { data, message, code } = res.data;
            if(code==="200"){
                    api.GetMarketStatusTemp({cplatToken: CplatToken})
                    .then((marketValidProcessResponse: any)=>{
                        setMarketValidateList(marketValidProcessResponse.data.data.length>0 ? marketValidProcessResponse.data.data[0].MarketStatusList : []);
                        let tmpList = data[0].MarketAccountList;
                        tmpList.map((td: any,tdi: number)=>{
                            let target = linkedMarkets?.find((lm: any,lmi: number)=>td.Key===lm.key && td.Value===lm.Value);
                            if(target){
                                td.isDeleting = target.isDeleting;
                            }else{
                                td.isDeleting = false;
                            }
                            return td;
                        });
                        setIsAbleToPostMessage(true);
                        setLinkedMarkets(tmpList);
                    });
            }else if(code==="530"){
                setLinkedMarkets(null);
            }else{
                alertToParent({message: "연동된 마켓을 불러오지 못했습니다.", type: "error"});
            }
            //핸들링 할게 있나
        })
    }

    //연동 시작하는 모듈
    const handleRegister = () => {

        //입력창 확인
        if(Object.values(inputData).filter((data: any)=>data==="").length>0){
            // alertToParent({message: "모든 항목을 입력하세요.", type: "error"});
            return;
        }

        if(isApiLoading===false){
            setAlertText({type: "", value: ""});
            setIsApiLoading(true);
            if(selectedMarket?.CategoryEn[selectedAccountTypeIndex]==="naver"){
                setIsNeedAppAuth(true);
            }else{
                setIsNeedAppAuth(false);
            }
    
            api.UserRegisterMarketAccount({
                MarketName: selectedMarket?.CategoryEn[selectedAccountTypeIndex],
                MarketLoginInfo: JSON.stringify(inputData),
                CplatToken: CplatToken
            })
            .then((res: any)=>{
                let { message, data ,code } = res.data;
                if(code==="200"){
                    UserGetRegisteredMarketAccountList();
                    handleModalClose();
                    alertToParent({message: "마켓이 등록되었습니다.", type: "success"});
                    // api.SetMarketStatusTemp({
                    //     cplatToken: CplatToken,
                    //     marketName: selectedMarket?.CategoryEn[selectedAccountTypeIndex],
                    //     marketId: inputData.MarketId
                    // }).then((marketValidProcessResponse: any)=>{
                    //     if(marketValidProcessResponse.data.code==="200"){
                    //         UserGetRegisteredMarketAccountList();
                    //         handleModalClose();
                    //         alertToParent({message: "마켓이 등록되었습니다.", type: "success"});
                    //     }else if("611"){
                    //         UserGetRegisteredMarketAccountList();
                    //         handleModalClose();
                    //         alertToParent({message: "입력한 ID는 이미 사용 가능한 ID 목록에 있습니다. 1:1 문의로 알려주새요.", type: "error"});
                    //     }
                    // })
                }else if(code==="610"){
                    setAlertText({type: "error", value: "아이디 또는 비밀번호가 일치하지 않습니다."});
                    // alertToParent({message: message, type: "error"});
                    // alertToParent({message: "아이디 또는 비밀번호가 일치하지 않습니다.", type: "error"});
                }else if(code==="611"){
                    setAlertText({type: "error", value: "이미 사용중인 마켓입니다."});
                    // alertToParent({message: message, type: "error"});
                    // alertToParent({message: "이미 사용중인 마켓입니다.", type: "error"});
                }else if(code==="615"){
                    // alertToParent({message: message, type: "success"});
                    // alertToParent({message: "2차 인증을 진행해주세요.", type: "success"});
                    // setAlertText("2차 인증 및 매니저 등록을 위한 SMS가 발송됩니다.");
                    setAlertText({type: "default", value: "1분 내 인증이 오지 않으면 다시 시도해 주세요."});
                    setIsNeedAppAuth(false);
                    setIsNeedSecondaryAuthenticationCode(true);
                }else{
                    alertToParent({message: message, type: "error"});
                }
            })
            .catch((err: any)=>{
                alertToParent({message: "오류가 발생했습니다. 다시 시도해 주세요.", type: "error"});
            })
            .finally(()=>{
                setIsNeedAppAuth(false);
                setIsApiLoading(false);
            })
        }else{
            alertToParent({message: "이미 연동중 입니다.", type: "error"});
        }
    }


    const handleReadMore = (linkedMarket: any) => {
        messageToParent({
            messageType: "route",
            messageData: {
                path: `/MarketValidProcess?MarketId=${linkedMarket.validateData.MarketId}&MarketName=${linkedMarket.validateData.MarketName}`
            }
        })
    }


    const handleModalInputHIde = (index: number) => {
        let tmp = {...modalInputHideObject};
        if(tmp[index]===undefined){
            tmp[index]=true;
        }else{
            delete tmp[index];
        }
        setModalInputHideObject(tmp);
    }

    return (
        <div style={{width: "800px",display: "flex", alignItems: "center", justifyContent: "center",backgroundColor: "#FFFFFF"}}>
            <Modal
                open={isMarketRegisterModalOpen}
                hideBackdrop={false}
                disableAutoFocus={true}
                style={{display: 'flex', justifyContent: "center", alignItems: "flex-start",backgroundColor: "#transparent"}}
            >

                <div  style={{width: config.width, height: config.height,display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{ position: "relative",boxSizing: "border-box",padding : "20px",width: config.width, height: config.height, overflow: "hidden", backgroundColor: "#FFFFFF",display: 'flex', flexDirection: "column", justifyContent: "flex-start", alignItems: 'center'}} onClick={(e)=>{e.stopPropagation()}}>
                        <div style={{height: isMobile ? 45 : 35}} />
                        <img style={{position: "absolute", top: isMobile ? 45 : 20, right: 20, cursor: "pointer",width: 18}} onClick={handleModalClose} src={exitIcon} />
                        {
                            isNeedAppAuth === false ?
                            <>
                                {
                                    isApiLoading && <div style={{position: 'absolute', zIndex: 1,top: 0,backgroundColor: "gray", opacity: 0.5, width: "100%", height: '200%'}}></div>
                                }
                                {
                                    isApiLoading && <img src={LoadingGear} style={{top: "calc(50% - 40px)",zIndex: 2,width: 80, height: 80,position: "absolute"}} />
                                }
                                <div className="flexRowWrapper rowLeft" >
                                    <div style={{marginRight: 10}}>
                                        <ShowMarketIcon name={selectedMarket?.Company[0]} />
                                    </div>
                                    <div className="flexColumnWrapper columnLeft" style={{height: "100%"}}>
                                        <div style={{color: "#3D3D3D",fontSize: 16, fontWeight: 500, lineHeight: "24px"}}>
                                            {selectedMarket?.Company[0]+ " 로그인"} 
                                        </div>
                                        <div style={{fontSize: 12, fontWeight: 400, color: "#AAAAAA", lineHeight: "12px"}}>
                                            {selectedMarket?.en[1]}
                                        </div>
                                    </div>
                                </div>
                                <div style={{height: 20}} />
                                {
                                    isNeedSecondaryAuthenticationCode ?
                                    // true ?
                                    <>
                                        <div  style={{position: "relative",width: "100%",boxSizing :"border-box",display: 'flex',alignItems: "center", backgroundColor: "#FFFFFF"}}>
                                            <img  src={transparentImage} style={{position: "absolute",width: "100%", height: "100%", backgroundColor: "transparent", opacity: 0}} 
                                            onClick={()=>{
                                                document.getElementById("input-second")?.focus();
                                            }}
                                            />
                                            <input id="input-second"  value={inputData.SecondaryAuthenticationCode} style={{cursor: isMobile ? "default" : "pointer", fontSize: "16px"}} onChange={(e: any)=>{
                                                    if(e.target.value.length<=6){
                                                        setInputData({...inputData,SecondaryAuthenticationCode: e.target.value});
                                                    }
                                                }} 
                                                onKeyPress={(e: any)=>{
                                                    if(e.key=="Enter"){
                                                            sendSecondaryAuthenticationCode();
                                                    }
                                                }}
                                                type="text" className="textInputNoMargin" placeholder={`인증번호를 입력해주세요.`} />
                                            <div className="absoluteBox flex font14middle" style={{color: "#0785FA",justifyContent: "center", alignItems: "center", height: 40,right: 20}} >
                                                {secondaryAuthenticationTimer}
                                            </div>
                                            {/* <div onClick={restartTimer} className="absoluteBox flexCenter font12small button" style={{width: 52, height: 26, borderRadius: 16, backgroundColor: "#F5F5F5",right: 10, color: "#8B8B8B" }}>재전송</div> */}
                                        </div>
                                        <div style={{height: 8}} />
                                        <div style={{paddingLeft: 10,display: 'flex', width: "100%",color : alertText.type == "error" ? "#DE2A2A" : alertText.type == "default" ? "#666666" : "#4472EB",fontSize: 13, fontWeight: 500}}>
                                            {alertText.value}
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div style={{width: "100%",boxSizing :"border-box",display: 'flex',marginBottom: 10}}>
                                            {
                                                selectedMarket?.Category.length > 1 && selectedMarket?.Category.map((category: any, categoryIndex: number)=>(
                                                    <div style={{position: "relative",display: "flex", alignItems: "center",marginRight: 6,padding: 2,cursor: isMobile ? "default" : "pointer"}}  onClick={()=>{setSelectedAccountTypeIndex(categoryIndex)}} >
                                                        <img  src={transparentImage} style={{position: "absolute",width: "100%", height: "100%", backgroundColor: "transparent", opacity: 0}} />
                                                        {
                                                            
                                                            // selectedAccountTypeIndex===categoryIndex ? <EnabledCheckBox /> : <DisabledCheckBox />
                                                            selectedAccountTypeIndex===categoryIndex ? <EnabledCircleCheckBox /> : <DisabledCircleCheckBox />
                                                        }
                                                        {/* <div style={{width: 16, height: 16, borderRadius: "50%",border: "1px solid #DADADA", backgroundColor : selectedAccountTypeIndex===categoryIndex ? "#FF9133" : "#FFFFFF",display: "flex", alignItems: "center",marginRight: 2}}></div> */}
                                                        <div style={{marginLeft: 6,fontWeight: 500,fontSize: 12, fontFamily: "Pretendard",letterSpacing: "-6%",lineHeight: "20px", color: "#666666"}}>
                                                            {selectedMarket.ModalDisplayCategory[categoryIndex]}
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        {
                                            selectedMarket?.Needs.map((need: any,needIndex: number)=>(<div className="textInput flexRowWrapper" style={{position: "relative", boxSizing: "border-box"}} onClick={()=>{
                                                document.getElementById("input-"+needIndex)?.focus();
                                            }}>
                                                <img  src={transparentImage} style={{position: "absolute",width: "100%", height: "100%", backgroundColor: "transparent", opacity: 0}} />
                                                {
                                                    needIndex!==0 && <div style={{position: "absolute",right: 20,top: 13+(modalInputHideObject[needIndex]===undefined ? 0 : -1)}} onClick={()=>{handleModalInputHIde(needIndex)}}>
                                                        <img  style={{width: "16px", height: (modalInputHideObject[needIndex]===undefined) ? "16px" : "12px"}} src={(modalInputHideObject[needIndex]===undefined) ? enableHideEye : disableHideEye}  />
                                                    </div>
                                                }
                                                <input 
                                                id={"input-"+needIndex}
                                                onKeyPress={(e: any)=>{
                                                    if(e.key=="Enter"){
                                                        if(isNeedSecondaryAuthenticationCode){

                                                            sendSecondaryAuthenticationCode()
                                                        }else{
                                                            handleRegister()
                                                        }
                                                    }
                                                }}
                                                onChange={(e: any)=>{
                                                    setInputData({...inputData,[need.type]: e.target.value});
                                                }} type={modalInputHideObject[needIndex]===undefined ? "password" : "text"} className="textInputWithoutBorder"style={{marginBottom: 0, backgroundColor: "transparent", padding: 0, width: "100%", fontSize: "16px"}} placeholder={`${need.display}를 입력하세요.`} />
                                            </div>
                                            ))
                                        }
                                        <div style={{paddingLeft: 10,display: 'flex', width: "100%",color : alertText.type == "error" ? "#DE2A2A" : alertText.type == "default" ? "#666666" : "#4472EB",fontSize: 13, fontWeight: 500}}>
                                            {alertText.value}
                                        </div>
                                    </>
                                }

                                {/* {
                                    (selectedMarket?.Company[0]==="스마트스토어" && !isNeedSecondaryAuthenticationCode) &&
                                    <div className="flexRowWrapper rowLeft"  >
                                        <div className="flexColumnWrapper columnLeft">
                                            <div className="font14middle grayColor" style={{width: 316}} >
                                                
                                            </div>
                                        </div>
                                        
                                    </div>
                                    
                                } */}
                                <div style={{height: 20}} />
                                <div className="flexRowWrapper" style={{position: "absolute", bottom: 30, left: 20,height: 44,width: "calc(100% - 40px)", boxSizing: "border-box", gap: 10}}>
                                    <div
                                        onClick={()=>{
                                            if(isNeedSecondaryAuthenticationCode){
                                                setIsNeedSecondaryAuthenticationCode(false);
                                            }else{
                                                handleModalClose();
                                            }
                                        }}
                                        className="cancelButton font14middle button" style={{color: "#3D3D3D",width: isMobile ? "calc((100% - 10px) / 3 *1)" : "150px", backgroundColor:  "#F5F5F5", borderRadius: 10,height: 44}}
                                    >
                                        {isNeedSecondaryAuthenticationCode ? "뒤로가기" : "취소하기"}
                                    </div>
                                    <div onClick={isNeedSecondaryAuthenticationCode ? secondaryAuthenticationTimer>0 ? sendSecondaryAuthenticationCode : ()=>{} : handleRegister} 
                                    className="registerButton font14middle button"

                                    style={{height: 44,backgroundColor: isNeedSecondaryAuthenticationCode === false ? ( Object?.values(inputData)?.filter((data: any,dataIndex)=>data==="" && Object.keys(inputData).indexOf("SecondaryAuthenticationCode")!==dataIndex)?.length>0 ? "#D9D9D9" : "#415B6D") : ((secondaryAuthenticationTimer<=0 || inputData?.SecondaryAuthenticationCode === "" || inputData?.SecondaryAuthenticationCode === undefined ) ? "#D9D9D9" : "#415B6D"), borderRadius: 10, width: isMobile ? "calc((100% - 10px) / 3 *2)" : "280px"}}
                                    // style={{backgroundColor: "#FFFFFF", border: "1px solid #FF9133",width: 70, height: 30,borderRadius: 32, lineHeight: "30px", textAlign: "center", color: "#FF9133",fontSize: 15, fontWeight: 500,marginTop: 10}}
                                    >{"연동하기"}</div>
                                </div>
                            </>
                            :
                            <div className="flexColumnWrapper" style={{width: "100%"}}>
                                {
                                    isMobile
                                    ?
                                    <div style={{display: "flex",flexDirection: "column", marginTop: 18,width: "100%"}}>
                                        <div className="flexRowWrapper rowLeft columnLeft" >
                                            <div>
                                                <div style={{height: 4}} />
                                                <ShowMarketIcon name={selectedMarket?.Company[0]} />
                                            </div>
                                            <div style={{width: 10}} />
                                            <div style={{flex: 1,boxSizing: "border-box",fontSize: 14,lineHeight: "24px", fontWeight: 500, color: "#3D3D3D",whiteSpace: "pre-wrap",wordBreak: "break-word"}}>
                                                2차 인증이 되어있다면,
                                                <br />
                                                 네이버 앱을 통해 인증 요청을 허용하시거나 
                                                <br />
                                                OTP 인증번호를 입력해 주세요.
                                            </div>
                                        </div>
                                        <img src={ mobileSecondaryAuthMock } style={{position: "absolute", top:  200 }} />    
                                    </div>
                                    :
                                    <div style={{display: "flex",flexDirection: "column", marginTop: 2,width: "100%"}}>
                                        <div className="flexRowWrapper rowLeft" >
                                            <ShowMarketIcon name={selectedMarket?.Company[0]} />
                                            <div style={{width: 10}} />
                                            <div style={{boxSizing: "border-box",fontSize: 14,lineHeight: "24px", fontWeight: 500, color: "#3D3D3D"}}>
                                                2차 인증이 되어있다면, 네이버 앱을 통해
                                                <br />
                                                인증 요청을 허용하시거나 OTP 인증번호를 입력해 주세요.
                                            </div>
                                        </div>
                                        <img src={pcSecondaryAuthMock} style={{position: "absolute", bottom: 0,left: 71}} />     
                                    </div>
                                }
                            </div>
                        }
                        {/* setIsNeedAppAuth(true);
                }else{
                    setIsNeedAppAuth(false);
                }
                setIsNeedSecondaryAuthenticationCode(true); */}


                        {/* export const UserGetRegisteredMarketAccountList = () => {
  console.log("UserGetRegisteredMarketAccountList");

  const token = store.getState().user.CplatToken;
  let payload = {
    CplatToken: token,
  };
  return axios.post(
    backendurl + `user/get_registered_market_account_list`,
    payload
  );
}; */}

{/* MarketName: marketData.CategoryEn[selectedStoreIndex],
          MarketLoginInfo: JSON.stringify(inputData), */}
          
          {/* type
          cplattoken */}

                    </div>
                </div>
            </Modal>
        </div>
    )
}

import styled from 'styled-components';
import { selectedType } from './MobileMainContainer';
import { colors } from '../../../../style';
import { Button } from '@mui/material';
import font from '../../../../style/font';
import { motion } from 'framer-motion/dist/framer-motion';

export const Container = styled.div({
  paddingTop: 148,
  width: '100%',
  position: 'relative',
  overflowX: 'hidden',
});

export const TopContainer = styled.div({
  paddingLeft: 20,
  paddingRight: 20,
  zIndex: 10,
});
export const BottomContainer = styled.div<{ type: selectedType }>(
  {
    paddingTop: 47,
    paddingBottom: 34,
    height: 'objectFit',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 10,
  },
  ({ type }) => ({
    backgroundColor: type === 'Sellerbox' ? '#FFE2C8' : '#F0EDFF',
  })
);
export const MainTextContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
  marginBottom: 24,
});
export const MainText = styled(motion.div)<{ type: selectedType }>(
  {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: '24px',
  },
  ({ type }) => ({
    color: type === 'Sellerbox' ? colors.orange.OR500 : colors.purple.PU500,
  })
);
export const SubTextContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 30,
});
export const SubText = styled(motion.div)<{ type: selectedType }>(
  {
    fontSize: 15,
    fontWeight: 500,
    lineHeight: '22.5px',
    color: colors.orange.OR500,
  },
  ({ type }) => ({
    color: type === 'Sellerbox' ? colors.orange.OR500 : colors.purple.PU500,
  })
);
export const MainImage = styled.img({
  width: 320,
  zIndex: 10,
});
export const FreeText = styled.div({
  marginTop: 50,
  marginBottom: 12,
  fontSize: 14,
  fontWeight: 600,
  lineHeight: '22px',
  color: colors.gray.GR700,
});
export const RightTopDecoration = styled.div<{ type: selectedType }>(
  {
    width: '135px',
    height: '135px',
    position: 'absolute',
    left: '300px',
    borderRadius: '160px',
    opacity: 1,
    top: '130px',
  },
  ({ type }) => ({
    transform: type === 'Sellerbox' ? 'rotate(40deg)' : 'rotate(-90deg)',
    background:
      type === 'Sellerbox'
        ? 'linear-gradient(180deg, rgba(255, 178, 122, 0.5) 0%, rgba(255, 178, 122, 0) 100%)'
        : 'linear-gradient(0deg, rgba(227, 221, 255, 1) 0%, rgba(227, 221, 255, 0) 100%)',
  })
);

export const LeftTopDecoration = styled('div')({
  width: '200px',
  height: '200px',
  position: 'absolute',
  top: '390px',
  left: '-50px',
  gap: '0px',
  opacity: 1,
  background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.6) 5.26%, rgba(255, 255, 255, 0) 50%)',
  borderRadius: '50% 50% 0 0',
  transform: 'rotate(-30deg)',
});

export const RightBottomDecoration = styled.div<{ type: selectedType }>(
  {
    width: '140px',
    height: '140px',
    position: 'absolute',
    top: '510px',
    right: '-70px',
    borderRadius: '160px',
    opacity: 1,
    transform: 'rotate(0deg)',
  },
  ({ type }) => ({
    background:
      type === 'Sellerbox'
        ? 'linear-gradient(289.93deg, #FFC67B 10.98%, rgba(255, 216, 165, 0) 89.02%)'
        : 'linear-gradient(289.93deg, #BFDAFF 10.98%, rgba(255, 216, 165, 0) 89.02%)',
  })
);

export const StyledPCButton = styled(Button)(({}) => ({
  ...font.Body3S15,
  paddingRight: 18,
  paddingLeft: 18,
  paddingTop: 13,
  paddingBottom: 13,
  backgroundColor: colors.gray.White,
  color: colors.gray.GR700,
  borderRadius: 8,
  gap: 25,
  width: 'fit-content',
  '&: hover': {
    backgroundColor: colors.gray.GR200,
  },
}));

import React from "react";
export let marketList = [
    {
      Company: ["스마트스토어","스마트스토어"],
      en: ["naver","smartstore"],
      Category: ["네이버","스마트스토어"],
      CategoryEn: ["naver","smartstore"],
      ModalDisplayCategory: ["네이버ID", "판매자ID"],
      Needs: [
        { type: "MarketId", display: "아이디" },
        { type: "MarketPassword", display: "비밀번호" },
      ],
      Icon:  "/images/marketImages/naver.png",
      marketAccounts: [],
    },
    // {
    //   Company: ["쿠팡"],
    //   en: ["coupang"],
    //   Category: ["쿠팡"],
    //   CategoryEn: ["coupang"],
    //   Needs: [
    //     { type: "VendorId", display: "업체코드" },
    //     { type: "AccessKey", display: "AccessKey" },
    //     { type: "SecretKey", display: "Secret Key" },
    //   ],
    //   Icon:  "/images/marketImages/coupang.png",
    // },
    {
      Company: ["ESMplus"],
      en: ["esmplus"],
      Category: ["ESMplus"],
      CategoryEn: ["esmplus"],
      ModalDisplayCategory: ["ESMplus"],
      Needs: [
        { type: "MarketId", display: "아이디" },
        { type: "MarketPassword", display: "비밀번호" },
      ],
      MarketId: "",
      Pwd: "",
      Icon:  "/images/marketImages/ESMplus.png",
      IsConnected: false,
      IsNeedSecondaryAuth: true,
      guide: "ESMplus 2차 인증을 해지해 주세요",
    },
    {
      Company: ["G마켓"],
      en: ["gmarket"],
      Category: ["G마켓"],
      CategoryEn: ["gmarket"],
      ModalDisplayCategory: ["G마켓"],
      Needs: [
        { type: "MarketId", display: "아이디" },
        { type: "MarketPassword", display: "비밀번호" },
      ],
      Icon:  "/images/marketImages/gmarket.png",
    },
    {
      Company: ["옥션"],
      en: ["auction"],
      Category: ["옥션"],
      CategoryEn: ["auction"],
      ModalDisplayCategory: ["옥션"],
      Needs: [
        { type: "MarketId", display: "아이디" },
        { type: "MarketPassword", display: "비밀번호" },
      ],
      MarketId: "",
      Pwd: "",
      Icon: "/images/marketImages/auction.png",
      IsConnected: false,
      IsNeedSecondaryAuth: true,
      guide: "옥션은 2차 인증을 해지해 주세요",
    },
    {
      Company: ["11번가"],
      en: ["11st"],
      Category: ["11번가"],
      CategoryEn: ["11st"],
      ModalDisplayCategory: ["11번가"],
      Needs: [
        { type: "MarketId", display: "아이디" },
        { type: "MarketPassword", display: "비밀번호" },
      ],
      SearchText: "십일번가 ㅅㅇㅂㄱ 11st 11ST",
      MarketId: "",
      Pwd: "",
      Icon: "/images/marketImages/eleven.png",
      IsConnected: false,
      IsNeedSecondaryAuth: false,
      guide: "",
    },
    {
      Company: ["에이블리"],
      en: ["ably"],
      Category: ["에이블리"],
      CategoryEn: ["ably"],
      ModalDisplayCategory: ["에이블리"],
      Needs: [
        { type: "MarketId", display: "아이디" },
        { type: "MarketPassword", display: "비밀번호" },
      ],
      SearchText: "ㅇㅇㅂㄹ",
      Icon:  "/images/marketImages/ably.png",
    },
    {
      Company: ["카페24"],
      en: ["cafe24"],
      Category: ["카페24"],
      CategoryEn: ["cafe24"],
      ModalDisplayCategory: ["카페24"],
      Needs: [
        { type: "MarketId", display: "아이디" },
        { type: "MarketPassword", display: "비밀번호" },
      ],
      SearchText: "",
      Icon:  "/images/marketImages/cafe24.png",
    },
];

export const getEnMarketIcon = (nameEn: string) => {
    let result: any = null;
    marketList.map((item) => {
      item.CategoryEn.map((res) => {
        if (res === nameEn) {
          result = item;
        }
      });
    });
    return result;
  };
  
export const getKoMarketIcon = (name: string) => {
    let result: any = null;
    marketList.map((item) => {
      item.Category.map((res) => {
        if (res === name) {
          result = item;
        }
      });
    });
    return result;
  };
  
export const getKorNameByEn = (name: string) => {
  return getEnMarketIcon(name).Category[getEnMarketIcon(name).CategoryEn.indexOf(name)]
}

export const ShowMarketIcon = ({
    name = null,
    nameEn = null,
    height = 40,
    width = 40,
    scale = null,
    cirlce = false,
  }: any) => {
    let foundMarket: any;
    if (name != null && name != undefined) {
      foundMarket = getKoMarketIcon(name);
    } else if (nameEn !== null && nameEn !== undefined) {
      foundMarket = getEnMarketIcon(nameEn);
    } else {
      foundMarket = null;
    }
    return (
      <div
        style={{
          width: width,
          height: height,
          overflow: "hidden",
          borderRadius: cirlce == true ? 50 : 0,
        }}
      >
        {foundMarket !== undefined && foundMarket !== null && (
            <img src={foundMarket.Icon} width={width} height={height}  />
        )}
      </div>
    );
  };
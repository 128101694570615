import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useMediaQueryForMobile } from '../../hooks/useMediaQueryForMobile';
import { motion } from 'framer-motion/dist/framer-motion';
import * as api from '../../controller/api';
import { useHistory } from 'react-router';
import { mobileAppBarHeight, webAppBarHeight } from '../../widgets/MyAppBar';
import { colors } from '../../style';
import useWindowSize from '../../hooks/useWindowSize';
import { promotion_3o3 } from '../../constants/urls';
import { useStoreValue } from '../../contexts';
import MainContainer from './_components/Web/MainContainer';
import FunctionContainer from './_components/Web/FunctionContainer';
import GuideContainer from './_components/Web/GuideContainer';
import { reviewList } from './_components/const';
import MobileMainContainer from './_components/Mobile/MobileMainContainer';
import MobileFunctionContainer from './_components/Mobile/MobileFunctionContainer';
import { MobileContentContainer } from '../SellkeyPage/_components/SellkeyPageStyle';
import MobileGuideContainer from './_components/Mobile/MobileGuideContainer';

export const AnimationDiv = ({ children, style, className, delay = 0 }: any) => {
  //하단에서 올라오는 애니메이션 기존 값 initial={{ y: 300, opacity: 0 }}
  return (
    <motion.div
      style={style}
      className={className}
      initial={{ y: 0, opacity: 0 }}
      whileInView={{
        opacity: 1,
        y: 0,
        transition: {
          type: 'spring',
          bounce: 0,
          duration: 1.8,
          delay: delay,
        },
      }}
      viewport={{ once: true }}
    >
      {children}
    </motion.div>
  );
};

const checkMobile = () => {
  var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
  if (varUA.indexOf('android') > -1) {
    return 'android';
  } else if (
    varUA.indexOf('iphone') > -1 ||
    varUA.indexOf('ipad') > -1 ||
    varUA.indexOf('ipod') > -1
  ) {
    return 'ios';
  } else {
    return 'other';
  }
};

export const handleDownLoad = (type = 'auto', isNaver = false, is3o3 = false) => {
  const isDevice = /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent);
  if (isDevice && is3o3) {
    window.open(
      'https://sellerbox.page.link/?link=https://sellerbox.io/3o3_promotion2310&apn=com.circleplatform.sellerbox&isi=1590729784&ibi=com.circleplatform.sellerbox&utm_campaign=sellerbox_3o3&utm_medium=message&utm_source=3o3',
      '_blank'
    );
  } else if (isDevice && isNaver) {
    window.open(
      'https://sellerbox.page.link/?link=https://sellerbox.io&apn=com.circleplatform.sellerbox&isi=1590729784&ibi=com.circleplatform.sellerbox&utm_campaign=sellerbox_naver&utm_medium=cpc&utm_source=naver',
      '_blank'
    );
  } else {
    switch (type) {
      case 'auto':
        switch (checkMobile()) {
          case 'android':
            window.open(
              'https://play.google.com/store/apps/details?id=com.circleplatform.sellerbox',
              '_blank'
            );
            break;
          case 'ios':
            window.open(
              'https://apps.apple.com/kr/app/%EC%85%80%EB%9F%AC%EB%B0%95%EC%8A%A4/id1590729784',
              '_black'
            );
            break;
        }
        break;
      default:
        if (type === 'android') {
          window.open(
            'https://play.google.com/store/apps/details?id=com.circleplatform.sellerbox',
            '_blank'
          );
        } else {
          window.open(
            'https://apps.apple.com/kr/app/%EC%85%80%EB%9F%AC%EB%B0%95%EC%8A%A4/id1590729784',
            '_blank'
          );
        }
        break;
    }
  }
};

export const SellerboxAppPage = () => {
  const { isEnteredByNaver } = useStoreValue();
  const history = useHistory();
  const isMobile = useMediaQueryForMobile();
  const [rawMarkets, setRawMarkets] = useState<any>([]);
  const [markets, setMarkets] = useState<any>([]);

  const dimension = useWindowSize();
  const [reviewPage, setReviewPage] = useState(0);
  const [reviewPageMouseEnterIndex, setReviewPageMouseEnterIndex] = useState<0 | 1 | null>(null);
  const [reviewVisible, setReviewVisible] = useState(true); //리뷰 리렌더링을 통한 애니메이션 구현

  const is3o3Promotion = window.location.href.includes(promotion_3o3);

  useEffect(() => {
    if (!reviewVisible) {
      setReviewVisible(true);
    }
  }, [reviewVisible]);

  const updateMarkets = () => {
    setMarkets(
      rawMarkets
        .filter((d) => d.MarketSeqNo !== 99 && !d.MarketName.includes('지원종료'))
        .map((market) => {
          return {
            ...market,
            defaultLeft: dimension.width * Math.random(),
            defaultTop: isMobile ? 660 * Math.random() : 930 * Math.random() - 40,
            left: 0,
            top: 0,
          };
        })
    );
  };

  useEffect(() => {
    api.GetMarketModel({}).then((res: any) => {
      let { code, data, message } = res.data;
      setRawMarkets(data.filter((d) => d.MarketSeqNo !== 99 && !d.MarketName.includes('지원종료')));
    });
  }, []);

  useEffect(() => {
    updateMarkets();
  }, [rawMarkets]);

  return (
    <WholePageWrapper id="hi">
      {isMobile ? (
        <MobilePageWrapper>
          {/* 셀러박스 메인 */}
          <MobileContentWhiteWrapperWithNoPadding>
            <MobileMainContainer selected={'Sellerbox'}></MobileMainContainer>
          </MobileContentWhiteWrapperWithNoPadding>
          {/* 셀러박스가 제공하는 기능 */}
          <MobileContentContainer>
            <MobileFunctionContainer selected={'Sellerbox'} />
          </MobileContentContainer>
          {/* 이렇게 활용하세요 */}
          <MobileGuideContainer />
          {/* 연동 가능한 상점 */}
          <div style={{ width: '100vw', backgroundColor: '#F9F9F9', textAlign: 'center' }}>
            <div style={{ height: 36 }} />
            <div style={{ display: 'flex', flexDirection: 'column', gap: 6, alignItems: 'center' }}>
              <img src="images/IconMarket.png" style={{ width: 30 }} alt="" />
              <div style={{ fontSize: 20, fontWeight: 700, lineHeight: '100%', color: '#3D3D3D' }}>
                연동 가능한 상점
              </div>
              <div style={{ fontSize: 14, fontWeight: 600, lineHeight: '24px', color: '#939393' }}>
                연동 가능 스토어를 지속적으로 확대 중입니다!
              </div>
            </div>
            <div style={{ height: 36 }} />
            <div
              className="flexColumnWrapper"
              style={{
                width: '100%',
                fontSize: 14,
                overflow: 'hidden',
                alignItems: 'flex-start',
                fontWeight: 600,
                lineHeight: '22px',
                color: '#939393',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  width: markets.slice(0, Math.ceil(markets.length / 2)).length * 100 * 2 + 'px',
                  animation: 'bannermove 10s linear infinite',
                }}
              >
                <Fragment>
                  {markets.slice(0, Math.ceil(markets.length / 2)).map((market: any) => {
                    return (
                      <div style={{ width: 100, textAlign: 'center' }}>
                        <img src={market.MarketIconUrl} style={{ width: 80, height: 80 }} alt="" />
                        <div style={{ height: 12 }} />
                        <div className="flexCenter" style={{ wordBreak: 'keep-all' }}>
                          {market.MarketName.split(' (')[0]}
                        </div>
                      </div>
                    );
                  })}
                  {markets.slice(0, Math.ceil(markets.length / 2)).map((market: any) => {
                    return (
                      <div style={{ width: 100, textAlign: 'center' }}>
                        <img src={market.MarketIconUrl} style={{ width: 80, height: 80 }} />
                        <div style={{ height: 12 }} />
                        <div className="flexCenter" style={{ wordBreak: 'keep-all' }}>
                          {market.MarketName.split(' (')[0]}
                        </div>
                      </div>
                    );
                  })}
                </Fragment>
              </div>
              <div style={{ height: 36 }} />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  width: markets.slice(Math.ceil(markets.length / 2)).length * 100 * 2 + 'px',
                  animation: 'bannermove 10s linear infinite',
                }}
              >
                <Fragment>
                  {markets.slice(Math.ceil(markets.length / 2)).map((market: any) => {
                    return (
                      <div style={{ width: 100, textAlign: 'center' }}>
                        <img src={market.MarketIconUrl} style={{ width: 80, height: 80 }} alt="" />
                        <div style={{ height: 12 }} />
                        <div className="flexCenter" style={{ wordBreak: 'keep-all' }}>
                          {market.MarketName.split(' (')[0]}
                        </div>
                      </div>
                    );
                  })}
                  {markets.slice(Math.ceil(markets.length / 2)).map((market: any) => {
                    return (
                      <div style={{ width: 100, textAlign: 'center' }}>
                        <img src={market.MarketIconUrl} style={{ width: 80, height: 80 }} alt="" />
                        <div style={{ height: 12 }} />
                        <div className="flexCenter" style={{ wordBreak: 'keep-all' }}>
                          {market.MarketName.split(' (')[0]}
                        </div>
                      </div>
                    );
                  })}
                </Fragment>
              </div>
            </div>
            <div style={{ height: 120 }} />
          </div>
          {/* 리뷰 슬라이드 */}
          <MobileContentBlackWrapperWithNoPadding>
            <div
              style={{
                width: '100%',
                height: 380,
                padding: '50px 30px 36px 30px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}
            >
              {reviewVisible && (
                <motion.div
                  style={{ position: 'relative', left: -30 }}
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    x: 30,
                    opacity: 1,
                  }}
                  transition={{ duration: 0.7 }}
                >
                  <div>
                    <div style={{ display: 'flex', gap: 12, alignItems: 'center' }}>
                      <img src="images/unkownUserImage.png" style={{ width: 36 }} alt="" />
                      <div
                        style={{ color: '#FFF', fontSize: 16, fontWeight: 500, lineHeight: '160%' }}
                      >
                        {reviewList[reviewPage].title}
                      </div>
                    </div>
                    <div style={{ height: 12 }} />
                    <div
                      style={{
                        color: '#FFF',
                        fontSize: 16,
                        fontWeight: 500,
                        lineHeight: '24px',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      {reviewList[reviewPage].description}
                    </div>
                  </div>
                </motion.div>
              )}
              <div style={{ height: 30 }} />
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  cursor: 'pointer',
                }}
              >
                <div
                  style={{
                    width: 48,
                    height: 48,
                    border: '1px solid #C8C8C8',
                    borderRightWidth: 0,
                    opacity: reviewPageMouseEnterIndex === 0 ? 0.7 : 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    setReviewPage((preReviewPage) =>
                      preReviewPage === 0 ? reviewList.length - 1 : preReviewPage - 1
                    );
                    setReviewVisible(false);
                  }}
                  onMouseEnter={() => {
                    setReviewPageMouseEnterIndex(0);
                  }}
                  onMouseLeave={() => {
                    setReviewPageMouseEnterIndex(null);
                  }}
                >
                  <img
                    src="images/sellerboxHomeReviewPreviewBackArrowImage.png"
                    style={{ width: 12 }}
                  />
                </div>
                <div style={{ height: 48, width: 1, backgroundColor: '#C8C8C8' }} />
                <div
                  style={{
                    width: 48,
                    height: 48,
                    border: '1px solid #C8C8C8',
                    borderLeftWidth: 0,
                    opacity: reviewPageMouseEnterIndex === 1 ? 0.7 : 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    setReviewPage((preReviewPage) =>
                      preReviewPage === reviewList.length - 1 ? 0 : preReviewPage + 1
                    );
                    setReviewVisible(false);
                  }}
                  onMouseEnter={() => {
                    setReviewPageMouseEnterIndex(1);
                  }}
                  onMouseLeave={() => {
                    setReviewPageMouseEnterIndex(null);
                  }}
                >
                  <img
                    src="images/sellerboxHomeReviewPreviewForwardArrowImage.png"
                    style={{ width: 12 }}
                  />
                </div>
              </div>
            </div>
          </MobileContentBlackWrapperWithNoPadding>
          {/* 서클플랫폼 이력 */}
          <MobileContentGrayWrapperWithNoPadding style={{ padding: '0px 30px' }}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative',
              }}
            >
              <div style={{ height: 100 }} />

              <div style={{ width: '100%' }}>
                <div
                  style={{ fontSize: 20, fontWeight: 700, lineHeight: '28px', color: '#1A1A1A' }}
                >
                  믿을 수 있는 스타트업 <br />
                  <span style={{ color: '#FF7F00' }}>서클플랫폼</span>에서 만들었어요
                </div>
                <div style={{ height: 12 }} />
                <div
                  style={{ fontSize: 16, fontWeight: 500, lineHeight: '24px', color: '#3D3D3D' }}
                >
                  모든 데이터는 암호화하여 안전하게 관리합니다
                </div>
                <div style={{ height: 40 }} />
                <AnimationDiv>
                  <img src="images/mobilePartnerListImage1.png" style={{ width: '100%' }} />
                </AnimationDiv>
                <div style={{ height: 12 }} />
                <AnimationDiv>
                  <img src="images/mobilePartnerListImage2.png" style={{ width: '100%' }} />
                </AnimationDiv>
                <div style={{ height: 12 }} />
                <AnimationDiv>
                  <img src="images/mobilePartnerListImage3.png" style={{ width: '100%' }} />
                </AnimationDiv>
                <div style={{ height: 12 }} />
                <AnimationDiv>
                  <img src="images/mobilePartnerListImage4.png" style={{ width: '100%' }} />
                </AnimationDiv>
              </div>
              <div style={{ height: 100 }} />
            </div>
          </MobileContentGrayWrapperWithNoPadding>
          {/* 의견 보내기 */}
          <div className="flexRowWrapper" style={{ width: '100%', position: 'relative' }}>
            <img
              src="images/mobile-home-last-background.png"
              style={{
                backgroundSize: 'contain',
                height: 500,
                position: 'absolute',
                zIndex: 0,
                width: '100%',
              }}
            />
            <div
              style={{
                width: '100%',
                height: 500,
                position: 'relative',
                zIndex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div style={{ fontSize: 16, fontWeight: 700, lineHeight: '150%', color: '#DFDFDF' }}>
                판매의 성장을 스마트하게
              </div>
              <div style={{ height: 12 }} />
              <div style={{ fontSize: 30, fontWeight: 800, lineHeight: '150%', color: '#F5F5F5' }}>
                셀러박스와 함께 시작해 보세요
              </div>
              <div
                className="flex"
                style={{
                  gap: 10,
                  marginTop: 16,
                  marginBottom: 20,
                  alignItems: 'center',
                  zIndex: 1,
                }}
              >
                <img
                  src="images/mobile-home-google-download.png"
                  onClick={() => {
                    handleDownLoad('android', isEnteredByNaver, is3o3Promotion);
                  }}
                  className="pointer"
                  style={{ width: 152, height: 48 }}
                />
                <img
                  src="images/mobile-home-apple-download.png"
                  onClick={() => {
                    handleDownLoad('ios', isEnteredByNaver, is3o3Promotion);
                  }}
                  className="pointer"
                  style={{ width: 152, height: 48 }}
                />
              </div>
              <div
                style={{
                  fontSize: 14,
                  fontWeight: 600,
                  lineHeight: '22px',
                  color: '#FAFAFA',
                  marginBottom: 12,
                }}
              >
                셀러박스에 원하는 기능이 있다면 저희에게 알려주세요!
              </div>
              <img
                className="pointer"
                onClick={() => {
                  window.scrollTo(0, 0);
                  history.push('/clients/contact');
                }}
                src="images/mobile-home-last-button.png"
                style={{ width: 136 }}
              />
            </div>
          </div>
        </MobilePageWrapper>
      ) : (
        <PcPageWrapper id="aa">
          {/* 셀러박스 메인 */}
          <MobileContentLightWhiteWrapperWithNoPadding>
            <MainContainer selected={'Sellerbox'} />
          </MobileContentLightWhiteWrapperWithNoPadding>
          {/* 셀러박스가 제공하는 기능 */}
          <MobileContentGrayWrapperWithNoPadding
            style={{
              background: colors.gray.GR50,
            }}
          >
            <FunctionContainer selected={'Sellerbox'} />
          </MobileContentGrayWrapperWithNoPadding>
          {/* 이렇게 활용하세요 */}
          <MobileContentWhiteWrapper>
            <GuideContainer />
          </MobileContentWhiteWrapper>
          {/* 연동 가능한 상점 */}
          <MobileContentWhiteWrapper>
            <div
              style={{
                width: 1290,
                padding: '94px 137px 124px 137px',
                borderRadius: 48,
                backgroundColor: '#F9F9F9',
                textAlign: 'center',
                marginTop: 120,
                marginBottom: 120,
              }}
            >
              <div
                style={{ display: 'flex', flexDirection: 'column', gap: 16, alignItems: 'center' }}
              >
                <img src="images/IconMarket.png" style={{ width: 40 }} alt="img" />
                <div
                  style={{ fontSize: 36, fontWeight: 700, lineHeight: '100%', color: '#3D3D3D' }}
                >
                  연동 가능한 상점
                </div>
                <div
                  style={{ fontSize: 16, fontWeight: 600, lineHeight: '24px', color: '#939393' }}
                >
                  연동 가능 스토어를 지속적으로 확대 중입니다!
                </div>
              </div>
              <div style={{ height: 70 }} />
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 24,
                  justifyContent: 'center',
                }}
              >
                {markets.map((market, marketIndex) => (
                  <div key={marketIndex} style={{ textAlign: 'center' }}>
                    <img src={market.MarketIconUrl} style={{ width: 80, height: 80 }} alt="logo" />
                    <div
                      style={{
                        fontSize: 15,
                        fontWeight: 600,
                        lineHeight: '22px',
                        color: '#939393',
                      }}
                    >
                      {market.MarketName.split(' (')[0]}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </MobileContentWhiteWrapper>

          {/* 리뷰 슬라이드 */}
          <MobileContentBlackWrapperWithNoPadding>
            <div
              style={{
                width: 1290,
                height: 280,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {reviewVisible && (
                <motion.div
                  style={{ position: 'relative', left: -100 }}
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    x: 100,
                    opacity: 1,
                  }}
                  transition={{ duration: 0.7 }}
                >
                  <div>
                    <div style={{ display: 'flex', gap: 12, alignItems: 'center' }}>
                      <img src="images/unkownUserImage.png" style={{ width: 36 }} alt="" />
                      <div
                        style={{ color: '#FFF', fontSize: 16, fontWeight: 500, lineHeight: '160%' }}
                      >
                        {reviewList[reviewPage].title}
                      </div>
                    </div>
                    <div style={{ height: 12 }} />
                    <div
                      style={{
                        color: '#FFF',
                        fontSize: 24,
                        fontWeight: 500,
                        lineHeight: '160%',
                        whiteSpace: 'pre-wrap',
                        paddingRight: 20,
                      }}
                    >
                      {reviewList[reviewPage].description}
                    </div>
                  </div>
                </motion.div>
              )}
              <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                <div
                  style={{
                    width: 48,
                    height: 48,
                    border: '1px solid #C8C8C8',
                    borderRightWidth: 0,
                    opacity: reviewPageMouseEnterIndex === 0 ? 0.7 : 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    setReviewPage((preReviewPage) =>
                      preReviewPage === 0 ? reviewList.length - 1 : preReviewPage - 1
                    );
                    setReviewVisible(false);
                  }}
                  onMouseEnter={() => {
                    setReviewPageMouseEnterIndex(0);
                  }}
                  onMouseLeave={() => {
                    setReviewPageMouseEnterIndex(null);
                  }}
                >
                  <img
                    src="images/sellerboxHomeReviewPreviewBackArrowImage.png"
                    style={{ width: 12 }}
                    alt=""
                  />
                </div>
                <div style={{ height: 48, width: 1, backgroundColor: '#C8C8C8' }} />
                <div
                  style={{
                    width: 48,
                    height: 48,
                    border: '1px solid #C8C8C8',
                    borderLeftWidth: 0,
                    opacity: reviewPageMouseEnterIndex === 1 ? 0.7 : 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    setReviewPage((preReviewPage) =>
                      preReviewPage === reviewList.length - 1 ? 0 : preReviewPage + 1
                    );
                    setReviewVisible(false);
                  }}
                  onMouseEnter={() => {
                    setReviewPageMouseEnterIndex(1);
                  }}
                  onMouseLeave={() => {
                    setReviewPageMouseEnterIndex(null);
                  }}
                >
                  <img
                    src="images/sellerboxHomeReviewPreviewForwardArrowImage.png"
                    style={{ width: 12 }}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </MobileContentBlackWrapperWithNoPadding>

          {/* 서클플랫폼 이력 */}
          <MobileContentGrayWrapperWithNoPadding>
            <div
              style={{
                width: '100%',
                height: 968,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative',
              }}
            >
              <div
                style={{
                  width: 240,
                  height: 240,
                  borderRadius: '50%',
                  backgroundColor: '#FF9C66',
                  position: 'absolute',
                  left: -43,
                  top: 87,
                }}
              />
              <div
                style={{
                  width: 322,
                  height: 322,
                  borderRadius: '50%',
                  backgroundColor: 'rgba(255, 255, 255, 0.20)',
                  position: 'absolute',
                  left: 53,
                  top: 267,
                }}
                className="backdropFilter15"
              />
              <div
                style={{
                  width: 416,
                  height: 198,
                  borderRadius: 57,
                  backgroundColor: '#FFBB7C',
                  position: 'absolute',
                  right: -213,
                  bottom: 309,
                }}
              />
              <div
                style={{
                  width: 322,
                  height: 322,
                  borderRadius: '50%',
                  backgroundColor: 'rgba(255, 255, 255, 0.20)',
                  position: 'absolute',
                  right: 53,
                  top: 309,
                }}
                className="backdropFilter15"
              />

              <div style={{ height: 190 }} />

              <div style={{ width: 1290, position: 'relative', zIndex: 1 }}>
                <div
                  style={{ fontSize: 44, fontWeight: 880, lineHeight: '130%', color: '#1A1A1A' }}
                >
                  믿을 수 있는 스타트업 <span style={{ color: '#FF7F00' }}>서클플랫폼</span>에서
                  만들었어요
                </div>
                <div style={{ height: 24 }} />
                <div
                  style={{ fontSize: 22, fontWeight: 500, lineHeight: '160%', color: '#3D3D3D' }}
                >
                  모든 데이터는 암호화하여 안전하게 관리합니다
                </div>
                <div style={{ height: 48 }} />
                <AnimationDiv>
                  <img src="images/pcPartnerListImage1.png" style={{ width: '100%' }} alt="" />
                </AnimationDiv>
                <div style={{ height: 30 }} />
                <AnimationDiv>
                  <img src="images/pcPartnerListImage2.png" style={{ width: '100%' }} alt="" />
                </AnimationDiv>
              </div>
              <div style={{ height: 200 }} />
            </div>
          </MobileContentGrayWrapperWithNoPadding>

          {/* 의견 보내기 */}
          <div className="flexRowWrapper" style={{ width: '100%', position: 'relative' }}>
            <img
              src="images/pc-home-last-background.png"
              style={{
                objectFit: 'cover',
                width: '100%',
                height: 600,
                position: 'absolute',
                zIndex: 0,
              }}
              alt=""
            />
            <div
              style={{
                width: '100%',
                height: 600,
                position: 'relative',
                zIndex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div style={{ fontSize: 28, fontWeight: 700, lineHeight: '150%', color: '#DFDFDF' }}>
                판매의 성장을 스마트하게
              </div>
              <div style={{ height: 12 }} />
              <div style={{ fontSize: 48, fontWeight: 700, lineHeight: '150%', color: '#F5F5F5' }}>
                셀러박스와 함께 시작해 보세요
              </div>
              <div
                className="flex"
                style={{
                  gap: 10,
                  marginTop: 16,
                  marginBottom: 40,
                  alignItems: 'center',
                  zIndex: 1,
                }}
              >
                <img
                  src="images/mobile-home-google-download.png"
                  onClick={() => {
                    handleDownLoad('android', isEnteredByNaver, is3o3Promotion);
                  }}
                  className="pointer"
                  style={{ width: 152, height: 48 }}
                  alt=""
                />
                <img
                  src="images/mobile-home-apple-download.png"
                  onClick={() => {
                    handleDownLoad('ios', isEnteredByNaver, is3o3Promotion);
                  }}
                  className="pointer"
                  style={{ width: 152, height: 48 }}
                  alt=""
                />
                {isEnteredByNaver ? (
                  <img src="images/QrDownloadByNaver.png" style={{ width: 78 }} alt="" />
                ) : is3o3Promotion ? (
                  <img src="images/QrDownloadBy3o3.png" style={{ width: 78 }} alt="" />
                ) : (
                  <img src="images/QrDownload.png" style={{ width: 78 }} alt="" />
                )}
              </div>
              <img
                className="pointer"
                onClick={() => {
                  window.scrollTo(0, 0);
                  history.push('/clients/contact');
                }}
                alt=""
                src="images/pc-home-inquiry-button.png"
                style={{ width: 486 }}
              />
            </div>
          </div>
        </PcPageWrapper>
      )}
    </WholePageWrapper>
  );
};

export const WholePageWrapper = styled.div`
  min-width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  overflow-x: hidden;
  margin-top: -112px;
`;

export const MobilePageWrapper = styled.div`
  min-height: calc(100vh - ${mobileAppBarHeight}px);
  overflow: hidden;
  width: 100%;
`;

export const MobileContentGrayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 30px;
  background-color: #f5f5f5;
`;

export const MobileContentGrayWrapperWithNoPadding = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 0px;
  background-color: #f5f5f5;
`;

export const MobileContentLightGrayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 30px;
  background-color: #f9f9f9;
`;

export const MobileContentLightWhiteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 30px;
  background-color: #fcfcfc;
`;

export const MobileContentLightWhiteWrapperWithNoPadding = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0px;
  background-color: #fcfcfc;
`;

export const MobileContentWhiteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 30px;
  background-color: #ffffff;
`;

export const MobileContentWhiteWrapperWithNoPadding = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0px;
  background-color: #fff;
`;

export const MobileContentBlackWrapperWithNoPadding = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0px;
  background-color: #47474d;
`;

export const PcPageWrapper = styled.div`
  min-height: calc(100vh - ${webAppBarHeight}px);
  /* width: 1440px; */
  width: 100%;
  // padding-bottom: 120px;
  min-width: 1440px;
  /* margin-top: 70px; */
  /* max-width: 1440px; */
`;

export const MainDetailDescriptionTextLeft = styled.div`
  text-align: left;
  font-weight: 500;
  font-size: 23px;
  letter-spacing: -0.04em;
`;

export const MainDetailDescriptionTextRight = styled.div`
  text-align: right;
  font-weight: 500;
  font-size: 23px;
  letter-spacing: -0.04em;
`;

import React, { useEffect, useRef, useState } from 'react';

import StepBlackRight from '../../../../assets/images/V2.1/StepBlackRight.png';

import {
  Container,
  MainText,
  ContentContainer,
  TitleContainer,
  Title,
  Indicator,
  DescContainer,
  Desc,
  StepContainer,
  StepText,
  DescTitle,
  DescText,
} from './GuideContainerStyle';
import { GUIDE_DATA } from '../const';

//GUIDE_DATA
const GuideContainer = () => {
  const [activedTab, setActivedTab] = useState<number>(0);
  const [indicatorPosition, setIndicatorPosition] = useState<number>(0);
  const titleRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    if (titleRefs.current[activedTab]) {
      const titleRect = titleRefs.current[activedTab]?.getBoundingClientRect();
      const containerRect = titleRefs.current[0]?.parentElement?.getBoundingClientRect();
      if (titleRect && containerRect) {
        const offset = titleRect.left - containerRect.left;
        setIndicatorPosition(offset);
      }
    }
  }, [activedTab]);

  return (
    <Container>
      <MainText>이렇게 활용하세요</MainText>
      <ContentContainer>
        <TitleContainer>
          <Indicator leftPosition={indicatorPosition} />
          {GUIDE_DATA.map((item, index) => (
            <Title
              key={item.tab}
              onClick={() => setActivedTab(index)}
              selected={GUIDE_DATA[activedTab].tab === item.tab}
              ref={(el) => (titleRefs.current[index] = el)}
            >
              {item.tab}
            </Title>
          ))}
        </TitleContainer>
        <DescContainer>
          {GUIDE_DATA[activedTab].content.map((item, index) => (
            <Desc>
              <StepContainer>
                <img src={StepBlackRight} width={80} height={40} />
                <StepText>STEP {index + 1}</StepText>
              </StepContainer>
              <DescTitle>{item.title}</DescTitle>
              <DescText>{item.text}</DescText>
              <img src={item.image} width={410} height={280} />
            </Desc>
          ))}
        </DescContainer>
      </ContentContainer>
    </Container>
  );
};

export default GuideContainer;

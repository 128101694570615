import React, { createContext, useContext, useMemo, useState } from 'react';

type StoreProvider = {
  children: React.ReactNode;
};

const params = new URLSearchParams(window.location.search);

const initState = {
  isModuleMatch:
    window.location.pathname.toLocaleLowerCase().indexOf('/module/') !== -1 ||
    window.location.pathname.toLocaleLowerCase().indexOf('smartStoreguide') !== -1 ||
    window.location.pathname.toLocaleLowerCase().indexOf('register') !== -1 ||
    window.location.pathname.toLocaleLowerCase().indexOf('finish') !== -1 ||
    window.location.pathname.toLocaleLowerCase().indexOf('signin') !== -1 ||
    window.location.pathname.toLocaleLowerCase().indexOf('smartStoreGuide') !== -1 ||
    window.location.pathname.toLocaleLowerCase().indexOf('code') !== -1 ||
    window.location.href.indexOf('?token=') !== -1,
  // TODO 다른 마켓은 어떻게 구별할건데?
  isEnteredByNaver: window.location.href.indexOf('?token=') !== -1,
  // TODO 좋지 못한 패턴...
  naverToken: params.get('token') ?? '',
  shopbyToken: '',

  userInfo: {
    CplatToken: '',
    StoreName: '',
  },
  alertInfo: {
    isAlertModalOpen: false,
    action: () => {},
    onClose: () => {},
    title: '',
    description: '',
  },
  enteredByMarketName: '',
  isSignup: false,
};

export type StoreValue = typeof initState;
type AlertInfo = typeof initState.alertInfo;
type UserInfo = typeof initState.userInfo;
export type StoreAction = {
  setNaverToken: (naverToken: string) => void;
  setAlertInfo: (alertInfo: AlertInfo) => void;
  setUserInfo: (userInfo: UserInfo) => void;
  setisModuleMatch: (isModuleMatch: boolean) => void;
  setIsEnteredByNaver: (isEnteredByNaver: boolean) => void;
  setEnteredByMarketName: (marketName: string) => void;
  setShopbyToken: (shopByToken: string) => void;
  setIsSignup: (flag: boolean) => void;
};

const StoreValueContext = createContext<StoreValue>(initState);
const StoreActionContext = createContext<StoreAction>({
  setNaverToken: () => {},
  setAlertInfo: () => {},
  setUserInfo: () => {},
  setisModuleMatch: () => {},
  setIsEnteredByNaver: () => {},
  setEnteredByMarketName: () => {},
  setShopbyToken: () => {},
  setIsSignup: () => {},
});

const StoreProvider = ({ children }: StoreProvider) => {
  const [state, setState] = useState(initState);

  const actions = useMemo(
    () => ({
      setNaverToken(naverToken: string) {
        setState((prev) => ({ ...prev, naverToken }));
      },
      setAlertInfo(alertInfo: AlertInfo) {
        setState((prev) => ({ ...prev, alertInfo }));
      },
      setUserInfo(userInfo: UserInfo) {
        setState((prev) => ({ ...prev, userInfo }));
      },
      setisModuleMatch(isModuleMatch: boolean) {
        setState((prev) => ({ ...prev, isModuleMatch }));
      },
      setIsEnteredByNaver(isEnteredByNaver: boolean) {
        setState((prev) => ({ ...prev, isEnteredByNaver }));
      },
      setEnteredByMarketName(enteredByMarketName: string) {
        setState((prev) => ({ ...prev, enteredByMarketName }));
      },
      setShopbyToken(shopbyToken: string) {
        setState((prev) => ({ ...prev, shopbyToken }));
      },
      setIsSignup(isSignup: boolean) {
        setState((prev) => ({ ...prev, isSignup }));
      },
    }),
    []
  );

  return (
    <StoreActionContext.Provider value={actions}>
      <StoreValueContext.Provider value={state}>{children}</StoreValueContext.Provider>
    </StoreActionContext.Provider>
  );
};

export const useStoreValue = () => {
  const value = useContext(StoreValueContext);
  if (value === undefined) throw new Error('useStoreValue should be used within StoreProvider');

  return value;
};

export const useStoreAction = () => {
  const action = useContext(StoreActionContext);
  if (action === undefined) throw new Error('useStoreActions should be used within StoreProvider');

  return action;
};

export default StoreProvider;

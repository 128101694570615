import * as React from "react";
import { styled } from "@mui/material";

export interface IImageMaskProps {}

export const ImageMask = styled("div")(({}) => ({
  backgroundColor: "rgba(18, 18, 18, 0.6)",
  position: "absolute",
  width: "100%",
  height: "100%",
}));

export const CompanyPageImageMask = styled("div")(({}) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  background:
    "linear-gradient(180deg, rgba(255, 255, 255, 0) 56.25%, #FFFFFF 100%)",
}));

export const CompanyPageImageMask2 = styled("div")(({}) => ({
  width: "100%",
  height: "100%",
  background:
    "linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.738802) 52.08%, #FFFFFF 100%)",
}));

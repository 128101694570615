import React from 'react';
import { handleDownLoad } from '../SellerboxAppPage';
import { useStoreValue } from '../../../contexts';

interface AppStoreLinkContainerProps {
  isMobile: boolean;
}

const AppStoreLinkContainer = ({ isMobile = false }: AppStoreLinkContainerProps) => {
  const { isEnteredByNaver } = useStoreValue();
  return (
    <div
      className="flex"
      style={{
        gap: 10,
        alignItems: 'center',
      }}
    >
      <img
        src="images/mobile-home-google-download.png"
        onClick={() => {
          handleDownLoad('android', isEnteredByNaver);
        }}
        className="pointer"
        style={{ width: 152, height: 48 }}
        alt=""
      />
      <img
        src="images/mobile-home-apple-download.png"
        onClick={() => {
          handleDownLoad('ios', isEnteredByNaver);
        }}
        className="pointer"
        style={{ width: 152, height: 48 }}
        alt=""
      />
      {!isMobile && (
        <>
          {isEnteredByNaver ? (
            <img src="images/QrDownloadByNaver.png" style={{ width: 78 }} alt="" />
          ) : (
            <img src="images/QrDownload.png" style={{ width: 78 }} alt="" />
          )}
        </>
      )}
    </div>
  );
};

export default AppStoreLinkContainer;

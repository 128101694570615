import { Button, ButtonProps } from "@mui/material";
import React from "react";

interface IFileUploaderProps extends ButtonProps {
  handleFile: (file: File) => void;
}
const FileUploader: React.FC<IFileUploaderProps> = ({
  handleFile,
  onClick,
  children,
  ...others
}) => {
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    console.log(hiddenFileInput.current)
    hiddenFileInput.current && hiddenFileInput.current.click();
  };
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    
    event.target.files && handleFile(event.target.files[0]);
  };

  return (
    <>
      <Button  {...others} onClick={handleClick}>
        {children}
      </Button>
      <input
        name="inquiryFileInput"
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }}
      />
    </>
  );
};
export default FileUploader;

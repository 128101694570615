import React, { useEffect, useState } from 'react';

import { ReactComponent as ArrowGrayRight } from '../../../../assets/icons/ArrowGrayRight.svg';
import { MAIN_DATA } from '../const';
import AppStoreLinkContainer from '../AppStoreLinkContainer';
import {
  BottomContainer,
  Container,
  FreeText,
  LeftTopDecoration,
  MainImage,
  MainText,
  MainTextContainer,
  RightBottomDecoration,
  RightTopDecoration,
  StyledPCButton,
  SubText,
  SubTextContainer,
  TopContainer,
} from './MobileMainContainerStyle';
import { useMediaQueryForMobile } from '../../../../hooks/useMediaQueryForMobile';
import { imageVariants, textVariants } from '../Web/MainContainerStyle';

export type selectedType = 'Sellerbox' | 'Sellkey';

interface MobileMainContainerProps {
  selected: selectedType;
}

const MobileMainContainer = ({ selected }: MobileMainContainerProps) => {
  const [activedTab, setActivedTab] = useState(0);
  const isMobile = useMediaQueryForMobile();

  const handleNextTab = () => {
    setActivedTab((prevTab) => (prevTab + 1) % MAIN_DATA[selected].text.length);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNextTab();
    }, 5000);

    return () => clearInterval(interval);
  }, [selected]);

  return (
    <Container>
      <TopContainer>
        <MainTextContainer>
          {MAIN_DATA[selected].text[activedTab].main.map((item) => (
            <MainText
              key={item}
              type={selected}
              variants={textVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={{ duration: 0.4 }}
            >
              {item}
            </MainText>
          ))}
        </MainTextContainer>
        <SubTextContainer>
          {MAIN_DATA[selected].text[activedTab].sub.map((item) => (
            <SubText
              key={item}
              type={selected}
              variants={textVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={{ duration: 0.4 }}
            >
              {item}
            </SubText>
          ))}
        </SubTextContainer>
      </TopContainer>
      <BottomContainer type={selected}>
        <MainImage src={MAIN_DATA[selected].image[activedTab]} />
        {selected === 'Sellerbox' ? (
          <>
            <FreeText>무료로 시작하세요!</FreeText>
            <AppStoreLinkContainer isMobile={isMobile} />
          </>
        ) : (
          <StyledPCButton
            onClick={() => window.open('https://sellkey.sellerbox.io/mobileKeywordAnalyze')}
            style={{
              marginTop: 30,
            }}
          >
            셀러키워드 바로가기
            <ArrowGrayRight />
          </StyledPCButton>
        )}
      </BottomContainer>
      <RightTopDecoration type={selected} />
      <LeftTopDecoration />
      <RightBottomDecoration type={selected} />
    </Container>
  );
};

export default MobileMainContainer;

import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useMediaQueryForMobile } from '../hooks/useMediaQueryForMobile';

//기존에 있던 컴포너트 사용

import naverIcon from '../assets/images/marketImages/naver.png';
import coupangIcon from '../assets/images/marketImages/coupang.png';
import gmarketIcon from '../assets/images/marketImages/gmarket.png';
import auctionIcon from '../assets/images/marketImages/auction.png';
import ESMplusIcon from '../assets/images/marketImages/ESMplus.png';
import ablyIcon from '../assets/images/marketImages/ably.png';
import cafe24Icon from '../assets/images/marketImages/cafe24.png';

import { motion } from 'framer-motion/dist/framer-motion';
import * as api from '../controller/api';
import { useHistory } from 'react-router';
import { mobileAppBarHeight, webAppBarHeight } from '../widgets/MyAppBar';
import { useStoreValue } from '../contexts';

export const AnimationDiv = ({ children, style, className, delay = 0 }: any) => {
  return (
    <motion.div
      style={style}
      className={className}
      initial={{ y: 300, opacity: 0 }}
      whileInView={{
        opacity: 1,
        y: 0,
        transition: {
          type: 'spring',
          bounce: 0,
          duration: 1.8,
          delay: delay,
        },
      }}
      viewport={{ once: true }}
    >
      {children}
    </motion.div>
  );
};

export const RightToLeftAnimationDiv = ({ children, style, className, delay = 0 }: any) => {
  return (
    <motion.div
      style={style}
      className={className}
      initial={{ x: '100%' }}
      whileInView={{
        opacity: 1,
        x: 0,
        transition: {
          type: 'spring',
          bounce: 0,
          duration: 1.8,
          delay: delay,
        },
      }}
      viewport={{ once: true }}
    >
      {children}
    </motion.div>
  );
};

const checkMobile = () => {
  var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
  if (varUA.indexOf('android') > -1) {
    return 'android';
  } else if (
    varUA.indexOf('iphone') > -1 ||
    varUA.indexOf('ipad') > -1 ||
    varUA.indexOf('ipod') > -1
  ) {
    return 'ios';
  } else {
    return 'other';
  }
};

export const handleDownLoad = (type = 'auto', isNaver = false) => {
  const isDevice = /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent);
  if (isDevice && isNaver) {
    window.open(
      'https://sellerbox.page.link/?link=https://sellerbox.io&apn=com.circleplatform.sellerbox&isi=1590729784&ibi=com.circleplatform.sellerbox&utm_campaign=sellerbox_naver&utm_medium=cpc&utm_source=naver',
      '_blank'
    );
  } else {
    switch (type) {
      case 'auto':
        switch (checkMobile()) {
          case 'android':
            window.open(
              'https://play.google.com/store/apps/details?id=com.circleplatform.sellerbox',
              '_blank'
            );
            break;
          case 'ios':
            window.open(
              'https://apps.apple.com/kr/app/%EC%85%80%EB%9F%AC%EB%B0%95%EC%8A%A4/id1590729784',
              '_black'
            );
            break;
        }
        break;
      default:
        if (type === 'android') {
          window.open(
            'https://play.google.com/store/apps/details?id=com.circleplatform.sellerbox',
            '_blank'
          );
        } else {
          window.open(
            'https://apps.apple.com/kr/app/%EC%85%80%EB%9F%AC%EB%B0%95%EC%8A%A4/id1590729784',
            '_blank'
          );
        }
        break;
    }
  }
};

const marketList = [
  {
    url: naverIcon,
    ko: '스마트스토어',
    en: 'SmartStore',
  },
  {
    url: coupangIcon,
    ko: '쿠팡',
    en: 'Coupang',
  },
  {
    url: gmarketIcon,
    ko: 'G마켓',
    en: 'G Market',
  },
  {
    url: auctionIcon,
    ko: '옥션',
    en: 'Auction',
  },
  {
    url: ESMplusIcon,
    ko: 'ESM PLUS',
    en: 'ESM PLUS',
  },
  {
    url: ablyIcon,
    ko: '에이블리',
    en: 'Ably',
  },
  {
    url: cafe24Icon,
    ko: '카페24',
    en: 'Cafe 24',
  },
  // {
  //     url: null,
  //     ko: "",
  //     en: "",
  // },
  // {
  //     url: null,
  //     ko: "",
  //     en: "",
  // },
];

export const SellerboxAppPage = () => {
  const { isEnteredByNaver } = useStoreValue();
  const history = useHistory();
  const orangeBackground = '#FFFFFF';
  const isMobile = useMediaQueryForMobile();
  const [markets, setMarkets] = useState<any>([]);

  useEffect(() => {
    api.GetMarketModel({}).then((res: any) => {
      let { code, data, message } = res.data;
      setMarkets(data.filter((d) => d.MarketSeqNo !== 99));
    });
  }, []);

  return (
    <WholePageWrapper id="hi">
      {isMobile ? (
        <MobilePageWrapper>
          <MobileContentGrayWrapper>
            <div style={{ height: 30 }} />

            <div
              className="fullWidth"
              onClick={() => {
                console.log(isEnteredByNaver);
              }}
            >
              <div className="orangeColor" style={{ fontSize: 28, fontWeight: 800 }}>
                셀러박스
              </div>
              <div style={{ fontSize: 28, fontWeight: 400 }}>
                성공에 필요한 <br />
                모든 데이터를 <br />
                확인하세요. <br />
              </div>
            </div>

            <AnimationDiv>
              <div className="v-space20" />
              <img
                src={'images/mobile-home-image-1.png'}
                style={{ width: '100%', boxSizing: 'border-box', padding: '0px 20px' }}
              />
            </AnimationDiv>
            <div className="v-space24" />

            <div className="flexRowWrapper" style={{ gap: 10 }}>
              <img
                className="pointer"
                src={'images/mobile-home-google-download.png'}
                style={{ width: 'calc(50% - 5px)' }}
                onClick={() => {
                  handleDownLoad('android', isEnteredByNaver);
                  // window.open(process.env.REACT_APP_GOOGLE_DOWNLOAD_URL,"_blank");
                }}
              />
              <img
                className="pointer"
                src={'images/mobile-home-apple-download.png'}
                style={{ width: 'calc(50% - 5px)' }}
                onClick={() => {
                  handleDownLoad('ios', isEnteredByNaver);
                  // window.open(process.env.REACT_APP_APPLE_DOWNLOAD_URL,"_blank");
                }}
              />
            </div>

            <div style={{ height: 8 }} />

            <img src={'images/scrollDownIcon.png'} style={{ width: 67 }} />

            <div style={{ height: 20 }} />
          </MobileContentGrayWrapper>

          <MobileContentLightGrayWrapper>
            <div style={{ height: 60 }} />
            <div className="fullWidth">
              <div className="font16bold orangeColor">일일리포트</div>
              <div style={{ height: 16 }} />
              <div className="font24middle">
                나의 마켓 데이터를
                <br />한 번에 확인할 수 있어요!
              </div>
              <div style={{ height: 24 }} />
              <div className="font24middle font14middle" style={{ color: '#6F6F6F' }}>
                셀러박스에 운영중인 마켓을 연동해 보세요. <br />
                판매 금액, 판매 건수, 정산 현황을 한눈에 <br />
                확인할 수 있어요.
              </div>
            </div>

            <div style={{ height: 40 }} />

            <div className="flexRowWrapper" style={{ gap: 20 }}>
              <div className="flexCenter" style={{ width: '50%' }}>
                <AnimationDiv delay={0.15}>
                  <img
                    src={'images/mobile-home-daily-1.png'}
                    style={{
                      width: '100%',
                      boxShadow: '-6.14035px 7.89474px 11.4035px rgba(0, 0, 0, 0.05)',
                      borderRadius: '10.5263px',
                    }}
                  />
                </AnimationDiv>
              </div>
              <div className="flexColumnWrapper" style={{ gap: 20, width: '50%' }}>
                <AnimationDiv>
                  <img
                    src={'images/mobile-home-daily-2.png'}
                    style={{
                      width: '100%',
                      boxShadow: '-6.14035px 7.89474px 11.4035px rgba(0, 0, 0, 0.05)',
                      borderRadius: '10.5263px',
                    }}
                  />
                </AnimationDiv>
                <AnimationDiv delay={0.3}>
                  <img
                    src={'images/mobile-home-daily-3.png'}
                    style={{
                      width: '100%',
                      boxShadow: '-6.14035px 7.89474px 11.4035px rgba(0, 0, 0, 0.05)',
                      borderRadius: '10.5263px',
                    }}
                  />
                </AnimationDiv>
              </div>
            </div>

            <div style={{ height: 60 }} />
          </MobileContentLightGrayWrapper>

          <MobileContentLightWhiteWrapper>
            <div style={{ height: 60 }} />
            <div className="fullWidth">
              <div className="font16bold orangeColor">판매</div>
              <div style={{ height: 16 }} />
              <div className="font24middle">
                주문 발생 알림과 정산 현황을
                <br />
                실시간으로 확인하세요!
              </div>
              <div style={{ height: 24 }} />
              <div className="font24middle font14middle" style={{ color: '#6F6F6F' }}>
                매일 업데이트 되는 판매 리포트로 <br />
                판매 현황을 확인할 수 있어요.
              </div>
            </div>

            <div style={{ height: 40 }} />

            <div className="flexRowWrapper" style={{ gap: 20 }}>
              <AnimationDiv>
                <img
                  src={'images/mobile-home-sales-1.png'}
                  style={{
                    width: '100%',
                    boxShadow: '-6.14035px 7.89474px 11.4035px rgba(0, 0, 0, 0.05)',
                    borderRadius: '10.5263px',
                  }}
                />
              </AnimationDiv>
              <AnimationDiv delay={0.15}>
                <img
                  src={'images/mobile-home-sales-2.png'}
                  style={{
                    width: '100%',
                    boxShadow: '-6.14035px 7.89474px 11.4035px rgba(0, 0, 0, 0.05)',
                    borderRadius: '10.5263px',
                  }}
                />
              </AnimationDiv>
            </div>

            <div style={{ height: 60 }} />
          </MobileContentLightWhiteWrapper>

          <MobileContentLightGrayWrapper>
            <div style={{ height: 60 }} />
            <div className="fullWidth">
              <div className="font16bold orangeColor">배송</div>
              <div style={{ height: 16 }} />
              <div className="font24middle">
                모든 마켓의 배송 현황
                <br />한 눈에 확인해 보세요!
              </div>
              <div style={{ height: 24 }} />
              <div className="font24middle font14middle" style={{ color: '#6F6F6F' }}>
                깜빡하고 배송하지 못한 건이 있나요? <br />
                이제 셀러박스에서 알려드릴게요!
              </div>
            </div>

            <div style={{ height: 40 }} />

            <div className="flexRowWrapper" style={{ gap: 20 }}>
              <AnimationDiv>
                <img
                  src={'images/mobile-home-delivery-1.png'}
                  style={{
                    width: '100%',
                    boxShadow: '-6.14035px 7.89474px 11.4035px rgba(0, 0, 0, 0.05)',
                    borderRadius: '10.5263px',
                  }}
                />
              </AnimationDiv>
              <AnimationDiv delay={0.15}>
                <img
                  src={'images/mobile-home-delivery-2.png'}
                  style={{
                    width: '100%',
                    boxShadow: '-6.14035px 7.89474px 11.4035px rgba(0, 0, 0, 0.05)',
                    borderRadius: '10.5263px',
                  }}
                />
              </AnimationDiv>
            </div>

            <div style={{ height: 60 }} />
          </MobileContentLightGrayWrapper>

          <MobileContentLightWhiteWrapper>
            <div style={{ height: 60 }} />
            <div className="fullWidth">
              <div className="font16bold orangeColor">구매</div>
              <div style={{ height: 16 }} />
              <div className="font24middle">
                구매자 패턴
                <br />
                셀러박스가 분석해 드려요!
                <br />
                리뷰도 편하게 관리하세요!
              </div>
              <div style={{ height: 24 }} />
              <div
                className="font24middle font14middle"
                style={{ color: '#6F6F6F', wordBreak: 'keep-all' }}
              >
                셀러박스에서 실시간으로 리뷰에 답변을 작성하세요.
                <br />
                빠른 대응으로 구매자에게 긍정적인 이미지를 만들어보세요!
              </div>
            </div>

            <div style={{ height: 40 }} />

            <div className="flexRowWrapper" style={{ gap: 20 }}>
              <AnimationDiv>
                <img
                  src={'images/mobile-home-delivery-1.png'}
                  style={{
                    width: '100%',
                    boxShadow: '-6.14035px 7.89474px 11.4035px rgba(0, 0, 0, 0.05)',
                    borderRadius: '10.5263px',
                  }}
                />
              </AnimationDiv>
              <AnimationDiv delay={0.15}>
                <img
                  src={'images/mobile-home-delivery-2.png'}
                  style={{
                    width: '100%',
                    boxShadow: '-6.14035px 7.89474px 11.4035px rgba(0, 0, 0, 0.05)',
                    borderRadius: '10.5263px',
                  }}
                />
              </AnimationDiv>
            </div>

            <div style={{ height: 60 }} />
          </MobileContentLightWhiteWrapper>

          <MobileContentWhiteWrapper>
            <div style={{ height: 60 }} />

            <div className="fullWidth">
              <div className="font16bold orangeColor">그 외 다양한 서비스</div>
              <div style={{ height: 16 }} />
              <div className="font24middle">
                더 많은 기능들을
                <br />
                직접 이용해 보세요!
              </div>
              <div style={{ height: 40 }} />

              <RightToLeftAnimationDiv>
                <div className="flex" style={{ gap: 16 }}>
                  <img src="images/mobile-home-others-2.png" style={{ width: 60, height: 60 }} />
                  <div className="flexColumnWrapper columnLeft">
                    <div
                      className="flexRowWrapper"
                      style={{ justifyContent: 'flex-start', height: 50 }}
                    >
                      월간 리포트
                    </div>
                    <div
                      className="font14middle"
                      style={{ color: '#6F6F6F', wordBreak: 'keep-all' }}
                    >
                      월 별로 판매 건수, 정산 금액,
                      <br />
                      리뷰 현황을 확인해 보세요.
                    </div>
                  </div>
                </div>
              </RightToLeftAnimationDiv>

              <div style={{ height: 20 }} />

              <RightToLeftAnimationDiv>
                <div className="flex" style={{ gap: 16 }}>
                  <img src="images/mobile-home-others-3.png" style={{ width: 60, height: 60 }} />
                  <div className="flexColumnWrapper columnLeft">
                    <div
                      className="flexRowWrapper"
                      style={{ justifyContent: 'flex-start', height: 50 }}
                    >
                      매거진
                    </div>
                    <div
                      className="font14middle"
                      style={{ color: '#6F6F6F', wordBreak: 'keep-all' }}
                    >
                      셀러에게 꼭 필요한
                      <br />
                      알찬 정보를 알아가세요.
                    </div>
                  </div>
                </div>
              </RightToLeftAnimationDiv>

              <div style={{ height: 20 }} />

              <RightToLeftAnimationDiv>
                <div className="flex" style={{ gap: 16 }}>
                  <img src="images/mobile-home-others-4.png" style={{ width: 60, height: 60 }} />
                  <div className="flexColumnWrapper columnLeft">
                    <div
                      className="flexRowWrapper"
                      style={{ justifyContent: 'flex-start', height: 50 }}
                    >
                      실시간 알림
                    </div>
                    <div
                      className="font14middle"
                      style={{ color: '#6F6F6F', wordBreak: 'keep-all' }}
                    >
                      신규 주문, 미배송 건에 대한
                      <br />
                      알림을 실시간으로 받으세요.
                    </div>
                  </div>
                </div>
              </RightToLeftAnimationDiv>

              <div style={{ height: 20 }} />

              <RightToLeftAnimationDiv>
                <div className="flex" style={{ gap: 16 }}>
                  <img src="images/mobile-home-others-1.png" style={{ width: 60, height: 60 }} />
                  <div className="flexColumnWrapper columnLeft">
                    <div
                      className="flexRowWrapper"
                      style={{ justifyContent: 'flex-start', height: 50 }}
                    >
                      리뷰 분석
                    </div>
                    <div
                      className="font14middle"
                      style={{ color: '#6F6F6F', wordBreak: 'keep-all' }}
                    >
                      내 마켓의 리뷰를 다른 상점과
                      <br />
                      비교해드려요. (OPEN 예정)
                    </div>
                  </div>
                </div>
              </RightToLeftAnimationDiv>
            </div>

            <div style={{ height: 60 }} />
          </MobileContentWhiteWrapper>

          <MobileContentLightWhiteWrapperWithNoPadding>
            <div style={{ height: 60 }} />

            <div className="font24middle">셀러님의 상점을 연동하고</div>
            <div className="font24middle">모든 정보를 확인하세요!</div>

            <div style={{ height: 40 }} />

            <div className="font12bold">연동 가능 상점</div>
            <div style={{ height: 20 }} />

            <div
              className="flexRowWrapper"
              style={{ width: '100vw', overflow: 'hidden', justifyContent: 'flex-start' }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  width: markets.slice(0, Math.ceil(markets.length / 2)).length * 100 * 2 + 'px',
                  animation: 'bannermove 10s linear infinite',
                }}
              >
                <Fragment>
                  {markets
                    .slice(0, Math.ceil(markets.length / 2))
                    .map((market: any, marketIndex: number) => {
                      return (
                        <div style={{ width: 100, textAlign: 'center' }} key={marketIndex}>
                          <img src={market.MarketIconUrl} style={{ width: 80, height: 80 }} />
                          <div style={{ height: 12 }} />
                          <div className="flexCenter" style={{ wordBreak: 'keep-all' }}>
                            {market.MarketName.split(' (')[0]}
                          </div>
                        </div>
                      );
                    })}
                  {markets
                    .slice(0, Math.ceil(markets.length / 2))
                    .map((market: any, marketIndex: number) => {
                      return (
                        <div style={{ width: 100, textAlign: 'center' }} key={marketIndex}>
                          <img src={market.MarketIconUrl} style={{ width: 80, height: 80 }} />
                          <div style={{ height: 12 }} />
                          <div className="flexCenter" style={{ wordBreak: 'keep-all' }}>
                            {market.MarketName.split(' (')[0]}
                          </div>
                        </div>
                      );
                    })}
                </Fragment>
              </div>
            </div>

            <div style={{ height: 30 }} />

            <div
              className="flexRowWrapper"
              style={{ width: '100vw', overflow: 'hidden', justifyContent: 'flex-end' }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: markets.slice(Math.ceil(markets.length / 2), -1).length * 100 * 2 + 'px',
                  animation: 'bannermovereverse 10s linear infinite',
                }}
              >
                <Fragment>
                  {markets
                    .slice(Math.ceil(markets.length / 2), -1)
                    .map((market: any, marketIndex: number) => {
                      return (
                        <div style={{ width: 100, textAlign: 'center' }} key={marketIndex}>
                          <img src={market.MarketIconUrl} style={{ width: 80, height: 80 }} />
                          <div style={{ height: 12 }} />
                          <div className="flexCenter" style={{ wordBreak: 'keep-all' }}>
                            {market.MarketName.split(' (')[0]}
                          </div>
                        </div>
                      );
                    })}
                  {markets
                    .slice(Math.ceil(markets.length / 2), -1)
                    .map((market: any, marketIndex: number) => {
                      return (
                        <div style={{ width: 100, textAlign: 'center' }} key={marketIndex}>
                          <img src={market.MarketIconUrl} style={{ width: 80, height: 80 }} />
                          <div style={{ height: 12 }} />
                          <div className="flexCenter" style={{ wordBreak: 'keep-all' }}>
                            {market.MarketName.split(' (')[0]}
                          </div>
                        </div>
                      );
                    })}
                </Fragment>
              </div>
            </div>
            <div style={{ height: 60 }} />
          </MobileContentLightWhiteWrapperWithNoPadding>

          <MobileContentGrayWrapperWithNoPadding>
            <div style={{ height: 60 }} />
            <div className="fullWidth" style={{ padding: '0px 30px' }}>
              <div className="font16bold orangeColor">셀러박스 키위, 셀키</div>
              <div style={{ height: 16 }} />
              <div className="font24middle">
                키워드 검색과 경쟁상점
                <br />
                분석을 한번에!
              </div>
              <div style={{ height: 24 }} />
              <div className="font24middle font14middle" style={{ color: '#6F6F6F' }}>
                필수 데이터와 핵심기능으로 셀러의 성장을 돕는 셀키!
                <br />
                모바일과 PC에서 편하게 만나보세요
              </div>
              <div style={{ height: 20 }} />
              <div className="fullWidth">
                <div
                  className="flex pointer"
                  onClick={() => {
                    window.open('https://sellkey.sellerbox.io', '_self');
                  }}
                  style={{
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                    gap: 6,
                    width: 137,
                    height: 46,
                    borderRadius: 8,
                    backgroundColor: '#FAFAFA',
                    paddingBottom: 13,
                  }}
                >
                  <img src="images/sellkeySmallIcon.png" style={{ width: 20, marginBottom: 2 }} />
                  <div style={{ lineHeight: '19.6px' }}>셀키 바로가기</div>
                </div>
              </div>
            </div>
            <div style={{ height: 40 }} />
            <div className="fullWidth">
              <AnimationDiv>
                <img src="images/sellkeyExampleImage.png" style={{ width: '100%' }} />
              </AnimationDiv>
            </div>
            <div style={{ height: 40 }} />
          </MobileContentGrayWrapperWithNoPadding>

          <div className="fullWidth flexCenter" style={{ position: 'relative' }}>
            <img src="images/mobile-home-last-background.png" style={{ width: '100%' }} />
            <img
              className="pointer"
              onClick={() => {
                window.open('https://www.cplat.io/sb', '_blank');
              }}
              src="images/mobile-home-last-button.png"
              style={{ width: '36%', position: 'absolute', bottom: '20%' }}
            />
          </div>
        </MobilePageWrapper>
      ) : (
        <PcPageWrapper id="aa">
          <MobileContentGrayWrapper style={{ position: 'relative' }}>
            <div className="flexRowWrapper" style={{ padding: '140px 0px', minHeight: '100vh' }}>
              <div className="flexColumn columnRight" style={{ width: '50%' }}>
                <div style={{ marginRight: 262 }}>
                  <img src="images/pc-home-text-image-1.png" style={{ width: 308 }} />
                  <div className="flex" style={{ gap: 10, marginTop: 37, alignItems: 'center' }}>
                    <img
                      src="images/mobile-home-google-download.png"
                      onClick={() => {
                        handleDownLoad('android', isEnteredByNaver);
                      }}
                      className="pointer"
                      style={{ width: 152, height: 48 }}
                    />
                    <img
                      src="images/mobile-home-apple-download.png"
                      onClick={() => {
                        handleDownLoad('ios', isEnteredByNaver);
                      }}
                      className="pointer"
                      style={{ width: 152, height: 48 }}
                    />
                    {isEnteredByNaver ? (
                      <img src="images/QrDownloadByNaver.png" style={{ width: 78 }} />
                    ) : (
                      <img src="images/QrDownload.png" style={{ width: 78 }} />
                    )}
                  </div>
                </div>
              </div>
              <div className="flexColumn columnLeft" style={{ width: '50%' }}>
                <RightToLeftAnimationDiv>
                  <img src="images/pc-home-image-1.png" style={{ width: 574 }} />
                </RightToLeftAnimationDiv>
              </div>
            </div>
            <img
              src="images/scrollDownIcon.png"
              style={{ width: 67, position: 'absolute', bottom: 34 }}
            />
          </MobileContentGrayWrapper>

          <MobileContentLightGrayWrapper>
            <div className="flexRowWrapper" style={{ padding: '120px 0px' }}>
              <div className="flexColumn columnRight rowCenter" style={{ width: '50%' }}>
                <div className="flex rowRight" style={{ gap: 48, marginRight: 30 }}>
                  <div className="flexColumn rowCenter">
                    <AnimationDiv>
                      <img
                        src="images/pc-home-daily-1.png"
                        style={{
                          width: 318,
                          boxShadow: '-6.14035px 7.89474px 11.4035px rgba(0, 0, 0, 0.05)',
                          borderRadius: '24px',
                        }}
                      />
                    </AnimationDiv>
                  </div>
                  <div className="flexColumn" style={{ gap: 48 }}>
                    <AnimationDiv>
                      <img
                        src="images/pc-home-daily-2.png"
                        style={{
                          width: 318,
                          boxShadow: '-6.14035px 7.89474px 11.4035px rgba(0, 0, 0, 0.05)',
                          borderRadius: '24px',
                        }}
                      />
                    </AnimationDiv>
                    <AnimationDiv>
                      <img
                        src="images/pc-home-daily-3.png"
                        style={{
                          width: 318,
                          boxShadow: '-6.14035px 7.89474px 11.4035px rgba(0, 0, 0, 0.05)',
                          borderRadius: '24px',
                        }}
                      />
                    </AnimationDiv>
                  </div>
                </div>
              </div>
              <div className="flexColumn columnLeft" style={{ width: '50%' }}>
                <img
                  src="images/pc-home-daily-text-image.png"
                  style={{ width: 364, marginLeft: 154 }}
                />
              </div>
            </div>
          </MobileContentLightGrayWrapper>

          <MobileContentLightWhiteWrapper>
            <div className="flexRowWrapper" style={{ padding: '120px 0px' }}>
              <div className="flexColumn columnRight" style={{ width: '50%' }}>
                <img
                  src="images/pc-home-sale-text-image.png"
                  style={{ width: 418, marginRight: 296 }}
                />
              </div>
              <div className="flexColumn columnLeft rowCenter" style={{ width: '50%' }}>
                <div className="flex rowRight" style={{ gap: 48, marginLeft: 10 }}>
                  <div className="flexColumn rowCenter">
                    <AnimationDiv>
                      <img
                        src="images/pc-home-sale-1.png"
                        style={{
                          width: 318,
                          boxShadow: '-6.14035px 7.89474px 11.4035px rgba(0, 0, 0, 0.05)',
                          borderRadius: '24px',
                        }}
                      />
                    </AnimationDiv>
                  </div>
                  <div className="flexColumn" style={{ gap: 48 }}>
                    <AnimationDiv delay={0.15}>
                      <img
                        src="images/pc-home-sale-2.png"
                        style={{
                          width: 318,
                          boxShadow: '-6.14035px 7.89474px 11.4035px rgba(0, 0, 0, 0.05)',
                          borderRadius: '24px',
                        }}
                      />
                    </AnimationDiv>
                  </div>
                </div>
              </div>
            </div>
          </MobileContentLightWhiteWrapper>

          <MobileContentLightGrayWrapper>
            <div className="flexRowWrapper" style={{ padding: '120px 0px' }}>
              <div className="flexColumn columnRight rowCenter" style={{ width: '50%' }}>
                <div className="flex rowRight" style={{ gap: 48, marginRight: 30 }}>
                  <div className="flexColumn rowCenter">
                    <AnimationDiv>
                      <img
                        src="images/pc-home-delivery-1.png"
                        style={{
                          width: 318,
                          boxShadow: '-6.14035px 7.89474px 11.4035px rgba(0, 0, 0, 0.05)',
                          borderRadius: '24px',
                        }}
                      />
                    </AnimationDiv>
                  </div>
                  <div className="flexColumn" style={{ gap: 48 }}>
                    <AnimationDiv delay={0.15}>
                      <img
                        src="images/pc-home-delivery-2.png"
                        style={{
                          width: 318,
                          boxShadow: '-6.14035px 7.89474px 11.4035px rgba(0, 0, 0, 0.05)',
                          borderRadius: '24px',
                        }}
                      />
                    </AnimationDiv>
                  </div>
                </div>
              </div>
              <div className="flexColumn columnLeft" style={{ width: '50%' }}>
                <img
                  src="images/pc-home-delivery-text-image.png"
                  style={{ width: 317, marginLeft: 154 }}
                />
              </div>
            </div>
          </MobileContentLightGrayWrapper>

          <MobileContentLightWhiteWrapper>
            <div className="flexRowWrapper" style={{ padding: '120px 0px' }}>
              <div className="flexColumn columnRight" style={{ width: '50%' }}>
                <img
                  src="images/pc-home-buyer-text-image.png"
                  style={{ width: 544, marginRight: 170 }}
                />
              </div>
              <div className="flexColumn columnLeft rowCenter" style={{ width: '50%' }}>
                <div className="flex rowRight" style={{ gap: 48, marginLeft: 10 }}>
                  <div className="flexColumn rowCenter">
                    <AnimationDiv>
                      <img
                        src="images/pc-home-buyer-1.png"
                        style={{
                          width: 318,
                          boxShadow: '-6.14035px 7.89474px 11.4035px rgba(0, 0, 0, 0.05)',
                          borderRadius: '24px',
                        }}
                      />
                    </AnimationDiv>
                  </div>
                  <div className="flexColumn" style={{ gap: 48 }}>
                    <AnimationDiv delay={0.15}>
                      <img
                        src="images/pc-home-buyer-2.png"
                        style={{
                          width: 318,
                          boxShadow: '-6.14035px 7.89474px 11.4035px rgba(0, 0, 0, 0.05)',
                          borderRadius: '24px',
                        }}
                      />
                    </AnimationDiv>
                  </div>
                </div>
              </div>
            </div>
          </MobileContentLightWhiteWrapper>

          <MobileContentWhiteWrapper>
            <div className="flexRowWrapper">
              <div className="flexColumn" style={{ width: 1457, padding: '120px 0px' }}>
                <img
                  src="images/pc-home-others-text-image.png"
                  style={{ width: 277, marginLeft: 14.5, marginBottom: 80 }}
                />
                <div className="flexRowWrapper" style={{ gap: 106 }}>
                  <AnimationDiv>
                    <img src="images/pc-home-others-1.png" style={{ width: 256 }} />
                  </AnimationDiv>
                  <AnimationDiv delay={0.15}>
                    <img
                      src="images/pc-home-others-2.png"
                      style={{ width: 227, marginRight: 29 }}
                    />
                  </AnimationDiv>
                  <AnimationDiv delay={0.3}>
                    <img src="images/pc-home-others-3.png" style={{ width: 254 }} />
                  </AnimationDiv>
                  <AnimationDiv delay={0.45}>
                    <img src="images/pc-home-others-4.png" style={{ width: 281 }} />
                  </AnimationDiv>
                </div>
              </div>
            </div>
          </MobileContentWhiteWrapper>

          <MobileContentLightWhiteWrapper>
            <div style={{ height: 140 }} />

            <div className="font36middle">셀러님의 상점을 연동하고</div>
            <div className="font36middle">모든 정보를 확인하세요!</div>

            <div style={{ height: 40 }} />

            <div className="font16bold">연동 가능 상점</div>

            <div style={{ height: 30 }} />

            <div
              className="flexRowWrapper"
              style={{ width: '100%', overflow: 'hidden', justifyContent: 'flex-start' }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  width: markets.length * 100 * 4 + 'px',
                  animation: 'bannermove 30s linear infinite',
                }}
              >
                <Fragment>
                  {markets.map((market: any, marketIndex: number) => {
                    return (
                      <div style={{ width: 100, textAlign: 'center' }} key={marketIndex}>
                        <img src={market.MarketIconUrl} style={{ width: 80, height: 80 }} />
                        <div style={{ height: 12 }} />
                        <div className="flexCenter" style={{ wordBreak: 'keep-all' }}>
                          {market.MarketName.split(' (')[0]}
                        </div>
                      </div>
                    );
                  })}
                  {markets.map((market: any, marketIndex: number) => {
                    return (
                      <div style={{ width: 100, textAlign: 'center' }} key={marketIndex}>
                        <img src={market.MarketIconUrl} style={{ width: 80, height: 80 }} />
                        <div style={{ height: 12 }} />
                        <div className="flexCenter" style={{ wordBreak: 'keep-all' }}>
                          {market.MarketName.split(' (')[0]}
                        </div>
                      </div>
                    );
                  })}
                  {markets.map((market: any, marketIndex: number) => {
                    return (
                      <div style={{ width: 100, textAlign: 'center' }} key={marketIndex}>
                        <img src={market.MarketIconUrl} style={{ width: 80, height: 80 }} />
                        <div style={{ height: 12 }} />
                        <div className="flexCenter" style={{ wordBreak: 'keep-all' }}>
                          {market.MarketName.split(' (')[0]}
                        </div>
                      </div>
                    );
                  })}
                  {markets.map((market: any, marketIndex: number) => {
                    return (
                      <div style={{ width: 100, textAlign: 'center' }} key={marketIndex}>
                        <img src={market.MarketIconUrl} style={{ width: 80, height: 80 }} />
                        <div style={{ height: 12 }} />
                        <div className="flexCenter" style={{ wordBreak: 'keep-all' }}>
                          {market.MarketName.split(' (')[0]}
                        </div>
                      </div>
                    );
                  })}
                </Fragment>
              </div>
            </div>
            <div style={{ height: 60 }} />

            <img src="images/pc-home-last-box-image.png" style={{ width: 340 }} />

            <div style={{ height: 160 }} />
          </MobileContentLightWhiteWrapper>

          <MobileContentGrayWrapper>
            <div style={{ height: 140 }} />
            <div className="flex" style={{ width: 1430, justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 32 }}>
                <img src="images/home-sellkey-text-image.png" style={{ width: 332 }} />
                <img
                  src="images/sellerboxHyperlinkIcon.png"
                  style={{ width: 152 }}
                  className="pointer"
                  onClick={() => {
                    window.open('https://sellkey.sellerbox.io');
                  }}
                />
              </div>
              <AnimationDiv>
                <img
                  src="images/pc-home-sellkey-image-1.png"
                  style={{
                    width: 645,
                    boxShadow: '-6.14035px 7.89474px 11.4035px rgba(0, 0, 0, 0.05)',
                    borderRadius: '24px',
                  }}
                />
              </AnimationDiv>
              <AnimationDiv delay={0.3}>
                <img
                  src="images/pc-home-sellkey-image-2.png"
                  style={{
                    width: 249,
                    boxShadow: '-6.14035px 7.89474px 11.4035px rgba(0, 0, 0, 0.05)',
                    borderRadius: '24px',
                  }}
                />
              </AnimationDiv>
            </div>
            <div style={{ height: 120 }} />
          </MobileContentGrayWrapper>

          <div className="flexRowWrapper" style={{ width: '100%', position: 'relative' }}>
            <img
              src="images/pc-home-last-background.png"
              style={{ width: '100%', minHeight: 460 }}
            />
            <img
              className="pointer"
              onClick={() => {
                window.open('https://www.cplat.io/sb');
              }}
              src="images/pc-home-inquiry-button.png"
              style={{ position: 'absolute', top: 257, width: 486 }}
            />
            <div
              style={{
                position: 'absolute',
                top: 141,
                fontSize: 36,
                fontWeight: 500,
                color: '#FAFAFA',
                textAlign: 'center',
                lineHeight: '54px',
              }}
            >
              셀러님과 함께 만드는 <span style={{ fontWeight: 800 }}>셀러박스!</span>
              <br />
              지금 시작해보세요
            </div>
          </div>
        </PcPageWrapper>
      )}
    </WholePageWrapper>
  );
};

export const WholePageWrapper = styled.div`
  min-width: 100%;
  // padding-top: 70px;
  /* min-width: 1440px; */
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  overflow-x: hidden;
`;

export const MobilePageWrapper = styled.div`
  min-height: calc(100vh - ${mobileAppBarHeight}px);
  overflow: hidden;
  width: 100%;
`;

export const MobileContentGrayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 30px;
  background-color: #f4f4f4;
`;

export const MobileContentGrayWrapperWithNoPadding = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 0px;
  background-color: #f4f4f4;
`;

export const MobileContentLightGrayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 30px;
  background-color: #f9f9f9;
`;

export const MobileContentLightWhiteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 30px;
  background-color: #fcfcfc;
`;

export const MobileContentLightWhiteWrapperWithNoPadding = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0px;
  background-color: #fcfcfc;
`;

export const MobileContentWhiteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 30px;
  background-color: #ffffff;
`;

export const PcPageWrapper = styled.div`
  min-height: calc(100vh - ${webAppBarHeight}px);
  /* width: 1440px; */
  width: 100%;
  // padding-bottom: 120px;
  min-width: 1440px;
  /* margin-top: 70px; */
  /* max-width: 1440px; */
`;

export const MainDetailDescriptionTextLeft = styled.div`
  text-align: left;
  font-weight: 500;
  font-size: 23px;
  letter-spacing: -0.04em;
`;

export const MainDetailDescriptionTextRight = styled.div`
  text-align: right;
  font-weight: 500;
  font-size: 23px;
  letter-spacing: -0.04em;
`;

import { styled, Button } from "@mui/material";

export const MyCircleButton = styled(Button)(({ theme }) => ({
  width: 211,
  height: 58,
  background: "#F3F3F3",
  borderRadius: 40,
  color: "#6F6F6F",
  "&:hover": {
    background: "#E2E2E2",
    color: "#6F6F6F",
  },
  [theme.breakpoints.down("sm")]: {
    width: 116,
    height: 40,
  },
}));

import React, { useState, useRef, useEffect } from 'react';
import { selectedType } from './MobileMainContainer';
import {
  Container,
  Desc,
  FunctionImageContainer,
  Indicator,
  Tab,
  TabContainer,
  Title,
} from './MobileFunctionContainerStyle';
import { FUNCTION_DATA } from '../const';

interface FunctionContainerProps {
  selected: selectedType;
}

const MobileFunctionContainer = ({ selected }: FunctionContainerProps) => {
  const [activedTab, setActivedTab] = useState<number>(0);
  const [indicatorPosition, setIndicatorPosition] = useState<number>(0);
  const [indicatorWidth, setIndicatorWidth] = useState<number>(0);
  const titleRefs = useRef<(HTMLDivElement | null)[]>([]);
  const tabContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (titleRefs.current[activedTab] && tabContainerRef.current) {
      const titleRect = titleRefs.current[activedTab]?.getBoundingClientRect();
      const containerRect = tabContainerRef.current?.getBoundingClientRect();
      const scrollLeft = tabContainerRef.current?.scrollLeft || 0; // 스크롤 위치 계산

      if (titleRect && containerRect) {
        const offset = titleRect.left - containerRect.left + scrollLeft; // 스크롤 위치를 반영한 좌표 계산
        setIndicatorPosition(offset);
        setIndicatorWidth(titleRect.width); // 탭의 너비로 인디케이터 너비 설정
      }
    }
  }, [activedTab]);

  return (
    <Container>
      {selected === 'Sellerbox' && (
        <>
          <Title>셀러박스가 제공하는 기능</Title>
          <Desc>판매를 더욱 쉽게 만들어드릴게요</Desc>
        </>
      )}
      {selected === 'Sellkey' && (
        <>
          <Title>셀러키워드가 제공하는 기능</Title>
          <Desc>핀매의 시작을 더욱 쉽게 만들어드릴게요</Desc>
        </>
      )}

      <TabContainer ref={tabContainerRef}>
        <Indicator leftPosition={indicatorPosition} width={indicatorWidth} type={selected} />
        {FUNCTION_DATA[selected].title.map((item, index) => (
          <Tab
            key={index}
            isActived={FUNCTION_DATA[selected].title[activedTab] === item}
            type={selected}
            onClick={() => setActivedTab(index)}
            ref={(el) => (titleRefs.current[index] = el)}
          >
            {item}
          </Tab>
        ))}
      </TabContainer>
      <FunctionImageContainer>
        <img
          src={FUNCTION_DATA[selected].mobileImage[activedTab]}
          style={{
            width: '100%',
          }}
        />
      </FunctionImageContainer>
    </Container>
  );
};

export default MobileFunctionContainer;

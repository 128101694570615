import { List, styled, Typography } from "@mui/material";
import { parse } from "date-fns";
import * as React from "react";
import { NoticeItemTest } from "../components/NoticeItemTest";

import { useMediaQueryForMobile } from "../hooks/useMediaQueryForMobile";
import { MyPagination } from "../widgets/MyPagination";
import { ContainerFrame } from "../App";
import { GetNoticeTest } from "../controller/api";
import { Context } from "react-responsive";
export interface INoticePageTestProps {}

export const NoticePageTest: React.FC<INoticePageTestProps> = ({}) => {
  const isMobile = useMediaQueryForMobile();
  const listStyle = isMobile
    ? { width: "100%" }
    : { width: "100%", marginTop: 85 };
  const TypoStyle = isMobile ? { paddingLeft: "3%" } : { paddingLeft: "1%" };

  const pageSize = 10;
  const [pageNum, setPageNum] = React.useState<number>(1);
  const [openIndex, setOpenIndex] = React.useState<number>(-1);
  const [renderData, setRenderData] = React.useState<any>([]);
  const [noticeData, setNoticeData] = React.useState<any>([]);
  const [maxPage, setMaxPage] = React.useState<number>(10);
  const [NoticeTitle, setNoticeTitle] = React.useState<string>("");
  const [NoticeContent, setNoticeContent] = React.useState<string>("");
  const [NoticeDate, setNoticeDate] = React.useState<any>("");
  const noticeinform = {
    OrderByMethod: 0,
    Skip: 1,
    Take: 10,
  };

  let getNoticeData: any = async () => {
    let result = await GetNoticeTest();
    return result.data[0].NoticeList.sort(
      (a: any, b: any) => parseInt(a.No) - parseInt(b.No)
    ).map((d: any) => {
      return {
        title: d.Item1,
        contents: d.Item2,
        date: d.Item3,
      };
    });
  };

  React.useEffect(() => {
    getNoticeData().then((res: any) => {
      setNoticeData(res);
    });
  }, []);

  // React.useEffect(() => {
  //   GetNoticeTest();
  // }, []);

  React.useEffect(() => {
    setPageNum(1);
  }, []);

  // React.useEffect(() => {
  //   getFAQData().then((res: any) => {
  //     setServerNoticeData(res);
  //   });
  // }, []);

  React.useEffect(() => {
    if (noticeData.length > 0) {
      setRenderData(
        noticeData.filter(
          (nothing: any, idx: number) =>
            idx >= (pageNum - 1) * pageSize && idx < pageNum * pageSize
        )
      );
    }
  }, [pageNum, noticeData]);

  React.useEffect(() => {
    setMaxPage(
      Math.floor(noticeData.length / pageSize) +
        (noticeData.length % pageSize === 0 ? 0 : 1)
    );
  }, [noticeData]);

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [pageNum]);

  return (
    <NoticeWrapper style={{ overflowX: "hidden" }}>
      <ContainerFrame style={{ width: "100%", overflowY: "hidden" }}>
        <Typography style={TypoStyle} variant={"h21"}>
          {"공지사항"}
        </Typography>
        <List style={listStyle}>
          {renderData.map((notice: any, idx: number) => (
            <NoticeItemTest
              openIndex={openIndex}
              setOpenIndex={setOpenIndex}
              index={idx}
              key={idx}
              title={notice.title}
              date={notice.date}
              contents={notice.contents}
            />
          ))}
        </List>
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <MyPagination
            style={{ marginTop: isMobile ? 73 : 55 }}
            count={maxPage}
            defaultPage={pageNum}
            page={pageNum}
            onChange={(event, page) => {
              setPageNum(page);
            }}
          />
        </div>
      </ContainerFrame>
    </NoticeWrapper>
  );
};

const NoticeWrapper = styled("div")(({ theme }) => ({
  height: 1100,
  // height: "100vh",
  paddingTop: 150,
  paddingLeft: 200,
  paddingRight: 200,
  [theme.breakpoints.down("sm")]: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 100,
  },
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

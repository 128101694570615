import * as React from 'react';
import { PrivacyPolicy, TermsOfUse, MarketingAgree } from '../constants/urls';
import { useHistory } from 'react-router';
import { ReactComponent as SellerboxColumnGray } from '../assets/icons/SellerboxColumnGray.svg';
import styled from 'styled-components';
import font from '../style/font';
import { colors } from '../style';
import { useMediaQueryForMobile } from '../hooks/useMediaQueryForMobile';

export function Footer() {
  const history = useHistory();
  const isMobile = useMediaQueryForMobile();

  const goTermsOfUse = React.useCallback(() => {
    history.push(TermsOfUse);
    window.scrollTo({
      top: 0,
    });
  }, [history]);

  const goPrivacyPolicy = React.useCallback(() => {
    history.push(PrivacyPolicy);
    window.scrollTo({
      top: 0,
    });
  }, [history]);

  const goMarketingAgreement = React.useCallback(() => {
    history.push(MarketingAgree);
    window.scrollTo({
      top: 0,
    });
  }, [history]);

  return (
    <Container>
      <ContentContainer isMobile={isMobile}>
        {isMobile && (
          <ButtonContainer isMobile={isMobile}>
            <ButtonText onClick={goTermsOfUse}>이용약관</ButtonText>
            <ButtonText onClick={goPrivacyPolicy}>개인정보처리방침</ButtonText>
            <ButtonText onClick={goMarketingAgreement}>마케팅 정보 활용 동의</ButtonText>
          </ButtonContainer>
        )}
        <LogoContainer>
          {!isMobile && <SellerboxColumnGray />}

          <LeftContainer>
            <div>
              {isMobile ? (
                <div>
                  <Text>서클플랫폼 주식회사 | 대표 최영우</Text>
                  <Text>주소 : 서울특별시 강남구 테헤란로5길7 9층, 120호 (KG타워)</Text>
                </div>
              ) : (
                <Text>
                  서클플랫폼 주식회사 | 대표 최영우 | 주소 : 서울특별시 강남구 테헤란로5길7 9층,
                  120호 (KG타워)
                </Text>
              )}
              {isMobile ? (
                <div>
                  <Text>사업자등록번호 : 557-87-02223</Text>
                  <Text>통신판매업신고 : 제2022-서울강남-01773호</Text>
                  <Text>메일 : support@cplat.io | 전화 : 02-558-2166</Text>
                  <Text>ⓒ circleplatform. All rights reserved.</Text>
                </div>
              ) : (
                <div>
                  <Text>
                    사업자등록번호 : 557-87-02223 | 통신판매업신고 : 제2022-서울강남-01773호
                  </Text>
                  <ContactContainer>
                    <Text>메일 : support@cplat.io | 전화 : 02-558-2166</Text>
                    <Text>ⓒ circleplatform. All rights reserved.</Text>
                  </ContactContainer>
                </div>
              )}
            </div>
          </LeftContainer>
        </LogoContainer>
        {!isMobile && (
          <ButtonContainer isMobile={isMobile}>
            <ButtonText onClick={goTermsOfUse}>이용약관</ButtonText>
            <ButtonText onClick={goPrivacyPolicy}>개인정보처리방침</ButtonText>
            <ButtonText onClick={goMarketingAgreement}>마케팅 정보 활용 동의</ButtonText>
          </ButtonContainer>
        )}
      </ContentContainer>
    </Container>
  );
}

const Container = styled.footer({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
});

const ContentContainer = styled.div<{
  isMobile: boolean;
}>(
  {
    width: 1180,
    display: 'flex',
    justifyContent: 'space-between',
  },
  ({ isMobile }) => ({
    flexDirection: isMobile ? 'column' : 'row',
    marginTop: isMobile ? 30 : 50,
    marginBottom: isMobile ? 32 : 45,
    marginLeft: isMobile ? 20 : 0,
    marginRight: isMobile ? 20 : 0,
  })
);

const LogoContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 16,
});

const LeftContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
});

const Text = styled.div({
  ...font.Body7M13,
  color: colors.gray.GR500,
});

const ContactContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 7,
});

const ButtonContainer = styled.div<{
  isMobile: boolean;
}>(
  {
    display: 'flex',
    alignItems: 'center',
  },
  ({ isMobile }) => ({
    gap: isMobile ? 24 : 36,
    marginBottom: isMobile ? 10 : 0,
  })
);
const ButtonText = styled.div({
  ...font.Body7M13,
  color: colors.gray.GR500,
  cursor: 'pointer',
});

import styled from 'styled-components';
import { colors } from '../../../../style';
import { selectedType } from './MainContainer';
import { Button } from '@mui/material';
import font from '../../../../style/font';
import { motion } from 'framer-motion/dist/framer-motion';

export const Container = styled('div')({
  display: 'flex',
  width: '100%',
  position: 'relative',
});

export const LeftContainer = styled('div')({
  display: 'flex',
  width: '50%',
  height: 900,
  zIndex: 10,
  flexDirection: 'column',
});

export const RightContainer = styled('div')<{ type: selectedType }>(
  {
    display: 'flex',
    width: '50%',
    height: 900,
    justifyContent: 'center',
    position: 'relative',
  },
  ({ type }) => ({
    background: type === 'Sellerbox' ? colors.orange.OR250 : colors.purple.PU250,
  })
);

export const LeftDecoration = styled.div<{ type: selectedType }>(
  {
    width: '320px',
    height: '320px',
    position: 'absolute',
    left: '-110px',
    borderRadius: '160px',
    opacity: 1,
  },
  ({ type }) => ({
    top: type === 'Sellerbox' ? '136px' : '550px',
    transform: type === 'Sellerbox' ? 'rotate(82.15deg)' : 'rotate(30deg)',
    background:
      type === 'Sellerbox'
        ? 'linear-gradient(0deg, rgba(255, 178, 122, 0.5) 0%, rgba(255, 178, 122, 0) 100%)'
        : 'linear-gradient(0deg, rgba(227, 221, 255, 1) 0%, rgba(227, 221, 255, 0) 100%)',
  })
);

export const RightTopDecoration = styled('div')({
  width: '300px',
  height: '300px',
  position: 'absolute',
  top: '144.17px',
  left: '995px',
  gap: '0px',
  opacity: 1,
  background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.6) 5.26%, rgba(255, 255, 255, 0) 50%)',
  borderRadius: '50% 50% 0 0',
  transform: 'rotate(-30deg)',
});

export const RightBottomDecoration = styled.div<{ type: selectedType }>(
  {
    width: '320px',
    height: '320px',
    position: 'absolute',
    top: '554.93px',
    right: '-70px',
    borderRadius: '160px',
    opacity: 1,
  },
  ({ type }) => ({
    background:
      type === 'Sellerbox'
        ? 'linear-gradient(289.93deg, #FFC67B 10.98%, rgba(255, 216, 165, 0) 89.02%)'
        : 'linear-gradient(289.93deg, #BFDAFF 10.98%, rgba(255, 216, 165, 0) 89.02%)',
  })
);

export const MainImageContainer = styled.div<{ type: selectedType }>(
  {
    zIndex: 10,
    width: '100%',
    position: 'absolute',
    top: '25%',
  },
  ({ type }) => ({
    maxWidth: type === 'Sellerbox' ? 650 : 680,
  })
);

export const MainImage = styled(motion.img)({
  width: '100%',
  objectFit: 'contain',
});

export const TextContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  textWrap: 'nowrap',
  position: 'absolute',
  top: '30%',
  left: 170,
});

export const MainTextContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
});

export const MainText = styled(motion.div)<{ type: selectedType }>(
  {
    fontSize: 44,
    fontWeight: 700,
  },
  ({ type }) => ({
    color: type === 'Sellerbox' ? colors.orange.OR500 : colors.purple.PU500,
  })
);
export const SubTextContainer = styled.div<{ type: selectedType }>(
  {
    marginTop: 32,
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
  },
  ({ type }) => ({
    marginBottom: type === 'Sellerbox' ? 58 : 40,
  })
);

export const SubText = styled(motion.div)<{ type: selectedType }>(
  {
    fontSize: 18,
    fontWeight: 500,
  },
  ({ type }) => ({
    color: type === 'Sellerbox' ? colors.orange.OR500 : colors.purple.PU500,
  })
);

export const FreeText = styled.div({
  fontSize: 15,
  fontWeight: 600,
  color: colors.gray.GR700,
});

export const StyledPCButton = styled(Button)(({}) => ({
  ...font.Body3S15,
  paddingRight: 18,
  paddingLeft: 18,
  paddingTop: 13,
  paddingBottom: 13,
  backgroundColor: colors.gray.GR100,
  color: colors.gray.GR700,
  borderRadius: 8,
  gap: 8,
  width: 'fit-content',
  '&: hover': {
    backgroundColor: colors.gray.GR200,
  },
}));

/** 애니메이션 효과 */
export const textVariants = {
  initial: { opacity: 0, y: -20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 20 },
};

export const imageVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

/* eslint-disable jsx-a11y/iframe-has-title */

import React from "react";

import { WholePageWrapper } from "../SellerboxAppPageV1";
import useWindowSize from "../../hooks/useWindowSize";
import { useHistory } from "react-router";
import font from "../../style/font";
import { colors } from "../../style";

//약관 전체보기 페이지
const termsUrlMap = {
  serviceTerms: "https://cplat-terms.s3.amazonaws.com/SELLERBOX-terms-use.html",
  privacyTerms:
    "https://cplat-terms.s3.amazonaws.com/SELLERBOX-terms-privacy.html",
  eventTerms:
    "https://cplat-terms.s3.amazonaws.com/SELLERBOX-terms-marketing.html",
};

const TermsDetail = () => {
  const dimensions = useWindowSize();
  const termsType = new URLSearchParams(window.location.search).get(
    "termsType"
  );

  const history = useHistory();

  return (
    <WholePageWrapper
      style={{ justifyContent: "flex-start", boxSizing: "border-box" }}
    >
      <div
        style={{
          backgroundColor: colors.gray.GR100,
          width: dimensions.width,
          height: dimensions.hieght,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "#FFFFFF",
            boxSizing: "border-box",
            padding: "0px 20px",
            paddingTop: 20,
            maxWidth: "600px",
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <img
              width={20}
              src="svg/headerLeftIcon.svg"
              style={{
                marginBottom: "calc( ( 100vh * 0.1 ) - 40px )",
              }}
              onClick={() => {
                history.goBack();
              }}
              alt="headerLeftIcon"
            />
            <div
              style={{
                ...font.Body3S15,
                color: colors.gray.GR900,
              }}
            >
              서비스 이용약관
            </div>
            <div style={{ width: 20 }} />
          </div>
          <div
            style={{
              flexGrow: 1,
              width: "100%",
              paddingBottom: 30,
            }}
          >
            {termsType && (
              <iframe
                style={{
                  height: "100%",
                  width: "100%",
                  border: "none",
                }}
                src={termsUrlMap[termsType]}
              />
            )}
          </div>
        </div>
      </div>
    </WholePageWrapper>
  );
};

export default TermsDetail;

import React from 'react';
import { styled, Typography } from '@mui/material';
import { useMediaQueryForMobile } from '../hooks/useMediaQueryForMobile';
import downloadQR from '../assets/qrcode/downloadQR.png';
import AppDownLoadImage01 from '../assets/images/AppDownLoadImage01.png';
import AppDownLoadImage02 from '../assets/images/AppDownLoadImage02.png';
import AppDownLoadImage03 from '../assets/images/AppDownLoadImage03.png';
import AppDownLoadImage04 from '../assets/images/AppDownLoadImage04.png';
import downloadRightImage from '../assets/images/downloadRightImage.png';
import axios from 'axios';
import { handleDownLoad } from './SellerboxAppPageV1';
import { useStoreValue } from '../contexts';
export interface IAppDownLoadPageProps {}
const AppDownLoadPage: React.FC<IAppDownLoadPageProps> = ({}) => {
  const xsMatches = useMediaQueryForMobile();

  return (
    <>
      {xsMatches ? (
        <MobileAppContainer style={{ overflowX: 'hidden' }}>
          <MobileAppTopZone>
            <Typography variant={'h20'} style={{ fontWeight: 800 }}>
              {'셀러박스로 모든 마켓'}
              <br />
              {'한 번에 관리하세요'}
            </Typography>
            <Typography style={{ marginTop: 20, marginBottom: 34 }} variant={'h13'}>
              {/* {"지금 바로 시작하세요."} */}
              {''}
            </Typography>

            <DownLoadAppComponent />
          </MobileAppTopZone>
          <MobileAppBottomZone>
            {/* <SellerBoxImageZone /> */}
            <img src={downloadRightImage} style={{ zIndex: 1, width: 300, marginTop: 20 }} />
            {/* <SellerBoxImageContainer /> */}
            <CirCleZone />
          </MobileAppBottomZone>
        </MobileAppContainer>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center ' }}>
          <DownloadWrapper>
            <AppPageContainer>
              <LeftZone>
                <Typography variant={'h2'} color="#1C1C1C">
                  {'셀러박스로 모든 마켓'}
                  <br />
                  {'한 번에 관리하세요'}
                </Typography>
                <Typography variant={'h13'} style={{ marginTop: 45, marginBottom: 116 }}>
                  {/* {"지금 바로 시작하세요."} */}
                  {''}
                </Typography>
                <div
                  style={{
                    marginLeft: 100,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '40px' }}>
                    <img src={downloadQR} style={{ width: '206px' }} />
                  </div>
                  <div>
                    <DownLoadAppComponent />
                  </div>
                </div>
              </LeftZone>
              <RightZoneWrapper>
                <RightZone>
                  <img src={downloadRightImage} style={{ zIndex: 1, width: 400, marginTop: 20 }} />
                  {/* <SellerBoxImageZone /> */}
                  {/* <SellerBoxImageContainer /> */}
                  <CirCleZone />
                </RightZone>
              </RightZoneWrapper>
            </AppPageContainer>
          </DownloadWrapper>
        </div>
      )}
    </>
  );
};

export const DownLoadAppComponent = () => {
  const { isEnteredByNaver } = useStoreValue();
  const xsMatches = useMediaQueryForMobile();

  const handleGoogleClick = () => {
    handleDownLoad('android', isEnteredByNaver);
    // window.open("https://play.google.com/store/apps/details?id=com.circleplatform.sellerbox");
    // window.open("https://sellerbox.page.link/?link=https://sellerbox.io&apn=com.circleplatform.sellerbox&isi=1590729784&ibi=com.circleplatform.sellerbox&utm_campaign=sellerbox_naver&utm_medium=cpc&utm_source=naver","_blank");
  };

  const handleAppClick = () => {
    handleDownLoad('ios', isEnteredByNaver);
    // window.open("https://apps.apple.com/kr/app/%EC%85%80%EB%9F%AC%EB%B0%95%EC%8A%A4/id1590729784");
    // window.open("https://sellerbox.page.link/?link=https://sellerbox.io&d=1&apn=com.circleplatform.sellerbox&isi=1590729784&ibi=com.circleplatform.sellerbox&utm_campaign=sellerbox_naver&utm_medium=cpc&utm_source=naver","_blank");
  };

  return (
    <DownLoadBtnWrapper style={{ cursor: 'pointer' }}>
      <img
        style={{ marginRight: xsMatches ? 7 : 28 }}
        src={AppDownLoadImage01}
        onClick={handleGoogleClick}
      />
      <img src={AppDownLoadImage02} onClick={handleAppClick} />
    </DownLoadBtnWrapper>
  );
};
const LeftZone = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '50%',
  marginRight: 84,
  alignItems: 'start',
  justifyContent: 'center',
}));
const RightZoneWrapper = styled('div')(({ theme }) => ({}));
const RightZone = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
}));
const DownLoadBtnWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
}));

const SellerBoxImageZone = styled('div')(({ theme }) => ({
  width: 268,
  height: 580,
  top: 110,
  right: 110,
  position: 'absolute',
  backgroundImage: `url(${AppDownLoadImage03})`,
  zIndex: 1,
}));
const SellerBoxImageContainer = styled('div')(({ theme }) => ({
  width: 495,
  height: 798,
  backgroundImage: `url(${AppDownLoadImage04})`,
  zIndex: 2,
}));
const CirCleZone = styled('div')(({ theme }) => ({
  width: 532,
  height: 532,
  background: 'linear-gradient(180deg, rgba(255, 127, 0, 0.8) 0%, rgba(255, 191, 128, 0.8) 100%)',
  transform: 'rotate(-180deg)',
  borderRadius: '50%',
  position: 'absolute',
  [theme.breakpoints.down('sm')]: {
    width: 456,
    height: 456,
  },
}));
const AppPageContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  background: 'E5E5E5',
  position: 'relative',
  marginTop: 70,
  // marginLeft: "14.35%",
  marginBottom: 70,
}));
const MobileAppContainer = styled('div')(({ theme }) => ({
  marginTop: 70,
  marginBottom: 70,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'center',
  width: '100%',
}));
const MobileAppTopZone = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 200,
  [theme.breakpoints.down('sm')]: {
    marginTop: 50,
  },
}));

const MobileAppBottomZone = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
}));

const DownloadWrapper = styled('div')(({ theme }) => ({
  // width: "100%",
  maxWidth: 1100,
}));
export default AppDownLoadPage;

import React, { useCallback } from "react";
import { styled, Typography } from "@mui/material";
import { useMediaQueryForMobile } from "../hooks/useMediaQueryForMobile";
import { MobileTermOfUseContainer, TermOfUseContainer, CenterContainer } from "./TermsOfUsePage";

export interface IPrivacyMainPageProps {}
const PrivacyMainPage: React.FC<IPrivacyMainPageProps> = ({}) => {
  const isMobile = useMediaQueryForMobile();
  return (
    <>
    {
      true ?
      <div style={{display: "flex", justifyContent: "center", alignItems: "center",boxSizing: "border-box",paddingTop: "70px"}}>
        {console.log(isMobile)}
        <div style={{boxSizing: "border-box",display: "flex", justifyContent: "center", alignItems: "center",width: "100%",padding: `0px ${isMobile ? 10 : 100}px`}}>
          <iframe 
            style={{ width: "100%", height: "calc(100vh - 300px)",border: "none"}}
            src={"https://cplat-terms.s3.amazonaws.com/SELLERBOX-terms-privacy.html"+"?date="+new Date()}
          />
        </div>
      </div>
      :
    <>
      {/* {isMobile ? ( */}
      <CenterContainer>
        <MobileTermOfUseContainer style={{maxWidth: "660px"}}>
          <TitleZone>
            <Typography variant={"h21"}>{"개인정보처리방침"}</Typography>
          </TitleZone>
          <ContentZone>
            <ContentItemZone>
              <Typography variant={"h16"}>
                {
                  "서클플랫폼 주식회사(이하 “회사”)는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 등 모든 관련 법규를 준수하며, 회사의 서비스를 이용하는 고객(이하 “이용자”)의 개인정보가 보호받을 수 있도록 최선을 다하고 있습니다."
                }
                <br />
                {
                  "‘개인정보처리방침’이란 이용자의 소중한 개인정보를 보호함으로써 이용자가 안심하고 서비스를 이용할 수 있도록 회사가 준수해야 할 지침을 의미합니다. 회사는 개인정보처리방침을 개정하는 경우   웹사이트   공지사항(또는   개별공지)을   통하여   공지할 예정이오니 서비스 이용시에 수시로 확인해주시기 바랍니다."
                }
              </Typography>
            </ContentItemZone>
            <ContentItemZone>
              <Typography variant={"body11"}>
                {"제 1 조 [수집하는 개인정보의 항목 및 수집방법]"}
              </Typography>
              <br />
              <Typography variant={"h16"}>
                {"① 수집하는 개인정보의 항목"}
                <br />
                {
                  "1) 회사는 회원가입 또는 서비스 이용 시 고객상담, 각종 서비스의 제공을 위하여 다음과 같은 개인정보를 수집 이용하고 있습니다."
                }
                <br />
                {"가. 필수정보"}
                <br />
                {
                  "- 아이디, 비밀번호, 상호(법인명), 성명(대표자명), 사업자등록번호, 업태/업종, 사업자유형, 사업장주소 및 우편번호, 본인확인 값 (CI, DI)"
                }
                <br />
                {"- 담당자명, 회사 전화번호, 담당자 이메일, "}
                <br />
                {
                  "- 거래쇼핑몰 관리자정보, 거래쇼핑몰 계정정보 (ID, PW, 2 차 비밀번호 등), 거래쇼핑몰 등록정보 (상호,사업자등록번호,  대표자명,  대표자 연락처,  정산은행명,  예금주,  계좌번호 등),  거래쇼핑몰 판매정보 (배송정보, 상품등록내역, 매출내역, 정산내역, 반품내역, 리뷰정보 등)"
                }
                <br />
                {"나 .선택정보"}
                <br />
                {"- 담당자 직급, 담당자 휴대전화번호, 팩스번호, 직원 수"}
                <br />
                {
                  "② 서비스 이용 과정이나 사업 처리 과정에서 다음과 같은 정보들이 자동으로 생성되어 수집될 수 있습니다."
                }
                <br />
                {
                  "- IP Address, 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용 기록"
                }
                <br />
                {
                  "③ 유료 서비스 이용 시 다음과 같은 정보들이 수집될 수 있습니다."
                }
                <br />
                {"가. 신용카드 결제 시 : 카드사명, 카드번호 등"}
                <br />
                {"나. 계좌이체 결제 시 : 송금자 이름, 송금 일자, 송금 은행 등"}
                <br />
                {
                  "④ 서비스 제공 또는 법정대리인의 동의 절차 등 본인확인이 필요한 경우 다음과 같은 정보가 수집될 수 있습니다."
                }
                <br />
                {
                  "- 성명, 생년월일, 성별, 내/외국인, 이동전화번호, 이동통신사 정보, 본인확인 값(CI, DI)"
                }
                <br />
                {"1) 개인정보 수집방법"}
                <br />
                {"회사는 다음과 같은 방법으로 개인정보를 수집합니다."}
                <br />
                {
                  "가. 홈페이지 회원가입, 서비스 이용, 서면양식, 팩스, 전화, 상담게시판, 이메일, 이벤트 응모, 배송요청"
                }
                <br />
                {"나. 제휴사에서 제공받음"}
                <br />
                {
                  "다. 금융사(카드, 은행, 보험, 증권 등) 또는 국세청 등에서 공인인증서를 통한 데이터 수집"
                }
                <br />
                {"라. 생성정보수집 도구 활용 등"}
                <br />
                {
                  "2) 회사는 기본적 인권침해의 우려가 있는 개인정보(인종 및 민족, 사상 및 신조, 출신지 및 본적지, 정치적 성향 및 범죄기록, 건강상태 및 성생활 등)는 요구하지 않습니다."
                }
                <br />
              </Typography>
            </ContentItemZone>
            <ContentItemZone>
              <Typography variant={"body11"}>
                {"제 2 조 [개인정보의 수집 및 이용목적]"}
              </Typography>
              <Typography variant={"h16"}>
                {"회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다."}
                <br />
                {
                  "① 서비스 제공에 관한 계약 이행 및 유료 서비스 제공에 따른 요금 정산, 콘텐츠 제공, 유료 서비스 이용에 대한 과금, 구매 및 요금 결제, 본인인증, 물품배송 또는 청구서 등 발송, 요금 추심"
                }
                <br />
                {
                  "② 회원관리 회원제 서비스 이용에 따른 본인확인, 개인식별, 불량회원의 부정 이용 방지와 비인가 사용"
                }
                <br />
                {
                  "방지, 중복가입 확인, 가입의사 확인, 분쟁 조정을 위한 기록보전, 불만처리 등 민원처리, 고지사항 전달"
                }
                <br />
                {
                  "③ 신규 서비스 개발 및 마케팅 및 광고에 활용 신규 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 접속 빈도 파악, 회원의 서비스 이용에 대한 통계, 이벤트 정보 및 참여기회 제공, 광고성 정보 제공"
                }
              </Typography>
            </ContentItemZone>
            <ContentItemZone>
              <Typography variant={"body11"}>
                {"제 3 조 [개인정보의 공유 및 제공]"}
              </Typography>
              <Typography variant={"h16"}>
                {
                  "① 회사는 이용자의 개인정보를 제2조(개인정보의 수집 및 이용목적)에서 고지한 범위 내에서 이용하며,"
                }
                <br />
                {
                  "이용자의 사전 동의 없이는 동 범위를 초과하여 이용하거나 타인 또는 타 기관에 제공 또는 공유하지"
                }
                <br />
                {"않습니다. 다만, 다음의 경우에는 예외로 합니다."}
                <br />
                {"1) 이용자가 사전에 동의한 경우"}
                <br />
                {
                  "2) 법령의 규정에 의거하거나, 수사상 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는"
                }
                <br />
                {"경우"}
                <br />
                {"3) 서비스 제공에 따른 요금 정산을 위해 필요한 경우"}
                <br />
                {
                  "4) 통계작성, 학술연구 또는 시장조사를 위하여 개인을 식별할 수 없는 형태로 제공하는 경우"
                }
                <br />
                {
                  "②  회사는 이용자에 대하여 보다 질 높은 서비스 제공 등을 위해 사전에 동의를 획득한 경우에 한하여 이용자의 개인정보를 제공하고 있습니다."
                }
              </Typography>
            </ContentItemZone>
            <ContentItemZone>
              <Typography variant={"body11"}>
                {"제 4 조 [개인정보의 처리 위탁]"}
              </Typography>
              <Typography variant={"h16"}>
                {
                  "회사는 서비스 이행을 위해 이용자의 개인정보를 위탁 처리할 경우 위탁하는 내용 및 수탁자를 고지하고,"
                }
                <br />
                {
                  "위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정해 관련 법규를 준수하고 이를"
                }
                <br />
                {
                  "감독합니다. 회사의 개인정보 위탁처리 기관 및 위탁업무 내용은 다음과 같습니다."
                }
                <br />
                <table style={{width: "100%"}}>
                  <tr>
                    <td style={{width: "50%",border: "1px solid #000000"}}>
                      {"수탁업체"}
                    </td>
                    <td style={{width: "50%",border: "1px solid #000000"}}>
                      {"위탁업무 내용"}      
                    </td>
                  </tr>
                  <tr>
                  <td style={{width: "50%",border: "1px solid #000000"}}>
                      {"해당없음"}
                    </td>
                    <td style={{width: "50%",border: "1px solid #000000"}}>
                      {"해당없음"}
                    </td>
                  </tr>
                </table>
                <br />
              </Typography>
            </ContentItemZone>
            <ContentItemZone>
              <Typography variant={"body11"}>
                {"제 5 조 [개인정보의 보유 및 이용기간]"}
              </Typography>
              <Typography variant={"h16"}>
                {
                  "① 이용자의 개인정보는 이용자가 회원탈퇴를 요청하거나 제공한 개인정보의 수집 및 이용에 대한 동의를"
                }
                <br />
                {
                  "철회하는 경우,  또는 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다.  다만,  회사는"
                }
                <br />
                {
                  "이용자로부터 개인정보 수집 시 동의받은 개인정보 보유 및 이용기간 내에서 개인정보를 보유, 처리할 수"
                }
                <br />
                {
                  "있습니다. 단, 다음의 정보에 대해서는 보존근거에서 명시한 근거에 따라 보존기간 동안 보존합니다."
                }
                <br />
                {
                  "1) 서비스 제공을 위해 수집한 회원가입 정보 : 회원 탈퇴 시까지"
                }
                <br />
                {"2) 관련법령에 의한 개인정보 보유"}
                <br />

                <table style={{ width: "100%"}}>
                  <tr>
                    <td style={{border: "1px solid #000000", width: "30%",textAlign: "center",fontWeight: "bold"}}>
                      {"관련법률"}
                    </td>
                    <td style={{border: "1px solid #000000", width: "50%",textAlign: "center",fontWeight: "bold"}}>
                      {"보유항목"}
                    </td>
                    <td style={{border: "1px solid #000000", width: "20%",textAlign: "center",fontWeight: "bold"}}>
                      {"보유기간"}
                    </td>
                  </tr>
                  <tr>
                    <td style={{border: "1px solid #000000", width: "30%"}}>
                    {"부가가치세법"}
                    </td>
                    <td style={{border: "1px solid #000000", width: "50%"}}>
                      {"세금계산서, 영수증 기록"}
                    </td>
                    <td style={{border: "1px solid #000000", width: "20%"}}>
                      {"5년"}
                    </td>
                  </tr>
                  <tr>
                    <td style={{border: "1px solid #000000", width: "30%"}}>
                      {"통신비밀보호법"}
                    </td>
                    <td style={{border: "1px solid #000000", width: "50%"}}>
                      {"웹사이트 방문 및 로그인 기록"}
                    </td>
                    <td style={{border: "1px solid #000000", width: "20%"}}>
                      {"3개월"}
                    </td>
                  </tr>
                  <tr>
                    <td style={{border: "1px solid #000000", width: "30%"}} rowSpan={4}>
                      {"전자상거래 등에서의"}
                      <br />
                      {"소비자보호에 관한 법률"}
                    </td>
                    <td style={{border: "1px solid #000000", width: "50%"}}>
                      {"계약 또는 청약철회 등에 관한 기록"}
                    </td>
                    <td style={{border: "1px solid #000000", width: "20%"}}>
                      {"5년"}
                    </td>
                  </tr>
                  <tr>
                    <td style={{border: "1px solid #000000", width: "50%"}}>
                      {"표시/광고에 관한 기록"}
                    </td>
                    <td style={{border: "1px solid #000000", width: "20%"}}>
                      {"6개월"}
                    </td>
                  </tr>
                  <tr>
                    <td style={{border: "1px solid #000000", width: "50%"}}>
                      {"대금결제 및 재화 등의 공급에 관한 기록"}
                    </td>
                    <td style={{border: "1px solid #000000", width: "20%"}}>
                      {"5년"}
                    </td>
                  </tr>
                  <tr>
                    <td style={{border: "1px solid #000000", width: "50%"}}>
                      {"소비자의 불만 또는 분쟁처리에 관한 기록"}
                    </td>
                    <td style={{border: "1px solid #000000", width: "20%"}}>
                      {"3년"}
                    </td>
                  </tr>
                  <tr>
                    <td style={{border: "1px solid #000000", width: "30%"}}>
                    {"전자금융거래법"}  
                    </td>
                    <td style={{border: "1px solid #000000", width: "50%"}}>
                      {"전자금융 거래에 관한 기록"}
                    </td>
                    <td style={{border: "1px solid #000000", width: "20%"}}>
                      {"5년"}
                    </td>
                  </tr>
                </table>

                <br />
                {"3) 회사 내부 방침에 따른 회원가입정보 보유"}
                <br />
                {
                  "가. 비정상적인 회원탈퇴 등으로 인한 피해예방 : 회원탈퇴 신청 후 7일"
                }
                <br />
                {
                  "나. 부정이용 이슈 방지 : 회원 탈퇴완료 후 6개월 (아이디, 이름, 연계정보)"
                }
                <br />
                {"② 개인정보 유효기간제(휴면계정 정책)"}
                <br />
                {
                  "1) 1 년 이상 서비스 이용기록이 없는 이용자의 회원가입정보는 정보통신망법 제29조에 따라 일반"
                }
                <br />
                {
                  "이용자의 회원가입정보와 분리하여 별도로 저장 및 관리 됩니다. (휴면계정으로 전환)"
                }
                <br />
                {
                  "2) 회사는 휴면계정으로 전환되는 시점 최소 30일 이전, 해당 내용에 대해 이메일 등을 통해 이용자에게 사전 통지합니다."
                }
                <br />
                {
                  "3)  분리 저장된 개인정보는 관련 법령에 특별한 규정이 있는 경우를 제외하고 해당 개인정보를 이용/제공하지 않습니다."
                }
              </Typography>
            </ContentItemZone>
            <ContentItemZone>
              <Typography variant={"body11"}>
                {"제 6 조 [개인정보의 파기절차 및 방법]"}
              </Typography>
              <Typography variant={"h16"}>
                {
                  "이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기됩니다. 회사의"
                }
                <br />
                {"개인정보 파기절차 및 방법은 다음과 같습니다."}
                <br />
                {"① 파기절차"}
                <br />
                {
                  "가.  자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져 내부방침 및 기타 관계법령에 의한 정보보호 사유에 따라 일정 기간 저장 후 파기됩니다."
                }
                <br />
                {
                  "나. 상기 개인 정보는 법률로 정한 보유 의무를 정한 경우를 제외하고 다른 목적으로 이용되지 않습니다."
                }
                <br />
                {"② 파기방법"}
                <br />
                {
                  "가. 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다."
                }
                <br />
                {
                  "나. 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다."
                }
                <br />
              </Typography>
            </ContentItemZone>
            <ContentItemZone>
              <Typography variant={"body11"}>
                {"제 7 조 [이용자 및 법정대리인의 권리와 그 행사방법]"}
              </Typography>
              <Typography variant={"h16"}>
                {
                  "① 이용자는 언제든지 이용자 본인의 개인정보를 조회하거나 정정할 수 있으며, 가입해지(회원탈퇴) 또는 개인정보의 삭제를 요청할 수 있습니다. 단, 서비스 제공을 위해 반드시 필요한 개인정보를 삭제하는 경우 관련 서비스를 제공받지 못할 수 있습니다."
                }
                <br />
                {
                  "②  이용자의 개인정보를 조회,  정정하기 위하여는 개인정보변경을 선택하고,  가입해지(동의철회)를 위하여는 서비스 해지를 신청하여 본인 확인 절차 후 직접 조회,  정정 또는 가입해지(동의철회)할 수 있습니다. 또는 개인정보보호책임자 및 담당자에게 서면, 전화 또는 이메일로 연락할 경우에도 본인 확인 절차 후 지체 없이 필요한 내용을 조치받을 수 있습니다."
                }
                <br />
                {
                  "③ 개인정보의 오류에 대한 정정을 요청한 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 합니다."
                }
                <br />
                {
                  "④ 회사는 이용자의 요청에 의해 해지 또는 삭제된 개인정보를 제5조(개인정보의 보유 및 이용기간)에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다. "
                }
              </Typography>
            </ContentItemZone>
            <ContentItemZone>
              <Typography variant={"body11"}>
                {
                  "제 8 조 [개인정보의 자동 수집 장치의 설치, 운영 및 그 거부에 관한 사항]"
                }
              </Typography>
              <Typography variant={"h16"}>
                {
                  "①  회사는 이용자에게 개인화되고 맞춤화된 서비스를 제공하기 위해 이용자의 정보를 저장하고 수시로"
                }
                <br />
                {
                  "찾아내는 쿠키(Cookie)를 사용할 수 있습니다. 쿠키는 웹사이트가 이용자의 웹브라우저로 전송하는 소량의"
                }
                <br />
                {"정보입니다."}
                <br />
                {
                  "② 이용자는 쿠키에 대한 선택권을 가지고 있습니다. 이용자는 웹 브라우저에서 [도구] > [인터넷옵션] >"
                }
                <br />
                {
                  "[개인정보] > [설정]을 선택함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나,"
                }
                <br />
                {
                  "아니면 모든 쿠키의 저장을 거부할 수 있습니다.  단,  모든 쿠키의 저장을 거부하는 경우,  쿠키를 통해"
                }
                <br />
                {"회사에서 제공하는 서비스를 이용할 수 없습니다."}
              </Typography>
            </ContentItemZone>
            <ContentItemZone>
              <Typography variant={"body11"}>
                {"제 9 조 [개인정보의 기술적/관리적 보호 대책]"}
              </Typography>
              <Typography variant={"h16"}>
                {
                  "회사는 이용자의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위해 기술적, 관리적 대책을 마련하고 있습니다."
                }
                <br />
                {
                  "회사는 이용자의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위해 기술적, 관리적 대책을 마련하고 있습니다."
                }
                <br />
                {"① 기술적 대책"}
                <br />
                {
                  "가. 이용자의 비밀번호는 암호화되어 있어 이용자 본인 외에는 알 수 없습니다."
                }
                <br />
                {
                  "나. 회사는 암호화 통신을 통하여 네트워크 상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다."
                }
                <br />
                {
                  "다. 회사는 해킹이나 컴퓨터바이러스 등에 의해 이용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다."
                }
                <br />
                {
                  "라.  회사는 개인정보의 훼손에 대비하여 자료를 수시로 백업하고 있고,  최신 백신프로그램을 이용하여 컴퓨터바이러스에 의한 피해를 방지하기 위한 조치를 취하고 있습니다."
                }
                <br />
                {
                  "마. 회사는 시스템에 대한 접근통제, 권한 관리, 취약점 점검 등의 조치를 통해 보안성이 강화될 수 있도록 지속적으로 노력하고 있습니다."
                }
                <br />
                {"② 관리적 대책"}
                <br />
                {
                  "가. 회사는 이용자의 개인정보에 대한 접근권한을 최소한의 인원으로 제한하고 있습니다."
                }
                <br />
                {
                  "나. 회사는 개인정보처리자를 대상으로 개인정보 보호 의무 등에 관해 정기적인 교육을 실시하고 있습니다."
                }
                <br />
                {
                  "다. 회사는 개인 정보보호 업무를 전담하는 부서에서 개인 정보처리방침 및 내부 규정 준수 여부를 확인하여 문제가 발견될 경우, 즉시 바로잡을 수 있도록 노력하고 있습니다."
                }
                <br />
                {
                  "라. 이용자 본인의 부주의나 회사가 관리하지 않는 영역에서의 사고 등 회사의 귀책에 기인하지 않은 손해에 대해서는 회사는 책임을 지지 않습니다."
                }
              </Typography>
            </ContentItemZone>
            <ContentItemZone>
              <Typography variant={"body11"}>
                {"제 10 조 [영업 양수 등의 통지]"}
              </Typography>
              <Typography variant={"h16"}>
                {
                  "회사는 영업의 전부 또는 일부를 양도하거나 합병, 상속 등으로 그 권리, 의무를 이전하는 경우 이용자에게"
                }
                <br />
                {"관련 내용으로 다음의 항목을 통지합니다."}
                <br />
                {"① 영업의 전부 또는 일부의 양도, 합병 또는 상속의 사실"}
                <br />
                {"② 권리, 의무를 승계한 자의 성명, 주소, 연락처"}
              </Typography>
            </ContentItemZone>
            <ContentItemZone>
              <Typography variant={"body11"}>
                {"제 11 조 [개인정보보호책임자 및 담당자의 연락처]"}
              </Typography>
              <Typography variant={"h16"}>
                <br />
                {
                  "①  회사는 이용자의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 다음과 같이"
                }
                <br />
                {"개인정보보호책임자를 두고 있습니다."}
                <br />
                {"가. 개인정보보호책임자"}
                <br />
                {
                  "- 성명 이준원 / 소속 서클플랫폼 / 전화번호 010-3059-0177 / 이메일 mars@cplat.io"
                }
                <br />
                {"나. 개인정보보호담당자"}
                <br />
                {
                  "- 성명 성락현 / 소속 서클플랫폼 / 전화번호 010-9985-1260 / 이메일 racgoo@cplat.io"
                }
                <br />
                {
                  "② 기타 개인정보 침해에 대한 신고나 상담이 필요하신 경우 다음 기관에 문의하시기 바랍니다."
                }
                <br />
                {"가. 개인정보침해신고센터(privacy.kisa.or.kr / 국번 없이 118)"}
                <br />
                {
                  "나. 대검찰청 사이버범죄수사단(cyberid@spo.go.kr / 02-3480-3571)"
                }
                <br />
                {
                  "다. 경찰청 사이버안전국(cyberbureau.police.go.kr / 국번 없이 182)"
                }
                <br />
                {"라. 개인정보 분쟁조정위원회(kopico.go.kr / 1833-6972)"}
              </Typography>
            </ContentItemZone>
            <ContentItemZone>
              <Typography variant={"body11"}>
                {"제 12 조 [링크 사이트]"}
              </Typography>
              <Typography variant={"h16"}>
                {
                  "회사는 이용자에게 다른 회사의 웹사이트에 대한 링크를 제공할 수 있습니다.  이 경우 링크되어 있는"
                }
                <br />
                {
                  "웹사이트가 개인정보를 수집하는 행위에 대해서는 본 개인정보처리방침이 적용되지 않습니다."
                }
              </Typography>
            </ContentItemZone>
            <ContentItemZone>
              <Typography variant={"body11"}>{"제 13 조 [기타]"}</Typography>
              <Typography variant={"h16"}>
                {
                  "본 개인정보처리방침의 내용 추가, 삭제 및 수정이 있을 시에는 최소 7일 전부터 홈페이지의 공지사항을"
                }
                <br />
                {"통해 고지할 것입니다."}
                <br />
                {"개인정보취급방침 공고일자 : 2021 년 11월  1일"}
                <br />
                {"개인정보취급방침 시행일자 : 2021 년 11월 15일"}
                <br />
              </Typography>
            </ContentItemZone>
          </ContentZone>
        </MobileTermOfUseContainer>
        </CenterContainer>
      {/* ) : (
        <PrivacyComponent />
      )} */}
    </>
    }
    </>
  );
};

const PrivacyComponent = () => {
  return (
    <TermOfUseContainer>
      <TitleZone>
        <Typography variant={"h21"}>{"개인정보처리방침"}</Typography>
      </TitleZone>
      <ContentZone>
        <ContentItemZone>
          <Typography variant={"body11"}>{"제1조 (목적)"}</Typography>
          <Typography variant={"h16"}>
            {
              "이 약관은 주식회사 서클플랫폼(이하 “회사”라 합니다)가 운영하는 셀러박스 “애플리케이션”(이하 “홈페이지”와 “애플리케이션”을 “APP”이라고 합니다)의 서비스 이용 및 제공에 관한 제반 사항의 규정을 목적으로 합니다."
            }
          </Typography>
        </ContentItemZone>
        <ContentItemZone>
          <Typography variant={"body11"}>{"제2조 (용어의 정의) "}</Typography>

          <Typography variant={"h16"}>
            {"① 이 약관에서 사용하는 용어의 정의는 다음과 같습니다."}
            <br />
            {
              "“서비스”라 함은 구현되는 PC, 모바일 기기를 통하여 “이용자”가 이용할 수 있는 데이터분석서비스 등 회사가 제공하는 제반 서비스를 의미합니다."
            }
            <br />
            {
              "“이용자”란 “APP”에 접속하여 이 약관에 따라 “APP”이 제공하는 서비스를 받는 회원 및 비회원을 말합니다."
            }
            <br />
            {
              "“회원”이란 “APP”에 개인정보를 제공하여 회원등록을 한 자로서, “APP”이 제공하는 서비스를 이용하는 자를 말합니다."
            }
            <br />
            {
              "“모바일 기기”란 콘텐츠를 다운로드 받거나 설치하여 사용할 수 있는 기기로서, 휴대폰, 스마트폰, 휴대정보단말기(PDA), 태블릿 등을 의미합니다."
            }
            <br />
            {
              "“계정정보”란 회원의 회원번호와 내보험다보여 등 외부계정정보, 기기정보 등 회원이 회사에 제공한 정보를 의미합니다."
            }
            <br />
            {
              "“애플리케이션”이란 회사가 제공하는 서비스를 이용하기 위하여 모바일 기기를 통해 다운로드 받거나 설치하여 사용하는 프로그램 일체를 의미합니다."
            }
            <br />
            {
              "② 이 약관에서 사용하는 용어의 정의는 본 조 제1항에서 정하는 것을 제외하고는 관계법령 및 서비스별 정책에서 정하는 바에 의하며, 이에 정하지 아니한 것은 일반적인 상 관례에 따릅니다."
            }
          </Typography>
        </ContentItemZone>

        <ContentItemZone>
          <Typography variant={"body11"}>
            {"제3조 (약관의 효력 및 변경)"}
          </Typography>

          <Typography variant={"h16"}>
            {
              " ① 본 약관은“APP” 내 또는 그 연결화면에 게시하거나 이용자에게 공지함으로써 효력이 발생합니다."
            }

            <br />
            {
              "② 회사는 불가피한 여건이나 사정이 있을 경우 「약관의 규제에 관한 법률」, 「정보통신망이용촉진 및 정보보호 등에 관한 법률」등 관련 법령에 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다."
            }
            <br />
            {
              "③ 회사가 약관을 개정할 경우에는 적용일자 및 개정내용, 개정사유 등을 명시하여 최소한 그 적용일 7일 이전부터 “APP” 내 또는 그 연결화면에 게시하여 이용자에게 공지합니다. 다만, 변경된 내용이 회원에게 불리하거나 중대한 사항의 변경인 경우에는 그 적용일 30일 이전까지 본문과 같은 방법으로 공지하고, 회원의 전자우편주소, 전자메모, 서비스 내 쪽지, 문자메시지(LMS/SMS)의 방법으로 회원에게 통지합니다. 이 경우 개정 전 내용과 개정 후 내용을 명확하게 비교하여 회원이 알기 쉽도록 표시합니다."
            }
            <br />
            {
              "④ 회사가 약관을 개정할 경우 개정약관 공지 후 개정약관의 적용에 대한 회원의 동의 여부를 확인합니다. 회사는 제3항의 공지 또는 통지를 할 경우 회원이 개정약관에 대해 동의 또는 거부의 의사표시를 하지 않으면 동의한 것으로 볼 수 있다는 내용도 함께 공지 또는 통지를 하며, 회원이 이 약관 시행일까지 거부의 의사표시를 하지 않는다면 개정약관에 동의한 것으로 볼 수 있습니다. 회원이 개정약관에 대해 동의하지 않는 경우 회사 또는 회원은 서비스 이용계약을 해지할 수 있습니다."
            }
          </Typography>
        </ContentItemZone>
        <ContentItemZone>
          <Typography variant={"body11"}>{"제4조 (약관 외 준칙)"}</Typography>
          <Typography variant={"h16"}>
            {
              "이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 「약관의 규제에 관한 법률」, 「정보통신망이용촉진 및 정보보호 등에 관한 법률」등 관련 법령 또는 상 관례에 따릅니다."
            }
          </Typography>
        </ContentItemZone>
        <ContentItemZone>
          <Typography variant={"body11"}>{"제5조 (회원가입)"}</Typography>
          <Typography variant={"h16"}>
            {
              " ① 이용자는 “APP”에서 정한 양식에 따라 회원정보를 기입한 후 이 약관의 내용에 대하여 동의하여 회원가입 신청을 하면, 회사가 이러한 신청을 승낙하여 회원으로 가입됩니다."
            }
            <br />
            {
              "② 회사는 원칙적으로 전 항에 따라 회원가입신청에 대하여 승낙함을 원칙으로 합니다. 다만, 회사는 다음 각 호의 어느 하나에 해당하는 이용자에 대해서는 회원가입을 거절하거나 사후에 회원자격을 상실시킬 수 있습니다."
            }
            <br />
            {"회원정보 내용을 허위로 기재하거나 타인의 명의를 도용한 경우"}
            <br />
            {
              "회사가 서비스를 제공하지 않은 국가에서 비정상적이거나 우회적인 방법을 통해 서비스를 이용하는 경우"
            }
            <br />
            {"사회의 안녕과 질서 또는 미풍양속을 저해할 목적으로 신청한 경우"}
            <br />
            {"부정한 용도로 서비스를 이용하고자 하는 경우"}
            <br />
            {"영리를 추구할 목적으로 서비스를 이용하고자 하는 경우"}
            <br />
            {
              "가입 신청자가 본 약관에 의거 이전에 회원자격을 상실한 적이 있는 경우"
            }
            <br />
            {"만 14세 미만인 경우"}
            <br />
            {
              "그 밖에 각 호에 준하는 사유로서 승낙이 부적절하다고 판단되는 경우"
            }
            <br />
            {
              "③ 회원은 가입시 등록한 회원정보의 변경이 발생한 경우, 즉시 “APP”에서 직접 수정 또는 전자우편, 기타 방법으로 회사에 그 변경 사실을 알려야 합니다."
            }
          </Typography>
        </ContentItemZone>
        <ContentItemZone>
          <Typography variant={"body11"}>
            {"제6조 (회원탈퇴 및 자격상실)"}
          </Typography>

          <Typography variant={"h16"}>
            {
              " ① 회원은 언제든지 서비스 이용을 원하지 않는 경우 언제든지 탈퇴를 요청할 수 있으며, 이 경우 회사는 즉시 회원탈퇴를 처리합니다. 회원탈퇴로 인해 회원이 서비스 내에서 보유한 이용정보는 모두 삭제되어 복구가 불가능하게 됩니다."
            }
            <br />
            {
              "② 회사는 회원이 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 등 본 계약을 유지할 수 없는 중대한 사유가 있는 경우에는 회원에게 통지하고, 서비스 이용을 제한․중지하거나 회원 자격을 상실시킬 수 있습니다."
            }
            <br />
            {
              "③ 회사가 회원자격을 상실시키는 경우에는 회원 등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원 등록 말소 전에 소명할 기회를 부여합니다."
            }
            <br />
            {
              "④ 회사는 최근의 서비스 이용일부터 연속하여 1년 동안 회사의 서비스를 이용하지 않은 회원(이하 “휴면계정”이라 합니다)의 개인정보를 보호하기 위해 이용계약을 정지 또는 해지하고 회원의 개인정보를 분리보관 또는 파기 등의 조치를 취할 수 있습니다. 이 경우 조치일 30일 전까지 계약 정지 또는 해지, 개인정보 분리보관 또는 파기 등의 조치가 취해진다는 사실 및 파기될 개인정보 등을 회원에게 통지합니다."
            }
          </Typography>
        </ContentItemZone>
        <ContentItemZone>
          <Typography variant={"body11"}>
            {"제7조 (회원에 대한 통지)"}
          </Typography>

          <Typography variant={"h16"}>
            {
              "① 회사가 회원에 대한 통지를 하는 경우, 회원이 회사에 제출한 전자우편 또는 휴대번호로 할 수 있다"
            }
            <br />
            {
              "② 회사는 불특정다수 회원에 대한 통지의 경우 30일 이상 “APP”에 게시함으로서 개별 통지에 갈음 할 수 있다."
            }
          </Typography>
        </ContentItemZone>
        <ContentItemZone>
          <Typography variant={"body11"}>{"제8조 (회사의 의무)"}</Typography>

          <Typography variant={"h16"}>
            {
              " ① 회사는 관련 법령, 이 약관에서 정하는 권리의 행사 및 의무의 이행을 신의에 따라 성실하게 준수합니다."
            }
            <br />
            {
              "② 회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을 갖추어야 하며 개인정보처리방침을 공시하고 준수합니다. 회사는 이 약관 및 개인정보처리방침에서 정한 경우를 제외하고는 회원의 개인정보가 제3자에게 공개 또는 제공되지 않도록 합니다."
            }
            <br />
            {
              "③ 회사는 계속적이고 안정적인 서비스의 제공을 위하여 서비스 개선을 하던 중 설비에 장애가 생기거나 데이터 등이 멸실․훼손된 때에는 천재지변, 비상사태, 현재의 기술로는 해결이 불가능한 장애나 결함 등 부득이한 사유가 없는 한 지체 없이 이를 수리 또는 복구하도록 최선의 노력을 다합니다."
            }
          </Typography>
        </ContentItemZone>
        <ContentItemZone>
          <Typography variant={"body11"}>{"제9조 (회원의 의무)"}</Typography>
          <Typography variant={"h16"}>
            {
              " ① 회원은 회사에서 제공하는 서비스의 이용과 관련하여 다음 각 호에 해당하는 행위를 해서는 안 됩니다."
            }

            <br />
            {
              "이용신청 또는 회원 정보 변경 시 타인의 명의를 도용하거나 허위사실을 기재하는 행위"
            }
            <br />
            {
              "회사의 직원이나 운영자를 가장하거나 타인의 명의를 도용하여 메일을 발송하는 행위, 타인으로 가장하거나 타인과의 관계를 허위로 명시하는 행위"
            }
            <br />
            {
              "다른 회원의 개인정보를 무단으로 수집⋅저장⋅게시 또는 유포하는 행위"
            }
            <br />
            {
              "서비스를 무단으로 영리, 영업, 광고, 홍보, 정치활동, 선거운동 등 본래의 용도 이외의 용도로 이용하는 행위"
            }
            <br />
            {
              "회사의 서비스를 이용하여 얻은 정보를 무단으로 복제․유통․조장하거나 상업적으로 이용하는 행위, 알려지거나 알려지지 않은 버그를 악용하여 서비스를 이용하는 행위"
            }
            <br />
            {
              "타인을 기망하여 이득을 취하는 행위, 회사의 서비스의 이용과 관련하여 타인에게 피해를 입히는 행위"
            }

            <br />
            {
              "회사나 타인의 지적재산권 또는 초상권을 침해하는 행위, 타인의 명예를 훼손하거나 손해를 가하는 행위"
            }
            <br />
            {
              "법령에 의하여 전송 또는 게시가 금지된 정보(컴퓨터 프로그램)나 컴퓨터 소프트웨어⋅하드웨어 또는 전기통신장비의 정상적인 작동을 방해⋅파괴할 목적으로 고안된 바이러스⋅컴퓨터 코드⋅파일⋅프로그램 등을 고의로 전송⋅게시⋅유포 또는 사용하는 행위"
            }
            <br />
            {
              "회사로부터 특별한 권리를 부여받지 않고 애플리케이션을 변경하거나, 애플리케이션에 다른 프로그램을 추가⋅삽입하거나, 서버를 해킹⋅역설계하거나, 소스 코드나 애플리케이션 데이터를 유출⋅변경하거나, 별도의 서버를 구축하거나, 웹사이트의 일부분을 임의로 변경⋅도용하여 회사를 사칭하는 행위"
            }
            <br />
            {
              "그 밖에 관련 법령에 위반되거나 선량한 풍속 기타 사회통념에 반하는 행위"
            }
            <br />
            {
              "② 회원의 ID와 비밀번호에 관한 관리책임은 회원에게 있으며, 이를 제3자가 이용하도록 하여서는 안됩니다."
            }
            <br />
            {
              "③ 이용자는 본 약관 및 관련법령에 규정한 사항을 준수하여야 합니다."
            }
          </Typography>
        </ContentItemZone>
        <ContentItemZone>
          <Typography variant={"body11"}>
            {"제10조 (서비스의 이용) "}
          </Typography>

          <Typography variant={"h16"}>
            {
              "① “APP”은 다음과 같은 서비스를 회원에게 제공합니다. 단, 회사가“APP”으로 제공하는 서비스 이용을 위해 필요시 이용자에게 위치정보이용약관 및 “계정정보”의 제공에 관한 동의를 추가로 요구할 수 있으며, 동의하지 않을 경우 보험계약 조회 등 일부 서비스가 제한될 수 있습니다."
            }
            <br />
            {"보험계약 조회 및 분석정보 제공"}
            <br />
            {"내보험다보여(한국신용정보원) APP 가입 대행"}
            <br />
            {"이메일 수신 서비스"}
            <br />
            {"보험상품 관련 설계사 연결 및 보장 컨설팅"}
            <br />
            {"회사가 취급하는 보험상품의 설명 및 안내"}
            <br />
            {"시사, 금융, 투자, 부동산, 건강관리 등 컨텐츠 제공"}
            <br />
            {"보험금 청구 관련 서비스"}
            <br />
            {"기타 회사가 정하는 서비스"}
            <br />
            {
              "② 회사는 회원에게 별도의 동의를 받은 경우 서비스 이용에 대한 유용한 각종 정보에 대하여 “APP”에 게재하는 것 이외에 문자메시지, 푸시(Push) 알림 등의 방법으로 회원에게 제공할 수 있습니다."
            }
            <br />
            {
              "③ 서비스의 이용은 “APP”의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴를 원칙으로 합니다. 다만, 정기점검 등의 필요로 인하여 회사가 정한 날 및 시간에 대해서는 예외로 합니다."
            }
            <br />
            {
              "④ 회사는 “APP” 시스템 등의 보수, 점검, 교체, 시스템의 고장, 통신의 두절, 기타 불가항력적 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다. 다만, 서비스 중단의 경우 회사는 “APP”에 사전 통지하고, 사전에 통지할 수 없는 부득이한 사유가 있는 경우 제 조에 정한 방법으로 회원에게 통지합니다."
            }
          </Typography>
        </ContentItemZone>
        <ContentItemZone>
          <Typography variant={"body11"}>
            {"제11조 (개인정보의 보호 및 사용)"}
          </Typography>

          <Typography variant={"h16"}>
            {
              " ① 회사는 이용자의 정보 수집시 원활한 서비스 제공을 위해 필요한 최소한의 정보를 수집합니다."
            }
            <br />
            {
              "② 회사가 이용자의 개인식별이 가능한 개인정보 및 계정정보를 수집하는 때에는 반드시 당해 이용자의 동의를 받습니다."
            }
            <br />
            {
              "③ 회사는 관련 법령에 의하거나, 관련 국가기관 등의 요청이 있는 경우를 제외하고는 회원의 개인정보를 본인의 동의 없이 타인에게 제공하지 않습니다."
            }
            <br />
            {
              "④ 이용자는 언제든지 회사가 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정 또는 동의 철회를 요구할 수 있으며 회사는 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는 그 오류를 정정할 때까지 해당 개인정보를 이용하지 않습니다."
            }
            <br />
            {
              "⑤ 기타 개인정보관련사항은 “APP”에 별도로 게시하는 개인정보취급방침에 의거합니다."
            }
          </Typography>
        </ContentItemZone>
        <ContentItemZone>
          <Typography variant={"body11"}>{"제12조 (회사의 면책)"}</Typography>

          <Typography variant={"h16"}>
            {
              " ① 회사는 이 약관 제10조 제3항, 제4항의 사유로 서비스 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대해서는 관련법에 특별한 규정이 없는 한 회원에게 별도의 보상을 하지 않습니다."
            }
            <br />
            {
              "② 회사는 회원의 고의 또는 과실로 인한 서비스 이용의 장애에 대하여는 책임을 지지 않습니다."
            }
            <br />
            {
              "③ 서비스와 관련하여 게재한 정보나 자료 등의 신뢰성, 정확성 등에 대하여 회사는 고의 또는 중대한 과실이 없는 한 책임을 지지 않습니다."
            }
            <br />
            {
              "④ 회사는 무료로 제공되는 서비스 이용과 관련하여 회원에게 발생한 손해에 대해서는 책임을 지지 않습니다."
            }
            <br />
            {
              "⑤ 회사는 회원이 서비스를 이용하여 기대하는 이익을 얻지 못하거나 상실한 것에 대하여 책임을 지지 않습니다."
            }
            <br />
            {
              "⑥ 회사는 회원이 “APP”아이디 및 비밀번호, 모바일 기기 비밀번호, 계정정보 등을 관리하지 않아 발생하는 손해에 대해 책임을 지지 않습니다."
            }
            <br />
            {
              "⑦ 회원이 모바일 기기의 변경, 모바일 기기의 번호 변경, 운영체제(OS) 버전의 변경, 해외 로밍, 통신사 변경 등으로 인해 콘텐츠 전부나 일부의 기능을 이용할 수 없는 경우 회사는 이에 대해 책임을 지지 않습니다."
            }
            <br />
            {
              "⑧ 회원이 회사가 제공하는 콘텐츠나 계정정보를 삭제한 경우 회사는 이에 대해 책임을 지지 않습니다."
            }
          </Typography>
        </ContentItemZone>
        <ContentItemZone>
          <Typography variant={"body11"}>
            {"제13조 (저작권 등의 귀속)"}
          </Typography>
          <Typography variant={"h16"}>
            {
              "① 회사가 제작한 서비스 내의 콘텐츠에 대한 저작권과 기타 지적재산권은 회사에 귀속합니다."
            }
            <br />
            {
              "② 회원은 회사가 제공하는 서비스를 이용하여 얻은 정보 중에서 회사 또는 제공업체에 지적재산권이 귀속된 정보를 회사 또는 제공업체의 사전 동의 없이 복제⋅전송 등의 방법(편집, 공표, 공연, 배포, 방송, 2차적 저작물 작성 등을 포함합니다. 이하 같습니다)에 의하여 영리목적으로 이용하거나 타인에게 이용하게 하여서는 안 됩니다."
            }
            <br />
            {
              "③ 회원의 게시물이 정보통신망법 및 저작권법 등 관련법에 위반되는 내용을 포함하는 경우, 권리자는 관련법이 정한 절차에 따라 해당 게시물의 게시중단 및 삭제 등을 요청할 수 있으며, 이로 인해 발생하는 민·형사상의 책임은 전적으로 해당 회원 본인이 부담하여야 하며, 회사는 관련법에 따라 조치를 취하여야 합니다."
            }
            <br />
            {
              "④ 회사는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가 있거나 기타 회사 정책 및 관련법에 위반되는 경우에는 관련법에 따라 해당 게시물에 대해 임시조치 등을 취할 수 있습니다."
            }
            <br />
            {
              "⑤ 이 조는 회사가 서비스를 운영하는 동안 유효하며, 회원 탈퇴 후에도 지속적으로 적용됩니다."
            }
            <br />
            {"제14조 (회원의 고충처리 및 분쟁해결)"}
            <br />
            {
              "① 회사는 회원의 편의를 고려하여 회원의 의견이나 불만을 제시하는 방법을 서비스 내 또는 그 연결화면에 안내합니다."
            }
            <br />
            {
              "② 회사는 회원으로부터 제기되는 의견이나 불만이 정당하다고 객관적으로 인정될 경우에는 합리적인 기간 내에 이를 신속하게 처리합니다. 다만, 처리에 장기간이 소요되는 경우에는 회원에게 장기간이 소요되는 사유와 처리일정을 서비스 내 공지하거나 제8조에 따라 통지합니다."
            }
          </Typography>
        </ContentItemZone>
        <ContentItemZone>
          <Typography variant={"body11"}>
            {"제15조 (재판권 및 준거법)"}
          </Typography>

          <Typography variant={"h16"}>
            {
              "이 약관은 대한민국 법률에 따라 규율되고 해석됩니다. 회사와 회원 간에 발생한 분쟁으로 소송이 제기되는 경우에는 법령에 정한 절차에 따른 법원을 관할 법원으로 합니다."
            }
          </Typography>
        </ContentItemZone>
      </ContentZone>
    </TermOfUseContainer>
  );
};
export default PrivacyMainPage;

const TitleZone = styled("div")(({ theme }) => ({
  marginTop: 85,
  marginBottom: 85,
  [theme.breakpoints.down("sm")]: {
    marginBottom: 35,
  },
}));

const ContentZone = styled("div")(({ theme }) => ({
  marginBottom: 85,
}));

const ContentItemZone = styled("div")(({ theme }) => ({
  marginBottom: 20,
}));

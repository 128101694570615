import * as React from "react";
import { styled, Typography, Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { PrivacyPopup } from "../popup/PrivacyPopup";
import { FileDrop } from "react-file-drop";
import FileUploader from "../widgets/FileUploaderButton";
import DeleteIcon from '@mui/icons-material/Delete';
import { ReactComponent as FileDownloadIcon } from "../assets/icons/FileDownload.svg";
import { TextLimitPopup } from "../popup/TextLimitPopup";
import { useMediaQueryForMobile } from "../hooks/useMediaQueryForMobile";
import { MyCheckbox } from "../widgets/MyCheckbox";
import { ContainerFrame } from "../App";
import { handleBreakpoints } from "@mui/system";
import Buttom from "@mui/material/Button";

import axios from "axios";
import { Context } from "react-responsive";
import * as api from "../controller/api";
import moment from "moment";

export interface IContactPage {
  isDataVoucher?: boolean
}
export interface FileInfo {
  uploadFiles: File[];
  setUploadFiles: React.Dispatch<React.SetStateAction<File[]>>;
  isMobile?: boolean;
}

export interface FileResult {
  uploadFiles: File[];
  setUploadFiles: any;
  isMobile?: boolean;
}




//   display: flex;
//   flex-wrap: wrap;
//   justify-content: center;
//   align-items: center;
//   width: 250px;
//   height: 150px;
//   border-radius: 10px;
//   border: 4px solid #FF7F00;
//   background-color: #FFFFFF;
//   color: #FF7F00;
//   font-weight: 550;

// `;

export const InputBox = (
  {value, onChange, placeholder, error, helperText, multiline = false, isMobile = false,height = isMobile ? 50 : 48}: 
  {value: string, onChange: (e: any) => void , placeholder: string, error: boolean, helperText: string, multiline?: boolean, height?: number, isMobile?: boolean}
) => {
  // error={titleValidate ? true : false}
  return <div style={{height: height, position: "relative", width: "100%"}} >
    {
      multiline 
      ?
      <textarea className="contactInputBox" value={value} onChange={onChange} placeholder={placeholder} style={{height: height, fontSize: isMobile ? 15 :16, fontWeight: 600, lineHeight: "24px", color: "#3D3D3D", padding: "13px 12px", borderRadius: 4, width: "100%", borderColor: error && "#FF5B5B"}}  />
      :
      <input className="contactInputBox"  value={value} onChange={onChange} placeholder={placeholder} style={{height: height, fontSize: isMobile ? 15 : 16, fontWeight: 600, lineHeight: "24px", color: "#3D3D3D", padding: "13px 12px", borderRadius: 4, width: "100%", borderColor: error && "#FF5B5B"}}  />
    }
      
      {
        (!helperText || helperText!=="") &&
        <div style={{position: "absolute",left: 0, bottom: -22,height: 20, fontSize: 12, lineHeight: "18px", fontWeight: 500, color: "#FF5B5B"}} >
          {helperText}
        </div>
      }
    </div>
}

export const InputLabel = ({text, required = false, isMobile = false}: {text: string; required: boolean, isMobile?: boolean}) => {
  return <div style={{color: "#3D3D3D", fontSize: isMobile ? 12 : 18, fontWeight: isMobile ? 700 : 600, lineHeight: isMobile ? "18px" : "28px",  minWidth: 110, display: "flex", alignItems: "center", height: "100%", marginBottom: isMobile ? 6 : 0}} >
    <div>{text}</div>{required && <span style={{marginLeft: 2, color: "#FF5B5B"}} >*</span>}
  </div>
}

export const ContactPage: React.FC<IContactPage> = ({isDataVoucher = false}) => {
  const [title,setTitle]=React.useState<string>("");
  const [name,setName]=React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [phone,setPhone] = React.useState<string>("");
  const [emailValidate, setEmailValidate] = React.useState<boolean>(false);
  const [phoneValidate, setPhoneValidate] = React.useState<boolean>(false);
  const [nameValidate, setNameValidate] = React.useState<boolean>(false);
  const [titleValidate, setTitleValidate] = React.useState<boolean>(false);
  const [contentValidate, setContentValidate] = React.useState<boolean>(false);
  const [content, setContent] = React.useState<string>("");
  const [isPrivacyAgree,setIsPrivacyAgree]=React.useState<boolean>(false);
  const [isPrivacy, setIsPrivacy] = React.useState<boolean>(false);
  const [isTextLimit, setIsTextLimit] = React.useState<boolean>(false);
  const [uploadFiles, setUploadFiles] = React.useState<File[]>([]);
  const [isApplyModalOpen,setIsApplyModalOpen]=React.useState<boolean>(false);
  const [applyErrorContext,setApplyErrorContext]=React.useState<string>("");
  const [applyErrorTitle,setApplyErrorTitle]=React.useState<string>("");
  const isMobile = useMediaQueryForMobile();

  const onChangeEmail = React.useCallback(
    (e) => {
      setEmail(e.target.value);
      const reg_email =
        /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
      reg_email.test(e.target.value) ? setEmailValidate(false) : setEmailValidate(true);
    },
    [email, emailValidate]
  );

  const onChangePhone = React.useCallback(
    (e)=>{
      if((/[0-9,\-]/.test(e.target.value[e.target.value.length-1])==false) && !(e.target.value.length==0 && phone.length==1)){
        return;
      }

      if((e.target.value[e.target.value.length-1]=="-" && [4,9].indexOf(e.target.value.length)==-1)){
        return;
      }

      if(e.target.value.length==14)return;
      if((e.target.value.length==3 && phone.length==2) || (e.target.value.length==8 && phone.length==7)){
        setPhone(e.target.value+"-");
      }else if((e.target.value.length==4 && phone.length==3) || (e.target.value.length==9 && phone.length==8)){
        if(e.target.value[e.target.value.length-1]!=="-"){
          setPhone(phone+"-"+e.target.value[e.target.value.length-1]);
        }else{
          setPhone(phone+e.target.value[e.target.value.length-1]);
        }
      }else{
        setPhone(e.target.value);
      }
      const reg_phone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
      reg_phone.test(e.target.value) ? setPhoneValidate(false) : setPhoneValidate(true);      
    }
    ,[phone,phoneValidate]
  );

  const onChangeContent = React.useCallback(
    (e) => {
      if(e.target.value.length<=500){
        setContent(e.target.value);
        setContentValidate(false);
      }else{
        setContentValidate(true)
      }
    },
    [content]
  );

  const onChangeTitle = React.useCallback(
      (e)=>{
        if(e.target.value.length<30){
          setTitle(e.target.value);
          setTitleValidate(false);
        }else{
          setTitleValidate(true);
        }
      },
      [title]
  );

  const onChangeName = React.useCallback(
    (e)=>{
      if(e.target.value.length<30){
        setName(e.target.value);
        setNameValidate(false);
      }else{
        setNameValidate(true);
      }
    },
    [name]
);

  const onClickApply = (e: any) => {
    if(!isPrivacyAgree){
      setApplyErrorTitle("개인정보 수집 및 이용을 동의해 주세요");
      setApplyErrorContext("서비스 문의 등록을 위해 약관을 동의해 주세요"); 
      setIsApplyModalOpen(true);
      return;
    }
    if (content.length > 500) {
      setIsTextLimit(true);
    }

    if(title=="" || name=="" || email=="" || content=="" || phone=="" || titleValidate || nameValidate || emailValidate || contentValidate || phoneValidate){
      setApplyErrorContext("모든 항목을 정확히 입력해 주세요"); 
      setIsApplyModalOpen(true);
      return;
    }

    api.sendInquiry(
      {
        title: isDataVoucher ? (`[데이터바우처 ${moment().format("YYYY")}] `+title) : title,
        name,
        email,
        phone,
        content,
        uploadFiles
      }
    )
    .then((res: any)=>{
      setTitle("");
      setPhone("");
      setName("");
      setEmail("");
      setContent("");
      setUploadFiles([]);
      setApplyErrorTitle("문의 접수가 완료되었습니다");
      setApplyErrorContext("확인 후 빠른 시일 내에 이메일로 답변드리겠습니다!");  
    })
    .catch(err=>{
      setApplyErrorContext("오류가 발생했습니다.\n잠시후에 다시 요청 부탁드립니다.");
    })
    .finally(()=>{
      setIsApplyModalOpen(true);
    })
     
  }

    const handlePrivacyAgree=  React.useCallback(
      (e)=>{
        if(isPrivacyAgree==true){
          setIsPrivacyAgree(false);
        }else{
          setIsPrivacyAgree(true);
        }
      },
      [isPrivacyAgree]
    );

  return (
    <>
      <ContactWrapper >
        <ContainerFrame style={isMobile ? {margin: 0, width: "100%"} : {}} >
          <div style={{color: "#1A1A1A", fontSize: isMobile ? 24 : 50, fontWeight: isMobile ? 700 : 800, lineHeight: isMobile ? "30px" : "100%"}} >
            {isDataVoucher ? "데이터바우처 문의하기" : "서비스 문의하기"}
          </div>
          <div style={{height: isMobile ? 16 : 40}} />
          <div style={{color: "#6F6F6F", fontSize: 16, fontWeight: 500, lineHeight: "160%", whiteSpace: "pre-wrap"}} >
            {`문의내용은 업무시간 내에 최대한 답변드려요!\n오류 등으로 저희가 보완할 부분이 있으면 답변이 지연될 수 있어요\n고객센터 운영 시간 - 평일 10:00 ~ 18:00`}
          </div>
         
          <ContentWrapper isMobile={isMobile} >
            
              <div style={{display: "flex",flexDirection: isMobile ? "column" : "row", width: "100%", gap: 30}} >  
                <div style={{display: "flex",flexDirection: isMobile ? "column" : "row", height: isMobile ? 72 : 50, width: isMobile ? "100%" : "50%"}} >
                  <InputLabel text="이름" required={true} isMobile={isMobile} />
                  <InputBox 
                    placeholder={"이름을 입력해 주세요"}
                    error={nameValidate ? true : false}
                    helperText={nameValidate ? "30자 이내로 입력해 주세요" : ""}
                    value={name}
                    onChange={onChangeName}
                    isMobile={isMobile}
                  />
                </div>
                <div style={{display: "flex",flexDirection: isMobile ? "column" : "row", height: isMobile ? 72 : 50, width: isMobile ? "100%" : "50%"}} >
                  <InputLabel text="연락처" required={true} isMobile={isMobile} />
                  <InputBox 
                    placeholder={"010-1234-5678"}
                    error={phoneValidate ? true : false}
                    helperText={phoneValidate ? "연락처 형식이 올바르지 않습니다." : ""}
                    onChange={onChangePhone}
                    value={phone}
                    isMobile={isMobile}
                  />
                </div>
              </div>
            
              <div style={{height: 30}} />

              <div style={{display: "flex",flexDirection: isMobile ? "column" : "row", height: isMobile ? 72 : 50, width: "100%"}} >
                <InputLabel text="이메일" required={true} isMobile={isMobile} />
                <InputBox 
                  value={email}
                  onChange={onChangeEmail}
                  error={emailValidate ? true : false}
                  helperText={emailValidate ? "이메일 형식이 올바르지 않습니다." : ""}
                  placeholder={ isMobile ? "이메일 주소를 입력해 주세요" : "연락받으실 수 있는 이메일 주소를 입력해 주세요"}
                />
              </div>

              <div style={{height: 30}} />

              <div style={{display: "flex",flexDirection: isMobile ? "column" : "row", height: isMobile ? 72 : 50, width: "100%"}} >
                <InputLabel text="제목" required={true} isMobile={isMobile} />
                <InputBox 
                  value={title} 
                  onChange={onChangeTitle}  
                  error={titleValidate ? true : false}
                  helperText={titleValidate ? "30자 이내로 입력해 주세요" : ""}
                  placeholder={"30자 이내로 입력해 주세요"}
                  isMobile={isMobile}
                />
              </div>

              <div style={{height: 30}} />

              <div style={{display: "flex",flexDirection: isMobile ? "column" : "row", height: isMobile ? 224 : 200, width: "100%",alignItems: "flex-start"}} >
                <div style={{height: 50}} >
                  <InputLabel text="내용" required={true} isMobile={isMobile} />
                </div>
                <div style={{width: "100%",height: 200, position: "relative"}}>
                  <InputBox 
                    value={content}
                    placeholder={"내용을 500자 이내로 작성해 주세요"}
                    error={contentValidate ? true : false}
                    helperText={contentValidate ? "500자 이내로 입력해 주세요" : ""}
                    onChange={onChangeContent}
                    multiline={true}
                    height={200}
                    isMobile={isMobile}
                  />
                  <div style={{position: 'absolute', bottom: 12, right: 16, fontSize: 14, fontWeight: 500, lineHeight: "22px"}} >
                    <span style={{color: "#3D3D3D"}} >{content.length}</span><span style={{color: "#A5A5A5"}} > / 500</span>
                  </div>
                </div>
              </div>
            
            {/* <ContactInfoWrapper>
              <OneLineTypography variant={"h23"} style={{ width: 85 }}>
                {"이름"}
              </OneLineTypography>
              <StyledDesTextField
                style={isMobile ? MobileStyledTextInner : StyledTextInner}
                id="contact-subject"
                placeholder={"이름을 입력해 주세요"}
                error={nameValidate ? true : false}
                helperText={nameValidate ? "30자 이내로 입력해 주세요" : ""}
                variant={'outlined' as any}
                value={name}
                inputProps={{ type: 'text'}}
                onChange={onChangeName}
              />
            </ContactInfoWrapper> */}
            {/* <ContactInfoWrapper>
              <OneLineTypography variant={"h23"} style={{ width: 85 }}>
                {"연락처"}
              </OneLineTypography>
              <StyledDesTextField
                style={isMobile ? MobileStyledTextInner : StyledTextInner}
                id="contact-subject"
                placeholder={"010-1234-5678"}
                error={phoneValidate ? true : false}
                helperText={phoneValidate ? "연락처 형식이 올바르지 않습니다." : ""}
                // variant={"standard" as any}
                variant={"outlined" as any}
                onChange={onChangePhone}
                value={phone}
              />
            </ContactInfoWrapper> */}
            {/* <ContactInfoWrapper>
            <OneLineTypography variant={"h23"} style={{ width: 85 }}>
                {"이메일"}
              </OneLineTypography>

              <StyledDesTextField
                value={email}
                style={isMobile ? MobileStyledEmail : StyledEmail}
                onChange={onChangeEmail}
                error={emailValidate ? true : false}
                helperText={emailValidate ? "이메일 형식이 올바르지 않습니다." : ""}
                id="contact-subject"
                placeholder={ isMobile ? "이메일 주소를 입력해 주세요" : "연락받으실 수 있는 이메일 주소를 입력해 주세요"}
                variant={"outlined" as any}
              />
            </ContactInfoWrapper> */}
            {/* <ContactInfoWrapper style={{ height: isMobile ? 202 : 102 }}>
              <Typography variant={"h23"} style={{ width: 85, marginTop: 26 }}>
                {"내용"}
              </Typography>

              <StyledOutlineTextField
                value={content}
                multiline
                rows={5}
                style={isMobile ? MobileStyledContent : StyledContent}
                placeholder={
                  "보다 정확한 답변을 위해 가능한 자세히 말씀해 주세요"
                }
                error={contentValidate ? true : false}
                helperText={contentValidate ? "500자 이내로 입력해 주세요" : ""}
                variant={"outlined" as any}
                onChange={onChangeContent}
              />
            </ContactInfoWrapper> */}
            
            <div style={{height: 30}} />

            <ContactInfoWrapper style={{marginBottom: 30}} >
              <InputLabel 
                text="파일첨부"
                required={false}
                isMobile={isMobile}
              />
              <div
                style={{
                  width: "100%",
                  height: isMobile ? 40 : 64
                }}
              >
                <FileDndComponent
                  uploadFiles={uploadFiles}
                  setUploadFiles={setUploadFiles}
                  isMobile={isMobile}
                />
              </div>
            </ContactInfoWrapper>
            <div style={{marginBottom: 40, width: "100%"}} >
              <ShowFileDndResult uploadFiles={uploadFiles} setUploadFiles={setUploadFiles} isMobile={isMobile} />
            </div>
            <ContactInfoWrapper>
              <InputLabel 
                text=""
                required={false}
              />
              <div
                style={{
                  width: "100%",
                  height: 64,
                  display: "flex",
                  justifyContent: isMobile ? "center" : "space-between",
                  alignItems: isMobile ? "flex-start" : "center",
                  flexDirection: isMobile ? "column" : "row"
                }}
              >
                <div style={{display: "flex", alignItems: "center"}} >
                  <MyCheckbox style={{ marginRight: isMobile ? 2 : 8 }} onChange={handlePrivacyAgree} />
                  <div style={{fontSize: isMobile ? 14 : 18, fontWeight: isMobile ? 600 : 500, lineHeight: isMobile ? "22px" : "28px", color: "#6F6F6F"}} >
                    <span
                      onClick={() => {
                        setIsPrivacy(true);
                      }}
                      style={{textDecoration: "underline", textUnderlineOffset: 2.5, textDecorationThickness: 1.5, cursor: "pointer"}}
                    >
                        개인정보 수집
                    </span>
                    {" 및 이용에 동의합니다."}
                  </div>
                </div>
                <div style={{width: isMobile ? "100%" : null, display: "flex", justifyContent: "center", marginTop: isMobile ? 36 : 0}} >
                  <StyledApplyButton
                    onClick={onClickApply}
                    style={{backgroundColor : !isPrivacyAgree ? "#EFEFEF" : null, width: 160, height: isMobile ? 50 : 52, borderRadius: 8 }}
                  >
                  <div style={{ color: "#FFFFFF", fontSize: 18, fontWeight: 600, lineHeight: "28px" }}>
                    {"문의 등록"}
                  </div>
                </StyledApplyButton>
                </div>
              </div>
            </ContactInfoWrapper>
          </ContentWrapper>
          {isPrivacy && <PrivacyPopup setIsPrivacy={setIsPrivacy} />}
          {isTextLimit && <TextLimitPopup setIsTextLimit={setIsTextLimit} />}
        </ContainerFrame>
      </ContactWrapper>
      <Modal
        open={isApplyModalOpen}
        onClose={()=>{}}
        style={{display: "flex", width: "100vw", justifyContent: "center", alignItems: "center"}}
      >
        <div style={{display: "flex",flexWrap: "wrap", justifyContent: "center", alignItems: "center",textAlign: 'center', width: "360px", borderRadius: "10px", backgroundColor: "#FFFFFF",color: "#6F6F6F", fontSize: 16,fontWeight: 500, lineHeight: "24px",padding: 30}}>
            {
              applyErrorTitle !== "" && <div style={{color: "#1A1A1A", fontSize: 18, fontWeight: 600, lineHeight: "28px",whiteSpace: "pre-wrap", width: 300, overflowWrap: "break-word", marginBottom: 16}} >{applyErrorTitle}</div>
            }
            <div style={{whiteSpace: "pre-wrap", width: 300, overflowWrap: "break-word"}} >{applyErrorContext}</div>
            <div 
              style={{marginTop: 40,display: "flex", justifyContent: "center", alignItems: "center", width: "200px", height: "44px", borderRadius: 8, backgroundColor: "#F5F5F5", color: "#555",fontWeight: 600, fontSize: 15, lineHeight: "22px",cursor: "pointer"}}
              onClick={(e)=>{
                setIsApplyModalOpen(false);
                setApplyErrorTitle("");
              }}
            >
            {"확인"}
          </div>
        </div>

      </Modal>
    </>
  );
};


export const StyledTextField = ({
  label,
  des,
  handler,
  renderValue,
  validate,
}: {
  label: string;
  des: string;
  handler: any;
  renderValue: any;
  validate: any;
}) => {
  const isMobile = useMediaQueryForMobile();
  return (
    <>
      <OneLineTypography variant={"h23"} style={{ minWidth: 85 }}>
        {label}
      </OneLineTypography>
      <StyledDesTextField
        style={(isMobile ? MobileStyledTextInner : StyledTextInner)}
        // style={isMobile ? MobileStyledTextInner : StyledTextInner}
        id="contact-subject"
        placeholder={des}
        error={validate!=="" ? true : false}
        helperText={validate!=="" ? validate : ""}
        value={renderValue}
        onChange={handler}
        variant={"outlined" as any}
      />
    </>
  );
};

export const FileDndComponent: React.FC<FileInfo> = ({
  uploadFiles,
  setUploadFiles,
  isMobile = false
}) => {
  const [isFileModalOpen,setIsFileModalOpen]=React.useState<boolean>(false);
  return (
    <>
      <FileDrop
        onFrameDragEnter={(event) => console.log("onFrameDragEnter", event)}
        onFrameDragLeave={(event) => console.log("onFrameDragLeave", event)}
        onFrameDrop={(event) => console.log("onFrameDrop", event)}
        onDragOver={(event) => console.log("onDragOver", event)}
        onDragLeave={(event) => console.log("onDragLeave", event)}
        onDrop={(files, event) => {
          if (!files) return;
          const newFiles = [...uploadFiles];
          for (let i = 0; i < files.length; i++) {
            const file = files.item(i);
            file && newFiles.push(file);
          }
          if(newFiles.reduce((acc,cur)=>acc+cur.size,0)<=30000000){
            setUploadFiles(newFiles);
          }else{
            setIsFileModalOpen(true);
          }
        }}
      >
        
          {
            isMobile ? <React.Fragment>
            <div style={{color: "#3D3D3D", fontSize: 16, fontWeight: 500, lineHeight: "28px"}} >
              <FileUploader
                handleFile={(file) => {
                  const newFiles = [...uploadFiles];
                  newFiles.push(file);
                  if(newFiles.reduce((acc,cur)=>acc+cur.size,0)<=30000000){
                    setUploadFiles(newFiles);
                  }else{
                    setIsFileModalOpen(true);
                  }
                }}
                style={{padding: 0, display: "flex"}}
              >
                <div style={{padding: "8px 18px",color: "#555", fontSize: 14, fontWeight: 600, lineHeight: "22px",borderRadius: 12, border: "1px solid #EFEFEF", cursor: "pointer", backgroundColor: "#FFF"}} >
                  {"파일 첨부"}
                </div>
                </FileUploader>
            </div>
            </React.Fragment>
            :
            <React.Fragment>
              <FileDownloadContainer >
              <div style={{color: "#3D3D3D", fontSize: 16, fontWeight: 500, lineHeight: "28px"}} >
                <span>파일을 드래그 하거나 </span>
                <FileUploader
                  handleFile={(file) => {
                    const newFiles = [...uploadFiles];
                    newFiles.push(file);
                    if(newFiles.reduce((acc,cur)=>acc+cur.size,0)<=30000000){
                      setUploadFiles(newFiles);
                    }else{
                      setIsFileModalOpen(true);
                    }
                  }}
                  style={{padding: 0}}
                >
                  <span style={{color: "#3D3D3D", fontSize: 16, fontWeight: 500, lineHeight: "28px",textDecoration: "underline", textUnderlineOffset: 2.5, textDecorationThickness: 1.5}} >
                    {"폴더에서 선택"}
                  </span>
                </FileUploader>
                <span>{" 하세요"}</span>
              </div>
              </FileDownloadContainer>
            </React.Fragment>
          }
      </FileDrop>
      <Modal
        open={isFileModalOpen}
        style={{display: "flex", width: "100vw", justifyContent: "center", alignItems: "center"}}
      >
        <div style={{display: "flex",flexWrap: "wrap", justifyContent: "center", alignItems: "center",textAlign: 'center', width: "360px", borderRadius: "10px", backgroundColor: "#FFFFFF",color: "#6F6F6F", fontSize: 16,fontWeight: 500, lineHeight: "24px",padding: 30}}>
            {"업로드 용량이 30MB를 초과할 수 없습니다."}
            <div 
              style={{marginTop: 40,display: "flex", justifyContent: "center", alignItems: "center", width: "200px", height: "44px", borderRadius: 8, backgroundColor: "#F5F5F5", color: "#555",fontWeight: 600, fontSize: 15, lineHeight: "22px",cursor: "pointer"}}
              onClick={(e)=>{setIsFileModalOpen(false);}}
            >
            {"확인"}
          </div>
        </div>
      </Modal>
    </>
  );
};

export const ShowFileDndResult: React.FC<FileResult> = ({ uploadFiles,setUploadFiles, isMobile }: any) => {
  return (
    <FileDownloadWrapper style={{padding: isMobile && 0}} >
      {uploadFiles.map((file: any, idx: number): any => (
        <div style={{ marginRight: isMobile ? 4 : "20px", fontSize: isMobile ? 12 : 16, display: "flex", alignItems: "center"  }} key={idx}>
          {/* <FileDownloadIcon style={{ marginRight: 10, transform: "scale(0.75)" }} /> */}
          {file.name}
          <DeleteIcon onClick={()=>{
            let tmpFiles=[...uploadFiles];
            setUploadFiles(tmpFiles.filter((f,fi)=>fi!==idx));
            }} style={{ marginRight: 10,fontSize: isMobile ? 16 : "20px",marginTop: "4px",fill: "#FF7F00", lineHeight: "16px"}} />
        </div>
      ))}
    </FileDownloadWrapper>
  );
};
export const ContactWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  // height: 1100,
  paddingBottom: 60,
  paddingTop: 180,
  [theme.breakpoints.down("sm")]: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 60,
    // height: 1200,
    paddingTop: 100,
  },
}));

export const ContentWrapper = styled("div")(({ theme, isMobile }) => ({
  marginTop: isMobile ? 45 : 60,
  // width: 1040,
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

export const ContactInfoWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  height: 70,
  marginBottom: 40,
  justifyContent: "center",
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    flexDirection: "column",
  },
}));

export const ContactBottomZone = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  textAlign: "center",
  width: "100%",
  justifyContent: "center",
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: 375,
  },
}));

export const ContactAgreeZone = styled("div")(({ theme }) => ({
  display: "flex",
  marginLeft: 135,
  flexDirection: "row",
  textAlign: "center",
  width: "100%",
  justifyContent: "start",
  [theme.breakpoints.down("sm")]: {
    justifyContent: "start",
    marginLeft: 0,
  },
}));

export const ContactBtnZone = styled("div")(({ theme }) => ({
  justifySelf: "flex-end",
  [theme.breakpoints.down("sm")]: {},
}));

export const StyledApplyButton = styled(Button)(({ theme }) => ({
  width: 120,
  height: 54,
  minHeight: 54,
  textAlign: "center",
  backgroundColor: theme.palette.primary.main,
  // backgroundColor: "#C0C0C0",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  // FF7F00
  "&:hover": {
    // backgroundColor: theme.palette.primary.main,
    backgroundColor: "#C0C0C0",
    // opacity: 0.9,
  },
  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
    width: "100%",
  },
}));

export const StyledTextInner = {
  marginLeft: 20,
  width: "100%",
  fontWeight: 400,
  fontStyle: "normal",
  fontSize: 16,
  lineHeight: 30
};

export const MobileStyledTextInner = {
  fontWeight: 400,
  fontStyle: "normal",
  fontSize: 14,
  lineHeight: 30,
  marginLeft: 0,
  width: "100%",
  // maxWidth: 375,
};

export const StyledEmail = {
  marginLeft: 20,
  width: "100%",
  fontWeight: 400,
  fontStyle: "normal",
  fontSize: 16,
  lineHeight: 30,
};

export const MobileStyledEmail = {
  marginLeft: 0,
  width: "100%",
  fontWeight: 400,
  fontStyle: "normal",
  fontSize: 14,
  lineHeight: 30,
  // maxWidth: 375,
};

export const StyledContent = {
  marginLeft: 20,
  width: "100%",
  borderRadius: "10px",
  lineHeight: 30,
  fontSize: 16,
  fontWeight: 400,
  fontStyle: "normal",
  height: 200,
};

export const MobileStyledContent = {
  marginLeft: 0,
  width: "100%",
  borderRadius: "10px",
  lineHeight: 30,
  fontSize: 14,
  fontWeight: 400,
  fontStyle: "normal",
  // maxWidth: 375,
};

export const FileDownloadWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyItems: "flex-start",
  alignItems: "start",
  paddingLeft: "10%",
  flexWrap: "wrap",
}));

export const FileDownloadContainer = styled("div")(({ theme }) => ({
  // marginLeft: 59,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 64,
  border: "1px dashed #DFDFDF",
  boxSizing: "border-box",
  borderRadius: 4,
  [theme.breakpoints.down("sm")]: {
    border: "1px dashed #EEEEEE",
  },
}));

export const StyledSpan = {
  textDecoration: "underline",
  textUnderlinePosition: "under",
  textDecorationColor: "#A4A4A4",
  cursor: "pointer",
};

export const OneLineTypography = styled(Typography)(({ theme }) => ({
  // whiteSpace: "wrap",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center"
}));

export const StyledDesTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    "&.MuiInput-root": {
      fontSize: 16,
      lineHeight: 30,
      fontStyle: "normal",
      fontWeight: "normal",
    },
  },
}));

export const StyledOutlineTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    "&.MuiOutlinedInput-root": {
      fontSize: 20,
      fontStyle: "normal",
      fontWeight: "normal",
      height: 180,
    },
  },
}));


export const ModalContainer=styled("div")({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  alignItems: "center",
  width: "250px",
  height: "150px",
  borderRadius: "5%",
  // border: "4px solid #FF7F00",
  backgroundColor: "#FFFFFF",
  color: "#000000",
  fontSize: "16px",
  padding: "10px",
  fontWeight: "normal",
  "&:focus":{
    outline: "none"
  }
});

export const ModalInnerButton=styled(Button)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "90px",
  height: "45",
  borderRadius: "4%",
  backgroundColor: "#FFFFFF",
  color: "#FF7F00",
  fontWeight: "normal",
  cursor: "pointer",
  padding: "10px 30px 10px 30px",
  fontSize: "14px",
  "&:hover":{
    backgroundColor: "#FF7F00",
    color: "#FFFFFF",
  }
});

import { useLocation } from 'react-router';
import React from 'react';
import { MenuType, registeredMenuItems } from './const';
import styled from 'styled-components';
import { Button, Typography } from '@mui/material';
import { ReactComponent as SellerLogo } from '../../../assets/images/SellerLogo.svg';
import font from '../../../style/font';
import { colors } from '../../../style';
import { sellkeyUrl } from '../../../constants/urls';

export interface IMenuBarButtonProps {
  isDarkMode: boolean;
  goUrl: (url: string) => () => void;
}
const WebMenuBar: React.FC<IMenuBarButtonProps> = ({ isDarkMode, goUrl }) => {
  const { pathname } = useLocation();

  const handleClickUrl = (item: MenuType) => () => {
    if (item.isRedirection) {
      window.open(item.url);
    } else {
      window.scrollTo({
        top: 0,
      });
      goUrl(item.url)();
    }
  };

  return (
    <Wrapper>
      <StyledMainLogoButton
        onClick={() => {
          goUrl('/services')();
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        }}
      >
        <SellerLogo />
        <Typography
          variant="h7"
          style={{
            marginLeft: 5,
            marginTop: 2,
            color: isDarkMode ? 'white' : '',
          }}
        ></Typography>
      </StyledMainLogoButton>

      <ButtonWrapper>
        {registeredMenuItems.map((item, idx) => (
          <MenuText
            key={idx}
            onClick={handleClickUrl(item)}
            clicked={pathname.indexOf(item.url) !== -1}
            url={item.url}
          >
            {item.label}
          </MenuText>
        ))}
      </ButtonWrapper>
      <StyledPCButton onClick={() => window.open('https://app.sellerbox.io?tab=phone')}>
        PC 연동
      </StyledPCButton>
    </Wrapper>
  );
};

export default WebMenuBar;

const Wrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  paddingTop: 36,
  paddingBottom: 36,
});

export const StyledMainLogoButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  display: 'flex',
  flexDirection: 'row',
}));

const ButtonWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  gap: 36,
  alignItems: 'center',
  marginRight: 100,
});

const MenuText = styled('span')<{ clicked: boolean; url: string }>`
  cursor: pointer;
  color: ${({ clicked, url }) =>
    clicked ? (url === sellkeyUrl ? colors.purple.PU500 : colors.orange.OR500) : colors.gray.Black};
  ${font.Body1S16};
  transition: color 0.3s ease, background 0.3s ease;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 4px;

  &:hover {
    background: ${({ clicked, url }) =>
      clicked
        ? url === sellkeyUrl
          ? colors.purple.PU250
          : colors.orange.OR200
        : colors.gray.GR200};
  }
`;

export const StyledPCButton = styled(Button)(({}) => ({
  ...font.Body3S15,
  paddingRight: 12,
  paddingLeft: 12,
  paddingTop: 8,
  paddingBottom: 8,
  backgroundColor: colors.orange.OR500,
  color: colors.gray.White,
  borderRadius: 8,
  '&: hover': {
    backgroundColor: colors.orange.OR700,
    color: colors.gray.White,
  },
}));

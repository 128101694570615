const fontSize = {
  s28: { fontSize: 28 },
  s24: { fontSize: 24 },
  s20: { fontSize: 20 },
  s18: { fontSize: 18 },
  s16: { fontSize: 16 },
  s15: { fontSize: 15 },
  s14: { fontSize: 14 },
  s13: { fontSize: 13 },
  s12: { fontSize: 12 },
  s10: { fontSize: 10 },
};
const fontFamily = {
  bold: { fontWeight: 700 }, //700
  semibold: { fontWeight: 600 }, //600
  medium: { fontWeight: 500 }, //500
  regular: { fontWeight: 400 }, //400
};

const lineHeight = {
  l36: { lineHeight: '36px' },
  l30: { lineHeight: '30px' },
  l28: { lineHeight: '28px' },
  l26: { lineHeight: '26px' },
  l24: { lineHeight: '24px' },
  l22: { lineHeight: '22px' },
  l20: { lineHeight: '20px' },
  l18: { lineHeight: '18px' },
  l16: { lineHeight: '16px' },
  l10: { lineHeight: '10px' },
};
export default {
  TitleS28: {
    ...fontSize.s28,
    ...fontFamily.semibold,
    ...lineHeight.l36,
  },
  Title1B24: {
    ...fontSize.s24,
    ...fontFamily.bold,
    ...lineHeight.l30,
  },
  Title2B20: {
    ...fontSize.s20,
    ...fontFamily.bold,
    ...lineHeight.l28,
  },
  Title3B18: {
    ...fontSize.s18,
    ...fontFamily.bold,
    ...lineHeight.l22,
  },
  Title3S18: {
    ...fontSize.s18,
    ...fontFamily.semibold,
    ...lineHeight.l26,
  },
  Body1S16: {
    ...fontSize.s16,
    ...fontFamily.semibold,
    ...lineHeight.l24,
  },
  Body2M16: {
    ...fontSize.s16,
    ...fontFamily.medium,
    ...lineHeight.l24,
  },
  Body3S15: {
    ...fontSize.s15,
    ...fontFamily.semibold,
    ...lineHeight.l22,
  },
  Body4B14: {
    ...fontSize.s14,
    ...fontFamily.bold,
    ...lineHeight.l22,
  },
  Body5S14: {
    ...fontSize.s14,
    ...fontFamily.semibold,
    ...lineHeight.l22,
  },
  Body6M14: {
    ...fontSize.s14,
    ...fontFamily.medium,
    ...lineHeight.l22,
  },
  Body7M13: {
    ...fontSize.s13,
    ...fontFamily.medium,
    ...lineHeight.l20,
  },
  Caption1M12: {
    ...fontSize.s12,
    ...fontFamily.medium,
    ...lineHeight.l18,
  },
  Caption2B12: {
    ...fontSize.s12,
    ...fontFamily.bold,
    ...lineHeight.l18,
  },
};

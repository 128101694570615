import React, { useEffect } from "react";
import styled from "styled-components";
import { MobilePageWrapper, PcPageWrapper, WholePageWrapper } from "./SellerboxAppPageV1";
import { useMediaQueryForMobile } from "../hooks/useMediaQueryForMobile";
import { useHistory } from "react-router";
const Logout = () => {
    const history = useHistory();
    useEffect(()=>{
        localStorage.removeItem("userToken");
        history.push("/services");
        window.location.reload();
    },[]);
    return <WholePageWrapper>
    </WholePageWrapper>
}

export default Logout;
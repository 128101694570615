import React, { useEffect } from 'react';
import { WholePageWrapper } from '../SellerboxAppPageV1';
import { useLocation } from 'react-router';

// cafe24 로그인 창으로 바로 이동
const Cafe24RegisterStart = () => {
  const location = useLocation();
  const queryString = new URLSearchParams(location.search);
  const mallId = queryString.get('mall_id') || '';

  useEffect(() => {
    localStorage.setItem('enteredByMarketName', 'cafe24');
    localStorage.setItem('mallId', mallId);
    console.log('mallId', mallId);
    window.open(
      'https://eclogin.cafe24.com/Shop/?menu=recpy&submenu=%2Fapi%2Fv2%2Foauth%2Fauthorize%3Fresponse_type%3Dcode%26client_id%3Dv8cawfS6HqqHTDeXSfJqKO%26state%3Dsellerbox%253D%26redirect_uri%3Dhttps%253A%252F%252Fsellerbox.io%252FsignIn%26scope%3Dmall.read_application%252Cmall.write_application%252Cmall.read_category%252Cmall.read_product%252Cmall.read_collection%252Cmall.read_supply%252Cmall.read_personal%252Cmall.read_order%252Cmall.write_order%252Cmall.read_community%252Cmall.write_community%252Cmall.read_customer%252Cmall.read_store%252Cmall.read_design%252Cmall.read_salesreport%252Cmall.read_shipping%252Cmall.write_shipping',
      '_self'
    );
  }, []);

  return <WholePageWrapper style={{ justifyContent: 'flex-start', boxSizing: 'border-box' }} />;
};

export default Cafe24RegisterStart;

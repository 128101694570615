import React, { useEffect } from 'react';
import { WholePageWrapper } from '../SellerboxAppPageV1';
import { useStoreAction, useStoreValue } from '../../contexts';
import { useHistory } from 'react-router';
import useAlertModal from '../../hooks/useAlertModal';

// 커머스 솔루션 연동 시작 페이지
// 판매자 센터에서 신청하기 누르면 이 페이지로 리이렉션
const NaverRegisterStart = () => {
  // useEffect(() => {
  //   if (naverToken === '') {
  //     //네이버에서 유입된게 아니면 가입을 거절
  //     history.push('/services');
  //     setisModuleMatch(false);
  //     requestAlertModal({
  //       title: '',
  //       description: '커머스 솔루션 마켓을 통해 셀러박스 가입을 진행해 주세요.',
  //       action: () => {
  //         window.open(
  //           'https://solution.smartstore.naver.com/ko/solution/10XxRcUYSy4N7fiZ5BYNMh/detail?accountIds=%5B%22ncp_1oe28g_01%22%5D#open_add-modal',
  //           '_self'
  //         );
  //       },
  //     });
  //   }
  // }, [naverToken]);

  useEffect(() => {
    //커머스 솔루션 가입 페이지로 전환
    //리다이렉션을 위한 페이지 입니다.
    //아래 링크로 리다이렉션이 진행되면 다시 네이버 쪽에서 NaverRegisterToken 스크린으로 리다이렉션 시켜줍니다.

    window.open(
      'https://sell.smartstore.naver.com/#/member/auth?interlockType=solution_auth&returnUrl=https://solution.smartstore.naver.com/ko/oauth/redirect?solutionId=10XxRcUYSy4N7fiZ5BYNMh',
      '_self'
    );
  }, []);

  return (
    <WholePageWrapper
      style={{ justifyContent: 'flex-start', boxSizing: 'border-box' }}
    ></WholePageWrapper>
  );
};

export default NaverRegisterStart;

import { ListItemButton, Collapse, ListItemButtonProps } from "@mui/material";
import * as React from "react";
import { ListItem, styled, Typography } from "@mui/material";
import { format } from "date-fns";

import { ReactComponent as DownArrow } from "../assets/icons/DownArrow.svg";
import { useMediaQueryForMobile } from "../hooks/useMediaQueryForMobile";
import { StyledHoverArrow } from "./NoticeItem";
import { AnySrvRecord } from "dns";

export type FAQItemType = {
  title: string;
  contents: string;
  type: string;
};

export interface IFAQItemProps extends Omit<ListItemButtonProps, "title"> {
  title: string;
  contents: React.ReactNode;
  searchValue: string;
  setOpenTitle: any;
  openTitle: any;
  tabName?: string;
}
export const FAQItem: React.FC<IFAQItemProps> = ({
  title,
  contents,
  style,
  searchValue,
  setOpenTitle,
  openTitle,
  tabName
}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const onClickItem = () => {
    // setOpen(!open);
    if(openTitle==title){
      setOpenTitle("");
    }else{
      setOpenTitle(title);
    }
  };
  const [hover, setHover] = React.useState<boolean>(false);
  const isMobile = useMediaQueryForMobile();

  React.useEffect(()=>{
    if(openTitle==title){
      setOpen(true);
    }else{
      setOpen(false);
    }
  },[openTitle])
  return (
    <div
      onMouseOver={(e) => {
        setHover(true);
      }}
      onMouseLeave={(e) => {
        setHover(false);
      }}
    >
      <StyledListItemButton 
        isMobile={isMobile}
        style={{...style,
            flexDirection: isMobile ? "column" : "row",
            justifyContent: isMobile ? "center" : "flex-start",
            alignItems: isMobile ? "flex-start" : "center",
        }} onClick={onClickItem}
        className={isMobile ? "" : "hoverScale"}
      >
        {
          !isMobile &&
          <div style={{
            color: "#939393",
            fontSize: 14,
            fontWeight: 700,
            lineHeight: "22px",
            marginRight: 28
          }} >{tabName}</div>
        }
        <div style={{display: "flex"}} >
          <div style={{
            fontSize: isMobile ? 16 : 20,
            fontWeight: 600,
            color: "#A5A5A5",
            marginRight: isMobile ? 6 : 14
          }} >Q</div>
          <TitleWrapperDiv>
            <QuestionWrapper>
              <div style={{
                fontSize: isMobile ? 16 : 20,
                fontWeight: 600,
                lineHeight: isMobile ? "24px" : "28px",
                color: "#3D3D3D"
              }} >
                  {title}
              </div>   
              {/* {searchValue != "" && title.indexOf(searchValue)!==-1 ? (
                <>
                  <Typography variant="h22">{title.split(searchValue)[0]}<Typography variant={isMobile ? "h16" : "h20" } color={"#FF7F00"}>{searchValue}</Typography>{title.split(searchValue)[1]}</Typography>
                </>
              ) : (
                <Typography variant="h22">{title}</Typography>
              )} */}
            </QuestionWrapper>
          </TitleWrapperDiv>
        </div>
        {
          isMobile && <React.Fragment>
            <div style={{
              color: "#939393",
              fontSize: 12,
              fontWeight: 500,
              lineHeight: "18px",
              marginLeft: 18
            }} >{tabName}</div>
          </React.Fragment>
        }
      </StyledListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <DescriptionWrapperDiv >
          <div style={{
            fontSize: isMobile ? 14 : 18,
            fontWeight: isMobile ? 500 : 600,
            color: "#6F6F6F",
            lineHeight: isMobile ? "22px" : "160%"
          }} >{contents}</div>
        </DescriptionWrapperDiv>
      </Collapse>
    </div>
  );
};
const QuestionWrapper = styled("div")(({ theme }: any) => ({
  display: "flex",
  flexDirection: "row",
}));

const StyledListItemButton = styled(ListItemButton)(({ theme,isMobile }: any) => ({
  display: "flex",
  alignItems: "center",
  padding: isMobile ? "12px 0px" : "24px 0px"
}));

export const QTypography = styled(Typography)(({ theme }: any) => ({
  color: theme.palette.primary.main,
  marginRight: 42,
  [theme.breakpoints.down("sm")]: {
    marginRight: 20,
  },
}));

const TitleWrapperDiv = styled("div")(({ theme }: any) => ({
  flex: 1,
}));

const StyledDownArrow = styled(DownArrow)<{ open: boolean }>(
  ({ theme, open }: any) => ({
    transform: open ? "rotate(180deg)" : "rotate(0deg)",
    transition: "transform 200ms ease-in-out",
  })
);
const DescriptionWrapperDiv = styled("div")(({ theme }: any) => ({
  padding: 20,
  backgroundColor: "#FBFBFB",
  [theme.breakpoints.down("sm")]: {
    padding: 10,
    marginBottom: 12
  },
}));

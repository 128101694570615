import { useStoreAction, useStoreValue } from '../contexts';

interface requestAlterModalType {
  title?: string;
  description?: string;
  action?: () => void;
  onClose?: () => void;
  isAlertModalOpen?: boolean;
}

const useAlertModal = () => {
  const { alertInfo } = useStoreValue();
  const { setAlertInfo } = useStoreAction();

  const requestAlertModal = (data: requestAlterModalType = {}) => {
    setAlertInfo({
      ...alertInfo,
      isAlertModalOpen: true,
      ...data,
    });
  };
  return requestAlertModal;
};
export default useAlertModal;

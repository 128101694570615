import {
  ContactUrl,
  faqUrl,
  noticeUrl,
  priceUrl,
  sellkeyUrl,
  serviceUrl,
} from '../../../constants/urls';

export type MenuType = {
  url: string;
  label: string;
  isRedirection?: boolean;
};

export const registeredMenuItems: MenuType[] = [
  {
    url: serviceUrl,
    label: '셀러박스',
    isRedirection: false,
  },
  {
    url: sellkeyUrl,
    label: '셀러키워드',
    isRedirection: false,
  },
  {
    url: priceUrl,
    label: '이용요금',
    isRedirection: false,
  },
  {
    url: 'https://blog.sellerbox.io/',
    label: '블로그',
    isRedirection: true,
  },
  {
    url: faqUrl,
    label: '고객지원',
    isRedirection: false,
  },
  {
    url: 'https://cplat.io/',
    label: '회사소개',
    isRedirection: true,
  },
];

export const SubMenuItems: MenuType[] = [
  {
    url: noticeUrl,
    label: '공지사항',
    isRedirection: false,
  },

  {
    url: faqUrl,
    label: '자주하는 질문',
    isRedirection: false,
  },
  {
    url: ContactUrl,
    label: '문의하기',
    isRedirection: false,
  },
];

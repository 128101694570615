import * as React from "react";
const useWindowSize = () => {
    const [dimention,setDimention]=React.useState({width: window.innerWidth, hieght: window.innerHeight});
    
    const resetDimention = () => {
        setDimention({width: window.innerWidth, hieght: window.innerHeight});
    };
    
    React.useEffect(()=>{
        window.addEventListener("resize",resetDimention);
        // return window.removeEventListener("resize",resetDimention);
    },[]);
    return dimention;
}   
export default useWindowSize;
import * as React from "react";
import { styled, Typography, Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { PrivacyPopup } from "../popup/PrivacyPopup";
import { FileDrop } from "react-file-drop";
import FileUploader from "../widgets/FileUploaderButton";
import DeleteIcon from '@mui/icons-material/Delete';
import { ReactComponent as FileDownloadIcon } from "../assets/icons/FileDownload.svg";
import { TextLimitPopup } from "../popup/TextLimitPopup";
import { useMediaQueryForMobile } from "../hooks/useMediaQueryForMobile";
import { MyCheckbox } from "../widgets/MyCheckbox";
import { ContainerFrame } from "../App";
import { handleBreakpoints } from "@mui/system";
import Buttom from "@mui/material/Button";

import axios from "axios";
import { Context } from "react-responsive";
import * as api from "../controller/api";
import { useLayoutSize } from "../hooks/useLayoutSize";
import useWindowSize from "../hooks/useWindowSize";

import pcDataVoucherImage from "../assets/images/pc-dataVoucherImage.png";
import mobileDataVoucherImage from "../assets/images/mobile-dataVoucherImage.png";
import { ContactPage } from "./ContactPage";


export interface IContactPage {}
export interface FileInfo {
  uploadFiles: File[];
  setUploadFiles: React.Dispatch<React.SetStateAction<File[]>>;
}

export interface FileResult {
  uploadFiles: File[];
  setUploadFiles: any;
}




//   display: flex;
//   flex-wrap: wrap;
//   justify-content: center;
//   align-items: center;
//   width: 250px;
//   height: 150px;
//   border-radius: 10px;
//   border: 4px solid #FF7F00;
//   background-color: #FFFFFF;
//   color: #FF7F00;
//   font-weight: 550;

// `;

export const DataVoucher: React.FC<IContactPage> = () => {
  const dimention = useWindowSize();
  
  const [title,setTitle]=React.useState<string>("");
  const [name,setName]=React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [phone,setPhone] = React.useState<string>("");
  const [emailValidate, setEmailValidate] = React.useState<boolean>(false);
  const [phoneValidate, setPhoneValidate] = React.useState<boolean>(false);
  const [nameValidate, setNameValidate] = React.useState<boolean>(false);
  const [titleValidate, setTitleValidate] = React.useState<boolean>(false);
  const [contentValidate, setContentValidate] = React.useState<boolean>(false);
  const [content, setContent] = React.useState<string>("");
  const [isPrivacyAgree,setIsPrivacyAgree]=React.useState<boolean>(false);
  const [isPrivacy, setIsPrivacy] = React.useState<boolean>(false);
  const [isTextLimit, setIsTextLimit] = React.useState<boolean>(false);
  const [uploadFiles, setUploadFiles] = React.useState<File[]>([]);
  const [isApplyModalOpen,setIsApplyModalOpen]=React.useState<boolean>(false);
  const [applyErrorContext,setApplyErrorContext]=React.useState<string>("");
  const isMobile = useMediaQueryForMobile();

  

  React.useEffect(()=>{console.log(dimention)},[dimention])
  const onChangeEmail = React.useCallback(
    (e) => {
      setEmail(e.target.value);
      const reg_email =
        /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
      reg_email.test(e.target.value) ? setEmailValidate(false) : setEmailValidate(true);
    },
    [email, emailValidate]
  );

  const onChangePhone = React.useCallback(
    (e)=>{
      if((/[0-9,\-]/.test(e.target.value[e.target.value.length-1])==false) && !(e.target.value.length==0 && phone.length==1)){
        return;
      }

      if((e.target.value[e.target.value.length-1]=="-" && [4,9].indexOf(e.target.value.length)==-1)){
        return;
      }

      if(e.target.value.length==14)return;
      if((e.target.value.length==3 && phone.length==2) || (e.target.value.length==8 && phone.length==7)){
        setPhone(e.target.value+"-");
      }else if((e.target.value.length==4 && phone.length==3) || (e.target.value.length==9 && phone.length==8)){
        if(e.target.value[e.target.value.length-1]!=="-"){
          setPhone(phone+"-"+e.target.value[e.target.value.length-1]);
        }else{
          setPhone(phone+e.target.value[e.target.value.length-1]);
        }
      }else{
        setPhone(e.target.value);
      }
      const reg_phone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
      reg_phone.test(e.target.value) ? setPhoneValidate(false) : setPhoneValidate(true);      
    }
    ,[phone,phoneValidate]
  );

  const onChangeContent = React.useCallback(
    (e) => {
      if(e.target.value.length<=500){
        setContent(e.target.value);
        setContentValidate(false);
      }else{
        setContentValidate(true)
      }
    },
    [content]
  );

  const onChangeTitle = React.useCallback(
      (e)=>{
        if(e.target.value.length<30){
          setTitle(e.target.value);
          setTitleValidate(false);
        }else{
          setTitleValidate(true);
        }
      },
      [title]
  );

  const onChangeName = React.useCallback(
    (e)=>{
      if(e.target.value.length<30){
        setName(e.target.value);
        setNameValidate(false);
      }else{
        setNameValidate(true);
      }
    },
    [name]
);

  const onClickApply = (e: any) => {
    if(!isPrivacyAgree){
      setApplyErrorContext("약관 동의 시 활성화됩니다."); 
      setIsApplyModalOpen(true);
      return;
    }
    if (content.length > 500) {
      setIsTextLimit(true);
    }

    if(title=="" || name=="" || email=="" || content=="" || titleValidate || nameValidate || emailValidate || contentValidate){
      setApplyErrorContext("모든 항목을 정확히 입력해 주세요."); 
      setIsApplyModalOpen(true);
      return;
    }

    api.sendInquiry(
      {
        title: ("[데이터바우처 2023] "+title),
        name,
        email,
        phone,
        content,
        uploadFiles
      }
    )
    .then((res: any)=>{
      setTitle("");
      setPhone("");
      setName("");
      setEmail("");
      setContent("");
      setUploadFiles([]);
      setApplyErrorContext("문의사항 접수가 완료되었습니다.");  
    })
    .catch(err=>{
      setApplyErrorContext("오류가 발생했습니다. 잠시후에 다시 요청 부탁드립니다.");
    })
    .finally(()=>{
      setIsApplyModalOpen(true);
    })
     
  }

    const handlePrivacyAgree=  React.useCallback(
      (e)=>{
        if(isPrivacyAgree==true){
          setIsPrivacyAgree(false);
        }else{
          setIsPrivacyAgree(true);
        }
      },
      [isPrivacyAgree]
    );

    const downloadFile = (url) => {
      fetch(url, { method: 'GET' })
          .then((res) => {
              return res.blob();
          })
          .then((blob) => {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = "서클플랫폼_사용자가이드(2023).pdf";
              document.body.appendChild(a);
              a.click();
              // setTimeout((_) => {
              //     window.URL.revokeObjectURL(url);
              // }, 60000);
              // a.remove();
          })
          .catch((err) => {
              console.error('err: ', err);
          });
        };

    const handleFileDownLoad = () => {
      
      downloadFile(`https://${window.location.href.indexOf("www")!==-1 ? "www." : "" }sellerbox.io/data2023.pdf`)
    }
    
  return (
    <>
      {
      isMobile
      ?
      <div style={{display: 'flex', justifyContent: "center",paddingBottom: 30,paddingTop: 30,position: 'relative'}} >
        <img src={mobileDataVoucherImage} style={{width: "100%"}} />
        <div style={{width: "83.3333%", height: "2.202%", borderRadius: 20, position: "absolute", left: "8.33333%", top: "20.1%"}} className="pointer" onClick={()=>{window.open("https://kdata.or.kr/datavoucher/bg/dvi/ptDvInfoDetail.do")}}></div>
          <div style={{borderRight: 10,width: "20.833333%", height: "1.72308%", borderRadius: 4,position: "absolute", right: "13.888888%", bottom: "4.66%"}} className="pointer" onClick={handleFileDownLoad}></div> 
          {/* 4.65 */}
          <div style={{borderRight: 10,width: "20.833333%", height: "1.72308%", borderRadius: 4,position: "absolute", right: "13.888888%", bottom: "10.30581%"}} className="pointer" onClick={handleFileDownLoad}></div>
      </div>                                                                                                                                                                      
      :
      <div style={{display: 'flex', justifyContent: "center", width: "100%"}} >
        <div style={{display: 'flex', justifyContent: "center", width:  1440,marginTop: dimention.width>1440 ? 70+60 : 0+60,marginBottom: 60,position: 'relative'}} >
          <img src={pcDataVoucherImage} style={{width: 1440}} />
          <div style={{width: 174, height: 38, borderRadius: 20, position: "absolute", left: 180, top: 306}} className="pointer" onClick={()=>{window.open("https://kdata.or.kr/datavoucher/bg/dvi/ptDvInfoDetail.do")}}></div>
          <div style={{width: 100, height: 30, borderRadius: 4, position: "absolute", right: 310, bottom: 175}} className="pointer" onClick={handleFileDownLoad}></div>
          <div style={{width: 100, height: 30, borderRadius: 4, position: "absolute", right: 310, bottom: 103}} className="pointer" onClick={handleFileDownLoad}></div>
        </div>
      </div>
      }
      <ContactPage isDataVoucher={true} />
    </>
  );
};


export const StyledTextField = ({
  label,
  des,
  handler,
  renderValue,
  validate,
}: {
  label: string;
  des: string;
  handler: any;
  renderValue: any;
  validate: any;
}) => {
  const isMobile = useMediaQueryForMobile();
  return (
    <>
      <OneLineTypography variant={"h23"} style={{ minWidth: 85 }}>
        {label}
      </OneLineTypography>
      <StyledDesTextField
        style={(isMobile ? MobileStyledTextInner : StyledTextInner)}
        // style={isMobile ? MobileStyledTextInner : StyledTextInner}
        id="contact-subject"
        placeholder={des}
        error={validate!=="" ? true : false}
        helperText={validate!=="" ? validate : ""}
        value={renderValue}
        onChange={handler}
        variant={"outlined" as any}
      />
    </>
  );
};

export const FileDndComponent: React.FC<FileInfo> = ({
  uploadFiles,
  setUploadFiles,
}) => {
  const [isFileModalOpen,setIsFileModalOpen]=React.useState<boolean>(false);
  return (
    <>
      <FileDrop
        onFrameDragEnter={(event) => console.log("onFrameDragEnter", event)}
        onFrameDragLeave={(event) => console.log("onFrameDragLeave", event)}
        onFrameDrop={(event) => console.log("onFrameDrop", event)}
        onDragOver={(event) => console.log("onDragOver", event)}
        onDragLeave={(event) => console.log("onDragLeave", event)}
        onDrop={(files, event) => {
          if (!files) return;
          const newFiles = [...uploadFiles];
          for (let i = 0; i < files.length; i++) {
            const file = files.item(i);
            file && newFiles.push(file);
          }
          if(newFiles.reduce((acc,cur)=>acc+cur.size,0)<=30000000){
            setUploadFiles(newFiles);
          }else{
            setIsFileModalOpen(true);
          }
        }}
      >
        <FileDownloadContainer>
          <Typography variant={"h23"}>{"파일을 드래그 하거나 "}</Typography>
          <FileUploader
            handleFile={(file) => {
              alert(JSON.stringify(file));
              const newFiles = [...uploadFiles];
              newFiles.push(file);
              if(newFiles.reduce((acc,cur)=>acc+cur.size,0)<=30000000){
                setUploadFiles(newFiles);
              }else{
                setIsFileModalOpen(true);
              }
            }}
          >
            <Typography
              variant={"h23"}
              color={"#FF7F00"}
              style={{ textDecoration: "underline" }}
            >
              {"폴더에서 선택"}
            </Typography>
          </FileUploader>
          <Typography variant={"h23"}>{"하세요."}</Typography>
        </FileDownloadContainer>
      </FileDrop>
      <Modal
        open={isFileModalOpen}
        style={{display: "flex", width: "100vw", justifyContent: "center", alignItems: "center"}}
      >
        <div style={{display: "flex",flexWrap: "wrap", justifyContent: "center", alignItems: "center", width: "250px", height: "150px", borderRadius: "10px",border: "4px solid #FF7F00", backgroundColor: "#FFFFFF",color: "#FF7F00",fontWeight: 550}}>
          {"업로드 용량이 30MB를 초과할 수 없습니다."}
          <div 
            style={{display: "flex", justifyContent: "center", alignItems: "center", width: "90px", height: "45", borderRadius: "10px", backgroundColor: "#FF7F00", color: "#FFFFFF",fontWeight: 500,cursor: "pointer"}}
            onClick={(e)=>{setIsFileModalOpen(false);}}
          >
          {"확인"}
        </div>
        </div>
      </Modal>
    </>
  );
};

export const ShowFileDndResult: React.FC<FileResult> = ({ uploadFiles,setUploadFiles }: any) => {
  return (
    <FileDownloadWrapper>
      {uploadFiles.map((file: any, idx: number): any => (
        <div style={{ marginRight: "10%" }} key={idx}>
          <FileDownloadIcon style={{ marginRight: 10 }} />
          {file.name}
          <DeleteIcon onClick={()=>{
            let tmpFiles=[...uploadFiles];
            setUploadFiles(tmpFiles.filter((f,fi)=>fi!==idx));
            }} style={{ marginRight: 10,fontSize: "20px",marginTop: "4px",fill: "#FF7F00"}} />
        </div>
      ))}
    </FileDownloadWrapper>
  );
};
export const ContactWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  // height: 1100,
  paddingBottom: 60,
  paddingTop: 180,
  [theme.breakpoints.down("sm")]: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 60,
    // height: 1200,
    paddingTop: 100,
  },
}));

export const ContentWrapper = styled("div")(({ theme }) => ({
  marginTop: 60,
  // width: 1040,
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

export const ContactInfoWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  height: 70,
  marginBottom: 40,
  justifyContent: "center",
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    flexDirection: "column",
  },
}));

export const ContactBottomZone = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  textAlign: "center",
  width: "100%",
  justifyContent: "center",
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: 375,
  },
}));

export const ContactAgreeZone = styled("div")(({ theme }) => ({
  display: "flex",
  marginLeft: 135,
  flexDirection: "row",
  textAlign: "center",
  width: "100%",
  justifyContent: "start",
  [theme.breakpoints.down("sm")]: {
    justifyContent: "start",
    marginLeft: 0,
  },
}));

export const ContactBtnZone = styled("div")(({ theme }) => ({
  justifySelf: "flex-end",
  [theme.breakpoints.down("sm")]: {},
}));

export const StyledApplyButton = styled(Button)(({ theme }) => ({
  width: 120,
  height: 54,
  minHeight: 54,
  textAlign: "center",
  backgroundColor: theme.palette.primary.main,
  // backgroundColor: "#C0C0C0",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  // FF7F00
  "&:hover": {
    // backgroundColor: theme.palette.primary.main,
    backgroundColor: "#C0C0C0",
    // opacity: 0.9,
  },
  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
    width: "100%",
  },
}));

export const StyledTextInner = {
  marginLeft: 20,
  width: "100%",
  fontWeight: 400,
  fontStyle: "normal",
  fontSize: 16,
  lineHeight: 30
};

export const MobileStyledTextInner = {
  fontWeight: 400,
  fontStyle: "normal",
  fontSize: 14,
  lineHeight: 30,
  marginLeft: 0,
  width: "100%",
  // maxWidth: 375,
};

export const StyledEmail = {
  marginLeft: 20,
  width: "100%",
  fontWeight: 400,
  fontStyle: "normal",
  fontSize: 16,
  lineHeight: 30,
};

export const MobileStyledEmail = {
  marginLeft: 0,
  width: "100%",
  fontWeight: 400,
  fontStyle: "normal",
  fontSize: 14,
  lineHeight: 30,
  // maxWidth: 375,
};

export const StyledContent = {
  marginLeft: 20,
  width: "100%",
  borderRadius: "10px",
  lineHeight: 30,
  fontSize: 16,
  fontWeight: 400,
  fontStyle: "normal",
  height: 200,
};

export const MobileStyledContent = {
  marginLeft: 0,
  width: "100%",
  borderRadius: "10px",
  lineHeight: 30,
  fontSize: 14,
  fontWeight: 400,
  fontStyle: "normal",
  // maxWidth: 375,
};

export const FileDownloadWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyItems: "space-between",
  alignItems: "start",
  paddingLeft: "10%",
  flexWrap: "wrap",
}));

export const FileDownloadContainer = styled("div")(({ theme }) => ({
  // marginLeft: 59,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 80,
  border: "1px dashed #E5E5E5",
  boxSizing: "border-box",
  borderRadius: 10,
  [theme.breakpoints.down("sm")]: {
    border: "1px dashed #EEEEEE",
  },
}));

export const StyledSpan = {
  textDecoration: "underline",
  textUnderlinePosition: "under",
  textDecorationColor: "#A4A4A4",
  cursor: "pointer",
};

export const OneLineTypography = styled(Typography)(({ theme }) => ({
  // whiteSpace: "wrap",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center"
}));

export const StyledDesTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    "&.MuiInput-root": {
      fontSize: 16,
      lineHeight: 30,
      fontStyle: "normal",
      fontWeight: "normal",
    },
  },
}));

export const StyledOutlineTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    "&.MuiOutlinedInput-root": {
      fontSize: 20,
      fontStyle: "normal",
      fontWeight: "normal",
      height: 180,
    },
  },
}));


export const ModalContainer=styled("div")({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  alignItems: "center",
  width: "250px",
  height: "150px",
  borderRadius: "5%",
  // border: "4px solid #FF7F00",
  backgroundColor: "#FFFFFF",
  color: "#000000",
  fontSize: "16px",
  padding: "10px",
  fontWeight: "normal",
  "&:focus":{
    outline: "none"
  }
});

export const ModalInnerButton=styled(Button)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "90px",
  height: "45",
  borderRadius: "4%",
  backgroundColor: "#FFFFFF",
  color: "#FF7F00",
  fontWeight: "normal",
  cursor: "pointer",
  padding: "10px 30px 10px 30px",
  fontSize: "14px",
  "&:hover":{
    backgroundColor: "#FF7F00",
    color: "#FFFFFF",
  }
});
import { List, styled, Typography } from '@mui/material';
import { parse } from 'date-fns';
import * as React from 'react';
import { NoticeItem } from '../components/NoticeItem';
import { NoticeData } from '../data/NoticeData';
import { useMediaQueryForMobile } from '../hooks/useMediaQueryForMobile';
import { MyPagination } from '../widgets/MyPagination';
import { ContainerFrame } from '../App';
import * as api from '../controller/api';
import { Context } from 'react-responsive';
export interface INoticePageProps {}

export const NoticePage: React.FC<INoticePageProps> = ({}) => {
  const isMobile = useMediaQueryForMobile();
  const listStyle = isMobile ? { width: '100%' } : { width: '100%', marginTop: 85 };
  const TypoStyle = isMobile ? { paddingLeft: '3%' } : { paddingLeft: '1%' };

  const pageSize = 10;
  const [pageNum, setPageNum] = React.useState<number>(1);
  const [openIndex, setOpenIndex] = React.useState<number>(-1);
  const [renderData, setRenderData] = React.useState<any>([]);
  const [noticeData, setNoticeData] = React.useState<any>([]);
  const [maxPage, setMaxPage] = React.useState<number>(10);

  React.useEffect(() => {
    api
      .getNotice()
      .then((res) => {
        console.log(res.data.data[0].NoticeList);
        setNoticeData(
          res.data.data[0].NoticeList.map((d: any) => {
            return {
              title: d.Item1,
              contents: d.Item2,
              date: d.Item3,
            };
          })
        );
      })
      .catch((err) => {
        // window.location.reload();
      });
  }, []);

  React.useEffect(() => {
    if (noticeData.length > 0) {
      setRenderData(
        noticeData.filter(
          (nothing: any, idx: number) => idx >= (pageNum - 1) * pageSize && idx < pageNum * pageSize
        )
      );
    }
  }, [pageNum, noticeData]);

  React.useEffect(() => {
    setMaxPage(
      Math.floor(noticeData.length / pageSize) + (noticeData.length % pageSize === 0 ? 0 : 1)
    );
  }, [noticeData]);

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [pageNum]);

  return (
    <NoticeWrapper style={{ overflowX: 'hidden' }} isMobile={isMobile}>
      <ContainerFrame style={{}}>
        <div
          style={{
            color: '#1A1A1A',
            fontSize: isMobile ? 24 : 50,
            fontWeight: isMobile ? 700 : 800,
            lineHeight: '100%',
            marginBottom: isMobile ? 28 : 68,
          }}
        >
          공지사항
        </div>
        <List style={listStyle}>
          {noticeData.map((notice: any, idx: number) => (
            <NoticeItem
              openIndex={openIndex}
              setOpenIndex={setOpenIndex}
              index={idx}
              key={idx}
              title={notice.title}
              date={notice.date}
              contents={notice.contents}
            />
          ))}
        </List>
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          {/* <MyPagination
            style={{ marginTop: isMobile ? 73 : 55 }}
            count={maxPage}
            defaultPage={pageNum}
            page={pageNum}
            onChange={(event, page) => {
              setPageNum(page);
            }}
          /> */}
        </div>
      </ContainerFrame>
    </NoticeWrapper>
  );
};

const NoticeWrapper = styled('div')(({ theme, isMobile }) => ({
  height: 1100,
  // height: "100vh",
  paddingTop: isMobile ? 60 : 150,
  paddingLeft: 200,
  paddingRight: 200,
  [theme.breakpoints.down('sm')]: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

import React, {useState,useEffect} from "react";
import { TextField } from "@mui/material";
import styled from "styled-components";




export const Card = () => {

    const CardTextField = styled(TextField)`
        & label.Mui-focused {
            color: "#191919";
        }
        & label {
            color: gray;
        }
    `

    const [CplatToken,setCplatToken] = useState<string>(window.location.href.split("?CplatToken=")[1]);
    const [isRegister,setIsRegister] = useState<boolean>(false);

    useEffect(()=>{
        console.log(window.location.href)
        if(CplatToken===undefined){
            window.parent.postMessage({
                child: "CardRegister",
                message: "Bad Request",
                code: 400
            },"*");
        }
    },[])
    
    return(
        <div style={{width: "100%", border: "2px solid green",display: "flex", alignItems: "center", justifyContent: "center"}}>
            <div style={{width: 500, height: 500,border: "1px solid green",padding: 30}}>
                {
                    isRegister===true ? 
                        <>
                            <div>카드 등록</div>
                                <div>
                                    <CardTextField
                                        placeholder="-없이"
                                        type="number"
                                        size="small"
                                        label="카드번호"
                                        variant="standard"
                                        color="secondary"
                                        inputProps={{ 
                                            style: {
                                                color: "#191919"
                                            }
                                        }}
                                    />
                                </div>
                                <div>
                                    <CardTextField
                                        placeholder="yyMM"
                                        type="number"
                                        size="small"
                                        label="유효기간"
                                        variant="standard"
                                        color="secondary"
                                        inputProps={{ 
                                            style: {
                                                color: "#191919"
                                            }
                                        }}
                                    />
                                </div>
                                <div>
                                    <CardTextField
                                        placeholder="앞 2자리"
                                        type="number"
                                        size="small"
                                        label="비밀번호"
                                        variant="standard"
                                        color="secondary"
                                        inputProps={{ 
                                            style: {
                                                color: "#191919"
                                            }
                                        }}
                                    />
                                </div>
                                <div>
                                    <CardTextField
                                        placeholder="yyMMdd"
                                        type="number"
                                        size="small"
                                        label="생년월일"
                                        variant="standard"
                                        color="secondary"
                                        inputProps={{ 
                                            style: {
                                                color: "#191919"
                                            }
                                        }}
                                    />
                                </div>
                                <div>
                                    <CardTextField
                                        placeholder="사업자 번호 10자리"
                                        type="number"
                                        size="small"
                                        label="사업자번호"
                                        variant="standard"
                                        color="secondary"
                                        inputProps={{ 
                                            style: {
                                                color: "#191919"
                                            }
                                        }}
                                    />
                                </div>
                                <input type="button" value="등록" style={{border: "1px solid green", backgroundColor: "red", color: "green"}} />
                        </>
                    :
                        <>
                            <div>카드 리스트</div>
                            <input type="button" onClick={()=>{setIsRegister(true)}} />
                        </>
                }
                
            </div>
        </div>
    )
}
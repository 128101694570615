import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../../../style';
import { GUIDE_DATA } from '../const';
import StepBlackRight from '../../../../assets/images/V2.1/StepBlackRight.png';

const MobileGuideContainer = () => {
  const [activedTab, setActivedTab] = useState<number>(0);
  const [indicatorPosition, setIndicatorPosition] = useState<number>(0);
  const [indicatorWidth, setIndicatorWidth] = useState<number>(0);
  const titleRefs = useRef<(HTMLDivElement | null)[]>([]);
  const tabContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (titleRefs.current[activedTab] && tabContainerRef.current) {
      const titleRect = titleRefs.current[activedTab]?.getBoundingClientRect();
      const containerRect = tabContainerRef.current?.getBoundingClientRect();
      const scrollLeft = tabContainerRef.current?.scrollLeft || 0; // 스크롤 위치 계산

      if (titleRect && containerRect) {
        const offset = titleRect.left - containerRect.left + scrollLeft; // 스크롤 위치를 반영한 좌표 계산
        setIndicatorPosition(offset);
        setIndicatorWidth(titleRect.width); // 탭의 너비로 인디케이터 너비 설정
      }
    }
  }, [activedTab]);
  return (
    <Container>
      <Title>이렇게 활용하세요</Title>
      <TabContainer ref={tabContainerRef}>
        <Indicator leftPosition={indicatorPosition} width={indicatorWidth} />
        {GUIDE_DATA.map((item, index) => (
          <Tab
            key={index}
            isActived={GUIDE_DATA[activedTab].tab === item.tab}
            onClick={() => setActivedTab(index)}
            ref={(el) => (titleRefs.current[index] = el)}
          >
            {item.tab}
          </Tab>
        ))}
      </TabContainer>
      <DescContainer>
        {GUIDE_DATA[activedTab].content.map((item, index) => (
          <Desc>
            <StepContainer>
              <img src={StepBlackRight} width={56} height={28} />
              <StepText>STEP {index + 1}</StepText>
            </StepContainer>
            <DescTitle>{item.title}</DescTitle>
            <DescText>{item.text}</DescText>
            <img
              src={item.image}
              //width={320} height={180}
              style={{
                width: '100%',
              }}
            />
          </Desc>
        ))}
      </DescContainer>
    </Container>
  );
};

export default MobileGuideContainer;

export const Container = styled.div({
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 60,
  paddingBottom: 60,
});

export const Title = styled.div({
  fontSize: 24,
  fontWeight: 800,
  lineHeight: '31.2px',
  color: colors.gray.GR900,
  marginBottom: 24,
});

export const TabContainer = styled.div({
  display: 'flex',
  gap: '28px',
  overflowX: 'auto',
  whiteSpace: 'nowrap',
  paddingBottom: 8,
  borderBottom: '1px solid',
  borderBottomColor: '#F0EDFF',
  position: 'relative',
  marginBottom: 30,
  touchAction: 'pan-x',
});

export const Tab = styled.div<{
  isActived: boolean;
}>(
  {
    fontSize: 14,
    lineHeight: '22px',
    whiteSpace: 'nowrap',
    width: 'max-content',
    touchAction: 'pan-x',
  },
  ({ isActived }) => ({
    fontWeight: isActived ? 600 : 500,
    color: isActived ? colors.gray.GR900 : colors.gray.GR750,
  })
);

export const Indicator = styled.div<{ leftPosition: number; width: number }>(
  ({ leftPosition, width }) => ({
    position: 'absolute',
    top: 28,
    width: `${width}px`, // Use dynamic width
    height: '2px',
    backgroundColor: colors.gray.GR900,
    transition: 'left 0.3s ease, width 0.3s ease', // Smooth transition for both position and width
    left: `${leftPosition}px`,
  })
);
const DescContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 40,
});
const Desc = styled.div({});
const StepContainer = styled.div({
  position: 'relative',
  marginBottom: 16,
});
export const StepText = styled.div({
  position: 'absolute',
  top: 5,
  left: 5,
  fontSize: 12,
  fontWeight: 800,
  color: colors.gray.White,
  lineHeight: '19.2px',
});
const DescTitle = styled.div({
  fontSize: 16,
  fontWeight: 700,
  lineHeight: '25.6px',
  color: colors.gray.GR800,
  marginBottom: 8,
});
const DescText = styled.div({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '22.4px',
  color: colors.gray.GR600,
  marginBottom: 20,
  whiteSpace: 'pre-wrap',
});

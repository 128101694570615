import SellerboxMain01 from '../../../assets/images/V2.1/SellerboxMain01.png';
import SellerboxMain02 from '../../../assets/images/V2.1/SellerboxMain02.png';
import SellerboxMain03 from '../../../assets/images/V2.1/SellerboxMain03.png';
import SellerboxFunction01 from '../../../assets/images/V2.1/SellerboxFunction01.png';
import SellerboxFunction02 from '../../../assets/images/V2.1/SellerboxFunction02.png';
import SellerboxFunction03 from '../../../assets/images/V2.1/SellerboxFunction03.png';
import SellerboxFunction04 from '../../../assets/images/V2.1/SellerboxFunction04.png';
import SellerboxFunction05 from '../../../assets/images/V2.1/SellerboxFunction05.png';
import SellkeyMain01 from '../../../assets/images/V2.1/SellkeyMain01.png';
import SellkeyMain02 from '../../../assets/images/V2.1/SellkeyMain02.png';
import SellkeyFunction01 from '../../../assets/images/V2.1/SellkeyFunction01.png';
import SellkeyFunction02 from '../../../assets/images/V2.1/SellkeyFunction02.png';
import SellkeyFunction03 from '../../../assets/images/V2.1/SellkeyFunction03.png';
import MobileSellkeyFunction01 from '../../../assets/images/V2.1/MobileSellkeyFunction01.png';
import MobileSellkeyFunction02 from '../../../assets/images/V2.1/MobileSellkeyFunction02.png';
import MobileSellkeyFunction03 from '../../../assets/images/V2.1/MobileSellkeyFunction03.png';
import MobileSellerboxFunction01 from '../../../assets/images/V2.1/MobileSellerboxFunction01.png';
import MobileSellerboxFunction02 from '../../../assets/images/V2.1/MobileSellerboxFunction02.png';
import MobileSellerboxFunction03 from '../../../assets/images/V2.1/MobileSellerboxFunction03.png';
import MobileSellerboxFunction04 from '../../../assets/images/V2.1/MobileSellerboxFunction04.png';
import MobileSellerboxFunction05 from '../../../assets/images/V2.1/MobileSellerboxFunction05.png';

import { selectedType } from './Web/MainContainer';
import SellerboxFuncIconParty from '../../../assets/images/V2.1/SellerboxFuncIconParty.png';
import SellerboxFuncIconFace from '../../../assets/images/V2.1/SellerboxFuncIconFace.png';
import SellerboxFuncIconClock from '../../../assets/images/V2.1/SellerboxFuncIconClock.png';
import SellerboxFuncIconPrize from '../../../assets/images/V2.1/SellerboxFuncIconPrize.png';
import SellerboxFuncIconRobot from '../../../assets/images/V2.1/SellerboxFuncIconRobot.png';
import SellerboxFuncIconReport from '../../../assets/images/V2.1/SellerboxFuncIconReport.png';
import SellerboxGuideBlog from '../../../assets/images/V2.1/SellerboxGuideBlog.png';
import SellerboxGuideStore from '../../../assets/images/V2.1/SellerboxGuideStore.png';
import SellerboxGuidePage from '../../../assets/images/V2.1/SellerboxGuidePage.png';
import SellerboxGuideOrder from '../../../assets/images/V2.1/SellerboxGuideOrder.png';
import SellerboxGuideShip from '../../../assets/images/V2.1/SellerboxGuideShip.png';
import SellerboxGuideKeyword from '../../../assets/images/V2.1/SellerboxGuideKeyword.png';
import SellerboxGuideRank from '../../../assets/images/V2.1/SellerboxGuideRank.png';
import SellerboxGuideProduct from '../../../assets/images/V2.1/SellerboxGuideProduct.png';

interface MainDataType {
  text: Array<{
    main: string[];
    sub: string[];
  }>;
  image: string[];
}

interface FunctionDataType {
  title: string[];
  icon: string[];
  text: string[];
  image: string[];
  mobileImage: string[];
}

interface GuideDataType {
  tab: string;
  content: Array<{
    title: string;
    text: string;
    image: string;
  }>;
}

export const MAIN_DATA: Record<selectedType, MainDataType> = {
  Sellerbox: {
    text: [
      {
        main: ['여러 마켓 로그인없이', '모바일에서 한 손으로', '주문 알림에서 발송 처리까지'],
        sub: [
          '신규상점, 위탁판매, 구매대행 셀러에게 꼭 필요한 앱!',
          '한 건의 소중한 주문도 놓치지 않아요.',
        ],
      },
      {
        main: [
          '사용이 어렵고 화면도 복잡한',
          '기존 서비스는 잊어요',
          '누구나 바로 사용하는 셀러박스',
        ],
        sub: [
          '쇼핑몰관리서비스는 어렵고 비싸다?',
          '사용하기 쉽고 직관적인 셀러박스를 이용해보세요.',
        ],
      },
      {
        main: ['주문이 적더라도', '판매 관리도구는 필요하죠', '필요한 기능만 모아 둔 셀러박스'],
        sub: ['셀러님 상점에 주문이 많아져서', '더 이상 셀러박스가 필요없을 때까지 함께 할께요.'],
      },
    ],
    image: [SellerboxMain01, SellerboxMain02, SellerboxMain03],
  },
  Sellkey: {
    text: [
      {
        main: ['신규 상점 셀러도', '키워드 분석은 전문가처럼'],
        sub: [
          '키워드 분석 서비스는 많지만',
          '예비 셀러와 신규 셀러를 위해 셀러키워드를 만들었어요.',
        ],
      },
      {
        main: ['네이버쇼핑 상위랭커 조건', '셀러키워드에서 확인하세요'],
        sub: [
          '씨앗상점, 새싹상점도 상위 노출이 가능할까요?',
          '상위 노출할 수 있는 키워드를 찾아보세요.',
        ],
      },
    ],
    image: [SellkeyMain01, SellkeyMain02],
  },
};

export const FUNCTION_DATA: Record<selectedType, FunctionDataType> = {
  Sellerbox: {
    title: [
      '판매가 되면 주문 알림을 보내요',
      '오전 9시 오늘 입금액을 알려줘요',
      '위탁판매 주문은 바로 처리해요',
      '네이버 노출순위를 모니터링해요',
      'AI로 상품명을 만들어요',
    ],
    icon: [
      SellerboxFuncIconParty,
      SellerboxFuncIconFace,
      SellerboxFuncIconClock,
      SellerboxFuncIconPrize,
      SellerboxFuncIconRobot,
    ],
    text: [
      '혹시 주문을 놓칠까 계속 판매자페이지를 접속하셨나요?\n소중한 주문, 셀러박스가 가장 먼저 알려드려요',
      '마켓마다 다른 정산주기, 입금액 계산하기 힘드셨죠?\n셀러박스가 똑똑하게 계산해드려요.',
      '위탁 판매는 빠른 주문 처리가 필수죠!\n언제 어디서나 셀러박스 하세요',
      '내 상품이 몇 위에 노출되는지 직접 확인하셨나요?\n셀러박스가 매일 매일 체크하고 알려드려요.',
      '같은 상품명으로 가격 경쟁하지 마세요.\n남다르게 표현하고 소비자의 관심을 끄세요.',
    ],
    image: [
      SellerboxFunction01,
      SellerboxFunction02,
      SellerboxFunction03,
      SellerboxFunction04,
      SellerboxFunction05,
    ],
    mobileImage: [
      MobileSellerboxFunction01,
      MobileSellerboxFunction02,
      MobileSellerboxFunction03,
      MobileSellerboxFunction04,
      MobileSellerboxFunction05,
    ],
  },
  Sellkey: {
    title: [
      '키워드를 알기 쉽게 분석해요',
      '상위 노출 상품을 분석해요',
      '네이버 노출 순위를 모니터링해요',
    ],
    icon: [SellerboxFuncIconFace, SellerboxFuncIconReport, SellerboxFuncIconReport],
    text: [
      '어려운 용어는 모두 없애고 카드 형태로 보여줘요.\n신규 상점 눈높이에서 분석해요.',
      '상위 랭커가 되기 위한 조건이 있을까요?\n셀러키워드에서 확인하세요.',
      '내 상품이 몇 위에 노출되는지 직접 확인하셨나요?\n셀러키워드가 매일 매일 체크하고 알려드려요.',
    ],
    image: [SellkeyFunction01, SellkeyFunction02, SellkeyFunction03],
    mobileImage: [MobileSellkeyFunction01, MobileSellkeyFunction02, MobileSellkeyFunction03],
  },
};

export const GUIDE_DATA: Array<GuideDataType> = [
  {
    tab: '셀러를 준비할 때',
    content: [
      {
        title: '셀러박스 블로그를 확인해보세요',
        text: '사업자등록증 준비, 통신판매업 신고 등\n필요한 핵심 정보만 간추렸어요',
        image: SellerboxGuideBlog,
      },
      {
        title: '상점을 개설하고 셀러박스와 연결하세요',
        text: '여러 마켓의 주문과 판매 관리를\n한 번에 관리하세요',
        image: SellerboxGuideStore,
      },
      {
        title: '상품명과 상품페이지를 만들어보세요',
        text: '소비자가 검색할만한 상품명,\n돋보이는 상품페이지를 만들어요',
        image: SellerboxGuidePage,
      },
    ],
  },
  {
    tab: '위탁 판매 중일 때',
    content: [
      {
        title: '상품명과 상품페이지를 만들어보세요',
        text: '소비자가 검색할만한 상품명,\n돋보이는 상품페이지를 만들어요',
        image: SellerboxGuidePage,
      },
      {
        title: '주문을 바로 처리해요',
        text: '주문이 들어오면 바로 접수하고\n도매몰에 발주를 넣어요',
        image: SellerboxGuideOrder,
      },
      {
        title: '송장번호를 입력해요',
        text: '도매몰에서 생성된 송장번호를 복사해\n셀러박스에서 발송처리를 마무리하세요',
        image: SellerboxGuideShip,
      },
    ],
  },
  {
    tab: '노출 순위를 관리할 때',
    content: [
      {
        title: '추적할 상품을 등록하세요',
        text: '스마트스토어에 판매 중인 상품과\n소비자 검색어를 입력하세요',
        image: SellerboxGuideProduct,
      },
      {
        title: '매일 변동 순위를 확인해요',
        text: '매일 오전 11시에\n상품의 순위 변동을 확인해요',
        image: SellerboxGuideRank,
      },
      {
        title: '키워드를 추가해보세요',
        text: '소비자가 찾아볼만한\n다른 키워드를 추가하고 추적하세요',
        image: SellerboxGuideKeyword,
      },
    ],
  },
];

export const reviewList = [
  {
    title: '신성이야님 후기',
    description: `여러 앱 써봤지만 단연 최곱니다. 디자인 인터페이스 깔끔하고. 속도도 정말빨라요.\n유료의 퀄리티를가진 무료앱이네요`,
  },
  {
    title: '애ㅖ싷ㄱㄷㄱㄷ그님',
    description: `한눈에 볼 수 있어서 너무 편하네요 어서 주문건이 많아서 더 이용해 보고싶어요!`,
  },
  {
    title: 'Jspman님',
    description: `쇼핑몰을 위한 정말 필요한 앱이네요.\n가려운 부분을 시원하게 긁어주는 앱입니다.`,
  },
  {
    title: 'Bomin Seungho님',
    description: `기존의 쇼핑몰 앱보다 훨씬 편리하고 이용하기 쉽게 잘 되어 있는 것 같습니다 ~~~!!!!!!\n다만 쇼핑몰 연동이 더 많이 되어 쓸 수있다면 더할 나위 없겠지만 그래도 현재 상태에서도 충분히 원하는 기능을\n직관적으로 쓸 수 있는 것 같습니다. 앞으로도 더욱 나아지기를 바래 봅니다`,
  },
  {
    title: '이연수님',
    description: `업데이트이후 더 깔해지고 정리된듯하여 편합니다. 지속적으로 관리해주시는 관리자님 덕분인듯합니다.\n앞으로도 더 많은 데이터 부탁드립니다.`,
  },
  {
    title: '이수희님',
    description: `사용자의 입장에서 고민한 흔적이 많이 느껴집니다. 개선의견도 빠르게 반영되서 만족하며 쓰고있습니다.\n번창하세요^^`,
  },
];

import React, { useState, useRef, useEffect } from 'react';
import { selectedType } from './MainContainer';
import { FUNCTION_DATA } from '../const';
import { imageVariants } from './MainContainerStyle';
import {
  Container,
  MainText,
  SubText,
  ContentContainer,
  TitleContainer,
  Indicator,
  Title,
  DescContainer,
  Desc,
  DescText,
} from './FunctionContainerStyle';

interface FunctionContainerProps {
  selected: selectedType;
}

const FunctionContainer = ({ selected }: FunctionContainerProps) => {
  const [activedTab, setActivedTab] = useState<number>(0);
  const [indicatorPosition, setIndicatorPosition] = useState<number>(0);
  const titleRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    if (titleRefs.current[activedTab]) {
      const titleRect = titleRefs.current[activedTab]?.getBoundingClientRect();
      const containerRect = titleRefs.current[0]?.parentElement?.getBoundingClientRect();
      if (titleRect && containerRect) {
        const offset = titleRect.top - containerRect.top;
        setIndicatorPosition(offset);
      }
    }
  }, [activedTab]);

  return (
    <Container>
      {selected === 'Sellerbox' && (
        <>
          <MainText>셀러박스가 제공하는 기능</MainText>
          <SubText>판매를 더욱 쉽게 만들어드릴게요</SubText>
        </>
      )}
      {selected === 'Sellkey' && (
        <>
          <MainText>셀러키워드가 제공하는 기능</MainText>
          <SubText>판매의 시작을 더욱 쉽게 만들어드릴게요</SubText>
        </>
      )}

      <ContentContainer>
        <TitleContainer type={selected}>
          <Indicator topPosition={indicatorPosition} type={selected} />
          {FUNCTION_DATA[selected].title.map((item, index) => (
            <Title
              key={item}
              isActived={FUNCTION_DATA[selected].title[activedTab] === item}
              onClick={() => setActivedTab(index)}
              ref={(el) => (titleRefs.current[index] = el)} // ref 저장
              type={selected}
            >
              {item}
            </Title>
          ))}
        </TitleContainer>
        <DescContainer>
          <Desc
            key={activedTab}
            variants={imageVariants}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            <img src={FUNCTION_DATA[selected].icon[activedTab]} width={50} height={50} />
            <DescText>{FUNCTION_DATA[selected].text[activedTab]}</DescText>
            <img src={FUNCTION_DATA[selected].image[activedTab]} width={957} height={294} />
          </Desc>
        </DescContainer>
      </ContentContainer>
    </Container>
  );
};

export default FunctionContainer;

import { styled, TextField, Typography, Modal } from "@mui/material";
import React, { Fragment, useRef } from "react";
import { ReactComponent as PartnerShipDownArrow } from "../assets/icons/PartnerShipDownArrow.svg";
import { ReactComponent as PartnerShipUpArrow } from "../assets/icons/PartnerShipUpArrow.svg";
import { useMediaQueryForMobile } from "../hooks/useMediaQueryForMobile";
import { PrivacyPopup } from "../popup/PrivacyPopup";
import { TextLimitPopup } from "../popup/TextLimitPopup";
import { MyCheckbox } from "../widgets/MyCheckbox";
import { ModalInnerButton, ModalContainer } from "./ContactPage";
import axios from "axios";
import {
  ContactWrapper,
  StyledDesTextField,
  StyledOutlineTextField,
} from "./ContactPage";
import {
  ContactAgreeZone,
  ContactBottomZone,
  ContactBtnZone,
  ContactInfoWrapper,
  ContentWrapper,
  FileDndComponent,
  MobileStyledContent,
  MobileStyledEmail,
  ShowFileDndResult,
  StyledApplyButton,
  StyledContent,
  StyledEmail,
  StyledSpan,
  StyledTextField,
  OneLineTypography,
} from "./ContactPage";
import { ContainerFrame } from "../App";
import { sendPartnerShip } from "../controller/api";

export interface IPartherShipProps {}
export const PartherShipPage: React.FC<IPartherShipProps> = ({}) => {
  const [partTitle, setPartTitle] = React.useState<string>("");
  const [partTitleValidate, setPartTitleValidate] = React.useState<string>("");
  const [partContent, setPartContent] = React.useState<string>("");
  const [partContentValidate, setPartContentValidate] =
    React.useState<string>("");
  const [partCompany, setPartCompany] = React.useState<string>("");
  const [partCompanyValidate, setPartCompanyValidate] =
    React.useState<string>("");
  const [partName, setPartName] = React.useState<string>("");
  const [partNameValidate, setPartNameValidate] = React.useState<string>("");
  const [partPhone, setPartPhone] = React.useState<string>("");
  const [partPhoneValidate, setPartPhoneValidate] = React.useState<string>("");
  const [partEmail, setPartEmail] = React.useState<string>("");
  const [partEmailValidate, setPartEmailValidate] = React.useState<string>("");
  const [partSite, setPartSite] = React.useState<string>("");
  const [partSiteValidate, setPartSiteValidate] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [validate, setValidate] = React.useState<boolean>(false);
  const [content, setContent] = React.useState<string>("");
  const [isPrivacy, setIsPrivacy] = React.useState<boolean>(false);
  const [isTextLimit, setIsTextLimit] = React.useState<boolean>(false);
  const [uploadFiles, setUploadFiles] = React.useState<File[]>([]);
  const [isPartPrivacyAgree, setIsPartPrivacyAgree] =
    React.useState<boolean>(false);
  const [isApplyModalOpen, setIsApplyModalOpen] =
    React.useState<boolean>(false);
  const [applyErrorContext, setApplyErrorContext] = React.useState<string>("");

  const handlePartTitle = (e: any) => {
    if (e.target.value.length <= 30) {
      setPartTitle(e.target.value);
      setPartTitleValidate("");
    } else {
      setPartTitleValidate("30자 이내로 입력해 주세요.");
    }
  };
  const handlePartContent = (e: any) => {
    if (e.target.value.length <= 1000) {
      setPartContent(e.target.value);
      setPartContentValidate("");
    } else {
      setPartContentValidate("1000자 이내로 입력해 주세요..");
    }
  };
  const handlePartCompany = (e: any) => {
    if (e.target.value.length <= 30) {
      setPartCompany(e.target.value);
      setPartCompanyValidate("");
    } else {
      setPartCompanyValidate("30자 이내로 입력해 주세요.");
    }
  };
  const handlePartName = (e: any) => {
    if (e.target.value.length <= 30) {
      setPartName(e.target.value);
      setPartNameValidate("");
    } else {
      setPartNameValidate("30자 이내로 입력해 주세요.");
    }
  };
  const handlePartPhone = (e: any) => {
    if (
      /[0-9,\-]/.test(e.target.value[e.target.value.length - 1]) == false &&
      !(e.target.value.length == 0 && partPhone.length == 1)
    ) {
      return;
    }

    if (
      e.target.value[e.target.value.length - 1] == "-" &&
      [4, 9].indexOf(e.target.value.length) == -1
    ) {
      return;
    }

    if (e.target.value.length == 14) return;
    if (
      (e.target.value.length == 3 && partPhone.length == 2) ||
      (e.target.value.length == 8 && partPhone.length == 7)
    ) {
      setPartPhone(e.target.value + "-");
    } else if (
      (e.target.value.length == 4 && partPhone.length == 3) ||
      (e.target.value.length == 9 && partPhone.length == 8)
    ) {
      if (e.target.value[e.target.value.length - 1] !== "-") {
        setPartPhone(
          partPhone + "-" + e.target.value[e.target.value.length - 1]
        );
      } else {
        setPartPhone(partPhone + e.target.value[e.target.value.length - 1]);
      }
    } else {
      setPartPhone(e.target.value);
    }
    const reg_phone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
    reg_phone.test(e.target.value)
      ? setPartPhoneValidate("")
      : setPartPhoneValidate("연락처 형식이 올바르지 않습니다.");
  };
  const handlePartEmail = (e: any) => {
    setPartEmail(e.target.value);
    const reg_email =
      /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
    reg_email.test(e.target.value)
      ? setPartEmailValidate("")
      : setPartEmailValidate("이메일 형식이 올바르지 않습니다.");
  };
  const handlePartSite = (e: any) => {
    if (e.target.value.length <= 50) {
      setPartSite(e.target.value);
      setPartSiteValidate("");
    } else {
      setPartSiteValidate("50자 이내로 입력해 주세요.");
    }
  };

  const handlePartPrivacyAgree = (e: any) => {
    if (isPartPrivacyAgree == true) {
      setIsPartPrivacyAgree(false);
    } else {
      setIsPartPrivacyAgree(true);
    }
  };

  const scrollToRef = (ref: any) =>
    window.scrollTo({ top: 40, left: 0, behavior: "smooth" });

  const scrollFromRef = (ref: any) =>
    window.scrollTo({
      top: ref.current.offsetTop + 180,
      left: 0,
      behavior: "smooth",
    });
  const PartnerShipTop = useRef(null);
  const PartnerShipDown = useRef(null);
  const PageScrollUp = () => window.scrollTo(0, 40);
  const PageScrollDown = () => {
    window.scrollTo(0, 850);
  };
  const isMobile = useMediaQueryForMobile();

  const onChangeEmail = React.useCallback(
    (e) => {
      setEmail(e.target.value);
      const reg_email =
        /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
      reg_email.test(email) ? setValidate(false) : setValidate(true);
    },
    [email, validate]
  );

  const onChangeContent = React.useCallback(
    (e) => {
      setContent(e.target.value);
    },
    [content]
  );

  const onClickApply = (e: any) => {
    if (!isPartPrivacyAgree) {
      setApplyErrorContext("약관 동의 시 활성화됩니다.");
      setIsApplyModalOpen(true);
      return;
    }

    if (
      partTitle == "" ||
      partName == "" ||
      partEmail == "" ||
      partContent == "" ||
      partCompany == "" ||
      partPhone == "" ||
      partSite == "" ||
      partTitleValidate !== "" ||
      partNameValidate !== "" ||
      partEmailValidate !== "" ||
      partContentValidate !== "" ||
      partCompanyValidate !== "" ||
      partPhoneValidate !== "" ||
      partSiteValidate !== ""
    ) {
      setApplyErrorContext("모든 항목을 정확히 입력해 주세요.");
      setIsApplyModalOpen(true);
      return;
    }
    sendPartnerShip({
      title: partTitle,
      content: partContent,
      uploadFiles,
      company: partCompany,
      name: partName,
      phone: partPhone,
      email: partEmail,
      site: partSite,
    })
      .then((res) => {
        setPartTitle("");
        setPartContent("");
        setPartCompany("");
        setPartName("");
        setPartPhone("");
        setPartEmail("");
        setPartSite("");
        setUploadFiles([]);
        setApplyErrorContext("문의사항 접수가 완료되었습니다.");
      })
      .catch((err) => {
        setApplyErrorContext(
          "오류가 발생했습니다. 잠시후에 다시 요청 부탁드립니다."
        );
      })
      .finally(() => {
        setIsApplyModalOpen(true);
      });
  };

  return (
    <>
      <ContactWrapper
        ref={PartnerShipTop}
        style={{
          height: isMobile ? 600 : 700,
        }}
      >
        <ContainerFrame>
          <ContentWrapper style={{ marginTop: 0 }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                margin: isMobile ? "0px 0px 30px 0px" : "0px 0px 60px 0px",
              }}
            >
              <Typography variant={"h21"}>{"제휴 문의"}</Typography>
            </div>
            {/* <ContactInfoWrapper>
              <StyledTextField
                handler={handlePartTitle}
                renderValue={partTitle}
                validate={partTitleValidate}
                label={"제목"}
                des={"30 자 이내로 입력하세요."}
              /> */}

            <ContactInfoWrapper style={{ height: isMobile ? "auto" : 70 }}>
              <ContactInfoWrapper style={{ marginBottom: 0 }}>
                <StyledTextField
                  handler={handlePartTitle}
                  renderValue={partTitle}
                  validate={partTitleValidate}
                  label={"제목"}
                  des={"30자 이내로 입력해 주세요."}
                />
              </ContactInfoWrapper>
            </ContactInfoWrapper>
            <ContactInfoWrapper style={{ height: isMobile ? 202 : 102 }}>
              <Typography
                variant={"h23"}
                style={{ minWidth: 85, paddingTop: 26 }}
              >
                {"문의내용"}
              </Typography>

              <StyledOutlineTextField
                multiline
                rows={isMobile ? 5.2 : 5.2}
                value={partContent}
                style={isMobile ? MobileStyledContent : StyledContent}
                placeholder={"제휴하고자 하는 사업 내용을 입력해 주세요."}
                error={partContentValidate !== "" ? true : false}
                helperText={
                  partContentValidate !== "" ? partContentValidate : ""
                }
                variant={"outlined" as any}
                onChange={handlePartContent}
              />
            </ContactInfoWrapper>
            <ContactInfoWrapper
              style={{
                flexDirection: "row",
                justifyContent: "flex-end",
                marginTop: isMobile ? -15 : 95,
                marginBottom: isMobile ? 0 : 0,
                height: isMobile ? 50 : 40,
              }}
            >
              <Typography
                variant={"body3"}
                style={{
                  color: "#A4A4A4",
                  marginRight: 20,
                }}
              >
                <span style={{ color: "#FF7F00" }}>{partContent.length}</span>
                /1000
              </Typography>
            </ContactInfoWrapper>
            <ContactInfoWrapper>
              <Typography
                variant={"h23"}
                style={{ minWidth: 85, marginTop: 26 }}
              >
                {"첨부파일"}
              </Typography>
              <div
                style={{
                  width: "100%",
                  marginLeft: isMobile ? 0 : 20,
                }}
              >
                <FileDndComponent
                  uploadFiles={uploadFiles}
                  setUploadFiles={setUploadFiles}
                />
              </div>
            </ContactInfoWrapper>
            <ContactInfoWrapper style={{ marginBottom: 0 }}>
              <ShowFileDndResult
                uploadFiles={uploadFiles}
                setUploadFiles={setUploadFiles}
              />
            </ContactInfoWrapper>
          </ContentWrapper>

          {isPrivacy && <PrivacyPopup setIsPrivacy={setIsPrivacy} />}
          {isTextLimit && <TextLimitPopup setIsTextLimit={setIsTextLimit} />}
        </ContainerFrame>
      </ContactWrapper>

      <ContactWrapper
        ref={PartnerShipDown}
        style={{ height: isMobile ? 900 : 1100, paddingTop: isMobile ? 0 : "" }}
      >
        <ContainerFrame>
          <ContactInfoWrapper>
            {!isMobile && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <StyledButton onClick={PageScrollDown}>
                  <Typography variant={"body17"}>
                    {"업체정보 입력하기"}
                    <PartnerShipDownArrow style={{ marginLeft: 10 }} />
                  </Typography>
                </StyledButton>
                <StyledButton onClick={PageScrollUp}>
                  <Typography variant={"body17"}>
                    {"제휴문의 수정하기"}
                    <PartnerShipUpArrow style={{ marginLeft: 10 }} />
                  </Typography>
                </StyledButton>
              </div>
            )}
          </ContactInfoWrapper>

          <ContentWrapper>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                margin: isMobile ? "60px 0px 30px 0px" : "30px 0px 60px 0px",
              }}
            >
              <Typography variant={"h21"}>{"업체 정보"}</Typography>
            </div>
            <ContactInfoWrapper>
              <StyledTextField
                handler={handlePartCompany}
                renderValue={partCompany}
                validate={partCompanyValidate}
                label={"회사(기관)명"}
                des={"회사(기관)명을 입력해 주세요."}
              />
            </ContactInfoWrapper>
            <ContactInfoWrapper>
              <StyledTextField
                handler={handlePartName}
                renderValue={partName}
                validate={partNameValidate}
                label={"이름"}
                des={"이름을 입력해 주세요."}
              />
            </ContactInfoWrapper>
            <ContactInfoWrapper>
              <StyledTextField
                handler={handlePartPhone}
                renderValue={partPhone}
                validate={partPhoneValidate}
                label={"연락처"}
                des={"010-1234-5678"}
              />
            </ContactInfoWrapper>
            <ContactInfoWrapper>
              <Typography
                variant={"h23"}
                style={{ minWidth: 85, display: "flex", alignItems: "center" }}
              >
                {"이메일"}
              </Typography>
              <StyledDesTextField
                value={partEmail}
                style={isMobile ? MobileStyledEmail : StyledEmail}
                onChange={handlePartEmail}
                error={partEmailValidate !== "" ? true : false}
                helperText={partEmailValidate !== "" ? partEmailValidate : ""}
                id="contact-subject"
                placeholder={
                  isMobile
                    ? "이메일 주소를 입력해 주세요."
                    : "연락받으실 수 있는 이메일 주소를 입력해 주세요."
                }
                variant={"outlined" as any}
              />
            </ContactInfoWrapper>
            <ContactInfoWrapper>
              <StyledTextField
                handler={handlePartSite}
                renderValue={partSite}
                validate={partSiteValidate}
                label={"홈페이지"}
                des={"회사의 홈페이지 주소를 입력해 주세요."}
              />
            </ContactInfoWrapper>

            <ContactBottomZone>
              <ContactAgreeZone>
                <MyCheckbox
                  onChange={handlePartPrivacyAgree}
                  style={{ marginRight: 15 }}
                />
                <Typography
                  variant={"body3"}
                  style={
                    isMobile
                      ? { color: "#A4A4A4", marginTop: 5 }
                      : { color: "#A4A4A4", marginTop: 10 }
                  }
                >
                  <span
                    onClick={() => {
                      setIsPrivacy(true);
                    }}
                    style={StyledSpan}
                  >
                    개인정보 수집 및 이용
                  </span>
                  {"에 동의합니다."}
                </Typography>
              </ContactAgreeZone>
              <ContactBtnZone>
                <StyledApplyButton
                  onClick={onClickApply}
                  style={
                    !isPartPrivacyAgree
                      ? {
                          backgroundColor: "#C0C0C0",
                          marginTop: isMobile ? 28 : 0,
                          marginBottom: isMobile ? 20 : 0,
                        }
                      : {
                          marginTop: isMobile ? 28 : 0,
                          marginBottom: isMobile ? 20 : 0,
                        }
                  }
                >
                  <Typography variant={"h7"} style={{ color: "white" }}>
                    {"제안하기"}
                  </Typography>
                </StyledApplyButton>
              </ContactBtnZone>
            </ContactBottomZone>
          </ContentWrapper>
        </ContainerFrame>
      </ContactWrapper>
      <Modal
        open={isApplyModalOpen}
        onClose={() => {}}
        style={{
          display: "flex",
          width: "100vw",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ModalContainer>
          {applyErrorContext}
          <ModalInnerButton
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "90px",
              height: "45",
              borderRadius: "10px",
              backgroundColor: "#FF7F00",
              color: "#FFFFFF",
              fontWeight: 500,
              cursor: "pointer",
            }}
            onClick={(e) => {
              setIsApplyModalOpen(false);
            }}
          >
            {"확인"}
          </ModalInnerButton>
        </ModalContainer>
      </Modal>
    </>
  );
};

export const StyledButton = styled("div")(({ theme }) => ({
  width: 158,
  height: 54,
  minHeight: 54,
  justifyItems: "center",
  alignItems: "center",
  cursor: "pointer",
}));

const PathnerWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  height: 1400,
  width: "100vw",
  paddingTop: 180,
  [theme.breakpoints.down("sm")]: {
    paddingLeft: 0,
    paddingRight: 0,
    width: 375,
    height: 600,
    paddingTop: 100,
  },
}));

const PathnerBottomWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  height: 1400,
  width: "100vw",
  paddingTop: 150,
  [theme.breakpoints.down("sm")]: {
    paddingLeft: 0,
    paddingRight: 0,
    width: 375,
    height: 800,
    paddingTop: 0,
  },
}));

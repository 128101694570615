import React from 'react';
import { WholePageWrapper } from '../SellerboxAppPageV1';
import useWindowSize from '../../hooks/useWindowSize';
import { Button } from '@mui/material';
import font from '../../style/font';
import { colors } from '../../style';
import { useHistory } from 'react-router';
import { marketFinishUrl } from '../../constants/urls';

const SmartStoreGuidePage = () => {
  const dimensions = useWindowSize();
  const userName = new URLSearchParams(window.location.search).get('userName');
  const history = useHistory();

  const handleFinish = () => {
    //모든 가이드를 확인하고 App Download를 유도하는 페이지로 전환 (naverSolutionStart 페이지가 최종적으로 마지막 scene)
    history.push(marketFinishUrl);
    window.scrollTo(0, 0);
  };

  return (
    <WholePageWrapper style={{ justifyContent: 'flex-start', boxSizing: 'border-box' }}>
      <div
        style={{
          backgroundColor: colors.gray.GR100,
          width: dimensions.width,
          height: dimensions.hieght,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            backgroundColor: '#FFFFFF',
            boxSizing: 'border-box',
            padding: '0px 20px',
            paddingTop: '70px',
            maxWidth: '600px',
            height: '100%',
            width: '100%',
          }}
        >
          <div
            style={{
              ...font.Title2B20,
              color: colors.gray.GR900,
              marginBottom: 12,
            }}
          >
            {userName}님의
            <br />
            셀러박스 구독이 완료됐어요!
          </div>

          <div
            style={{
              ...font.Body2M16,
              color: colors.gray.GR750,
              marginBottom: 46,
            }}
          >
            스마트 스토어의 리뷰 데이터 수집을 위해
            <br />
            셀러박스를 주매니저로 추가해 주세요
          </div>

          <div style={{ position: 'relative' }}>
            <div
              style={{
                position: 'absolute',
                top: '9.55%',
                left: '2%',
                width: '96%',
                height: '3.1%',
                cursor: 'pointer',
              }}
              onClick={() => {
                window.open('https://sell.smartstore.naver.com/#/member/auth/management');
              }}
            />
            <img
              src="svg/smartstoreRegisterGuideImage.svg"
              width={'100%'}
              alt="이미지"
              style={{ marginBottom: 120 }}
            />
          </div>

          <div
            style={{
              position: 'fixed',
              bottom: 30,
              width: '100%',
            }}
          >
            <div
              style={{
                width: '100%',
                maxWidth: 600,
                boxSizing: 'border-box',
                paddingRight: 40,
              }}
            >
              <Button
                onClick={handleFinish}
                style={{
                  width: '100%',
                  backgroundColor: colors.orange.OR500,
                  color: colors.gray.White,
                  borderRadius: 16,
                  padding: '14px 0px',
                  ...font.Body1S16,
                }}
              >
                확인했어요
              </Button>
            </div>
          </div>
        </div>
      </div>
    </WholePageWrapper>
  );
};

export default SmartStoreGuidePage;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useLocation, useParams, useHistory } from 'react-router';
import { naverRegisterStart, signIn } from '../../constants/urls';
import { useStoreAction } from '../../contexts';

const Register = () => {
  const params = useParams<{ marketName: string }>();
  const { setEnteredByMarketName, setShopbyToken } = useStoreAction();
  const location = useLocation();
  const history = useHistory();

  const marketName = params.marketName || '';

  useEffect(() => {
    const queryString = new URLSearchParams(location.search);
    setEnteredByMarketName(marketName);
    localStorage.setItem('enteredByMarketName', marketName);

    if (marketName === 'naver') {
      history.push(naverRegisterStart);
    } else if (marketName === 'shopby') {
      // 여기 shopby 구현 code 값 저장해야 할 듯?
      const code = queryString.get('code') || '';
      // window.open(`http://localhost:3000/signin?code=${code}`, '_self');
      setShopbyToken(code);
      history.push(signIn);
    } else {
      history.push('/');
      localStorage.removeItem('enteredByMarketName');
    }
  }, []);

  return null;
};

export default Register;

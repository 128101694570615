import React, { useCallback } from "react";
import { styled, Typography } from "@mui/material";
import { useMediaQueryForMobile } from "../hooks/useMediaQueryForMobile";
export interface ITermsOfUseProps {}
export const TermsOfUsePage: React.FC<ITermsOfUseProps> = ({}) => {
  const xsMatches = useMediaQueryForMobile();
  return (
    <>
    {
      true ?
      <>
      <div style={{display: "flex", justifyContent: "center", alignItems: "center",boxSizing: "border-box",paddingTop: "70px"}}>
        <div style={{boxSizing: "border-box",display: "flex", justifyContent: "center", alignItems: "center",width: "100%",padding: `0px ${xsMatches ? 10 : 100}px`}}>
          <iframe 
            style={{ width: "100%", height: "calc(100vh - 300px)",border: "none"}}
            src={"https://cplat-terms.s3.amazonaws.com/SELLERBOX-terms-use.html"+"?date="+new Date()}
          />
        </div>
      </div>
      </>
      :
    <>
      {/* {xsMatches ? ( */}
      <CenterContainer>
        <MobileTermOfUseContainer>
          <TitleZone>
            <Typography variant={"h21"}>{"이용약관"}</Typography>
          </TitleZone>
          <ContentZone>
            <ContentItemZone>
              <Typography variant={"body11"}>{"제 1 조 [목적]"}</Typography>
              <br />
              <Typography variant={"h16"}>
                {
                  '본 약관은 서클플랫폼 주식회사(이하 “회사”)가 제공하는 셀러박스 서비스(이하"서비스")의 이용과 '
                }
                <br />
                {
                  "관련하여 회사와 이용자의 권리ᆞ의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다."
                }
              </Typography>
            </ContentItemZone>
            <ContentItemZone>
              <Typography variant={"body11"}>
                {"제 2 조 [용어의 정의]"}
              </Typography>
              <br />
              <Typography variant={"h16"}>
                {"본 약관에서 사용하는 용어의 정의는 다음과 같습니다."}
                <br />
                {
                  "1. 서비스 : 이용자가 쇼핑몰 판매활동을 통합 관리할 수 있도록 매출관리, 입금관리, 사용자리뷰관리 등을"
                }
                <br />
                {"할 수 있는 서비스를 말합니다."}
                <br />
                {
                  "2. 유료서비스 : 회사가 제공하는 서비스 중 이용요금이 부과되는 별도의 서비스를 말합니다."
                }
                <br />
                {
                  "3. 이용자 : 본 약관에 따라 회사와 서비스 이용계약을 체결하고 회사가 제공하는 서비스를 이용하는 개인 "
                }
                <br />
                {"또는 법인을 의미합니다."}
                <br />
                {
                  "4. 아이디(ID) : 이용자 식별과 이용자의 서비스 이용을 위하여 이용자가 선정하고 회사가 승인하는 문자와 "
                }
                <br />
                {"숫자의 조합을 의미합니다."}
                <br />
                {
                  "5. 비밀번호 : 아이디(ID)와 일치된 이용자임을 확인하고, 이용자의 비밀을 보호하기 위해 이용자가 설정한 "
                }
                <br />
                {"문자와 숫자의 조합을 의미합니다."}
                <br />
                {
                  "6. 스팸 : 수신자가 원하지 않는데도 불구하고 정보통신망을 통해 일방적으로 전송 또는 게시되는 "
                }
                <br />
                {"영리목적의 광고성 정보를 말합니다."}
                <br />
                {
                  "7. 피싱메시지 : 메시지 내용 중 인터넷 주소를 클릭하면 악성코드가 설치되어 수신자가 모르는 사이에 "
                }
                <br />
                {
                  "금전적 피해 또는 개인·금융정보 탈취 피해를 야기하는 메시지를 말합니다."
                }
                <br />
                {
                  "8. 어플리케이션 : 이용자에게 회사의 서비스를 제공할 수 있는 프로그램을 뜻하며, 앱 뿐만 아니라 웹 "
                }
                <br />
                {"형태를 모두 포함합니다."}
              </Typography>
            </ContentItemZone>
            <ContentItemZone>
              <Typography variant={"body11"}>
                {"제 3 조 [약관의 게시와 개정]"}
              </Typography>
              <br />
              <Typography variant={"h16"}>
                {
                  "① 회사는 본 약관의 내용과 함께 상호, 대표자 성명, 회사 소재지 주소, 사업자등록번호, 연락처,"
                }
                <br />
                {
                  "개인정보관리책임자 등을 이용자가 쉽게 알 수 있도록 홈페이지 초기 화면(www.sellerbox.io)에 "
                }
                <br />
                {"게시합니다."}
                <br />
                {
                  "② 회사는 전기통신사업법, 약관 규제에 관한 법률, 개인정보보호법, 정보통신망 이용촉진 및 정보보호 등에 "
                }
                <br />
                {
                  "관한 법률(이하 “정보통신망법”), 전자상거래 등에서의 소비자 보호에 관한 법률 등 관련법을 위배하지 않는"
                }
                <br />
                {"범위에서 본 약관을 개정할 수 있습니다."}
                <br />
                {
                  "③ 회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 제1항의 방식에 따라"
                }
                <br />
                {
                  "그 개정약관의 적용일자 7일 전부터 적용일자 전까지 홈페이지에 공지합니다. 다만, 이용자의 권리 또는"
                }
                <br />
                {
                  "의무에 관한 중요한 규정의 변경은 최소한 30일 전에 공지하고 이용자가 사전에 등록한 이메일, 전화번호로"
                }
                <br />
                {
                  "메일, 문자메시지 등의 전자적 수단을 통해 별도로 명확히 통지하도록 합니다."
                }
                <br />
                {
                  "④ 회사가 본 조 제3항에 따라 개정약관을 공지 또는 통지하면서 이용자에게 약관 변경 적용 일까지"
                }
                <br />
                {
                  "거부의사를 표시하지 않으면 동의한 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도 이용자가"
                }
                <br />
                {
                  "명시적으로 거부의 의사표시를 하지 아니한 경우 이용자가 개정약관에 동의한 것으로 봅니다."
                }
                <br />
                {
                  "⑤ 이용자가 개정약관의 적용에 동의하지 않을 경우 회사는 개정약관의 내용을 적용할 수 없으며, 이 경우"
                }
                <br />
                {
                  "이용자는 이용계약을 해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 "
                }
                <br />
                {"회사는 이용계약을 해지할 수 있습니다."}
                <br />
                {
                  "⑥ 본 약관은 이용자가 약관의 내용에 동의함으로써 효력이 발생하며 이용계약 종료 일까지 적용됩니다. 단, "
                }
                <br />
                {
                  "채권 또는 채무관계가 있을 경우에는 채권, 채무의 완료 일까지로 규정합니다."
                }
              </Typography>
            </ContentItemZone>
            <ContentItemZone>
              <Typography variant={"body11"}>
                {"제 4 조 [약관 외 준칙]"}
              </Typography>
              <br />
              <Typography variant={"h16"}>
                {
                  "본 약관에서 정하지 않은 사항과 본 약관의 해석에 관하여는 전기통신사업법, 개인정보보호법,"
                }
                <br />
                {
                  "정보통신망법, 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제에 관한 법률 및 기타 관계법령 "
                }
                <br />
                {
                  "(‘이하 ‘관계법령’) 또는 상관례에 따릅니다. 또한 본 약관과 관계법령의 내용이 충돌하는 경우 관계법령의 "
                }
                <br />
                {"규정을 따릅니다."}
              </Typography>
            </ContentItemZone>
            <ContentItemZone>
              <Typography variant={"body11"}>
                {"제 5 조 [이용계약의 체결]"}
              </Typography>
              <br />
              <Typography variant={"h16"}>
                {
                  '① 이용계약은 이용자가 되고자 하는 자(이하 "가입신청자")가 약관의 내용에 대하여 동의를 한 다음 '
                }
                <br />
                {
                  "회원가입신청을 하고 회사가 이러한 신청에 대하여 승낙함으로써 체결됩니다."
                }
                <br />
                {
                  "② 제1항 회사의 승낙에 따라 이용자는 회사가 정한 무료서비스를 기간 제한 없이 이용할 수 있습니다. "
                }
                <br />
                {
                  "회사가 별도의 유료서비스 범위를 정한 경우, 이용자는 이용요금을 선납함에 따라 정한 기간동안 "
                }
                <br />
                {"유료서비스를 이용할 수 있습니다."}
                <br />
                {
                  "③ 회사는 가입신청자의 신청에 대하여 서비스 이용을 승낙함을 원칙으로 합니다. 다만, 회사는 다음 각 호에"
                }
                <br />
                {
                  "해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다."
                }
                <br />
                {
                  "1. 가입신청자가 본 약관에 의하여 이전에 이용자자격을 상실한 적이 있는 경우, 단 회사의 이용자 재가입"
                }
                <br />
                {"승낙을 얻을 경우에는 예외로 함"}
                <br />
                {"2. 실명이 아니거나 타인의 명의를 이용한 경우"}
                <br />
                {
                  "3. 허위의 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 않은 경우"
                }
                <br />
                {"4. 만 14세 미만의 가입자인 경우"}
                <br />
                {
                  "5. 이용자가 서비스의 정상적인 제공을 저해하거나 다른 이용자의 서비스 이용에 지장을 준 경우"
                }
                <br />
                {
                  "6. 이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우"
                }
                <br />
                {
                  "7. 기타 회사가 관련법령 등을 기준으로 하여 명백하게 사회질서 및 미풍양속에 반할 우려가 있음을 "
                }
                <br />
                {"인정하는 경우"}
                <br />
                {
                  "④ 본 조 제1항에 따른 신청에 있어 회사는 이용자의 종류에 따라 전문기관을 통한 실명확인 및 본인인증을"
                }
                <br />
                {"요청하거나 증빙자료를 요청할 수 있습니다."}
                <br />
                {
                  "⑤ 회사는 본 조 제3항 각호에 따라 이용신청이 이루어지지 않는지 관리·감독할 수 있습니다."
                }
                <br />
                {
                  "⑥ 회사는 다음 각호에 해당하는 신청에 대해서는 승낙을 지연할 수 있습니다."
                }
                <br />
                {"1. 회사의 설비에 여유가 없거나 기술적 장애가 있는 경우"}
                <br />
                {
                  "2. 서비스 상의 장애 또는 서비스 이용요금 결제수단의 장애가 발생한 경우"
                }
                <br />
                {"3. 기타 회사가 재정적, 기술적으로 필요하다고 인정하는 경우"}
                <br />
                {
                  "⑦ 회사와 이용자가 서비스 이용에 관하여 별도의 계약을 체결한 경우, 당해 별도의 계약이 본 약관에 "
                }
                <br />
                {"우선하여 적용됩니다."}
              </Typography>
            </ContentItemZone>
            <ContentItemZone>
              <Typography variant={"body11"}>
                {"제 6 조 [개인정보 수집]"}
              </Typography>
              <br />
              <Typography variant={"h16"}>
                {
                  "① 회사는 적법하고 공정한 수단에 의하여 이용계약의 성립 및 이행에 필요한 최소한의 개인정보를"
                }
                <br />
                {"수집합니다."}
                <br />
                {
                  "② 회사는 개인정보의 수집 시 관련법규에 따라 개인정보처리방침에 그 수집범위 및 목적을 사전 고지합니다."
                }
                <br />
                {
                  "③ 회사는 서비스 화면에서 회사가 수집한 개인정보의 수집, 이용 또는 제공에 대한 동의를 철회할 수 있도록"
                }
                <br />
                {"필요한 조치를 취해야 합니다."}
              </Typography>
            </ContentItemZone>
            <ContentItemZone>
              <Typography variant={"body11"}>
                {"제 7 조 [개인정보보호 의무]"}
              </Typography>
              <br />
              <Typography variant={"h16"}>
                {
                  "① 회사는 개인정보보호법, 정보통신망법 등 관계 법령이 정하는 바에 따라 이용자의 개인정보를 보호하기 "
                }
                <br />
                {"위해 노력합니다."}
                <br />
                {
                  "② 개인정보의 보호 및 사용에 대해서는 관련법 및 회사의 개인정보처리방침이 적용됩니다. 다만, 회사의"
                }
                <br />
                {
                  "공식 사이트 이외의 링크된 사이트에서는 회사의 개인정보처리방침이 적용되지 않습니다."
                }
              </Typography>
            </ContentItemZone>
            <ContentItemZone>
              <Typography variant={"body11"}>
                {"제 8 조 [이용자의 아이디 및 비밀번호의 관리에 대한 의무]"}
              </Typography>
              <br />
              <Typography variant={"h16"}>
                {
                  "① 이용자는 서비스 이용을 위한 아이디 및 비밀번호의 관리에 대한 책임, 본인 아이디의 제3자에 의한 "
                }
                <br />
                {
                  "부정사용 등 이용자의 고의 또는 과실로 인해 발생하는 모든 불이익에 대한 책임을 부담합니다."
                }
                <br />
                {
                  "② 이용자에게 통보된 서비스 ID 또는 비밀번호에 의하여 발생되는 제3자에 의한 부정사용 또는 회사의 "
                }
                <br />
                {
                  "의사와 무관한 서비스 사용상의 불법행위 내지 과실에 대한 모든 책임은 이용자에게 있습니다. 다만, 회사의"
                }
                <br />
                {"고의 또는 과실이 있는 경우에는 그러하지 아니합니다."}
                <br />
                {
                  "③ 회사는 이용자의 아이디가 개인정보 유출 우려가 있거나 반사회적 또는 미풍양속에 어긋나거나 회사 또는"
                }
                <br />
                {
                  "회사의 운영자로 오인할 우려가 있는 경우 해당 아이디의 활용을 제한할 수 있습니다."
                }
                <br />
                {
                  "④ 이용자는 아이디 및 비밀번호가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 회사에"
                }
                <br />
                {"통지하고 회사의 안내에 따라야 합니다."}
                <br />
                {
                  "⑤ 본 조 제4항의 경우에 해당 이용자가 회사에 그 사실을 통지하지 않거나 통지한 경우에도 회사의 안내에 "
                }
                <br />
                {"따르지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다."}
              </Typography>
            </ContentItemZone>
            <ContentItemZone>
              <Typography variant={"body11"}>
                {"제 9 조 [이용자 정보의 변경]"}
              </Typography>
              <br />
              <Typography variant={"h16"}>
                {
                  "① 이용자는 개인정보관리화면을 통하여 본인의 개인정보를 열람하고 수정할 수 있습니다. 다만, 서비스 "
                }
                <br />
                {
                  "관리를 위해 필요한 실명, 아이디 등은 홈페이지에 기재된 회사의 전화번호 또는 이메일, 정해진 양식 작성을"
                }
                <br />
                {"통해 수정을 요청하여야 합니다."}
                <br />
                {
                  "② 이용자는 서비스 이용신청 시 기재한 사항이나 서비스 이용 과정에서 회사에 제공한 정보가 변경되었을"
                }
                <br />
                {
                  "경우 본 조 제1항의 방법으로 이용자 정보를 변경하여야 하며, 변경사항을 수정하지 않아 발생한 불이익에 "
                }
                <br />
                {"대하여 회사는 책임지지 않습니다."}
              </Typography>
            </ContentItemZone>
            <ContentItemZone>
              <Typography variant={"body11"}>
                {"제 10 조 [이용자에 대한 통지]"}
              </Typography>
              <br />
              <Typography variant={"h16"}>
                {
                  "① 회사가 이용자에 대한 통지를 하는 경우 본 약관에 별도 규정이 없는 한 이용자의 등록된 이메일, "
                }
                <br />
                {"전화번호 등으로 통지할 수 있습니다."}
                <br />
                {
                  "② 회사는 전체에 대한 통지의 경우 7일 이상 서비스 공지사항에 게시함으로써 제1항의 통지에 갈음할 수 "
                }
                <br />
                {"있습니다."}
                <br />
                {
                  "③ 이용자는 회사에 실제로 연락이 가능한 이메일, 전화번호 등의 정보를 제공하고 해당 정보들을 최신으로"
                }
                <br />
                {"유지하여야 하며 회사의 통지를 확인하여야 합니다."}
              </Typography>
            </ContentItemZone>
            <ContentItemZone>
              <Typography variant={"body11"}>
                {"제 11 조 [회사의 의무]"}
              </Typography>
              <br />
              <Typography variant={"h16"}>
                {
                  "① 회사는 관련법과 본 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로"
                }
                <br />
                {"서비스를 제공하기 위하여 최선을 다하여 노력합니다."}
                <br />
                {
                  "② 회사는 이용자가 안전하게 서비스를 이용할 수 있도록 개인정보 보호를 위해 보안시스템을 갖추어야 하며"
                }
                <br />
                {"개인정보처리방침을 공시하고 준수합니다."}
                <br />
                {
                  "③ 회사는 서비스 제공과 관련하여 알고 있는 이용자의 개인정보를 이용자의 승낙 없이 제3자에게 누설,"
                }
                <br />
                {
                  "배포하지 않습니다. 다만, 관계법령에 의한 관계기관으로부터의 요청 등 법률의 규정에 따른 적법한 절차에 "
                }
                <br />
                {"의한 경우에는 그러하지 않습니다."}
                <br />
                {
                  "④ 회사는 이용자에게 제공하는 서비스를 계속적이고 안정적으로 제공하기 위하여 설비에 장애가 생기거나 "
                }
                <br />
                {
                  "멸실 되었을 때 지체 없이 이를 수리 또는 복구할 수 있도록 노력합니다. 다만, 천재지변이나 비상사태 등"
                }
                <br />
                {
                  "부득이한 경우에는 서비스를 일시 중단하거나 영구 중지할 수 있습니다."
                }
                <br />
                {
                  "⑤ 회사는 이용자로부터 제기되는 의견이나 불만이 정당하다고 인정할 경우에는 즉시 처리하여야 합니다. "
                }
                <br />
                {
                  "다만, 즉시 처리가 곤란한 경우는 이용자에게 그 사유와 처리일정을 전자메일 등으로 통보하여야 합니다."
                }
                <br />
                {
                  "⑥ 회사는 서비스 제공목적에 맞는 서비스 이용 여부를 확인하기 위하여 상시적으로 모니터링을 실시합니다."
                }
                <br />
                {
                  "⑦ 회사는 스팸 또는 피싱메시지(이하 통칭하여 “불법스팸”)를 전송한 사실을 확인한 경우, "
                }
                <br />
                {
                  "한국인터넷진흥원 불법스팸대응센터에 관련 자료를 첨부하여 신고할 수 있습니다."
                }
                <br />
                {
                  "⑧ 회사는 불법스팸 발송을 방지하기 위하여 불법스팸으로 인지되는 메시지에 대해 필터링 또는 차단을 할 "
                }
                <br />
                {"수 있습니다."}
                <br />
                {
                  "⑨ 회사는 불법스팸 수신거부 처리 등 불법스팸 관련 민원을 자체적으로 처리하기 위한 고충처리창구를 "
                }
                <br />
                {"운영합니다."}
                <br />
                {
                  "⑩ 회사는 전기통신사업법 제84조의2 및 과학기술정보통신부 고시 “거짓으로 표시된 전화번호로 인한 "
                }
                <br />
                {
                  "피해 예방 등에 관한 고시”(이하 “고시”)에 따라 발신번호 사전등록 서비스를 제공 및 운영합니다."
                }
                <br />
                {
                  " ⑪ 회원이 전기통신사업법 제84조의2 제1항을 위반하여 발신번호를 변작하는 등 거짓으로 표시하는 "
                }
                <br />
                {
                  "경우, 회사는 해당 메시지의 전송을 차단할 수 있습니다. 단, “고시” 제7조 제2항에 따라 회사는 전단의 "
                }
                <br />
                {
                  "메시지 전송을 차단한 후 지체 없이 당해 차단 사실을 이용자에게 통지합니다."
                }
                <br />
                {
                  " ⑫ 본 조 제11항의 경우 회사는 “고시” 제16조 제1항에 따라 차단된 메시지에 관한 자료(변작된 "
                }
                <br />
                {
                  "발신번호, 차단시각, 전송자명 등)를 1년간 보관·관리하고 이를 한국인터넷진흥원에 제출할 수 있습니다."
                }
                <br />
                {
                  " ⑬ 회사는 이용자가 일정기간 생성한 데이터에 대하여 별도의 파일 형태로 제공을 요청하면 이에 응할 수 "
                }
                <br />
                {
                  "있습니다. 단, 회사는 이용자에게 요청 파일에 대한 일정 비용을 청구할 수 있습니다."
                }
              </Typography>
            </ContentItemZone>
            <ContentItemZone>
              <Typography variant={"body11"}>
                {"제 12 조 [이용자의 의무]"}
              </Typography>
              <br />
              <Typography variant={"h16"}>
                {"① 이용자는 다음 행위를 하여서는 안 됩니다."}
                <br />
                {
                  "1. 서비스 이용 신청 또는 서비스 내용 변경 시 허위내용을 등록하는 행위"
                }
                <br />
                {"2. 타인의 정보나 명의를 도용하거나 부정하게 사용하는 행위"}
                <br />
                {
                  "3. 다른 이용자의 개인정보를 동의 없이 수집, 저장, 공개하는 행위"
                }
                <br />
                {"4. 회사가 게시한 정보를 변경하거나 제3자에게 제공하는 행위"}
                <br />
                {"5. 회사 또는 제3자의 저작권 등 지식재산권에 대한 침해 행위"}
                <br />
                {
                  "6. 회사 또는 제3자의 명예를 손상시키거나 업무를 방해하는 행위"
                }
                <br />
                {
                  "7. 외설 또는 폭력적인 메시지, 팩스, 음성, 메일, 기타 공서양속에 반하는 정보를 서비스에 공개 또는 "
                }
                <br />
                {"게시하는 행위"}
                <br />
                {
                  "8. 회사의 동의 없이 본 약관의 목적 범위를 벗어나 영리를 목적으로 서비스를 사용하는 행위"
                }
                <br />
                {"9. 타인의 의사에 반하는 내용을 지속적으로 전송하는 행위"}
                <br />
                {"10. 범죄행위를 목적으로 하거나 범죄행위를 교사하는 행위"}
                <br />
                {"11. 선량한 풍속 또는 기타 사회질서를 해치는 행위"}
                <br />
                {
                  "12. 현행 법령, 회사가 제공하는 서비스에 정한 약관, 이용안내 및 서비스와 관련하여 공지한 주의사항, "
                }
                <br />
                {
                  "회사가 통지하는 사항, 기타 서비스 이용에 관한 규정을 위반하는 행위"
                }
                <br />
                {
                  "13. 서비스의 안정적인 운영에 지장을 주거나 줄 우려가 있는 일체의 행위"
                }
                <br />
                {"14. 제3자에게 임의로 서비스를 임대하는 행위"}
                <br />
                {
                  "15. 전기통신사업법 제84조의2 제1항을 위반하여 메시지 전송 시 발신번호를 변작하는 등 거짓으로 "
                }
                <br />
                {"표시하는 행위"}
                <br />
                {"16. 서비스를 불법스팸 전송에 이용하는 행위"}
                <br />
                {"17. 기타 불법적이거나 부당한 행위"}
                <br />
                {
                  "② 이용자는 관계법, 본 약관의 규정, 이용안내 및 서비스와 관련하여 공지한 주의사항, 회사가 통지하는 "
                }
                <br />
                {
                  "사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안 됩니다."
                }
                <br />
                {
                  "③ 이용자는 회사가 정한 유료서비스 이용요금을 지정된 날짜에 납입할 의무가 있습니다."
                }
                <br />
                {
                  "④ 이용자는 정보통신망법의 광고성 정보 전송 시 의무사항 및 회사의 이용약관을 준수하여야 합니다."
                }
                <br />
                {
                  "⑤ 이용자는 불법스팸 전송 등 불법행위를 하거나 전기통신사업법 등 관련 법령을 준수하지 않아 발생하는 "
                }
                <br />
                {"모든 민ᆞ형사상의 책임을 부담합니다."}
                <br />
                {
                  "⑥ 전기통신사업법 제84조의2에 의거 이용자는 본인의 발신번호를 사전에 등록하고 등록된 번호로만 "
                }
                <br />
                {
                  "발송해야 하며, 메시지 전송 시 발신번호를 변작하는 등 거짓으로 표시하여서는 안됩니다."
                }
              </Typography>
            </ContentItemZone>
            <ContentItemZone>
              <Typography variant={"body11"}>
                {"제 13 조 [불만처리]"}
              </Typography>
              <br />
              <Typography variant={"h16"}>
                {
                  "① 회사는 개인정보와 관련하여 이용자의 의견을 수렴하고 불만을 처리하기 위한 절차를 마련하여야 합니다."
                }
                <br />
                {
                  "② 회사는 전화, 전자우편 또는 서비스 화면의 상담창구를 통하여 이용고객의 불만사항을 접수, 처리하여야 "
                }
                <br />
                {"합니다."}
              </Typography>
            </ContentItemZone>
            <ContentItemZone>
              <Typography variant={"body11"}>
                {"제 14 조 [서비스 제공]"}
              </Typography>
              <br />
              <Typography variant={"h16"}>
                {"① 회사는 이용자에게 제공하는 서비스를 홈페이지에 게시합니다."}
                <br />
                {
                  "② 서비스는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다. 단, 회사는 서비스 제공에 필요한 경우 "
                }
                <br />
                {
                  "정기점검을 실시할 수 있으며 정기점검시간은 서비스 화면 등에 회사가 사전에 공지한 바에 따릅니다."
                }
                <br />
                {
                  "③ 회사는 서비스를 일정범위로 분할하여 각 범위 별로 이용가능 시간을 별도로 지정할 수 있습니다. 다만 "
                }
                <br />
                {"이러한 경우에는 그 내용을 사전에 공지합니다."}
                <br />
                {
                  "④ 회사는 다음 각 호에 해당하는 경우 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다."
                }
                <br />
                {"1. 서비스용 설비의 보수 등 공사로 인한 부득이한 경우"}
                <br />
                {
                  "2. 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우"
                }
                <br />
                {"3. 천재지변, 국가비상사태 등 불가항력적 사유가 있는 경우"}
                <br />
                {"4. 기타 서비스를 유지할 수 없는 중대한 사유가 발생한 경우"}
                <br />
                {
                  "⑤ 본 조 제4항의 경우 회사는 제10조에서 정한 방법으로 이용자에게 통지합니다. 다만, 회사가 사전에 "
                }
                <br />
                {
                  "통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다."
                }
                <br />
                {
                  "⑥ 이용자가 유료서비스를 이용하는 경우, 서비스 이용기간, 서비스 이용요금, 납부절차 등은 별도의 서비스 "
                }
                <br />
                {
                  "내용을 홈페이지나 서비스 어플리케이션을 통해 통지할 수 있습니다. "
                }
                <br />
                {
                  "⑦ 회사는 이용자의 무료서비스 이용 시 이용자가 업로드 하는 데이터를 관리해야 할 의무가 없으며 "
                }
                <br />
                {"무료서비스 이용과 관련한 어떠한 책임도 부담하지 않습니다."}
              </Typography>
            </ContentItemZone>
            <ContentItemZone>
              <Typography variant={"body11"}>
                {"제 15 조 [서비스의 변경 또는 중단]"}
              </Typography>
              <br />
              <Typography variant={"h16"}>
                {
                  "① 회사는 상당한 이유가 있는 경우에 회사의 정책상, 운영상, 기술상의 필요에 따라 제공하고 있는 서비스의"
                }
                <br />
                {
                  "전부 또는 일부를 변경 또는 중단할 수 있으며, 이에 대하여 관련 법에 특별한 규정이 없는 한 이용자에게 "
                }
                <br />
                {"별도 보상을 하지 않습니다."}
                <br />
                {
                  "② 서비스의 내용, 이용방법, 이용시간에 대하여 변경 또는 중단이 있는 경우에는 변경 또는 중단 사유, 변경 "
                }
                <br />
                {
                  "또는 중단될 서비스의 내용 및 제공일자 등을 그 변경 또는 중단 전에 제10조의 방법으로 이용자에게 "
                }
                <br />
                {"통지합니다."}
                <br />
                {
                  "③ 회사가 제공하기로 한 유료서비스의 내용을 기술적 사양의 변경 또는 특정서비스의 중지의 사유로 "
                }
                <br />
                {
                  "변경함으로 인해 이용자가 입은 손해에 대해서 회사는 이용요금 환불, 서비스의 개선 등 적절한 조치를"
                }
                <br />
                {
                  "취합니다. 다만, 회사에게 귀책사유가 없는 경우와 부득이한 사유로 인해 관련법규 또는 합리적인 상행위"
                }
                <br />
                {
                  "관행에 따른 조치로서 변경일자 7일 이전에 게시한 경우에는 그러하지 아니합니다."
                }
              </Typography>
            </ContentItemZone>
            <ContentItemZone>
              <Typography variant={"body11"}>
                {"제 16 조 [서비스 이용의 제한 및 정지]"}
              </Typography>
              <br />
              <Typography variant={"h16"}>
                {
                  "① 회사는 주민등록법을 위반한 명의도용 및 결제도용, 저작권법을 위반한 불법프로그램의 제공 및 "
                }
                <br />
                {
                  "운영방해, 정보통신망법을 위반한 불법통신, 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과 같이 "
                }
                <br />
                {
                  "관련법을 위반한 경우에는 즉시 영구이용정지를 할 수 있습니다. 본 항에 따른 서비스 이용정지 시 서비스"
                }
                <br />
                {
                  "내의 혜택 및 권리 등도 모두 소멸되며 회사는 이에 대해 별도로 보상하지 않습니다."
                }
                <br />
                {
                  "② 회사는 다음 각 호에 해당하는 경우에 이용자의 서비스 이용을 제한할 수 있습니다."
                }
                <br />
                {
                  "1. 이용자가 유료서비스 이용 기간 만료일 경과 후에도 이용요금을 납부하지 않을 경우"
                }
                <br />
                {"2. 제12조 규정에 의한 이용자의 의무를 이행하지 아니한 경우"}
                <br />
                {"3. 이용자의 이름 등 이용자 정보가 정확하지 않은 경우"}
                <br />
                {
                  "4. 다른 이용자 또는 제3자의 지식재산권을 침해하거나 명예를 손상시킨 경우"
                }
                <br />
                {
                  "5. 방송통신심의위원회의 시정요구가 있거나 불법선거운동과 관련하여 선거관리위원회의 유권해석을 받은"
                }
                <br />
                {"경우"}
                <br />
                {
                  "6. 공공질서 및 미풍양속에 저해되는 내용을 고의로 유포시킨 경우"
                }
                <br />
                {
                  "7. 이용자가 서비스를 임의로 재판매 하거나 변형하여 제3자가 이용하도록 하는 경우"
                }
                <br />
                {
                  "8. 서비스를 이용하여 얻은 정보를 이용고객의 개인적인 이용 이외에 복사, 가공, 번역, 2차적 저작물 등을"
                }
                <br />
                {
                  "제작하여 복제, 공연, 방송, 전시, 배포, 출판 등에 사용하는 경우"
                }
                <br />
                {"9. 기타 회사가 이용자로 부적당하다고 판단한 경우"}
                <br />
                {"10. 이용자가 서비스에 대량의 트래픽을 발생시키는 경우"}
                <br />
                {
                  "③ 회사는 이용자가 다음 중 하나에 해당하는 경우 1개월 동안의 기간을 정하여 당해 서비스의 이용을 "
                }
                <br />
                {"정지할 수 있습니다."}
                <br />
                {
                  "1. 방송통신위원회 또는 한국인터넷진흥원 등 관계기관이 불법스팸 전송사실을 확인하여 이용정지를"
                }
                <br />
                {"요청하는 경우"}
                <br />
                {
                  "2. 이용자가 전송하는 광고로 인하여 회사의 서비스 제공에 장애를 야기하거나 야기할 우려가 있는 경우"
                }
                <br />
                {
                  "3. 이용자가 전송하는 광고의 수신자가 불법스팸으로 신고하는 경우"
                }
                <br />
                {
                  "4. 이용자가 제12조 제1항 제15호를 위반하여 발신번호를 변작하는 등 거짓으로 표시한 경우"
                }
                <br />
                {
                  "5. 과학기술정보통신부장관 또는 한국인터넷진흥원 등 관련 기관이 발신번호를 변작 등을 확인하여 이용 "
                }
                <br />
                {"정지를 요청하는 경우"}
                <br />
                {
                  "④ 회사가 본 조 제2항 내지 제3항의 규정에 의하여 서비스를 제한 또는 정지하는 경우, 그 사실을 "
                }
                <br />
                {
                  "이용자에게 사전 통보하여야 합니다. 다만, 사전에 통보하는 것이 곤란할 경우 이용정지 조치 후 통보할 수"
                }
                <br />
                {"있습니다."}
                <br />
                {"조치를 할 수 있습니다."}
                <br />
                {
                  "⑥ 회사는 휴면계정으로 분류되기 30일 전까지 개인정보가 파기되거나 분리되어 저장되는 사실과 기간"
                }

                <br />
                {
                  "만료일 및 해당 개인정보의 항목을 이메일, 서면, 모사전송, 전화 또는 유사한 방법 중 하나의 방법으로"
                }

                <br />
                {
                  "이용자에게 알리도록 합니다. (단, 이용자의 정보가 정확하지 않은 경우 알림 대상에서 제외될 수 있습니다.)"
                }

                <br />
                {
                  "⑦ 본 조에 의한 서비스 이용 정지 또는 이용 계약의 해지 시 데이터 삭제에 대한 책임은 이용자가 부담하며,"
                }

                <br />
                {
                  "회사는 고의 또는 과실이 없는 한 데이터 삭제에 따른 책임을 부담하지 않습니다."
                }

                <br />
                {
                  "⑧ 회사가 사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우,"
                }

                <br />
                {
                  "회사는 제10조에서 정한 방법으로 이용자에게 통지하고 서비스를 종료할 수 있습니다."
                }
              </Typography>
            </ContentItemZone>
            <ContentItemZone>
              <Typography variant={"body11"}>
                {"제 17 조 [이용요금 등의 종류]"}
              </Typography>
              <br />
              <Typography variant={"h16"}>
                {
                  "① 서비스 이용과 관련하여 이용자가 납부하여야 할 이용요금은 홈페이지나 서비스 어플리케이션에 게재한"
                }
                <br />
                {
                  "바에 따릅니다. 단, 홈페이지와 서비스 어플리케이션에 게재된 이용요금이 상이한 경우 서비스 "
                }
                <br />
                {"어플리케이션 기준을 우선하여 적용됩니다."}
                <br />
                {
                  "② 이용자는 대부분의 서비스를 무료로 이용할 수 있으나, 회사가 유료로 제공하는 서비스를 이용하기 위해"
                }
                <br />
                {
                  "납입해야 하는 이용요금은 월단위 또는 연단위로 납부할 수 있습니다."
                }
                <br />
                {"③ 서비스 이용요금은 선불결제를 원칙으로 합니다."}
              </Typography>
            </ContentItemZone>
            <ContentItemZone>
              <Typography variant={"body11"}>
                {"제 18 조 [불법 면탈 요금의 청구]"}
              </Typography>
              <br />
              <Typography variant={"h16"}>
                {
                  "① 이용자가 불법으로 서비스 이용요금 등을 면탈할 경우에는 면탈한 금액의 2배에 해당하는 금액을"
                }
                <br />
                {"청구합니다."}
                <br />
                {
                  "② 회사는 2개월 이상 서비스 이용요금이 연체된 이용자를 신용기관에 신용불량자로 등록할 수 있습니다."
                }
              </Typography>
            </ContentItemZone>
            <ContentItemZone>
              <Typography variant={"body11"}>
                {"제 19 조 [이용요금 등의 환불]"}
              </Typography>
              <br />
              <Typography variant={"h16"}>
                {
                  "① 회사는 서비스 이용요금 등의 과납 또는 오납이 있을 때에는 그 과납 또는 오납된 서비스 이용요금을"
                }
                <br />
                {"반환합니다."}
                <br />
                {
                  "② 서비스 이용기간 중 서비스 이용계약이 중도 해지되는 경우, 회사는 이용자에게 잔여기간에 대한 서비스"
                }
                <br />
                {
                  "이용요금을 일할 계산 하여 잔여금액을 환불하며, 서비스 약정기간에 따라 이용자가 서비스 이용요금을 할인"
                }
                <br />
                {
                  "받았을 경우 잔여금액에서 할인 받은 금액을 공제하고 환불합니다. 단, 잔여금액보다 이용자가 할인 받은 "
                }
                <br />
                {
                  "금액이 더 클 경우, 이용자는 초과된 금액(=할인금액-잔여금액)을 ‘회사’에 즉시 지급하여야 합니다."
                }
              </Typography>
            </ContentItemZone>
            <ContentItemZone>
              <Typography variant={"body11"}>
                {"제 20 조 [이용계약 해지]"}
              </Typography>
              <br />
              <Typography variant={"h16"}>
                {
                  "① 이용자는 이용계약을 해지 하고자 할 때 본인이 직접 서비스를 통하여 신청하거나 전자우편, 전화 등의 "
                }
                <br />
                {"방법을 통하여 회사에 신청하여야 합니다."}
                <br />
                {
                  "② 회사는 본 조 제1항의 규정에 의하여 해지신청이 접수되면 즉시 이용계약을 해지합니다. 단, 별도의 "
                }
                <br />
                {"채권·채무관계가 있을 경우에는 그러하지 아니합니다."}
                <br />
                {
                  "③ 회사는 이용자가 다음 각 호에 해당할 경우에는 이용자의 동의 없이 이용계약을 해지할 수 있으며 그 "
                }
                <br />
                {
                  "사실을 이용자에게 통지합니다. 다만 회사가 긴급하게 해지할 필요가 있다고 인정하는 경우나 이용자의"
                }
                <br />
                {
                  "귀책사유로 인하여 통지할 수 없는 경우에는 지체 없이 사후 통지로 대체 할 수 있습니다."
                }
                <br />
                {
                  "1. 타인의 명의로 서비스 계약을 체결하였거나 서비스 계약 체결 시 제출한 자료 및 정보가 허위 또는 누락"
                }
                <br />
                {"되었음이 확인된 경우"}
                <br />
                {
                  "2. 이용자가 제12조를 포함한 본 약관을 위반하고 일정 기간 이내에 위반 내용을 해소하지 않는 경우"
                }
                <br />
                {
                  "3. 회사의 서비스 제공목적 외의 용도로 서비스를 이용하거나 제3자에게 임의로 서비스를 임대한 경우"
                }
                <br />
                {
                  "4. 방송통신위원회 또는 한국인터넷진흥원 등이 불법스팸의 전송사실을 확인하여 회사에게 서비스 계약"
                }
                <br />
                {"해지를 요청하는 경우"}
                <br />
                {
                  "5. 이용자가 제12조 제6항을 위반하여 발신번호를 변작하는 등 거짓으로 표시한 경우"
                }
                <br />
                {
                  "6. 제16조 규정에 의하여 이용정지를 당한 이후 1년 이내에 이용정지 사유가 재발한 경우"
                }
                <br />
                {
                  "7. 회사의 유료서비스 이용요금 등의 납입청구에 대하여 이용자가 이용요금을 체납할 경우"
                }
                <br />
                {
                  "④ 이용자 또는 회사가 계약을 해지할 경우 관련법 및 개인정보처리방침에 따라 회사가 이용자 정보를"
                }
                <br />
                {
                  "보유하는 경우를 제외하고는 해지 즉시 이용자의 모든 데이터는 소멸될 수 있으며, 소멸된 데이터는 어떤"
                }
                <br />
                {"경우에도 이용자에게 반환되지 않습니다."}
                <br />
                {
                  "⑤ 본 조에 따라 서비스 이용계약 해지에 따른 이용자의 손해가 발생한 경우 회사는 책임을 부담하지"
                }
                <br />
                {
                  "않습니다. 단, 회사의 귀책사유로 인하여 서비스 이용계약이 해지되는 경우에는 그러하지 않습니다."
                }
              </Typography>
            </ContentItemZone>
            <ContentItemZone>
              <Typography variant={"body11"}>
                {"제 21 조 [각종 데이터의 보관기간]"}
              </Typography>
              <br />
              <Typography variant={"h16"}>
                {
                  "회사는 이용자의 정상적인 서비스 이용 중 이용자가 저장하고 있는 자료에 대하여 보관기간을 정하고"
                }
                <br />
                {
                  "있으며, 개인정보처리방침에 데이터의 보관기간을 사전고지합니다. 회사는 필요에 따라 이용자에게 사전 "
                }
                <br />
                {"공지 후 보관기간을 변경할 수 있습니다."}
              </Typography>
            </ContentItemZone>
            <ContentItemZone>
              <Typography variant={"body11"}>
                {"제 22 조 [지식재산권]"}
              </Typography>
              <br />
              <Typography variant={"h16"}>
                {
                  "① 서비스 자체에 대한 지식재산권은 회사에 귀속됩니다. 다만, 이용자가 서비스 페이지에 게시하거나 "
                }
                <br />
                {"등록한 자료의 지식재산권은 이용자에게 귀속됩니다."}
                <br />
                {
                  "② 이용자가 서비스를 통하여 업로드하는 이미지나 자료가 제3자의 저작권 및 지식재산권을 침해하여서는"
                }
                <br />
                {
                  "안되며 이로 인해 발생하는 모든 문제는 전적으로 이용자의 책임이며, 회사와 제3자 사이에 분쟁이 발생시"
                }
                <br />
                {"이용자의 비용으로 이를 보상 혹은 배상해야 합니다."}
              </Typography>
            </ContentItemZone>
            <ContentItemZone>
              <Typography variant={"body11"}>
                {"제 23 조 [광고게재]"}
              </Typography>
              <br />
              <Typography variant={"h16"}>
                {
                  "① 회사는 서비스의 운영과 관련하여 서비스 어플리케이션 화면, 이메일 등에 광고를 게재할 수 있습니다. 단"
                }
                <br />
                {
                  "이용자가 원하지 않는 경우에는 영리목적의 광고성 전자우편을 발송하지 않습니다."
                }
                <br />
                {
                  "② 이용자는 본조 제1항에 따라 게재된 광고내용이나 회사를 통한 광고주의 판촉활동에 대하여 본인의 "
                }
                <br />
                {
                  "책임과 판단 하에 참여하며, 그 결과로 발생하는 모든 손실 또는 손해에 대해서 회사는 책임을 지지 않습니다."
                }
              </Typography>
            </ContentItemZone>
            <ContentItemZone>
              <Typography variant={"body11"}>
                {"제 24 조 [쇼핑몰 및 솔루션 연동]"}
              </Typography>
              <br />
              <Typography variant={"h16"}>
                {
                  "① 이용자는 서비스 이용을 위하여 회사가 쇼핑몰에 자동 로그인할 수 있도록 조치를 취할 의무를 지닙니다."
                }
                <br />
                {
                  "② 쇼핑몰이 자동 로그인 방식을 차단하는 경우, 서비스 어플리케이션과 쇼핑몰 간의 연동이 중단될 수"
                }
                <br />
                {"있습니다."}
                <br />
                {
                  "③ 쇼핑몰의 정책 변경 또는 쇼핑몰이 회사와의 연동을 일방적으로 중단하는 등 불가피하게 쇼핑몰과의"
                }
                <br />
                {
                  "연동을 유지할 수 없는 사정이 발생한 경우, 회사는 쇼핑몰과의 연동을 중단할 수 있으며, 이 경우 "
                }
                <br />
                {"이용자에게 발생한 손해에 대해서는 책임을 부담하지 않습니다."}
              </Typography>
            </ContentItemZone>
            <ContentItemZone>
              <Typography variant={"body11"}>
                {"제 25 조 [손해배상의 범위 및 청구]"}
              </Typography>
              <br />
              <Typography variant={"h16"}>
                {
                  "① 회사의 귀책사유로 인한 유료서비스 장애가 발생하여 이용자가 서비스를 연속 1시간 이상 이용하지 "
                }
                <br />
                {
                  "못하였을 경우, 아래와 같이 회사는 이용자에게 무상기간을 제공합니다. 단, 보상의 한도는 무상 서비스 "
                }
                <br />
                {"이용기간 최대 15일 제공을 초과하지 않습니다."}
                <br />
                {"장애 발생 시간 (연속) 보상 내용"}
                <br />
                {"1시간 이상 3시간 미만 무상 기간 3일 제공"}
                <br />
                {"3시간 이상 4시간 미만 무상 기간 6일 제공"}
                <br />
                {"4시간 이상 5시간 미만 무상 기간 9일 제공"}
                <br />
                {"5시간 이상 6시간 미만 무상 기간 12일 제공"}
                <br />
                {"6시간 이상 무상 기간 15일 제공"}
                <br />
                {
                  "② 전 1항의 서비스 장애 이외에 회사가 고의 또는 과실로 이용자에게 손해를 끼친 경우, 손해에 대하여"
                }
                <br />
                {
                  "배상할 책임이 있습니다. 이 경우 이용자는 서비스 무상 이용 기간 제공 배상 또는 현금 배상의 방식 중"
                }
                <br />
                {
                  "하나를 선택하고, 이용자에게 발생한 손해에 대한 배상 금액은 이용자와 회사가 합의하여 정합니다. 단, 각"
                }
                <br />
                {
                  "배상 방식 별 손해배상의 한도는 아래의 각 호를 초과하지 않습니다."
                }
                <br />
                {
                  "1. 무상 서비스 이용 기간 제공 방식 배상 : 이용자의 손해배상 청구일로부터 최근 3개월 평균 이용요금의 3"
                }
                <br />
                {"배에 해당하는 무상 서비스 이용기간 제공"}
                <br />
                {
                  "2. 현금 배상 : 이용자의 손해배상 청구일로부터 최근 3개월 평균 월 서비스 이용요금의 2배에 해당하는"
                }
                <br />
                {"현금의 배상"}
                <br />
                {
                  "③ 회사는 그 손해가 천재지변 등 불가항력이거나 이용자의 고의 또는 과실로 인하여 발생된 때에는"
                }
                <br />
                {"손해배상을 하지 않습니다."}
                <br />
                {
                  "④ 이용자가 고의 또는 과실로 회사에 손해를 끼친 경우, 이용자는 회사에 대하여 발생한 손해를 배상할"
                }
                <br />
                {"책임이 있습니다."}
                <br />
                {
                  "⑤ 이용자는 불법스팸 전송 등 불법행위를 하거나 전기통신사업법 등 관련 법령을 준수하지 못해 발생하는"
                }
                <br />
                {"모든 민형사상의 책임을 부담하며, 회사는 면책됩니다."}
                <br />
                {
                  "⑥ 손해배상의 청구는 회사에 청구사유, 청구금액 및 산출근거를 기재하여 회사가 정한 방식 (이메일, 전화"
                }
                <br />
                {"등)으로 신청하여야 합니다."}
                <br />
                {
                  "⑦ 회사 및 타인에게 피해를 주어 피해자의 고발 또는 소송 제기로 인하여 손해배상이 청구된 이용자는 회사"
                }
                <br />
                {"및 수사기관의 요청에 적극 협조하여야 합니다."}
              </Typography>
            </ContentItemZone>
            <ContentItemZone>
              <Typography variant={"body11"}>{"제 26 조 [면책]"}</Typography>
              <br />
              <Typography variant={"h16"}>
                {
                  "① 회사는 다음 각 호의 경우로 서비스를 제공할 수 없는 경우 이로 인하여 이용자 또는 제3자에게 발생한 "
                }
                <br />
                {"손해에 대해서는 책임을 부담하지 않습니다."}
                <br />
                {"1. 천재지변 또는 이에 준하는 불가항력의 상태가 있는 경우"}
                <br />
                {
                  "2. 서비스의 효율적인 제공을 위한 시스템 개선, 장비 증설 등 계획된 서비스 중지 일정을 사전에 공지한"
                }
                <br />
                {"경우"}
                <br />
                {"3. 이용자의 귀책사유로 서비스 이용에 장애가 있는 경우"}
                <br />
                {"4. 회사의 고의 과실이 없는 사유로 인한 경우"}
                <br />
                {
                  "5. 회사에게 회선, 통신망, 전용선을 제공하고 있는 이동통신사 또는 부가통신사업자 측의 장애·귀책사유로"
                }
                <br />
                {
                  "인한 서비스의 불완전 또는 불능으로 이용자 또는 제3자에게 야기된 손해"
                }
                <br />
                {
                  "② 회사는 이용자가 서비스를 통해 얻은 정보 또는 자료 등으로 인해 발생한 손해와 서비스를 이용하거나"
                }
                <br />
                {
                  "이용할 것으로부터 발생하거나 기대하는 손익 등에 대하여 책임을 면합니다."
                }
                <br />
                {
                  "③ 회사는 서비스 이용의 장애로 인하여 발생한 이용자의 부가적, 영업적인 손해에 대해서는 책임을 지지"
                }
                <br />
                {"않습니다."}
                <br />
                {
                  "④ 회사는 이용자가 게시 또는 전송한 데이터의 내용에 대해서는 책임을 면합니다."
                }
                <br />
                {
                  "⑤ 회사는 이용자 상호 간 또는 이용자와 제3자 상호 간에 서비스를 매개로 하여 거래 등을 한 경우에는"
                }
                <br />
                {"책임을 면합니다."}
                <br />
                {
                  "⑥ 회사는 무료로 제공하는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다."
                }
                <br />
                {
                  "⑦ 회사는 이용자가 서비스 이용 정지 또는 종료 후 제20조에 따라 파기된 데이터와 이용자가 직접 삭제를"
                }
                <br />
                {"요청한 데이터에 대해서는 책임을 지지 않습니다."}
                <br />
                {
                  "⑧ 회사는 회사의 고의 또는 과실이 없는 한 서비스를 활용하여 발송된 메시지 관련 발신자와 수신자 간 "
                }
                <br />
                {
                  "분쟁에 대하여 개입할 의무가 없으며 이로 인한 손해를 배상할 책임이 없습니다."
                }
                <br />
                {
                  "⑨ 회사는 제16조에 따라 이용자의 서비스 이용을 정지 또는 제한하는 경우, 이용의 제한으로 발생할 수 "
                }
                <br />
                {"있는 이용자의 손해 등에 대해서는 책임이 면제됩니다."}
              </Typography>
            </ContentItemZone>
            <ContentItemZone>
              <Typography variant={"body11"}>
                {"제 27 조 [분쟁조정]"}
              </Typography>
              <br />
              <Typography variant={"h16"}>
                {
                  "① 회사와 이용자 간 제기된 소송은 대한민국법을 준거법으로 합니다."
                }
                <br />
                {
                  "② 서비스 이용과 관련하여 회사와 이용자 간에 발생한 분쟁에 대해서는 민사소송법상의 주소지를 관할하는 "
                }
                <br />
                {"법원을 합의관할로 합니다."}
                <br />
                {
                  "③ 해외에 주소나 거소가 있는 이용자의 경우 회사와 이용자간 발생한 분쟁에 관한 소송은 전항에도 "
                }
                <br />
                {"불구하고 대한민국 서울중앙지방법원을 관할법원으로 합니다."}
                <br />
                {"* 부 칙 *"}
              </Typography>
            </ContentItemZone>
            <ContentItemZone>
              <Typography variant={"h16"}>
                {"1. 본 약관은 2021년 11월 15일부터 적용됩니다."}
                <br />
                {"서비스 이용약관 공고일자 : 2021년 11월  1일"}
                <br />
                {"서비스 이용약관 시행일자 : 2021년 11월 15일"}
              </Typography>
            </ContentItemZone>
          </ContentZone>
        </MobileTermOfUseContainer>
        </CenterContainer>
      {/* // ) : (
      //   <TermOfUseHome />
      // )} */}
    </>
    }
    </>
  );
};

const TermOfUseHome = () => {
  return (
    <TermOfUseContainer>
      <TitleZone>
        <Typography variant={"h21"}>{"이용약관"}</Typography>
      </TitleZone>
      <ContentZone>
        <ContentItemZone>
          <Typography variant={"body11"}>{"제1조 (목적)"}</Typography>
          <br />
          <Typography variant={"h16"}>
            {
              "이 약관은 주식회사 서클플랫폼(이하 “회사”라 합니다)가 운영하는 셀러박스 “애플리케이션”(이하 “홈페이지”와 “애플리케이션”을 “APP”이라고 합니다)의 서비스 이용 및 제공에 관한 제반 사항의 규정을 목적으로 합니다."
            }
          </Typography>
        </ContentItemZone>
        <ContentItemZone>
          <Typography variant={"body11"}>{"제2조 (용어의 정의)"}</Typography>
          <br />
          <Typography variant={"h16"}>
            {"① 이 약관에서 사용하는 용어의 정의는 다음과 같습니다."}
            <br />
            {
              "“서비스”라 함은 구현되는 PC, 모바일 기기를 통하여 “이용자”가 이용할 수 있는 데이터분석서비스 등 회사가 제공하는 제반 서비스를 의미합니다."
            }
            <br />
            {
              "“이용자”란 “APP”에 접속하여 이 약관에 따라 “APP”이 제공하는 서비스를 받는 회원 및 비회원을 말합니다."
            }
            <br />
            {
              "“회원”이란 “APP”에 개인정보를 제공하여 회원등록을 한 자로서, “APP”이 제공하는 서비스를 이용하는 자를 말합니다."
            }
            <br />
            {
              "“모바일 기기”란 콘텐츠를 다운로드 받거나 설치하여 사용할 수 있는 기기로서, 휴대폰, 스마트폰, 휴대정보단말기(PDA), 태블릿 등을 의미합니다."
            }
            <br />
            {
              "“계정정보”란 회원의 회원번호와 내보험다보여 등 외부계정정보, 기기정보 등 회원이 회사에 제공한 정보를 의미합니다."
            }
            <br />
            {
              "“애플리케이션”이란 회사가 제공하는 서비스를 이용하기 위하여 모바일 기기를 통해 다운로드 받거나 설치하여 사용하는 프로그램 일체를 의미합니다."
            }
            <br />
            {
              "② 이 약관에서 사용하는 용어의 정의는 본 조 제1항에서 정하는 것을 제외하고는 관계법령 및 서비스별 정책에서 정하는 바에 의하며, 이에 정하지 아니한 것은 일반적인 상 관례에 따릅니다."
            }
          </Typography>
        </ContentItemZone>
        <ContentItemZone>
          <Typography variant={"body11"}>
            {"제3조 (약관의 효력 및 변경)"}
          </Typography>
          <br />
          <Typography variant={"h16"}>
            {
              " ① 본 약관은“APP” 내 또는 그 연결화면에 게시하거나 이용자에게 공지함으로써 효력이 발생합니다."
            }
            <br />
            {
              "② 회사는 불가피한 여건이나 사정이 있을 경우 「약관의 규제에 관한 법률」, 「정보통신망이용촉진 및 정보보호 등에 관한 법률」등 관련 법령에 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다."
            }
            <br />
            {
              "③ 회사가 약관을 개정할 경우에는 적용일자 및 개정내용, 개정사유 등을 명시하여 최소한 그 적용일 7일 이전부터 “APP” 내 또는 그 연결화면에 게시하여 이용자에게 공지합니다. 다만, 변경된 내용이 회원에게 불리하거나 중대한 사항의 변경인 경우에는 그 적용일 30일 이전까지 본문과 같은 방법으로 공지하고, 회원의 전자우편주소, 전자메모, 서비스 내 쪽지, 문자메시지(LMS/SMS)의 방법으로 회원에게 통지합니다. 이 경우 개정 전 내용과 개정 후 내용을 명확하게 비교하여 회원이 알기 쉽도록 표시합니다."
            }
            <br />
            {
              "④ 회사가 약관을 개정할 경우 개정약관 공지 후 개정약관의 적용에 대한 회원의 동의 여부를 확인합니다. 회사는 제3항의 공지 또는 통지를 할 경우 회원이 개정약관에 대해 동의 또는 거부의 의사표시를 하지 않으면 동의한 것으로 볼 수 있다는 내용도 함께 공지 또는 통지를 하며, 회원이 이 약관 시행일까지 거부의 의사표시를 하지 않는다면 개정약관에 동의한 것으로 볼 수 있습니다. 회원이 개정약관에 대해 동의하지 않는 경우 회사 또는 회원은 서비스 이용계약을 해지할 수 있습니다."
            }
          </Typography>
        </ContentItemZone>
        <ContentItemZone>
          <Typography variant={"body11"}>{"제4조 (약관 외 준칙)"}</Typography>
          <br />
          <Typography variant={"h16"}>
            {
              "이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 「약관의 규제에 관한 법률」, 「정보통신망이용촉진 및 정보보호 등에 관한 법률」등 관련 법령 또는 상 관례에 따릅니다."
            }
          </Typography>
        </ContentItemZone>
        <ContentItemZone>
          <Typography variant={"body11"}>{"제5조 (회원가입)"}</Typography>
          <br />
          <Typography variant={"h16"}>
            {
              " ① 이용자는 “APP”에서 정한 양식에 따라 회원정보를 기입한 후 이 약관의 내용에 대하여 동의하여 회원가입 신청을 하면, 회사가 이러한 신청을 승낙하여 회원으로 가입됩니다."
            }
            <br />
            {
              "② 회사는 원칙적으로 전 항에 따라 회원가입신청에 대하여 승낙함을 원칙으로 합니다. 다만, 회사는 다음 각 호의 어느 하나에 해당하는 이용자에 대해서는 회원가입을 거절하거나 사후에 회원자격을 상실시킬 수 있습니다."
            }
            <br />
            {"회원정보 내용을 허위로 기재하거나 타인의 명의를 도용한 경우"}
            <br />
            {
              "회사가 서비스를 제공하지 않은 국가에서 비정상적이거나 우회적인 방법을 통해 서비스를 이용하는 경우"
            }
            <br />
            {"사회의 안녕과 질서 또는 미풍양속을 저해할 목적으로 신청한 경우"}
            <br />
            {"부정한 용도로 서비스를 이용하고자 하는 경우"}
            <br />
            {"영리를 추구할 목적으로 서비스를 이용하고자 하는 경우"}
            <br />
            {
              "가입 신청자가 본 약관에 의거 이전에 회원자격을 상실한 적이 있는 경우"
            }
            <br />
            {"만 14세 미만인 경우"}
            <br />
            {
              "그 밖에 각 호에 준하는 사유로서 승낙이 부적절하다고 판단되는 경우"
            }
            <br />
            {
              "③ 회원은 가입시 등록한 회원정보의 변경이 발생한 경우, 즉시 “APP”에서 직접 수정 또는 전자우편, 기타 방법으로 회사에 그 변경 사실을 알려야 합니다."
            }
          </Typography>
        </ContentItemZone>
        <ContentItemZone>
          <Typography variant={"body11"}>
            {"제6조 (회원탈퇴 및 자격상실)"}
          </Typography>
          <br />
          <Typography variant={"h16"}>
            {
              " ① 회원은 언제든지 서비스 이용을 원하지 않는 경우 언제든지 탈퇴를 요청할 수 있으며, 이 경우 회사는 즉시 회원탈퇴를 처리합니다. 회원탈퇴로 인해 회원이 서비스 내에서 보유한 이용정보는 모두 삭제되어 복구가 불가능하게 됩니다."
            }
            <br />
            {
              "② 회사는 회원이 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 등 본 계약을 유지할 수 없는 중대한 사유가 있는 경우에는 회원에게 통지하고, 서비스 이용을 제한․중지하거나 회원 자격을 상실시킬 수 있습니다."
            }
            <br />
            {
              "③ 회사가 회원자격을 상실시키는 경우에는 회원 등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원 등록 말소 전에 소명할 기회를 부여합니다."
            }
            <br />
            {
              "④ 회사는 최근의 서비스 이용일부터 연속하여 1년 동안 회사의 서비스를 이용하지 않은 회원(이하 “휴면계정”이라 합니다)의 개인정보를 보호하기 위해 이용계약을 정지 또는 해지하고 회원의 개인정보를 분리보관 또는 파기 등의 조치를 취할 수 있습니다. 이 경우 조치일 30일 전까지 계약 정지 또는 해지, 개인정보 분리보관 또는 파기 등의 조치가 취해진다는 사실 및 파기될 개인정보 등을 회원에게 통지합니다."
            }
          </Typography>
        </ContentItemZone>
        <ContentItemZone>
          <Typography variant={"body11"}>
            {"제7조 (회원에 대한 통지)"}
          </Typography>
          <br />
          <Typography variant={"h16"}>
            {
              "① 회사가 회원에 대한 통지를 하는 경우, 회원이 회사에 제출한 전자우편 또는 휴대번호로 할 수 있다."
            }
            <br />
            {
              "② 회사는 불특정다수 회원에 대한 통지의 경우 30일 이상 “APP”에 게시함으로서 개별 통지에 갈음 할 수 있다."
            }
          </Typography>
        </ContentItemZone>
        <ContentItemZone>
          <Typography variant={"body11"}>{"제8조 (회사의 의무)"}</Typography>
          <br />
          <Typography variant={"h16"}>
            {
              " ① 회사는 관련 법령, 이 약관에서 정하는 권리의 행사 및 의무의 이행을 신의에 따라 성실하게 준수합니다."
            }
            <br />
            {
              "② 회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을 갖추어야 하며 개인정보처리방침을 공시하고 준수합니다. 회사는 이 약관 및 개인정보처리방침에서 정한 경우를 제외하고는 회원의 개인정보가 제3자에게 공개 또는 제공되지 않도록 합니다."
            }
            <br />
            {
              "③ 회사는 계속적이고 안정적인 서비스의 제공을 위하여 서비스 개선을 하던 중 설비에 장애가 생기거나 데이터 등이 멸실․훼손된 때에는 천재지변, 비상사태, 현재의 기술로는 해결이 불가능한 장애나 결함 등 부득이한 사유가 없는 한 지체 없이 이를 수리 또는 복구하도록 최선의 노력을 다합니다."
            }
          </Typography>
        </ContentItemZone>
        <ContentItemZone>
          <Typography variant={"body11"}>{"제9조 (회원의 의무)"}</Typography>
          <br />
          <Typography variant={"h16"}>
            {
              " ① 회원은 회사에서 제공하는 서비스의 이용과 관련하여 다음 각 호에 해당하는 행위를 해서는 안 됩니다."
            }
            <br />
            {
              "이용신청 또는 회원 정보 변경 시 타인의 명의를 도용하거나 허위사실을 기재하는 행위"
            }
            <br />
            {
              "회사의 직원이나 운영자를 가장하거나 타인의 명의를 도용하여 메일을 발송하는 행위, 타인으로 가장하거나 타인과의 관계를 허위로 명시하는 행위"
            }
            <br />
            {
              "다른 회원의 개인정보를 무단으로 수집⋅저장⋅게시 또는 유포하는 행위"
            }
            <br />
            {
              "서비스를 무단으로 영리, 영업, 광고, 홍보, 정치활동, 선거운동 등 본래의 용도 이외의 용도로 이용하는 행위"
            }
            <br />
            {
              "회사의 서비스를 이용하여 얻은 정보를 무단으로 복제․유통․조장하거나 상업적으로 이용하는 행위, 알려지거나 알려지지 않은 버그를 악용하여 서비스를 이용하는 행위"
            }
            <br />
            {
              "타인을 기망하여 이득을 취하는 행위, 회사의 서비스의 이용과 관련하여 타인에게 피해를 입히는 행위"
            }
            <br />
            {
              "회사나 타인의 지적재산권 또는 초상권을 침해하는 행위, 타인의 명예를 훼손하거나 손해를 가하는 행위"
            }
            <br />
            {
              "법령에 의하여 전송 또는 게시가 금지된 정보(컴퓨터 프로그램)나 컴퓨터 소프트웨어⋅하드웨어 또는 전기통신장비의 정상적인 작동을 방해⋅파괴할 목적으로 고안된 바이러스⋅컴퓨터 코드⋅파일⋅프로그램 등을 고의로 전송⋅게시⋅유포 또는 사용하는 행위"
            }
            <br />
            {
              "회사로부터 특별한 권리를 부여받지 않고 애플리케이션을 변경하거나, 애플리케이션에 다른 프로그램을 추가⋅삽입하거나, 서버를 해킹⋅역설계하거나, 소스 코드나 애플리케이션 데이터를 유출⋅변경하거나, 별도의 서버를 구축하거나, 웹사이트의 일부분을 임의로 변경⋅도용하여 회사를 사칭하는 행위 그 밖에 관련 법령에 위반되거나 선량한 풍속 기타 사회통념에 반하는 행위"
            }
            <br />
            {
              "② 회원의 ID와 비밀번호에 관한 관리책임은 회원에게 있으며, 이를 제3자가 이용하도록 하여서는 안됩니다."
            }
            <br />
            {
              "③ 이용자는 본 약관 및 관련법령에 규정한 사항을 준수하여야 합니다."
            }
          </Typography>
        </ContentItemZone>
        <ContentItemZone>
          <Typography variant={"body11"}>{"제10조 (서비스의 이용)"}</Typography>
          <br />
          <Typography variant={"h16"}>
            {
              "① “APP”은 다음과 같은 서비스를 회원에게 제공합니다. 단, 회사가“APP”으로 제공하는 서비스 이용을 위해 필요시 이용자에게 위치정보이용약관 및 “계정정보”의 제공에 관한 동의를 추가로 요구할 수 있으며, 동의하지 않을 경우 보험계약 조회 등 일부 서비스가 제한될 수 있습니다."
            }
            <br />
            {"보험계약 조회 및 분석정보 제공"}
            <br />
            {"내보험다보여(한국신용정보원) APP 가입 대행"}
            <br />
            {"이메일 수신 서비스"}
            <br />
            {"보험상품 관련 설계사 연결 및 보장 컨설팅"}
            <br />
            {"회사가 취급하는 보험상품의 설명 및 안내"}
            <br />
            {"시사, 금융, 투자, 부동산, 건강관리 등 컨텐츠 제공"}
            <br />
            {"보험금 청구 관련 서비스"}
            <br />
            {"기타 회사가 정하는 서비스"}
            <br />
            {
              "② 회사는 회원에게 별도의 동의를 받은 경우 서비스 이용에 대한 유용한 각종 정보에 대하여 “APP”에 게재하는 것 이외에 문자메시지, 푸시(Push) 알림 등의 방법으로 회원에게 제공할 수 있습니다."
            }
            <br />
            {
              "③ 서비스의 이용은 “APP”의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴를 원칙으로 합니다. 다만, 정기점검 등의 필요로 인하여 회사가 정한 날 및 시간에 대해서는 예외로 합니다."
            }
            <br />
            {
              "④ 회사는 “APP” 시스템 등의 보수, 점검, 교체, 시스템의 고장, 통신의 두절, 기타 불가항력적 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다. 다만, 서비스 중단의 경우 회사는 “APP”에 사전 통지하고, 사전에 통지할 수 없는 부득이한 사유가 있는 경우 제 조에 정한 방법으로 회원에게 통지합니다."
            }
          </Typography>
        </ContentItemZone>
        <ContentItemZone>
          <Typography variant={"body11"}>
            {"제11조 (개인정보의 보호 및 사용)"}
          </Typography>
          <br />
          <Typography variant={"h16"}>
            {
              "① 회사는 이용자의 정보 수집시 원활한 서비스 제공을 위해 필요한 최소한의 정보를 수집합니다."
            }
            <br />
            {
              "② 회사가 이용자의 개인식별이 가능한 개인정보 및 계정정보를 수집하는 때에는 반드시 당해 이용자의 동의를 받습니다."
            }
            <br />
            {
              "③ 회사는 관련 법령에 의하거나, 관련 국가기관 등의 요청이 있는 경우를 제외하고는 회원의 개인정보를 본인의 동의 없이 타인에게 제공하지 않습니다."
            }
            <br />
            {
              "④ 이용자는 언제든지 회사가 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정 또는 동의 철회를 요구할 수 있으며 회사는 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는 그 오류를 정정할 때까지 해당 개인정보를 이용하지 않습니다."
            }
            <br />
            {
              "⑤ 기타 개인정보관련사항은 “APP”에 별도로 게시하는 개인정보취급방침에 의거합니다."
            }
          </Typography>
        </ContentItemZone>
        <ContentItemZone>
          <Typography variant={"body11"}>{"제12조 (회사의 면책)"}</Typography>
          <br />
          <Typography variant={"h16"}>
            {
              "① 회사는 이 약관 제10조 제3항, 제4항의 사유로 서비스 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대해서는 관련법에 특별한 규정이 없는 한 회원에게 별도의 보상을 하지 않습니다."
            }
            <br />
            {
              "② 회사는 회원의 고의 또는 과실로 인한 서비스 이용의 장애에 대하여는 책임을 지지 않습니다."
            }
            <br />
            {
              "③ 서비스와 관련하여 게재한 정보나 자료 등의 신뢰성, 정확성 등에 대하여 회사는 고의 또는 중대한 과실이 없는 한 책임을 지지 않습니다."
            }
            <br />
            {
              "④ 회사는 무료로 제공되는 서비스 이용과 관련하여 회원에게 발생한 손해에 대해서는 책임을 지지 않습니다."
            }
            <br />
            {
              "⑤ 회사는 회원이 서비스를 이용하여 기대하는 이익을 얻지 못하거나 상실한 것에 대하여 책임을 지지 않습니다."
            }
            <br />
            {
              "⑥ 회사는 회원이 “APP”아이디 및 비밀번호, 모바일 기기 비밀번호, 계정정보 등을 관리하지 않아 발생하는 손해에 대해 책임을 지지 않습니다."
            }
            <br />
            {
              "⑦ 회원이 모바일 기기의 변경, 모바일 기기의 번호 변경, 운영체제(OS) 버전의 변경, 해외 로밍, 통신사 변경 등으로 인해 콘텐츠 전부나 일부의 기능을 이용할 수 없는 경우 회사는 이에 대해 책임을 지지 않습니다."
            }
            <br />
            {
              "⑧ 회원이 회사가 제공하는 콘텐츠나 계정정보를 삭제한 경우 회사는 이에 대해 책임을 지지 않습니다."
            }
          </Typography>
        </ContentItemZone>
        <ContentItemZone>
          <Typography variant={"body11"}>
            {"제13조 (저작권 등의 귀속)"}
          </Typography>
          <br />
          <Typography variant={"h16"}>
            {
              "① 회사가 제작한 서비스 내의 콘텐츠에 대한 저작권과 기타 지적재산권은 회사에 귀속합니다."
            }
            <br />
            {
              "② 회원은 회사가 제공하는 서비스를 이용하여 얻은 정보 중에서 회사 또는 제공업체에 지적재산권이 귀속된 정보를 회사 또는 제공업체의 사전 동의 없이 복제⋅전송 등의 방법(편집, 공표, 공연, 배포, 방송, 2차적 저작물 작성 등을 포함합니다. 이하 같습니다)에 의하여 영리목적으로 이용하거나 타인에게 이용하게 하여서는 안 됩니다."
            }
            <br />
            {
              "③ 회원의 게시물이 정보통신망법 및 저작권법 등 관련법에 위반되는 내용을 포함하는 경우, 권리자는 관련법이 정한 절차에 따라 해당 게시물의 게시중단 및 삭제 등을 요청할 수 있으며, 이로 인해 발생하는 민·형사상의 책임은 전적으로 해당 회원 본인이 부담하여야 하며, 회사는 관련법에 따라 조치를 취하여야 합니다"
            }
            <br />
            {
              "④ 회사는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가 있거나 기타 회사 정책 및 관련법에 위반되는 경우에는 관련법에 따라 해당 게시물에 대해 임시조치 등을 취할 수 있습니다."
            }
            <br />
            {
              "⑤ 이 조는 회사가 서비스를 운영하는 동안 유효하며, 회원 탈퇴 후에도 지속적으로 적용됩니다."
            }
          </Typography>
        </ContentItemZone>
        <ContentItemZone>
          <Typography variant={"body11"}>
            {"제14조 (회원의 고충처리 및 분쟁해결)"}
          </Typography>
          <br />
          <Typography variant={"h16"}>
            {
              "① 회사는 회원의 편의를 고려하여 회원의 의견이나 불만을 제시하는 방법을 서비스 내 또는 그 연결화면에 안내합니다."
            }
            <br />
            {
              "② 회사는 회원으로부터 제기되는 의견이나 불만이 정당하다고 객관적으로 인정될 경우에는 합리적인 기간 내에 이를 신속하게 처리합니다. 다만, 처리에 장기간이 소요되는 경우에는 회원에게 장기간이 소요되는 사유와 처리일정을 서비스 내 공지하거나 제8조에 따라 통지합니다."
            }
          </Typography>
        </ContentItemZone>
        <ContentItemZone>
          <Typography variant={"body11"}>
            {"제15조 (재판권 및 준거법)"}
          </Typography>
          <br />
          <Typography variant={"h16"}>
            {
              " 이 약관은 대한민국 법률에 따라 규율되고 해석됩니다. 회사와 회원 간에 발생한 분쟁으로 소송이 제기되는 경우에는 법령에 정한 절차에 따른 법원을 관할 법원으로 합니다."
            }
          </Typography>
        </ContentItemZone>
      </ContentZone>
    </TermOfUseContainer>
  );
};
export const CenterContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justiftContent: "center"
}))

export const MobileTermOfUseContainer = styled("div")(({ theme }) => ({
  height: "100%",
  paddingTop: 30,
  paddingLeft: 30,
  paddingRight: 30,
  marginLeft: "auto",
  marginRight: "auto",
  // marginLeft: "14.35%",
  // marginRight: "14.35%",
}));
export const TermOfUseContainer = styled("div")(({ theme }) => ({
  height: "100%",
  marginTop: 150,
  marginLeft: "14.35%",
  marginRight: "14.35%",
}));
const TitleZone = styled("div")(({ theme }) => ({
  marginTop: 85,
  marginBottom: 85,
  [theme.breakpoints.down("sm")]: {
    marginBottom: 35,
  },
}));

const ContentZone = styled("div")(({ theme }) => ({
  marginBottom: 85,
}));

const ContentItemZone = styled("div")(({ theme }) => ({
  marginBottom: 20,
}));

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useHistory } from 'react-router';

import useAlertModal from '../hooks/useAlertModal';
import { useStoreAction, useStoreValue } from '../contexts';

type GuardForMarketRegisterProps = {
  children: any;
};

const GuardForMarketRegister = ({ children }: GuardForMarketRegisterProps) => {
  const { naverToken, shopbyToken } = useStoreValue();
  const enteredByMarketName = localStorage.getItem('enteredByMarketName') || '';
  const mallId = localStorage.getItem('mallId') || '';
  const { setisModuleMatch } = useStoreAction();
  const history = useHistory();
  const requestAlertModal = useAlertModal();

  useEffect(() => {
    if (naverToken === '' && enteredByMarketName === 'naver') {
      history.push('/services');
      setisModuleMatch(false);
      localStorage.removeItem('enteredByMarketName');
      requestAlertModal({
        title: '',
        description: '커머스 솔루션 마켓을 통해 셀러박스 가입을 진행해 주세요.',
        action: () => {
          window.open(
            'https://solution.smartstore.naver.com/ko/solution/10XxRcUYSy4N7fiZ5BYNMh/detail?accountIds=%5B%22ncp_1oe28g_01%22%5D#open_add-modal',
            '_blank'
          );
        },
      });
      return;
    }

    if (shopbyToken === '' && enteredByMarketName === 'shopby') {
      setisModuleMatch(false);
      requestAlertModal({
        title: '',
        description: 'NHN 커머스 앱 스토어를 통해 셀러박스 가입을 진행해 주세요.',
        action: () => {
          window.open(
            'https://apps.nhn-commerce.com/apps?_gl=1*cf8nq2*_ga*MTg3NDg0NzM5NC4xNzAxNzQyOTY5*_ga_Z1WW9QB68M*MTcwMTg0ODQ0NC40LjEuMTcwMTg0ODUwOC42MC4wLjA.',
            '_blank'
          );
        },
      });
      localStorage.removeItem('enteredByMarketName');
      history.push('/services');
      return;
    }

    if (mallId === '' && enteredByMarketName === 'cafe24') {
      setisModuleMatch(false);
      requestAlertModal({
        title: '',
        description: 'cafe24 앱 스토어를 통해 셀러박스 가입을 진행해 주세요.',
        action: () => {
          window.open('https://store.cafe24.com/kr/apps/13737', '_blank');
        },
      });
      localStorage.removeItem('enteredByMarketName');
      history.push('/services');
      return;
    }

    // TODO 추후 naverRegisterStart 경로가 삭제되면 제거
    if (shopbyToken === '' && naverToken === '' && mallId === '' && enteredByMarketName === '') {
      localStorage.removeItem('enteredByMarketName');
      history.push('/services');
      return;
    }
  }, [shopbyToken, naverToken]);

  return children;
};

export default GuardForMarketRegister;

import styled from 'styled-components';
import { colors } from '../../../../style';
import font from '../../../../style/font';
import { selectedType } from './MobileMainContainer';
import { motion } from 'framer-motion/dist/framer-motion';

export const Container = styled.div({
  paddingLeft: 20,
  paddingRight: 20,
});
export const Title = styled.div({
  fontSize: 24,
  fontWeight: 800,
  lineHeight: '31.2px',
  color: colors.gray.GR900,
  marginBottom: 8,
});
export const Desc = styled.div({
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '22.4px',
  color: colors.gray.GR800,
  marginBottom: 24,
});
export const TabContainer = styled.div({
  display: 'flex',
  gap: '20px',
  overflowX: 'auto',
  whiteSpace: 'nowrap',
  touchAction: 'pan-x',
  paddingBottom: 8,
  borderBottom: '1px solid',
  borderBottomColor: '#F0EDFF',
  position: 'relative',
});

export const Tab = styled.div<{
  isActived: boolean;
  type: selectedType;
}>(
  {
    fontSize: 14,
    lineHeight: '22px',
    whiteSpace: 'nowrap',
    width: 'max-content',
    touchAction: 'pan-x',
  },
  ({ isActived, type }) => ({
    fontWeight: isActived ? 600 : 500,
    color: isActived
      ? type === 'Sellerbox'
        ? colors.orange.OR500
        : colors.purple.PU500
      : colors.gray.GR750,
  })
);

export const Indicator = styled.div<{ leftPosition: number; width: number; type: selectedType }>(
  ({ leftPosition, width, type }) => ({
    position: 'absolute',
    top: 28,
    width: `${width}px`, // Use dynamic width
    height: '2px',
    backgroundColor: type === 'Sellerbox' ? colors.orange.OR500 : colors.purple.PU500,
    transition: 'left 0.3s ease, width 0.3s ease', // Smooth transition for both position and width
    left: `${leftPosition}px`,
  })
);

export const FunctionImageContainer = styled.div({
  width: '100%',
});

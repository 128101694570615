import { Pagination, styled } from "@mui/material";

export const MyPagination = styled(Pagination)(({ theme }) => ({
  "&.MuiTablePagination-root": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },

  "& .MuiInputBase-root": {
    display: "none",
  },

  "& .MuiTablePagination-selectLabel": {
    display: "none",
  },

  "& .MuiTablePagination-displayedRows": {
    display: "none",
  },

  "& .MuiTablePagination-toolbar": {
    minHeight: 50,
  },

  "& .MuiTablePagination-caption": {
    display: "none",
  },
  "& .MuiTablePagination-action": {
    // position: "absolute",
    // left: "50%",
  },
}));

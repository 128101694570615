import React, { useState } from 'react';
import { IMenuBarButtonProps, StyledPCButton } from './WebMenuBar';
import { MenuType, registeredMenuItems } from './const';
import { ReactComponent as SellerLogo } from '../../../assets/images/SellerLogo.svg';
import styled from 'styled-components';
import { ReactComponent as MenuIcon } from '../../../assets/icons/MenuIcon.svg';
import { ReactComponent as CloseIconSmall } from '../../../assets/icons/CloseIconSmall.svg';

import { useLocation } from 'react-router';
import { sellkeyUrl } from '../../../constants/urls';
import { colors } from '../../../style';
import font from '../../../style/font';

const MobileMenuBar: React.FC<IMenuBarButtonProps> = ({ isDarkMode, goUrl }) => {
  const { pathname } = useLocation();
  const handleClickUrl = (item: MenuType) => () => {
    if (item.isRedirection) {
      window.open(item.url);
    } else {
      window.scrollTo({
        top: 0,
      });
      goUrl(item.url)();
      setIsOpen(false);
    }
  };
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <Container>
        <SellerLogo
          width={130}
          height={22}
          onClick={() => {
            goUrl('/services')();
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          }}
        />
        <MenuIcon onClick={() => setIsOpen(true)} />
      </Container>
      <Overlay isOpen={isOpen} onClick={() => setIsOpen(false)} />
      <MenuWrapper isOpen={isOpen}>
        <CloseIconSmall
          onClick={() => setIsOpen(false)}
          style={{
            alignSelf: 'flex-end',
          }}
        />

        <MenuContent>
          <StyledPCButton onClick={() => window.open('https://app.sellerbox.io?tab=phone')}>
            PC 연동
          </StyledPCButton>
          <ItemContainer>
            {registeredMenuItems.map((item, idx) => (
              <MenuItem
                key={idx}
                onClick={handleClickUrl(item)}
                clicked={pathname.indexOf(item.url) !== -1}
                url={item.url}
              >
                {item.label}
              </MenuItem>
            ))}
          </ItemContainer>
        </MenuContent>
      </MenuWrapper>
    </>
  );
};

export default MobileMenuBar;

const Container = styled.div({
  padding: 20,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const Overlay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: opacity 0.3s ease;
  pointer-events: ${({ isOpen }) => (isOpen ? 'all' : 'none')};
`;

const MenuWrapper = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  width: 80%;
  height: 100vh;
  background: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  z-index: 100;
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(100%)')};
  transition: transform 0.3s ease; /* CSS transition 추가 */
`;

const MenuContent = styled.div({
  marginTop: 36,
});

const ItemContainer = styled.div({
  marginTop: 20,
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
});

const MenuItem = styled.div<{ clicked: boolean; url: string }>`
  color: ${({ clicked, url }) =>
    clicked ? (url === sellkeyUrl ? colors.purple.PU500 : colors.orange.OR500) : colors.gray.Black};
  ${font.Body1S16};
`;

import { ListItemButton, Collapse, ListItemButtonProps } from "@mui/material";
import * as React from "react";
import { ListItem, styled, Typography } from "@mui/material";
import { format } from "date-fns";

import { ReactComponent as DownArrow } from "../assets/icons/DownArrow.svg";
import { ReactComponent as HoverDownArrow } from "../assets/icons/HoverDownArrow.svg";
import { useMediaQueryForMobile } from "../hooks/useMediaQueryForMobile";
import { QTypography } from "./FAQItem";

export type NoticeItemType = {
  title: string;
  date: Date;
  contents: string;
  // openIndex: number;
  // setOpenIndex: any;
  // index: number;
};
export interface INoticeItemProps extends Omit<ListItemButtonProps, "title"> {
  title: React.ReactNode;
  date: Date;
  contents: React.ReactNode;
  openIndex: number;
  setOpenIndex: any;
  index: number;
}
export const NoticeItem: React.FC<INoticeItemProps> = ({
  title,
  date,
  contents,
  style,
  openIndex,
  setOpenIndex,
  index
  
}) => {
  const [open, setOpen] = React.useState<boolean>(false);

  React.useEffect(()=>{
    if(index!==openIndex){
      setOpen(false);
    }
  },[openIndex]);

  const onClickItem = () => {
    if(open){
      setOpenIndex(-1);
    }else{
      setOpenIndex(index);
    }
    setOpen(!open);
  };

  const [hover, setHover] = React.useState<boolean>(false);

  const isMobile = useMediaQueryForMobile();
  return (
    <>
      <div
        onMouseOver={(e) => {
          setHover(true);
        }}
        onMouseLeave={(e) => {
          setHover(false);
        }}
      >
        <StyledListItemButton isMobile={isMobile} style={style} onClick={onClickItem} className={isMobile ? "" : "hoverScale"} >
          <TitleWrapperDiv>
            <div 
              style={{
                fontSize: isMobile ? 16 : 20,
                fontWeight: 600,
                lineHeight: isMobile ? "24px" : "28px",
                color: "#3D3D3D"
              }} 
            >
              {title}
            </div>   
          </TitleWrapperDiv>
        </StyledListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <DescriptionWrapperDiv>
            <div style={{
              fontSize: isMobile ? 14 : 18,
              fontWeight: isMobile ? 500 : 600,
              color: "#6F6F6F",
              lineHeight: isMobile ? "22px" : "160%"
            }} >{contents}</div>
          </DescriptionWrapperDiv>
        </Collapse>
      </div>
    </>
  );
};

const StyledListItemButton = styled(ListItemButton)(({ theme,isMobile }) => ({
  display: "flex",
  alignItems: "center",
  padding: isMobile ? "12px 0px" : "24px 0px"
}));

const TitleWrapperDiv = styled("div")(({ theme }) => ({
  flex: 1,
}));

const DateWrapperDiv = styled("div")(({ theme }) => ({
  width: 148,
  [theme.breakpoints.down("sm")]: {
    width: 78,
    marginLeft: 10,
  },
}));

const DateTypography = styled(Typography)(({ theme }) => ({
  color: "#A4A4A4",
}));

const StyledDownArrow = styled(DownArrow)<{ open: boolean }>(
  ({ theme, open }) => ({
    transform: open ? "rotate(180deg)" : "rotate(0deg)",
    transition: "transform 200ms ease-in-out",
  })
);

export const StyledHoverArrow = styled(HoverDownArrow)<{ open: boolean }>(
  ({ theme, open }) => ({
    transform: open ? "rotate(180deg)" : "rotate(0deg)",
    transition: "transform 200ms ease-in-out",
  })
);
const DescriptionWrapperDiv = styled("div")(({ theme }) => ({
  padding: 20,
  backgroundColor: "#FBFBFB",
  [theme.breakpoints.down("sm")]: {
    padding: 10,
    marginBottom: 12
  },
}));

import React from 'react';
import MainContainer from '../SellerboxAppPage/_components/Web/MainContainer';
import FunctionContainer from '../SellerboxAppPage/_components/Web/FunctionContainer';
import SellkeyDeco01 from '../../assets/images/V2.1/SellkeyDeco01.png';
import SellkeyDeco02 from '../../assets/images/V2.1/SellkeyDeco02.png';
import { AnimationDiv } from '../SellerboxAppPage/SellerboxAppPage';
import {
  Container,
  ContentContainer,
  EventContainer,
  TextContainer,
  MainText,
  SubTextContainer,
  SubText,
  MobileEventContainer,
  MobileContentContainer,
  EventTextContainer,
  EventTitle,
  EventDesc,
} from './_components/SellkeyPageStyle';
import { useMediaQueryForMobile } from '../../hooks/useMediaQueryForMobile';
import MobileMainContainer from '../SellerboxAppPage/_components/Mobile/MobileMainContainer';
import { MobileContentGrayWrapperWithNoPadding } from '../SellerboxAppPageV1';
import MobileFunctionContainer from '../SellerboxAppPage/_components/Mobile/MobileFunctionContainer';

const SellkeyPage = () => {
  const isMobile = useMediaQueryForMobile();
  return (
    <>
      {isMobile ? (
        <Container>
          <MobileMainContainer selected={'Sellkey'} />
          <MobileContentContainer>
            {/* 이벤트 TODO: 임시 주석처리 */}
            {/* <MobileEventContainer>
              <EventTextContainer>
                <EventTitle>신규 셀러를 위한 이벤트</EventTitle>
                <EventDesc>지금 셀러키워드 멤버십을 이용하시면 50% 할인 혜택을 드려요</EventDesc>
                <div style={{ height: 8 }}></div>
                <EventDesc>할인 쿠폰 코드 : 2024welcome</EventDesc>
              </EventTextContainer>
              <img src={SellkeyDeco02} width={81} height={50} />
            </MobileEventContainer> */}
            {/* 제공하는 기능 */}
            <MobileFunctionContainer selected={'Sellkey'} />
          </MobileContentContainer>
          <MobileContentGrayWrapperWithNoPadding style={{ padding: '0px 30px' }}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative',
              }}
            >
              <div style={{ height: 100 }} />

              <div style={{ width: '100%' }}>
                <div
                  style={{ fontSize: 20, fontWeight: 700, lineHeight: '28px', color: '#1A1A1A' }}
                >
                  믿을 수 있는 스타트업 <br />
                  <span style={{ color: '#FF7F00' }}>서클플랫폼</span>에서 만들었어요
                </div>
                <div style={{ height: 12 }} />
                <div
                  style={{ fontSize: 16, fontWeight: 500, lineHeight: '24px', color: '#3D3D3D' }}
                >
                  모든 데이터는 암호화하여 안전하게 관리합니다
                </div>
                <div style={{ height: 40 }} />
                <AnimationDiv>
                  <img src="images/mobilePartnerListImage1.png" style={{ width: '100%' }} />
                </AnimationDiv>
                <div style={{ height: 12 }} />
                <AnimationDiv>
                  <img src="images/mobilePartnerListImage2.png" style={{ width: '100%' }} />
                </AnimationDiv>
                <div style={{ height: 12 }} />
                <AnimationDiv>
                  <img src="images/mobilePartnerListImage3.png" style={{ width: '100%' }} />
                </AnimationDiv>
                <div style={{ height: 12 }} />
                <AnimationDiv>
                  <img src="images/mobilePartnerListImage4.png" style={{ width: '100%' }} />
                </AnimationDiv>
              </div>
              <div style={{ height: 100 }} />
            </div>
          </MobileContentGrayWrapperWithNoPadding>
        </Container>
      ) : (
        <Container>
          <MainContainer selected={'Sellkey'} />
          <ContentContainer>
            {/* TODO: 임시 주석처리
            <EventContainer> 
              <TextContainer>
                <MainText>신규 셀러를 위한 이벤트</MainText>
                <SubTextContainer>
                  <SubText>지금 셀러키워드 멤버십을 이용하시면 50% 할인 혜택을 드려요</SubText>
                  <SubText>할인 쿠폰 코드 : 2024welcome</SubText>
                </SubTextContainer>
              </TextContainer>
              <img src={SellkeyDeco01} width={451} height={180} />
            </EventContainer> */}
            <FunctionContainer selected={'Sellkey'} />
            <div
              style={{
                width: '100%',
                height: 968,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative',
              }}
            >
              <div
                style={{
                  width: 240,
                  height: 240,
                  borderRadius: '50%',
                  backgroundColor: '#FF9C66',
                  position: 'absolute',
                  left: -43,
                  top: 87,
                }}
              />
              <div
                style={{
                  width: 322,
                  height: 322,
                  borderRadius: '50%',
                  backgroundColor: 'rgba(255, 255, 255, 0.20)',
                  position: 'absolute',
                  left: 53,
                  top: 267,
                }}
                className="backdropFilter15"
              />
              <div
                style={{
                  width: 416,
                  height: 198,
                  borderRadius: 57,
                  backgroundColor: '#FFBB7C',
                  position: 'absolute',
                  right: -213,
                  bottom: 309,
                }}
              />
              <div
                style={{
                  width: 322,
                  height: 322,
                  borderRadius: '50%',
                  backgroundColor: 'rgba(255, 255, 255, 0.20)',
                  position: 'absolute',
                  right: 53,
                  top: 309,
                }}
                className="backdropFilter15"
              />

              <div style={{ height: 190 }} />

              <div style={{ width: 1290, position: 'relative', zIndex: 1 }}>
                <div
                  style={{ fontSize: 44, fontWeight: 880, lineHeight: '130%', color: '#1A1A1A' }}
                >
                  믿을 수 있는 스타트업 <span style={{ color: '#FF7F00' }}>서클플랫폼</span>에서
                  만들었어요
                </div>
                <div style={{ height: 24 }} />
                <div
                  style={{ fontSize: 22, fontWeight: 500, lineHeight: '160%', color: '#3D3D3D' }}
                >
                  모든 데이터는 암호화하여 안전하게 관리합니다
                </div>
                <div style={{ height: 48 }} />
                <AnimationDiv>
                  <img src="images/pcPartnerListImage1.png" style={{ width: '100%' }} alt="" />
                </AnimationDiv>
                <div style={{ height: 30 }} />
                <AnimationDiv>
                  <img src="images/pcPartnerListImage2.png" style={{ width: '100%' }} alt="" />
                </AnimationDiv>
              </div>
              <div style={{ height: 200 }} />
            </div>
          </ContentContainer>
        </Container>
      )}
    </>
  );
};

export default SellkeyPage;

/* eslint-disable no-useless-escape */
import React, { Fragment, useEffect, useState } from 'react';
import { WholePageWrapper } from '../SellerboxAppPageV1';
import { Button } from '@mui/material';
import {
  SetCafe24CommerceCode,
  SetNaverCommerceToken,
  SetShopbyCommerceCode,
  UserSendAuthenticationCode,
  UserSignInByPhoneNo,
  UserVerifyAuthenticationCode,
} from '../../controller/api';
import useAlertModal from '../../hooks/useAlertModal';
import { useHistory, useLocation } from 'react-router';
import useWindowSize from '../../hooks/useWindowSize';
import font from '../../style/font';
import { colors } from '../../style';
import { useStoreAction, useStoreValue } from '../../contexts';
import { marketFinishUrl, smartStoreGuideUrl } from '../../constants/urls';

const SignIn = () => {
  const { naverToken, shopbyToken } = useStoreValue();
  const { setUserInfo } = useStoreAction();
  const history = useHistory();
  const dimensions = useWindowSize();
  const requestAlertModal = useAlertModal();
  const [phoneNumber, setPhoneNumber] = useState('');
  const location = useLocation();

  const [phoneNumberValiation, setPhoneNumberValiation] = useState(false);
  const [phoneNumberValidMessage, setPhoneNumberValidMessage] = useState('');

  const [authCodeGenerateLoading, setAuthCodeGenerateLoading] = useState(false);
  const [smsToken, setSmsToken] = useState('');
  const [authCode, setAuthCode] = useState('');

  const [authCodeValiation, setAuthCodeValiation] = useState(false);
  const [authCodeValidMessage, setAuthCodeValidMessage] = useState('');

  const [isAuthLoading, setIsAuthLoading] = useState(false);

  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [timerCount, setTimerCount] = useState(-1);

  const authCodeGenerateButtonDisabledFlag =
    !phoneNumberValiation || authCodeGenerateLoading || timerCount > 175;
  const authCodeSendButtonDisabledFlag = authCode.length !== 6 || isAuthLoading || timerCount <= 0;
  const authCodeGenerateButtonText = timer ? '재전송' : '인증하기';

  const autoZero = (value, length = 1) => {
    //length에 맞춰서 value문자열 앞에 0을 추가해서 length를 조정하는 함수
    let tmp = value + '';
    if (tmp.length < length) {
      for (let i = 0; i < length - tmp.length; i++) {
        tmp = '0' + tmp;
      }
    }
    return tmp;
  };

  const enteredByMarketName = localStorage.getItem('enteredByMarketName') || '';
  // cafe24 전용
  const mallId = localStorage.getItem('mallId') || '';

  useEffect(() => {
    //폰 번호 길이와 폰번호 validationMessage를 사용하여 입력된 폰 번호로 정확성 flag인  phoneNumberValiation 수정
    setPhoneNumberValiation(phoneNumber.length === 13 && phoneNumberValidMessage === '');
  }, [phoneNumber, phoneNumberValidMessage]);

  useEffect(() => {
    if (timerCount === 0) {
      setAuthCodeValiation(false);
      setAuthCodeValidMessage('입력시간이 초과되었습니다');
      setAuthCode('');
    }
  }, [timerCount]);

  const onChangePhoneNumber = (text) => {
    //휴대폰 번호 변경시 올바른 폰 번호인지 확인하는 함수 (phone번호를 입력하는 input태그에 onChange로 들어감)
    if (text.length >= 3) {
      if (['010', '011', '016', '017', '019'].indexOf(text.substr(0, 3)) === -1) {
        setPhoneNumberValidMessage(`올바른 휴대폰 번호를 입력해주세요.`);
      } else {
        setPhoneNumberValidMessage('');
      }
    } else {
      setPhoneNumberValidMessage('');
    }

    if (
      (/[0-9,\-]/.test(text[text.length - 1]) === false &&
        !(text === 0 && phoneNumber.length === 1)) ||
      text.length >= 14
    ) {
      return;
    } else {
      let rawText = text.replace(/-/gi, '');
      let rawPhone = phoneNumber.replace(/-/gi, '');
      if (rawPhone.length < rawText.length) {
        setPhoneNumber(
          rawText
            .split('')
            .map((d, di) => ([2, 6].indexOf(di) !== -1 ? d + '-' : d))
            .join('')
        );
      } else {
        setPhoneNumber(text);
      }
    }
  };

  const getAuthCode = () => {
    if (phoneNumberValiation) {
      setAuthCodeGenerateLoading(true);
      //인증코드 요청
      UserSendAuthenticationCode({
        PhoneNo: phoneNumber.replace(/-/gi, ''),
      })
        .then((res) => {
          const { data } = res.data;
          //인증 코드와 인증을 시도한 휴대폰 번호가 암호화 되어서 들어가 있는 JWT토큰을 smsToken으로 저장합니다. (서버쪽에서 검증할때 필요한 데이터)
          setSmsToken(data[0].SmsToken);
          setAuthCode('');
          //타이머 초기화
          clearInterval(timer);
          setAuthCodeValiation(true);
          setAuthCodeValidMessage('');
          setTimerCount(180);
          let newTimer = setInterval(() => {
            setTimerCount((timerCount) => (timerCount > 0 ? timerCount - 1 : timerCount));
          }, 1000);
          setTimer(newTimer);
        })
        .finally(() => {
          setAuthCodeGenerateLoading(false);
        });
    }
  };

  const onChangeAuthCode = (text) => {
    //인증코드 입력 함수
    if (text.length <= 6) setAuthCode(text);
  };

  const auth = () => {
    //getAuthCode 함수로 얻은 smsToken토큰과 사용자가 입력한 smsCode를 사용하여 최종 인증을 진행
    if (isAuthLoading) return;
    setIsAuthLoading(true);
    UserVerifyAuthenticationCode({
      SmsToken: smsToken,
      SmsCode: authCode,
    })
      .then((res) => {
        const { data, code } = res.data;
        let verificationToken;
        if (code === '200') {
          //verificationToken => 휴대폰 인증이 끝난걸 증명하는 토큰 (smsToken과 유사하며 내부 데이터는 모두 암호화 되어있고 phoneNumber 데이터를 포함)
          verificationToken = data[0].VerificationToken;
          UserSignInByPhoneNo({
            verificationToken: verificationToken,
            OsType: 'web',
            CurrentVersion: 'naver-commerce-solution-market',
          })
            .then((res) => {
              const { data, message, code } = res.data;
              if (message === 'OK' && code === '200') {
                //로그인 성공 (기존 유저)
                const userName = data[0].StoreName;
                setUserInfo(data[0]);
                // TODO 여기서 API 호출 분기..
                // naver는 두번 리다이렉션 하기 때문에 상태관리로 값을 저장 못한다. TODO 로컬스토리지 사용해야 할 듯
                if (enteredByMarketName === 'naver' || enteredByMarketName === '') {
                  SetNaverCommerceToken({
                    CplatToken: data[0].CplatToken,
                    NaverCommerceToken: naverToken,
                  })
                    .then((res) => {
                      const { code, message } = res.data;
                      if (code === '200') {
                        setIsAuthLoading(false);
                        //셀러박스 기 가입자 구독 완료 페이지로 전환 (메니저 초대 방식등 설명이 있는 페이지)
                        history.push(`${smartStoreGuideUrl}?userName=${userName}`);
                      } else {
                        setIsAuthLoading(false);
                        requestAlertModal({
                          title: '',
                          description: message,
                        });
                      }
                    })
                    .catch((err) => {
                      setIsAuthLoading(false);
                      requestAlertModal({
                        title: '',
                        description: '서버와 통신이 원활하지 않습니다.\n다시 시도해 주세요.',
                      });
                    });
                }

                if (enteredByMarketName === 'shopby') {
                  SetShopbyCommerceCode({
                    cplatToken: data[0].CplatToken,
                    shopbyAuthorizationCode: shopbyToken,
                  })
                    .then((res) => {
                      const { code, message } = res.data;
                      history.push(marketFinishUrl);
                      // if (code === '200') {
                      //   setIsAuthLoading(false);
                      //   //셀러박스 기 가입자 구독 완료 페이지로 전환 (메니저 초대 방식등 설명이 있는 페이지)
                      //   history.push(marketFinishUrl);
                      // } else {
                      //   setIsAuthLoading(false);
                      //   requestAlertModal({
                      //     title: 'error',
                      //     description: message,
                      //   });
                      // }
                    })
                    .catch((err) => {
                      setIsAuthLoading(false);
                      requestAlertModal({
                        title: '',
                        description: '서버와 통신이 원활하지 않습니다.\n다시 시도해 주세요.',
                      });
                    });
                }

                if (enteredByMarketName === 'cafe24') {
                  const queryString = new URLSearchParams(location.search);
                  const code = queryString.get('code') || '';
                  SetCafe24CommerceCode({
                    cplatToken: data[0].CplatToken,
                    authorizationCode: code,
                    mallId,
                  }).then((res) => {
                    const { code, message } = res.data;

                    if (code === '200') history.push(marketFinishUrl);
                    else
                      requestAlertModal({
                        title: '',
                        description: message,
                      });
                  });
                }
              } else {
                //로그인 실패 (신규 유저)
                setIsAuthLoading(false);
                //셀러박스 미 가입자 약관동의 페이지 전환 (약관 동의 페이지에서 동의 이후에 verificationToken을 사용하여 회원가입을 진행)
                //verificationToken은 회원가입때 보내면 되는 토큰
                history.push(`registerTerms?verificationToken=${verificationToken}`);
              }
            })
            .catch(() => {
              requestAlertModal({
                title: '',
                description: '서버와 통신이 원활하지 않습니다.\n다시 시도해 주세요.',
              });
            });
        } else {
          setAuthCodeValiation(false);
          setAuthCodeValidMessage('인증번호가 일치하지 않습니다. 다시 확인해주세요.');
          setIsAuthLoading(false);
        }
      })
      .catch(() => {
        setIsAuthLoading(false);
        requestAlertModal({
          title: '',
          description: '서버와 통신이 원활하지 않습니다.\n다시 시도해 주세요.',
        });
      });
  };

  return (
    <WholePageWrapper style={{ justifyContent: 'flex-start', boxSizing: 'border-box' }}>
      {
        <div
          style={{
            backgroundColor: colors.gray.GR100,
            width: dimensions.width,
            height: dimensions.hieght,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              backgroundColor: '#FFFFFF',
              boxSizing: 'border-box',
              padding: '0px 20px',
              paddingTop: 'calc( 100vh * 0.1 )',
              maxWidth: '600px',
              height: '100%',
              width: '100%',
            }}
          >
            <div
              style={{
                ...font.Title2B20,
                color: colors.gray.GR800,
                marginBottom: 12,
              }}
            >
              휴대폰 번호로 시작하기
            </div>

            <div
              style={{
                ...font.Body2M16,
                color: colors.gray.GR750,
                marginBottom: 32,
              }}
            >
              연락처로 가입 및 로그인을 진행합니다.
              <br />
              셀러님의 번호는 암호화 되어 안전하게 보관됩니다.
            </div>

            <div style={{ display: 'flex', gap: 10 }}>
              <input
                type="tel"
                value={phoneNumber}
                onChange={(event) => {
                  onChangePhoneNumber(event.target.value);
                }}
                placeholder="휴대폰 번호 입력"
                className="registerPhoneNumberInput"
                style={{
                  ...font.Body1S16,
                  color: colors.gray.GR900,
                  border: 'none',
                  borderBottom: `1px solid ${
                    phoneNumberValidMessage !== '' ? colors.red.Red600 : colors.gray.GR200
                  }`,
                  borderRadius: 0,
                  paddingBottom: 12,
                  width: '100%',
                }}
              />
              {/* //signin signup 두개 모두 token 넣는게 필요함 */}
              <Button
                onClick={getAuthCode}
                disabled={authCodeGenerateButtonDisabledFlag}
                style={{
                  backgroundColor: authCodeGenerateButtonDisabledFlag
                    ? colors.gray.GR200
                    : colors.orange.OR100,
                  color: authCodeGenerateButtonDisabledFlag
                    ? colors.gray.White
                    : colors.orange.OR500,
                  borderRadius: 8,
                  minWidth: 85,
                  width: 85,
                  boxSizing: 'border-box',
                  padding: '8px 18px',
                }}
              >
                {authCodeGenerateButtonText}
              </Button>
            </div>
            <div
              style={{
                color: colors.red.Red600,
                ...font.Body6M14,
                marginBottom: 20,
              }}
            >
              {phoneNumberValidMessage}
            </div>
            {smsToken !== '' && (
              <Fragment>
                <div style={{ display: 'flex', gap: 10, position: 'relative' }}>
                  <input
                    type="number"
                    value={authCode}
                    onChange={(event) => {
                      onChangeAuthCode(event.target.value);
                    }}
                    placeholder="인증번호 6자리"
                    className="registerPhoneNumberInput"
                    style={{
                      ...font.Body1S16,
                      color: colors.gray.GR900,
                      border: 'none',
                      borderBottom: `1px solid ${
                        !authCodeValiation ? colors.red.Red600 : colors.gray.GR200
                      }`,
                      paddingBottom: 12,
                      flexGrow: 1,
                    }}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      right: 0,
                      top: 4,
                      color: !authCodeValiation ? colors.red.Red600 : colors.blue.Blue400,
                      ...font.Body1S16,
                    }}
                  >
                    {autoZero(Math.floor(timerCount / 60), 1) +
                      ':' +
                      autoZero(Math.floor(timerCount % 60), 2)}
                  </div>
                </div>

                {authCodeValidMessage !== '' && (
                  <div
                    style={{
                      color: !authCodeValiation ? colors.red.Red600 : colors.gray.GR200,
                      ...font.Body6M14,
                      marginBottom: 20,
                      width: '100%',
                      textAlign: 'left',
                    }}
                  >
                    {authCodeValidMessage}
                  </div>
                )}
              </Fragment>
            )}
            <div
              style={{
                position: 'fixed',
                bottom: 30,
                width: '100%',
                display: 'flex',
                textAlign: 'center',
              }}
            >
              <div
                style={{
                  width: '100%',
                  maxWidth: 600,
                  boxSizing: 'border-box',
                  paddingRight: 40,
                }}
              >
                <Button
                  onClick={auth}
                  disabled={authCodeSendButtonDisabledFlag || isAuthLoading}
                  style={{
                    width: '100%',
                    backgroundColor: authCodeSendButtonDisabledFlag
                      ? colors.gray.GR200
                      : colors.orange.OR500,
                    color: colors.gray.White,
                    borderRadius: 16,
                    padding: '14px 0px',
                    ...font.Body1S16,
                  }}
                >
                  다음
                </Button>
              </div>
            </div>
          </div>
        </div>
      }
    </WholePageWrapper>
  );
};

export default SignIn;

import styled from 'styled-components';
import { colors } from '../../../style';
import font from '../../../style/font';

type PriceType = 'free' | 'plus';

export const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
});

export const TopContainer = styled.div({
  marginTop: 76,
  marginBottom: 36,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const MainText = styled.div({
  fontSize: 44,
  fontWeight: 700,
  lineHeight: '44px',
  marginTop: 16,
  marginBottom: 12,
});
export const DescText = styled.div({
  fontSize: 18,
  fontWeight: 500,
  lineHeight: '27px',
  color: colors.orange.OR500,
});

export const BottomContainer = styled.div<{
  isMobile: boolean;
}>(
  {
    display: 'flex',
    gap: 34,
    marginBottom: 200,
    width: '100%',
    justifyContent: 'center',
  },
  ({ isMobile }) => ({
    flexDirection: isMobile ? 'column' : 'row',
  })
);

export const LeftContainer = styled.div<{
  isMobile: boolean;
}>(
  {
    backgroundColor: colors.gray.GR100,
    borderRadius: 24,
    // padding: '32px 40px 40px 40px',
    transition: 'box-shadow 0.3s ease, transform 0.3s ease',
    '&:hover': {
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      transform: 'translateY(-2px)',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  ({ isMobile }) => ({
    width: isMobile ? 'auto' : 520,
    height: isMobile ? 'auto' : 512,
    padding: isMobile ? 20 : '32px 40px 40px 40px',
    marginRight: isMobile ? 20 : 0,
    marginLeft: isMobile ? 20 : 0,
  })
);
export const RightContainer = styled.div<{
  isMobile: boolean;
}>(
  {
    backgroundColor: '#FFF8F2',
    borderRadius: 24,
    // padding: '32px 30px 30px 30px',
    transition: 'box-shadow 0.3s ease, transform 0.3s ease',
    '&:hover': {
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      transform: 'translateY(-2px)',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  ({ isMobile }) => ({
    width: isMobile ? 'auto' : 520,
    height: isMobile ? 'auto' : 512,
    padding: isMobile ? 20 : '32px 40px 40px 40px',
    marginRight: isMobile ? 20 : 0,
    marginLeft: isMobile ? 20 : 0,
  })
);

export const Title = styled.div<{
  type: PriceType;
}>(
  {
    fontSize: 24,
    fontWeight: 800,
    lineHeight: '24px',
    marginBottom: 13,
  },
  ({ type }) => ({
    color: type === 'free' ? colors.gray.GR800 : colors.orange.OR500,
  })
);
export const Desc = styled.div<{
  type: PriceType;
}>(
  {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '16px',
    marginBottom: 27,
  },
  ({ type }) => ({
    color: type === 'free' ? colors.gray.GR600 : colors.gray.GR800,
  })
);
export const Price = styled.div<{
  type: PriceType;
  isMobile: boolean;
}>(
  {
    fontWeight: 800,
  },
  ({ type, isMobile }) => ({
    color: type === 'free' ? colors.gray.GR800 : colors.orange.OR500,
    marginBottom: type === 'free' ? 20 : 0,
    fontSize: isMobile ? 24 : 40,
    lineHeight: isMobile ? '24px' : '40px',
  })
);
export const PriceContainer = styled.div({
  display: 'flex',
  alignItems: 'end',
  justifyContent: 'start',
  gap: 10,
  marginBottom: 20,
});
export const PriceSubText = styled.div({
  fontSize: 16,
  fontWeight: 500,
  lineHeight: '16px',
  color: colors.gray.GR600,
});
export const BulletList = styled.ul<{
  type: PriceType;
}>(
  {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '25.6px',
    paddingLeft: 28,
    margin: 0,
  },
  ({ type }) => ({
    color: type === 'free' ? colors.gray.GR800 : colors.orange.OR500,
  })
);
export const BulletListContainer = styled.div({
  padding: '15px 10px',
  backgroundColor: colors.gray.White,
  marginTop: 20,
  marginBottom: 16,
  borderRadius: 10,
});
export const LastText = styled.div({
  ...font.Body2M16,
  color: colors.gray.Black,
  marginTop: 16,
});

export const PointLastText = styled.span({
  ...font.Body2M16,
  color: colors.orange.OR500,
  cursor: 'pointer',
  '&:hover': {
    color: colors.gray.GR800,
    transition: 'color 0.3s ease',
  },
});

export const LinkContainer = styled.div({
  display: 'flex',
  gap: 10,
  alignItems: 'center',
  justifyContent: 'center',
});

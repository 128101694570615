import * as React from 'react';
import { styled, AppBar, AppBarProps } from '@mui/material';
import { SportsRugbySharp } from '@mui/icons-material';
import { useMediaQueryForMobile } from '../hooks/useMediaQueryForMobile';
import Modal from '@mui/material/Modal';
import moment from 'moment';

export interface IMyAppBarProps extends AppBarProps {
  borderStyle?: boolean;
}
export const webAppBarHeight = 70;
export const mobileAppBarHeight = 58;

export const MyAppBar: React.FC<IMyAppBarProps> = ({
  borderStyle = false,
  children,
  ...others
}) => {
  const isMobile = useMediaQueryForMobile();
  const [isMenuShow, setIsMenuShow] = React.useState(true);
  const [preScrollPosition, setPreScrollPosition] = React.useState(0);
  const [windowWidth, setWindowWidth] = React.useState(1440);
  const [isOpenPopup, setIsOpenPopup] = React.useState(false);

  const handleStopOneDay = () => {
    let now: any = moment();
    window.localStorage.setItem('popupDisabledDateEnd', now.add(1, 'days').valueOf());
    setIsOpenPopup(false);
  };

  React.useEffect(() => {
    let popupDisabledDateEnd: any = 'Invalid date';
    if (window.localStorage.getItem('popupDisabledDateEnd') !== null) {
      popupDisabledDateEnd = window.localStorage.getItem('popupDisabledDateEnd');
    }
    let now: any = moment();
    if (popupDisabledDateEnd == 'Invalid date' || now.valueOf() - popupDisabledDateEnd > 0) {
      setIsOpenPopup(true);
    }
  }, []);

  const handleScroll = (e: any) => {
    setPreScrollPosition(window.scrollY);
    if (preScrollPosition < window.scrollY) {
      setIsMenuShow(false);
    } else {
      setIsMenuShow(true);
    }
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  React.useEffect(() => {
    console.log(isMenuShow);
  }, [isMenuShow]);

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  React.useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  return (
    <>
      <StyledAppbar
        isMobile={isMobile}
        windowWidth={windowWidth}
        isShow={isMenuShow}
        withborder={borderStyle}
        {...others}
        style={{ transform: isMenuShow ? 'translateY(100px);' : 'translateY(-100px);' }}
      >
        {children}
      </StyledAppbar>
      {/* <div style={{paddingBottom: windowWidth>1440 ? "70px" : isMobile ? "14px" : "0px",maxWidth: 1440}}></div> */}
      {/* <Modal
        open={isOpenPopup}
        onClose={()=>{setIsOpenPopup(false)}}
        style={{display: "flex", width: "100vw", height: "100vh", justifyContent: "center", alignItems: "center"}}
        disableAutoFocus={true}
      >
        <div style={{display: "flex", flexDirection: "column",backgroundColor: "#FFFFFF",borderRadius: 12,overflow: "hidden"}}>
          <img onClick={()=>{window.open("https://utis.info/main/main.php")}} src="/images/popup1.png" style={{width: "70vw", height: "70vw", maxWidth: 600, maxHeight: 600}} />
          <div style={{display: "flex", justifyContent: "flex-end",alignItems: "center", height: 20}}>
            <div onClick={()=>{setIsOpenPopup(false)}} style={{fontSize: isMobile ? 14 : 16 ,fontWeight: 400,textDecoration: "underline",cursor: "pointer"}}>닫기</div>
            <div style={{width: 8}} />
            <div onClick={handleStopOneDay} style={{fontSize: isMobile ? 14 : 16, fontWeight: 400,textDecoration: "underline",cursor: "pointer"}} >1일동안 보지 않기</div>
            <div style={{width: 8}} />
          </div>
        </div>
      </Modal> */}
    </>
  );
};

const StyledAppbar = styled(AppBar)<{
  withborder: boolean;
  isShow: boolean;
  windowWidth: number;
  isMobile: boolean;
}>(({ isMobile, isShow, windowWidth, withborder, theme }) => ({
  // minWidth: "1440px",
  // padding: "0px 200px",
  top: 0,
  backgroundColor: withborder ? 'rgba(255, 255, 255, 0.85)' : 'rgba(0, 0, 0, 0.22)',
  // height: webAppBarHeight,
  transition: 'transform 0.5s',
  '& > .MuiToolbar-root': {
    // height: webAppBarHeight,
    boxSizing: 'border-box',
  },
  [theme.breakpoints.down('sm')]: {
    height: mobileAppBarHeight,
    '& > .MuiToolbar-root': {
      height: mobileAppBarHeight,
    },
    backgroundColor: withborder ? 'white' : 'rgba(255, 255, 255, 0)',
  },
  boxShadow: 'none',
  borderBottom: withborder ? '1px solid #F1F1F1' : '',
  minWidth: isMobile ? '100%' : '1440px',
  width: '100%',
  position: 'sticky',
  boxSizing: 'border-box',
}));

import React, { useCallback } from "react";
import { Button, styled, Typography } from "@mui/material";
import { MyPopup } from "../widgets/MyPopup";
import { useMediaQueryForMobile } from "../hooks/useMediaQueryForMobile";
import { useHistory } from "react-router";
interface IPrivacyAlertProps {
  setIsPrivacy: React.Dispatch<React.SetStateAction<boolean>>;
}
export const PrivacyPopup: React.FC<IPrivacyAlertProps> = ({
  setIsPrivacy,
}) => {
  const history = useHistory();
  const isMobile = useMediaQueryForMobile();
  const onClose = useCallback(() => {
    setIsPrivacy(false);
  }, [setIsPrivacy]);

  return (
    // <MyPopup open={true} onClick={onClose}>
      <MyPopup open={true} >
      <PrivacyWrapper>
        <PrivacyContainer>
          
          <div style={{display: "flex", justifyContent: "center", marginBottom: 16}} >  
            <div style={{color: "#1A1A1A", fontSize: 18, fontWeight: 600, lineHeight: "28px"}} >
              {"개인정보 수집 및 이용동의"}
            </div>
          </div>

          <div style={{whiteSpace: "pre-wrap", color: "#6F6F6F", fontSize: 16, fontWeight: 500, lineHeight: "24px"}} >
            {`서클플랫폼(주)는 아래의 목적으로 개인정보를 수집 및 이용하며, 
회원의 개인정보를 안전하게 취급하는데 최선을 다합니다.

1. 수집 항목 : [필수] 이름, 메일주소
2. 수집 및 이용 목적 : 문의사항 대응
보유 및 이용 기간 : 문의 사항 상담서비스를 위해 검토 완료 후 3개월 간 보관하며, 
이후 해당 정보를 지체 없이 파기합니다.
그 밖의 사항은 별도로 고지하는 `}
            <span
              style={{
                textDecoration: "underline",
                textUnderlinePosition: "under",
                cursor: "pointer",
              }}
              onClick={(e) => {
                history.push("/privacy");
                window.scrollTo({
                  top: 0,
                });
              }}
            >
              개인정보처리방침
            </span>
            {`을 참고해 주시기 바랍니다.`}
          </div>

          <div style={{display: "flex", justifyContent: "center", alignItems: "center"}} >
            <div 
              style={{marginTop: 40,display: "flex", justifyContent: "center", alignItems: "center", width: "200px", height: "44px", borderRadius: 8, backgroundColor: "#F5F5F5", color: "#555",fontWeight: 600, fontSize: 15, lineHeight: "22px",cursor: "pointer"}}
              onClick={onClose}
            >
              {"확인"}
            </div>
          </div>
            {/* <StyledApplyButton onClick={onClose}>
              <Typography
                variant={"h23"}
                // style={{ color: isMobile ? "#FF7F00" : "#A4A4A4" }}
                // style={{ color: isMobile ? "#FF7F00" : "#black" }}
                
                >
                
              </Typography>
            </StyledApplyButton> */}
          
        </PrivacyContainer>
      </PrivacyWrapper>
    </MyPopup>
  );
};

export const PrivacyWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "fixed",
  width: 566,
  backgroundColor: "white",
  borderRadius: "5%",
  padding: "30px 30px 20px 30px",
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    width: 320,
  },
}));
const PrivacyContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
}));

const TitleZone = styled("div")(({ theme }) => ({
  justifyContent: "center",
  textAlign: "center",
  marginTop: 27,
  marginBottom: 16,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginTop: 37,
    marginBottom: 16,
  },
}));

const ContentZone = styled("div")(({ theme }) => ({
  textAlign: "start",
  marginBottom: 20,
  width: 487,
  height: 231,
  marginLeft: "10%",
  justifyContent: "center",
  alignContent: "center",
  [theme.breakpoints.down("sm")]: {
    marginBottom: 0,
    width: 262,
    height: 337,
    marginLeft: 20,
    justifyContent: "center",
    alignContent: "center",
  },
}));
const ContentContainer = styled("div")(({ theme }) => ({
  marginBottom: 20,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    flexWrap: "wrap",
    marginBottom: 20,
  },
}));

const ButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    height: "100%",
    marginTop: 37,
    marginBottom: 25,
    borderTop: "1px solid #E5E5E5",
  },
}));
const StyledApplyButton = styled(Button)(({ theme }) => ({
  textAlign: "center",
  width: 158,
  height: 54,
  minHeight: 54,
  backgroundColor: "#FFFFFF",
  color: theme.palette.primary.main,
  // FF7F00
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    color: "#FFFFFF",
    // opacity: 0.1,
  },
}));

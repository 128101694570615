import React, { useCallback } from "react";
import { Button, styled, Typography } from "@mui/material";
import { MyPopup } from "../widgets/MyPopup";

export interface ITextLimitPopupProps {
  setIsTextLimit: React.Dispatch<React.SetStateAction<boolean>>;
}
export const TextLimitPopup: React.FC<ITextLimitPopupProps> = ({
  setIsTextLimit,
}) => {
  const onClose = useCallback(() => {
    setIsTextLimit(false);
  }, [setIsTextLimit]);

  return (
    <MyPopup open={true} onClick={onClose}>
      <Container>
        <TitleContainer>
          <Typography variant={"body9"}>
            {"글자수는 최대 500자입니다."}
          </Typography>
        </TitleContainer>
        <BottomZone>
          <StyledApplyButton onClick={onClose}>
            <Typography variant={"h23"} style={{ color: "#FF7F00" }}>
              {"확인"}
            </Typography>
          </StyledApplyButton>
        </BottomZone>
      </Container>
    </MyPopup>
  );
};

const Container = styled("div")(({ theme }) => ({
  width: 587,
  height: 455,
  backgroundColor: "#FFFFFF",
  position: "fixed",
  borderRadius: "5%",
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    width: 302,
    height: 208,
  },
}));

const TitleContainer = styled("div")(({ theme }) => ({
  alignItems: "center",
  marginTop: "30%",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    height: "100%",
    marginTop: "10%",
  },
}));

const BottomZone = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "end",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    borderTop: "1px solid #E5E5E5",
    maxHeight: 34,
  },
}));

const StyledApplyButton = styled(Button)(({ theme }) => ({
  textAlign: "center",
  width: 158,
  height: 54,
  minHeight: 54,
  marginTop: "30%",
  backgroundColor: "#FFFFFF",
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    opacity: 0.9,
  },
  [theme.breakpoints.down("sm")]: {
    minHeight: 24,
    marginTop: "10%",
  },
}));

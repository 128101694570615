import { styled, Typography } from "@mui/material";
import * as React from "react";
import { ReactComponent as ArrowIcon } from "../assets/icons/Arrow.svg";
import { ReactComponent as HoverArrowIcon } from "../assets/icons/HoverArrow.svg";
import { ReactComponent as OrangeCicle } from "../assets/icons/OrangeCicle.svg";
import CompanyIntroduceImage01 from "../assets/images/CompanyIntroduceImage01.png";
import CompanyIntroduceImage02 from "../assets/images/CompanyIntroduceImage02.png";
import CompanyIntroduceImage03 from "../assets/images/CompanyIntroduceImage03.png";
import { useMediaQueryForMobile } from "../hooks/useMediaQueryForMobile";
import {
  CompanyPageImageMask,
  CompanyPageImageMask2,
} from "../widgets/ImageMask";
import { MySection } from "../widgets/MySection";
import { MySellerBox } from "../widgets/MySellerBox";
import mainLogo from "../assets/logo/mainLogo.png";

export interface ICompanyIntroduceProps {}

export const CompanyIntroducePage: React.FC<ICompanyIntroduceProps> = ({}) => {
  const xsMatches = useMediaQueryForMobile();

  return (
    <>
      <MySection
        style={{
          height: xsMatches ? 812 : 960,
          backgroundImage: `url(${CompanyIntroduceImage01})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CompanyPageImageMask
          style={{
            zIndex: 1,
          }}
        />
        {xsMatches ? (
          <Typography
            variant={"h1"}
            textAlign={"center"}
            style={{
              color: "#1C1C1C",
              zIndex: 2,
            }}
          >
            {"무한한 성장의 선순환"}
            <br />
            {"서클플랫폼"}
            <br />
          </Typography>
        ) : (
          <Typography
            variant={"h1"}
            textAlign={"center"}
            style={{
              color: "#1C1C1C",
              zIndex: 2,
            }}
          >
            {"무한한 성장의 선순환"}
            <br />
            {"서클플랫폼"}
            <br />
          </Typography>
        )}
      </MySection>
      <MySection
        style={{
          height: xsMatches ? 441 : 888,
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          color: "#1C1C1C",
        }}
      >
        {/* <MySellerBox /> */}
        <img src={mainLogo} style={{ width: "160px", height: "90px" }} />
        <Typography
          variant={"body1"}
          textAlign={"center"}
          style={{ paddingTop: xsMatches ? 107 : 70 }}
        >
          {"무한한 성장의 선순환을 이끄는 Circle처럼"} <br />
          {"끝없이 정진하는 서클플랫폼입니다."} <br />
          {"무한한 데이터 속에서 의미와 가치를 발견하고"} <br />
          {"그 가치를 실현하면서 고객의 성공을 지원합니다."}
        </Typography>
      </MySection>
      <MySection
        style={{
          height: xsMatches ? 900 : 1108,
          backgroundImage: `url(${CompanyIntroduceImage02})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CompanyPageImageMask2
          style={{
            zIndex: 1,
            height: "100%",
          }}
        >
          <PlattformContainer>
            <PlattTitleZone>
              <Typography
                variant={"h2"}
                textAlign={"center"}
                style={{
                  height: 120,
                  width: xsMatches ? 213 : 610,
                  paddingTop: xsMatches ? 137 : 0,
                }}
              >
                {"오늘도 서클플랫폼은"}
                {xsMatches ? (
                  <>
                    <br />
                    {"또 하나의 가치를"}
                    <br />
                    {"발견하고 있습니다."}
                  </>
                ) : (
                  <>
                    <br />
                    {"또 하나의 가치를 발견하고 있습니다."}
                  </>
                )}
              </Typography>
            </PlattTitleZone>
            <TextZone>
              <LeftZone>
                {/* <LeftTopZone style={{ paddingTop: 170, position: "relative" }}>
                  <StyledTypography type="left" label={".04"} />
                  <Typography
                    style={{ marginTop: 6 }}
                    variant={xsMatches ? "h16" : "h8"}
                  >
                    {"셀러박스 서비스 기획"}
                  </Typography>
                </LeftTopZone> */}
                <Typography
                  style={{
                    marginTop: 200,
                    marginBottom: 23,
                    marginRight: xsMatches ? 16 : 50,
                  }}
                  variant={"h10"}
                >
                  2022
                </Typography>
                <LeftBottomZone>
                  <StyledTypography type="left" label={".01"} />
                  {xsMatches ? (
                    <Typography
                      style={{ marginTop: 6 }}
                      variant={xsMatches ? "h16" : "h8"}
                    >
                      {"데이터 바우처"}
                      <br />
                      {"공급기업 선정"}
                    </Typography>
                  ) : (
                    <Typography variant={xsMatches ? "h16" : "h8"}>
                      {"데이터 바우처 공급기업 선정"}
                    </Typography>
                  )}
                </LeftBottomZone>
              </LeftZone>
              <RightZone>
                <RightTopZone>
                  <Typography style={{ marginBottom: 23 }} variant={"h10"}>
                    2021
                  </Typography>
                  {/* <StyledTypography type="right" label={".03"} />
                  {xsMatches ? (
                    <Typography
                      style={{ marginTop: 6 }}
                      variant={xsMatches ? "h16" : "h8"}
                    >
                      {"셀러박스"}
                      <br />
                      {" v1.0 App 런칭"}
                    </Typography>
                  ) : (
                    <Typography variant={xsMatches ? "h16" : "h8"}>
                      {"셀러박스 v1.0 App 런칭"}
                    </Typography>   )}*/}

                  <StyledTypography type="right" label={".03"} />
                  {xsMatches ? (
                    <Typography
                      style={{ marginTop: 6 }}
                      variant={xsMatches ? "h16" : "h8"}
                    >
                      {"서클플랫폼 주식회사"}
                      <br />
                      {"설립"}
                    </Typography>
                  ) : (
                    <Typography variant={xsMatches ? "h16" : "h8"}>
                      {"서클플랫폼 주식회사 설립"}
                    </Typography>
                  )}
                </RightTopZone>
                <RightBottomZone>
                  <StyledTypography type="right" label={".03"} />
                  {xsMatches ? (
                    <Typography
                      style={{ marginTop: 6 }}
                      variant={xsMatches ? "h16" : "h8"}
                    >
                      {"셀러박스"}
                      <br />
                      {" v1.0 App 런칭"}
                    </Typography>
                  ) : (
                    <Typography variant={xsMatches ? "h16" : "h8"}>
                      {"셀러박스 v1.0 App 런칭"}
                    </Typography>
                  )}
                </RightBottomZone>
              </RightZone>
            </TextZone>
          </PlattformContainer>
        </CompanyPageImageMask2>
      </MySection>
      {/* <MySection
        style={{
          height: xsMatches ? 826 : 888,
          backgroundImage: `url(${CompanyIntroduceImage03})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {xsMatches ? (
          <>
            <MobileSubContainer>
              <MobileSubTopZone>
                <Typography variant={"h18"}>
                  서클플랫폼이 가져올 혁신적인 변화
                </Typography>
                <div style={{ marginTop: 20 }}>
                  <Typography variant={"body7"}>{"2021.9월 기준"}</Typography>
                </div>
              </MobileSubTopZone>
              <MobileSubBottomZone>
                <StyledRightTypography
                  label={"누적 가입자수"}
                  content={"5만명+"}
                />
                <StyledRightTypography
                  label={"누적 데이터 수"}
                  content={"10억건+"}
                />
                <StyledRightTypography
                  label={"누적 투자금액"}
                  content={"50억+"}
                />
              </MobileSubBottomZone>
            </MobileSubContainer>
          </>
        ) : (
          <>
            <SubContainer>
              <SubLeftZone>
                <Typography
                  variant={"h2"}
                  style={{
                    color: "#FFFFFF",
                    width: 341,
                    height: 120,
                    marginBottom: 71,
                    paddingTop: 181,
                  }}
                >
                  {"서클플랫폼이 가져올 혁신적인 변화"}
                </Typography>
                <Typography variant={"body7"}>{"2021.9월 기준"}</Typography>
              </SubLeftZone>
              <SubRightZone>
                <div style={{ paddingTop: 181 }} />
                <StyledRightTypography
                  label={"누적 가입자수"}
                  content={"5만명+"}
                />
                <StyledRightTypography
                  label={"누적 데이터 수"}
                  content={"10억건+"}
                />
                <StyledRightTypography
                  label={"누적 투자금액"}
                  content={"50억+"}
                />
              </SubRightZone>
            </SubContainer>
          </>
        )}
      </MySection> */}
      {/* <MySection
        style={{
          height: xsMatches ? 1050 : 1440,
          justifyContent: xsMatches ? " start" : "center",
          alignItems: xsMatches ? "start" : "center",
        }}
      >
        {xsMatches ? (
          <>
            <MobileArticleWrapper>
              <MobileArticleTopZone>
                <Typography variant={"h3"} style={{ color: "#1C1C1C" }}>
                  {"서클플랫폼이 나온"}
                  <br />
                  {"언론기사"}
                </Typography>
              </MobileArticleTopZone>
              <MobileArticleBottomZone>
                <MobileStyledArticle
                  label={"조선일보"}
                  date={"2022.11.20"}
                  content={"데이터로 세상을 바꾼 회사 서클플랫폼"}
                />
                <MobileStyledArticle
                  label={"동아일보"}
                  date={"2022.07.08"}
                  content={"서클플랫폼이 유니콘 회사가 되기까지"}
                />
                <MobileStyledArticle
                  label={"전자신문 "}
                  date={"2022.05.13"}
                  content={
                    "셀러들에게 선풍적인 인기를 끌고있는 데이터 분석 앱, 셀러박스"
                  }
                />
                <MobileStyledArticle
                  label={"동아일보"}
                  date={"2022.04.08"}
                  content={"셀러박스 출시 1개월만에 가입자 1만명 돌파"}
                />
                <MobileStyledArticle
                  label={"매일경제"}
                  date={"2022.03.12"}
                  content={"각자 다른 마켓의 매출,입금내역을 한곳에서"}
                />
              </MobileArticleBottomZone>
            </MobileArticleWrapper>
          </>
        ) : (
          <>
            <ArticleWrapper>
              <Typography variant={"h2"} style={{ color: "#1C1C1C" }}>
                {"서클플랫폼이 나온 언론기사"}
              </Typography>
              <StyledArticle
                label={"조선일보"}
                date={"2022.11.20"}
                content={"데이터로 세상을 바꾼 회사 서클플랫폼"}
              />
              <StyledArticle
                label={"동아일보"}
                date={"2022.07.08"}
                content={"서클플랫폼이 유니콘 회사가 되기까지"}
              />
              <StyledArticle
                label={"전자신문 "}
                date={"2022.05.13"}
                content={
                  "셀러들에게 선풍적인 인기를 끌고있는 데이터 분석 앱, 셀러박스"
                }
              />
              <StyledArticle
                label={"동아일보"}
                date={"2022.04.08"}
                content={"셀러박스 출시 1개월만에 가입자 1만명 돌파"}
              />
              <StyledArticle
                label={"매일경제"}
                date={"2022.03.12"}
                content={"각자 다른 마켓의 매출,입금내역을 한곳에서"}
              />
            </ArticleWrapper>
          </>
        )}
      </MySection> */}
    </>
  );
};

const StyledTypography = ({
  label,
  type = "left",
}: {
  label: string;
  type: "left" | "right";
}) => {
  const xsMatches = useMediaQueryForMobile();
  const wProps = type === "left" ? { right: -11 } : { left: -9 };
  return (
    <Typography
      variant={xsMatches ? "h17" : "h9"}
      style={{ position: "relative" }}
    >
      <OrangeCicle
        style={{
          ...wProps,
          position: "absolute",
          top: 4,
        }}
      />
      <span style={{ position: "relative" }}>{label}</span>
    </Typography>
  );
};

const StyledRightTypography = ({
  label,
  content,
}: {
  label: string;
  content: string;
}) => {
  const xsMatches = useMediaQueryForMobile();
  return (
    <RightTextWrapper>
      <Typography variant={xsMatches ? "h19" : "h13"}>{label}</Typography>
      <br />
      <Typography variant={xsMatches ? "body10" : "body8"}>
        {content}
      </Typography>
    </RightTextWrapper>
  );
};

const MobileStyledArticle = ({
  label,
  date,
  content,
}: {
  label: string;
  date: string;
  content: string;
}) => {
  return (
    <MobileArticleContainer>
      <MobileArticleTitleZone>
        <Typography style={{ marginRight: 16 }} variant={"h14"}>
          {label}
        </Typography>
        <Typography variant={"h15"}> {date}</Typography>
      </MobileArticleTitleZone>

      <Typography variant={"body9"}>{content}</Typography>
    </MobileArticleContainer>
  );
};

const StyledArticle = ({
  label,
  date,
  content,
}: {
  label: string;
  date: string;
  content: string;
}) => {
  const [hover, setHover] = React.useState<boolean>(false);
  return (
    <ArticleContainer
      onMouseOver={(e) => {
        setHover(true);
      }}
      onMouseLeave={(e) => {
        setHover(false);
      }}
    >
      <ArticleLeftZone>
        <ArticleTitleZone>
          <Typography style={{ marginRight: 22 }} variant={"h14"}>
            {label}
          </Typography>
          <Typography variant={"h15"}> {date}</Typography>
        </ArticleTitleZone>
        <Typography variant={"body9"}>{content}</Typography>
      </ArticleLeftZone>
      <ArticleRightZone>
        {hover ? (
          <HoverArrowIcon
            style={{
              textAlign: "center",
              marginLeft: "20%",
            }}
          />
        ) : (
          <ArrowIcon
            style={{
              textAlign: "center",
              marginLeft: "20%",
            }}
          />
        )}
      </ArticleRightZone>
    </ArticleContainer>
  );
};
const LeftZone = styled("div")({
  width: "50%",
  display: "flex",
  flexDirection: "column",
  textAlign: "end",
  borderRight: "1px solid rgba(166,166,166,0.6)",
  height: "100%",
});
const RightZone = styled("div")({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
});
const TextZone = styled("div")({
  paddingTop: "15%",
  position: "relative",
  display: "flex",
  height: "100%",
});

const LeftTopZone = styled("div")(({ theme }) => ({
  marginRight: 62,
  marginBottom: 187,
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("sm")]: {
    marginRight: 15,
    marginBottom: 133,
  },
}));

const LeftBottomZone = styled("div")(({ theme }) => ({
  marginRight: 62,
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("sm")]: {
    marginRight: 15,
  },
}));
const RightTopZone = styled("div")(({ theme }) => ({
  marginLeft: 62,
  marginBottom: 187,
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("sm")]: {
    marginLeft: 15,
    marginBottom: 133,
  },
}));

const RightBottomZone = styled("div")(({ theme }) => ({
  marginLeft: 62,
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("sm")]: {
    marginLeft: 15,
  },
}));

const PlattformContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
}));
const PlattTitleZone = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  textAlign: "end",
  justifyContent: "center",
}));

const SubContainer = styled("div")(({ theme }) => ({
  maxWidth: 1440,
  display: "flex",
  zIndex: 1,
  height: "100%",
  width: "100%",
}));
const SubLeftZone = styled("div")({
  width: "50%",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  paddingLeft: "16%",
});
const SubRightZone = styled("div")({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  height: "100%",
});

const MobileSubContainer = styled("div")(({ theme }) => ({
  width: "100%",
  paddingLeft: 35,
  paddingRight: 35,
  display: "flex",
  flexDirection: "column",
  zIndex: 1,
}));
const MobileSubTopZone = styled("div")(({ theme }) => ({
  height: 127,
  width: 213,
  marginBottom: 4,
  marginTop: -110,
}));

const MobileSubBottomZone = styled("div")(({ theme }) => ({
  height: 381,
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "end",
}));

const RightTextWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  textAlign: "end",
  justifyContent: "center",
  width: 228,
  height: 105,
  marginBottom: 86,
  [theme.breakpoints.down("sm")]: {
    textAlign: "end",
    marginBottom: 48,
    height: 95,
  },
}));

const MobileArticleWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  paddingLeft: 35,
  paddingRight: 35,
  paddingTop: 86,
}));

const MobileArticleTopZone = styled("div")(({ theme }) => ({
  width: 200,
  height: 72,
  paddingBottom: 36,
}));

const MobileArticleBottomZone = styled("div")(({ theme }) => ({}));
const MobileArticleContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: 146,
  borderBottom: "1px solid #E5E5E5",
  justifyContent: "center",
}));

const MobileArticleTitleZone = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  height: 30,
  width: "100%",
  marginBottom: 6,
}));

const ArticleWrapper = styled("div")(({ theme }) => ({
  paddingTop: 181,
  maxWidth: 1100,
  width: "calc(100% - 340px)",
  display: "flex",
  flexDirection: "column",
}));

const ArticleContainer = styled("div")(({ theme }) => ({
  display: "flex",
  height: 156,
  borderBottom: "1px solid #E5E5E5",
  justifyContent: "space-between",
}));
const ArticleLeftZone = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

const ArticleTitleZone = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  textAlign: "center",
  marginBottom: 3,
}));

const ArticleRightZone = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "end",
  justifyContent: "center",
}));

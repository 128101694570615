import { createTheme } from "@mui/material";
import React from "react";
import { TrueLiteral } from "typescript";


export const MainTheme: any = createTheme({
  // SpoqaHanSansNeo-Regular
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0,0,0,0.2)", //material ui modal 배경 색상
        },
      },
    }
  },
  typography: {
    fontFamily: ["Pretendard"].join(","),
    h1: {
      fontWeight: "bold",
      fontStyle: "normal",
      fontSize: "45px",
      lineHeight: "65px",
    },
    h2: {
      fontWeight: "bold",
      fontStyle: "normal",
      fontSize: "40px",
      lineHeight: "60px",
    },
    h3: {
      fontWeight: "bold",
      fontStyle: "normal",
      fontSize: "25px",
      lineHeight: "36px",
    },
    h4: {
      fontWeight: "bold",
      fontStyle: "normal",
      fontSize: "23px",
      lineHeight: "35px",
    },
    h5: {
      fontWeight: "bold",
      fontStyle: "normal",
      fontSize: "23px",
      lineHeight: "30px",
    },
    h6: {
      fontWeight: "bold",
      fontStyle: "normal",
      fontSize: "20px",
      lineHeight: "25.14px",
      color: "#102819",
    },
    h7: {
      fontWeight: "bold",
      fontStyle: "normal",
      fontSize: "14px",
      color: "#1C1C1C",
    },
    h8: {
      fontWeight: "normal",
      fontStyle: "normal",
      fontSize: "20px",
      lineHeight: "30px",
      color: "#1C1C1C",
    },
    h9: {
      fontWeight: "bold",
      fontStyle: "normal",
      fontSize: "20px",
      lineHeight: "30px",
      color: "#1C1C1C",
    },
    h10: {
      fontWeight: "normal",
      fontStyle: "normal",
      fontSize: "35px",
      lineHeight: "60px",
      color: "#1C1C1C",
    },
    h11: {
      fontWeight: "bold",
      fontStyle: "normal",
      fontSize: "14px",
      lineHeight: "35px",
    },
    h12: {
      fontWeight: "bold",
      fontStyle: "normal",
      fontSize: "13px",
      lineHeight: "35px",
    },
    h13: {
      fontWeight: "normal",
      fontStyle: "normal",
      fontSize: "23px",
      lineHeight: "35px",
      color: "#A4A4A4",
    },
    h14: {
      fontWeight: "bold",
      fontStyle: "normal",
      fontSize: "17px",
      lineHeight: "30px",
      color: "#FF7F00",
    },
    h15: {
      fontWeight: "normal",
      fontStyle: "normal",
      fontSize: "17px",
      lineHeight: "30px",
      color: "#A4A4A4",
    },
    h16: {
      fontWeight: "normal",
      fontStyle: "normal",
      fontSize: "16px",
      lineHeight: "25px",
      color: "#1C1C1C",
    },
    h17: {
      fontWeight: "bold",
      fontStyle: "normal",
      fontSize: "18px",
      lineHeight: "30px",
      color: "#1C1C1C",
    },
    h18: {
      fontWeight: "bold",
      fontStyle: "normal",
      fontSize: "25px",
      lineHeight: "36px",
      color: "#FFFFFF",
    },
    h19: {
      fontWeight: "normal",
      fontStyle: "normal",
      fontSize: "18px",
      lineHeight: "35px",
      color: "#C8C8C8",
    },
    h20: {
      fontWeight: "bold",
      fontStyle: "normal",
      fontSize: "25px",
      lineHeight: "42px",
      color: "#1C1C1C",
    },
    h21: {
      fontWeight: "bold",
      fontStyle: "normal",
      fontSize: "30px",
      lineHeight: "30px",
      color: "#1C1C1C",
    },
    h22: {
      fontStyle: "normal",
      fontSize: "20px",
      lineHeight: "30px",
      color: "#1C1C1C",
    },
    h23: {
      fontWeight: "normal",
      fontStyle: "normal",
      fontSize: "16px",
      lineHeight: "30px",
    },
    h24: {
      fontWeight: "normal",
      fontStyle: "normal",
      fontSize: "30px",
      lineHeight: "30px",
    },
    body1: {
      fontWeight: "normal",
      fontStyle: "normal",
      fontSize: "25px",
      lineHeight: "45px",
    },
    body2: {
      fontWeight: "normal",
      fontStyle: "normal",
      fontSize: "15px",
      lineHeight: "30px",
    },
    body3: {
      fontWeight: "normal",
      fontStyle: "normal",
      fontSize: "14px",
      lineHeight: "30px",
    },
    body4: {
      fontWeight: "normal",
      fontStyle: "normal",
      fontSize: "12px",
      lineHeight: "30px",
      color: "#9E9E9E",
    },
    body5: {
      fontWeight: "normal",
      fontStyle: "normal",
      fontSize: "12px",
      lineHeight: "30px",
      color: "#D8D8D8",
    },
    body6: {
      fontWeight: "normal",
      fontStyle: "normal",
      fontSize: "13px",
      lineHeight: "23px",
      color: "#9E9E9E",
    },
    body7: {
      fontWeight: "normal",
      fontStyle: "normal",
      fontSize: "17px",
      lineHeight: "35px",
      color: "#A4A4A4",
    },
    body8: {
      fontWeight: "bold",
      fontStyle: "normal",
      fontSize: "60px",
      lineHeight: "60px",
      color: "#FFFFFF",
    },
    body9: {
      fontWeight: "normal",
      fontStyle: "normal",
      fontSize: "23px",
      lineHeight: "30px",
      color: "#1C1C1C",
    },
    body10: {
      fontWeight: "bold",
      fontStyle: "normal",
      fontSize: "45px",
      lineHeight: "60px",
      color: "#FFFFFF",
    },
    body11: {
      fontWeight: "bold",
      fontStyle: "normal",
      fontSize: "16px",
      lineHeight: "25px",
      color: "#1C1C1C",
    },
    body12: {
      fontWeight: "normal",
      fontStyle: "normal",
      fontSize: "18px",
      lineHeight: "30px",
      color: "#A4A4A4",
    },
    body13: {
      fontStyle: "normal",
      fontSize: "25px",
      lineHeight: "45px",
      color: "#1C1C1C",
    },
    body14: {
      fontWeight: "normal",
      fontStyle: "normal",
      fontSize: "13px",
      lineHeight: "19.5px",
      color: "#D9D9D9",
    },
    body15: {
      fontWeight: "bold",
      fontStyle: "normal",
      fontSize: "18px",
      lineHeight: "25px",
      color: "#202020",
    },
    body16: {
      fontWeight: "normal",
      fontStyle: "normal",
      fontSize: "14px",
      lineHeight: "25px",
      color: "#1C1C1C",
    },
    body17: {
      fontWeight: "normal",
      fontStyle: "normal",
      fontSize: "16px",
      lineHeight: "20.03px",
      color: "#FF7F00",
    },
    caption: {
      fontWeight: "normal",
      fontStyle: "normal",
      fontSize: "14px",
      lineHeight: "23px",
    },
  },
  palette: {
    primary: {
      main: "#FF7F00",
    },
    secondary: {
      main: "#191919",
    }
  },
});

MainTheme.typography.h1 = {
  ...MainTheme.typography.h1,
  [MainTheme.breakpoints.down("sm")]: {
    fontSize: "25px",
    lineHeight: "42px",
  },
};
MainTheme.typography.h2 = {
  ...MainTheme.typography.h2,
  [MainTheme.breakpoints.down("sm")]: {
    fontSize: "23px",
    lineHeight: "42px",
  },
};
MainTheme.typography.h5 = {
  ...MainTheme.typography.h5,
  [MainTheme.breakpoints.down("sm")]: {
    fontSize: "17px",
    lineHeight: "30px",
  },
};
MainTheme.typography.h6 = {
  ...MainTheme.typography.h6,
  [MainTheme.breakpoints.down("sm")]: {
    fontSize: "18px",
    lineHeight: "23px",
  },
};
MainTheme.typography.h13 = {
  ...MainTheme.typography.h13,
  [MainTheme.breakpoints.down("sm")]: {
    fontSize: "16px",
    lineHeight: "25px",
    color: "#A4A4A4",
  },
};
MainTheme.typography.h14 = {
  ...MainTheme.typography.h14,
  [MainTheme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
};
MainTheme.typography.h15 = {
  ...MainTheme.typography.h15,
  [MainTheme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
};
MainTheme.typography.h16 = {
  ...MainTheme.typography.h16,
  [MainTheme.breakpoints.down("sm")]: {
    fontSize: "13px",
  },
};
MainTheme.typography.h21 = {
  ...MainTheme.typography.h21,
  [MainTheme.breakpoints.down("sm")]: {
    fontSize: "20px",
  },
};
MainTheme.typography.h22 = {
  ...MainTheme.typography.h22,
  [MainTheme.breakpoints.down("sm")]: {
    fontSize: "15px",
    lineHeight: "26px",
  },
};
MainTheme.typography.h23 = {
  ...MainTheme.typography.h23,
  [MainTheme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
};
MainTheme.typography.body1 = {
  ...MainTheme.typography.body1,
  [MainTheme.breakpoints.down("sm")]: {
    fontSize: "17px",
    lineHeight: "35px",
  },
};

MainTheme.typography.body2 = {
  ...MainTheme.typography.body2,
  [MainTheme.breakpoints.down("sm")]: {
    fontSize: "13px",
    lineHeight: "30px",
  },
};
MainTheme.typography.body9 = {
  ...MainTheme.typography.body9,
  [MainTheme.breakpoints.down("sm")]: {
    fontSize: "17px",
  },
};
MainTheme.typography.body11 = {
  ...MainTheme.typography.body11,
  [MainTheme.breakpoints.down("sm")]: {
    fontSize: "13px",
  },
};
MainTheme.typography.body12 = {
  ...MainTheme.typography.body12,
  [MainTheme.breakpoints.down("sm")]: {
    fontSize: "14px",
    lineHeight: "26px",
  },
};
MainTheme.typography.body13 = {
  ...MainTheme.typography.body13,
  [MainTheme.breakpoints.down("sm")]: {
    fontSize: "17px",
    lineHeight: "35px",
  },
};
MainTheme.typography.body15 = {
  ...MainTheme.typography.body15,
  [MainTheme.breakpoints.down("sm")]: {
    fontSize: "16px",
    lineHeight: "35px",
  },
};
declare module "@mui/material/styles" {
  interface TypographyVariants {
    h7: React.CSSProperties;
    h8: React.CSSProperties;
    h9: React.CSSProperties;
    h10: React.CSSProperties;
    h11: React.CSSProperties;
    h12: React.CSSProperties;
    h13: React.CSSProperties;
    h14: React.CSSProperties;
    h15: React.CSSProperties;
    h16: React.CSSProperties;
    h17: React.CSSProperties;
    h18: React.CSSProperties;
    h19: React.CSSProperties;
    h20: React.CSSProperties;
    h21: React.CSSProperties;
    h22: React.CSSProperties;
    h23: React.CSSProperties;
    h24: React.CSSProperties;

    body3: React.CSSProperties;
    body4: React.CSSProperties;
    body5: React.CSSProperties;
    body6: React.CSSProperties;
    body7: React.CSSProperties;
    body8: React.CSSProperties;
    body9: React.CSSProperties;
    body10: React.CSSProperties;
    body11: React.CSSProperties;
    body12: React.CSSProperties;
    body13: React.CSSProperties;
    body14: React.CSSProperties;
    body15: React.CSSProperties;
    body16: React.CSSProperties;
    body17: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    h7?: React.CSSProperties;
    h8?: React.CSSProperties;
    h9?: React.CSSProperties;
    h10?: React.CSSProperties;
    h11?: React.CSSProperties;
    h12?: React.CSSProperties;
    h13?: React.CSSProperties;
    h14?: React.CSSProperties;
    h15?: React.CSSProperties;
    h16?: React.CSSProperties;
    h17?: React.CSSProperties;
    h18?: React.CSSProperties;
    h19?: React.CSSProperties;
    h20?: React.CSSProperties;
    h21?: React.CSSProperties;
    h22?: React.CSSProperties;
    h23?: React.CSSProperties;
    h24?: React.CSSProperties;

    body3?: React.CSSProperties;
    body4?: React.CSSProperties;
    body5?: React.CSSProperties;
    body6?: React.CSSProperties;
    body7?: React.CSSProperties;
    body8?: React.CSSProperties;
    body9?: React.CSSProperties;
    body10?: React.CSSProperties;
    body11?: React.CSSProperties;
    body12?: React.CSSProperties;
    body13?: React.CSSProperties;
    body14?: React.CSSProperties;
    body15?: React.CSSProperties;
    body16?: React.CSSProperties;
    body17?: React.CSSProperties;
  }
}
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    h7: true;
    h8: true;
    h9: true;
    h10: true;
    h11: true;
    h12: true;
    h13: true;
    h14: true;
    h15: true;
    h16: true;
    h17: true;
    h18: true;
    h19: true;
    h20: true;
    h21: true;
    h22: true;
    h23: true;
    h24: true;

    body3: true;
    body4: true;
    body5: true;
    body6: true;
    body7: true;
    body8: true;
    body9: true;
    body10: true;
    body11: true;
    body12: true;
    body13: true;
    body14: true;
    body15: true;
    body16: true;
    body17: true;
  }
}

import React, { useEffect } from "react";
import { useParams } from "react-router";
const Company = () => {
    const { company,type } = useParams();
    //Danal 본인인증 혹은 다른 인증들이 완료되면 Redirection이 필요합니다.
    //이를테면 앱에서 다날 본인인증을 진행하고 마무리 되면 특정 페이지로 리다이렉션 하면서 끝나게 됩니다.
    //마무리 페이지로 보여질 스크린입니다.
    //기능은 없습니다.
    //앱에서 webview로 띄어진 페이지에서 해당 스크린의 path를 listen하여 본인인증이 끝났는지 확인할때 사용합니다.
    //Danal 뿐만 아니라 다른 서비스가 추가되면 company를 추가하여 핸들링 합니다.
    useEffect(()=>{
        window.parent.postMessage({
            company: company,
            type: type
        },"*");
    },[]);
    return <div>
        {/* {company} {type}  */}
    </div>
}
export default Company;
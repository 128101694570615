import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { List, styled } from "@mui/material";
import * as React from "react";
import { ContainerFrame } from "../App";
import { FAQItem } from "../components/FAQItem";
import { FAQData} from "../data/FAQData";
import { useMediaQueryForMobile } from "../hooks/useMediaQueryForMobile";
import { MyPagination } from "../widgets/MyPagination";
import MySearchTextField from "../widgets/MySearchTextField";
import { MyTab } from "../widgets/MyTab";
import { MyTabList } from "../widgets/MyTabList";
import { getFaqInfo } from "../controller/api";
import { useHistory } from "react-router";
import { ContactUrl } from "../constants/urls";

export interface IFAQPageProps {}

interface AllQuestionProps {
  searchValue: string;
}

export const FAQPage: React.FC<IFAQPageProps> = ({}) => {
  const history = useHistory();
  const isMobile = useMediaQueryForMobile();
  const listStyle = isMobile
    ? { width: "100%", padding: 0, marginTop: 24 }
    : { width: "100%", padding: 0,marginTop:  36};


  const [faqList,setFaqList] = React.useState<any>([]);
  const [tabValues, setTabValues] = React.useState([]);
  const [currentTabIndex,setCurrentTabIndex] = React.useState(0);
  const [openTitle,setOpenTitle]=React.useState<string>("");
  const pageSize = 10;
  const [currentPage,setCurrentPage] = React.useState(1);
  const [maxPage,setMaxPage] = React.useState(10);
  const [searchValue, setSearchValue] = React.useState<string>("");
  let getFAQData: any=async()=>{
    let result=await getFaqInfo();
    let {data ,code, message} = result.data;
    let faqList = data[0].FaqList;
    let tabValues: any = ["전체",...Array.from(new Set(faqList.map((faq: any) => faq.Item2)))];
    setFaqList(faqList);
    setTabValues(tabValues);
  }

  React.useEffect(() => {
    setCurrentPage(1);
  }, [searchValue]);

  React.useEffect(()=>{
    getFAQData()
  },[])

  
  React.useEffect(()=>{
    window.scrollTo({
      top: 0,
    });
    setOpenTitle("");
  },[currentPage]);

  React.useEffect(()=>{
    let selectedFaqLength = (faqList.filter(faq => {
      if(tabValues[currentTabIndex]==="전체"){
        return true;
      }
      if(faq.Item2 == tabValues[currentTabIndex]){
        return true;
      }else{
        return false;
      }
    }).length);

    setMaxPage(
      Math.floor(selectedFaqLength / pageSize )+ (selectedFaqLength % pageSize ===0 ? 0 : 1)
    );
    setCurrentPage(1);
    setOpenTitle("");
  },[currentTabIndex,faqList,tabValues]);

  
 
  const handleChangeCurrentTab = (tabName,index) => {
    setCurrentTabIndex(index);
  }

  return (
    <FAQWrapper style={{height: "100%"}}  isMobile={isMobile} >
      <ContainerFrame >
        <div
          style={{
            color: "#1A1A1A",
            fontSize: isMobile ? 24 : 50,
            fontWeight: isMobile ? 700 : 800
          }
        }
        >
          무엇을 도와드릴까요?
        </div>
        <div style={{height: isMobile ? 16 : 44}} />
        <div style={{display: "flex"}} >
          <div 
            style={{
              borderRadius: 31,
              backgroundColor: "#F5F5F5",
              color: "#555",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              fontSize: isMobile ? 16 : 24,
              fontWeight: isMobile ? 600 : 700,
              padding: isMobile ? 10 : 16,
              lineHeight: isMobile ? "24px" : "30px"
            }} 
            onClick={()=>{
              history.push(ContactUrl);
            }}
          >
            <img src="/images/headsetIcon.png" style={{height: 20}} />
            <div
              style={{marginLeft: isMobile ? 4 : 18}}
            >
              1:1 문의하기
            </div>
            <img src="/images/iconArrowRegularForward.png" style={{height: 12, marginLeft: isMobile ? 4 : 12}} />
          </div>
        </div>
        <div style={{height: isMobile ? 40 : 80}} />
        <div 
          style={{
            fontSize: isMobile ? 16 : 24,
            fontWeight: isMobile ? 600 : 700,
            lineHeight: isMobile ? "24px" : "30px",
            color: "#1A1A1A"
          }} 
        >
          자주 묻는 질문이에요
        </div>
        <div style={{height: 16}} />
        {/* <MySearchTextField
          style={{ height: 150 }}
          defaultValue={searchValue}
          onChange={(e)=>{setSearchValue(e.target.value)}}
        /> */}
        <MenuBarContainer>
          <TabContext value={currentTabIndex.toString()}>
            <div
              style={{ 
                display: "flex",
                flexWrap: "wrap",
                width: "100%", 
                gap: 10,
                overflowX: "scroll",
                touchAction: "pan-x",
                ...(
                  isMobile
                  ?
                  {
                    fontSize: 14,
                    fontWeight: 600,
                    lineHeight: "24px"
                  }
                  :
                  {
                    fontSize: 16,
                    fontWeight: 500,
                    lineHeight: "24px"
                  }
                )
              }}
            >
              {
                tabValues.map((tabName,index) => <div 
                  onClick={()=>{
                    handleChangeCurrentTab(tabName,index);
                  }} 
                  style={{
                    cursor: "pointer",
                    padding: "6px 12px",
                    borderRadius: "4px",
                    backgroundColor: currentTabIndex===index ? "#FFF4E8" : "#F5F5F5",
                    color: currentTabIndex===index ? "#FF7F00" : "#939393",
                    touchAction: "pan-x",
                    flex: "0 0 auto"
                  }}
                >
                  {tabName}
                </div>)
              }
            </div>
              {/* {
                tabValues.map((tabName,index) => <MyTab 
                  label={tabName} 
                  value={index.toString()} 
                  onClick={()=>{
                    handleChangeCurrentTab(tabName,index);
                  }} 
                  style={{
                    touchAction: "pan-x"
                  }}
                />)
              } */}
            {/* <MyTabList
              style={{ width: "100%",overflowX: "scroll",touchAction: "pan-x" }}
              aria-label="lab API tabs example"
            >
            </MyTabList> */}
            <List style={listStyle}>
              {faqList.filter(faq => {
                if(tabValues[currentTabIndex]==="전체"){
                  return true;
                }
                if(faq.Item2 == tabValues[currentTabIndex]){
                  return true;
                }else{
                  return false;
                }
              }).sort((a,b)=>{
                if(a.Item1.indexOf(searchValue)!==-1){
                  return -1;
                }else{
                  return 1;
                }
              })
              // .filter((faq,faqIndex) => {
              //   if(pageSize * (currentPage - 1) <= faqIndex && pageSize * (currentPage) > faqIndex )return true;
              //   else return false;
              // })
              .map((faq: any, idx: any) => (
                <FAQItem
                  tabName={faq.Item2}
                  key={idx}
                  title={faq.Item1}
                  contents={faq.Item3}
                  searchValue={searchValue}
                  setOpenTitle={setOpenTitle}
                  openTitle={openTitle}
                />))}
            </List>
            {/* <MyPagination
                style={{ marginTop: isMobile ? 73 : 55 }}
                count={maxPage}
                defaultPage={currentPage}
                page={currentPage}
                onChange={(event, page) => {
                  setCurrentPage(page);
                }}
              /> */}
            <div style={{height: 80}} />
          </TabContext>
        </MenuBarContainer>
      </ContainerFrame>
    </FAQWrapper>
  );
};

const FAQWrapper = styled("div")(({ theme, isMobile }) => ({
  height: 1400,
  paddingTop: isMobile ? 60 : 150,
  [theme.breakpoints.down("sm")]: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));
const MenuBarContainer = styled("div")(({ theme }) => ({
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  width: "100%",
  paddingBottom: 20,
}));

const StyledTabPanel = styled(TabPanel)(({ theme }) => ({
  padding: "0px",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    padding: 0,
  },
}));
